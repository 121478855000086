import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnInit } from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";

@Component({
  selector: "modal-script",
  templateUrl: "./modal-script.component.html",
  styleUrls: ["./modal-script.component.scss"],
})
export class ModalScriptComponent implements OnInit {
  script = "";
  constructor(
    protected ref: NbDialogRef<ModalScriptComponent>,
    private clipboard: Clipboard,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {}
  copy() {
    this.clipboard.copy(this.script);
    this.toastrService.show("Copied to Clipboard", "Notification", {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
  }
  dismiss(status = false) {
    this.ref.close(status);
  }
}
