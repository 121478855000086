<form [formGroup]="callSettingForm" (submit)="onSubmit()">
  <nb-card class="setting-wrapper">
    <nb-card-header
      class="infor-header"
      style="padding: 0.5rem 1.5rem; padding-right: 0.7rem"
    >
      <div class="d-flex justify-content-between" style="align-items: center">
        <div class="title-page-wrapper">
          <span>Settings</span
          ><nb-icon
            nbTooltip="General settings of your flow."
            nbTooltipPlacement="right"
            nbTooltipTrigger="hover"
            nbTooltipStatus="basic"
            (click)="routerVariable()"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <button (click)="dismiss()" type="button" ghost size="medium" nbButton>
          <nb-icon icon="close-outline"></nb-icon>
        </button></div
    ></nb-card-header>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="General">
          <setting-incoming-call
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
            [languageDefault]="languageDefault"
            [phoneNumberList]="phoneNumberList"
            [botContactList]="botContactList"
          ></setting-incoming-call>
        </nb-tab>
        <nb-tab tabTitle="Text To Speech">
          <setting-language
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
          ></setting-language>
        </nb-tab>
        <nb-tab tabTitle="Speech Recognition">
          <setting-speech-to-text
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
          ></setting-speech-to-text>
        </nb-tab>
        <nb-tab tabTitle="Translate">
          <setting-translate
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
          ></setting-translate>
        </nb-tab>
        <nb-tab tabTitle="Chatbot">
          <setting-chatbot
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
          ></setting-chatbot>
        </nb-tab>
        <!-- <nb-tab tabTitle="Videobot">
          <setting-media
            [callSetting]="callSetting"
            [parentForm]="callSettingForm"
          ></setting-media>
        </nb-tab> -->
        <nb-tab tabTitle="Environment Variable" [active]="updateVariable">
          <setting-configure #configure></setting-configure>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-footer>
      <button
        [nbSpinner]="isLoading"
        nbSpinnerSize="small"
        nbSpinnerStatus="primary"
        type="submit"
        [disabled]="isLoading || isSaveLoading || isReadOnly == true"
        nbButton
        status="primary"
        size="small"
      >
        <nb-icon icon="save-outline"></nb-icon>
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</form>
