import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import { MxGraph } from "../../../@core/model/mxgraph";
import { DataService } from "../../../@core/utils/data.service";
import { EventBusService } from "../../../@core/utils/eventbus.service";
import { GraphHandlerService } from "../../../@core/utils/graph.service";
import {
  CheckExitsUserActionCommand,
  CreateUserActionCommand,
  UpdateUserActionCommand,
  UserActionClient,
  UserActionDto,
} from "../../../System-api";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../@core/utils/helpers";
import { NbPopoverDirective } from "@nebular/theme";

@Component({
  selector: "menu-app",
  templateUrl: "menu-app.component.html",
  styleUrls: ["./menu-app.component.scss"],
})
export class MenuAppComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnChanges
{
  @ViewChildren("userAction", { read: ElementRef })
  userAction: QueryList<ElementRef>;
  @Input() appItems: any = {};
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  currentDataSystem: any = [];
  currentDataUser: any;
  filteredDataSystem: any = [];
  filteredDataUser: any;
  userActionForm: UntypedFormGroup;
  userActionName: string;
  data: UserActionDto = new UserActionDto();
  graph: MxGraph;
  term: any;
  isEditActionName: string = "";
  currentName: string;
  newUserAction: any;
  openRemove = false;
  timeoutHandler: any;
  dataSelect = [];
  isExist: boolean = false;
  isMaxLength: boolean = false;
  showName: boolean = true;
  x: any;
  y: any;
  addUserActionDragDrop = false;
  search = "";
  constructor(
    private graphHandler: GraphHandlerService,
    private eventBusService: EventBusService,
    private userActionClient: UserActionClient,
    private dataService: DataService,
    private formBuilder: RxFormBuilder
  ) {
    this.eventBusService.on("getMxGraph", (graph: MxGraph) => {
      this.graph = graph;
    });
    this.eventBusService.on("getMxGraph", (graph: MxGraph) => {
      this.graph = graph;
    });
  }
  clearSearch() {
    this.search = "";
    this.currentDataSystem = this.filteredDataSystem;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["appItems"]) {
    }
  }
  scrollUp() {
    var menu = document.getElementById("system-action");

    if (menu.scrollTop === 0) {
    } else {
      menu.scrollTop = menu.scrollTop - 50;
    }
  }
  scrollDown() {
    var menu = document.getElementById("system-action");
    const elementHeight = menu.clientHeight;
    const scrollableHeight = menu.scrollHeight;
    const scrollTop = menu.scrollTop;
    if (scrollTop + elementHeight === scrollableHeight) {
    } else {
      menu.scrollTop = menu.scrollTop + 50;
    }
  }
  toggleMenu() {
    this.showName = !this.showName;
    this.currentDataSystem = this.filteredDataSystem;
  }
  show() {
    if (this.popover.isShown) {
      this.popover.hide();
    } else {
      this.popover.show();
    }
    this.appItems.user.forEach((application) => {
      let temp = { ...application };
      const element = document.getElementById("item-" + temp.id);
      if (element) {
        temp.id = temp.id.split("-")[0];
        this.graphHandler.addApplication(this.graph, element, temp);
      }
    });
  }
  handleFilter() {
    Object.keys(this.appItems).forEach((key) => {
      if (key != "user") {
        this.appItems[key].forEach((element) => {
          this.filteredDataSystem.push(element);
          this.currentDataSystem.push(element);
        });
      }
    });
  }
  ngOnInit() {
    this.createForm();
    this.handleFilter();
    this.filteredDataUser = this.appItems.user;
    this.currentDataUser = this.appItems.user;
    this.graphHandler.closeMenuAction.subscribe((flow) => {
      if (this.openRemove == true) {
        this.openRemove = false;
      }
    });
    this.graphHandler.reloadMenu.subscribe((flow) => {
      let checkExist = false;
      let position = 0;

      for (let index = 0; index < this.appItems.user.length; index++) {
        var idUserAction = this.appItems.user[index].id.split("-")[1];

        if (idUserAction == flow.id) {
          checkExist = true;
          position = index;
        }
      }
      this.newUserAction = {
        id: flow.applicationId + "-" + flow.id,
        name: flow.name,
        description: "",
        iconImage: flow.iconImage,
        data: JSON.parse(flow.data),
      };
      if (checkExist == true) {
        this.appItems.user[position] = this.newUserAction;
        this.dataService.userAction[position] = this.newUserAction;
      } else {
        this.appItems.user.unshift(this.newUserAction);
        this.dataService.userAction.unshift(this.newUserAction);
      }
      this.filteredDataUser = this.appItems.user;
      this.currentDataUser = this.appItems.user;
      const wait = setTimeout(() => {
        let temp = { ...this.newUserAction };
        const element = document.getElementById(
          "item-" + this.newUserAction.id
        );
        if (element) {
          temp.id = temp.id.split("-")[0];
          this.graphHandler.addApplication(this.graph, element, temp);
        }
        clearTimeout(wait);
      }, 0);
    });
  }

  removeAction(id) {
    const idUserAction = id.split("-")[1];
    this.userActionClient.delete(idUserAction).subscribe((rs) => {
      if (rs) {
        const element = document.getElementById("item-" + id);
        element.parentElement.classList.add("remove-animate");
        const waitAnimation = setTimeout(() => {
          for (let index = 0; index < this.appItems.user.length; index++) {
            if (this.appItems.user[index].id == id) {
              if (index != 0) {
                this.appItems.user.splice(index, 1);
                this.dataService.userAction.splice(index, 1);
              } else {
                this.appItems.user.shift();
                this.dataService.userAction.shift();
              }
            }
          }
          clearTimeout(waitAnimation);
        }, 60);
      }
    });
  }

  filterAction(event) {
    let val = event?.target?.value.toLowerCase();
    this.currentDataSystem = this.filteredDataSystem.filter(function (item) {
      if (item["name"].toString().toLowerCase().indexOf(val) !== -1 || !val) {
        return true;
      }
    });
  }
  filterUserAction(event) {
    let val = event?.target?.value.toLowerCase();
    this.currentDataUser = this.filteredDataUser.filter((item) => {
      if (item["name"].toString().toLowerCase().indexOf(val) !== -1 || !val) {
        return true;
      }
    });
  }
  ngAfterContentInit() {}
  ngAfterViewInit() {
    Object.keys(this.appItems).forEach((key) => {
      if (key != "user") {
        this.appItems[key].forEach((item) => {
          const element = document.getElementById("item-" + item.id);
          this.graphHandler.addApplication(this.graph, element, item);
        });
      }
    });
  }
  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
    }
  }
  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }
  createForm() {
    this.userActionForm = this.formBuilder.group({
      name: [""],
    });
  }
  handleInputBlur($event) {
    if (!this.isMaxLength) {
      var userAction = new UpdateUserActionCommand();
      userAction.id = Number(this.isEditActionName.split("-")[1]);
      userAction.name = $event.srcElement.value.trim();
      let data = new CheckExitsUserActionCommand();
      data.userActionName = userAction.name;
      this.userActionClient.checkExitsUserActionName(data).subscribe((rs) => {
        if (!rs) {
          if (userAction.name != "" && userAction.name != this.currentName) {
            this.userActionClient.update(userAction).subscribe((rs) => {
              if (rs != null) {
                this.graphHandler.reloadMenu.emit(rs);
              }
            });
          }
        }
      });
    }
    this.isEditActionName = "";
    this.currentName = "";
  }

  editActionName(action) {
    this.currentName = action.name.trim();
    this.isEditActionName = action.id;
    this.handleFocus(this.isEditActionName);
  }
  handleFocus(id) {
    setTimeout(() => {
      const element = document.getElementById("input-" + id);
      element.focus();
    }, 100);
  }

  checkUserActionExist(value) {
    clearTimeout(this.timeoutHandler);
    this.timeoutHandler = setTimeout(() => {
      let data = new CheckExitsUserActionCommand();
      data.userActionName = value.trim();
      this.userActionClient.checkExitsUserActionName(data).subscribe(
        (rs) => {
          if (rs && value?.trim() != this.currentName) {
            this.userActionForm.controls["name"].setErrors({
              isExist: rs,
              maxLength: false,
            });
            this.isExist = true;
          } else {
            this.isExist = false;
          }
        },
        (error) => {}
      );
    }, 1000);
  }

  checkUserActionName(value) {
    value = value?.trim();
    if (value?.length <= 35) {
      if (value != "" && value != this.currentName) {
        this.checkUserActionExist(value);
      }
      this.isMaxLength = false;
    } else {
      this.userActionForm.controls["name"].setErrors({
        isExist: false,
        maxLength: true,
      });
      this.isMaxLength = true;
    }
  }
}
