<div [formGroup]="renameFlowForm" data-target="#modal">
  <nb-card style="width: 450px">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        Rename Flow
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label class="form-label label required">Name</label>
        <input
          type="text"
          id="name"
          [(ngModel)]="name"
          nbInput
          fullWidth
          #inputName
          [defaultValue]="name"
          formControlName="name"
          placeholder="Flow Name"
          [status]="
            renameFlowForm.controls['name'].invalid &&
            (renameFlowForm.controls['name'].dirty ||
              renameFlowForm.controls['name'].touched)
              ? 'danger'
              : 'basic'
          "
          (keyup.enter)="rename()"
        />
        <ng-container
          *ngIf="
            renameFlowForm.controls['name'].invalid &&
            (renameFlowForm.controls['name'].dirty ||
              renameFlowForm.controls['name'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              renameFlowForm.controls['name'].hasError('required') ||
              renameFlowForm.controls['name'].hasError('whitespace')
            "
          >
            Flow Name is required
          </p>
          <p
            style="margin-bottom: 0"
            class="caption status-danger"
            *ngIf="renameFlowForm.controls['name'].errors.serverError"
          >
            {{ renameFlowForm.controls["name"].errors.serverError }}
          </p>
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              renameFlowForm.controls['name'].errors.maxlength &&
              !renameFlowForm.controls['name'].hasError('required') &&
              !renameFlowForm.controls['name'].hasError('whitespace')
            "
          >
            Maximum length is 50 characters.
          </p>
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              renameFlowForm.controls['name'].errors.regex &&
              !renameFlowForm.controls['name'].hasError('required') &&
              !renameFlowForm.controls['name'].hasError('whitespace') &&
              !renameFlowForm.controls['name'].errors.maxlength
            "
          >
            Flow Name cannot contain any of the following characters: \ / : *? "
            < > |
          </p>
        </ng-container>
      </div>
      <button
        class="float-end mt-2"
        nbButton
        size="small"
        type="button"
        [nbSpinner]="loadingButton"
        nbSpinnerSize="small"
        nbSpinnerStatus="info"
        status="primary"
        (click)="rename()"
        [disabled]="
          name.trim() === oldName || !renameFlowForm.valid || loadingButton
        "
      >
        Confirm
      </button>
    </nb-card-body>
  </nb-card>
</div>
