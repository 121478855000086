import { IApplicationData } from "./common";
export class Cards implements IApplicationData {
  constructor() {
    this.name = "Cards";
    this.option = "static";
    this.imageRatio = "horizontal";
    this.output = "";
    this.dynamicCards = "";
    this.cards = "[]";
    this.multipleCase = true;
    this.transition = true;
  }
  name: string;
  option: string;
  cards: string;
  imageRatio: string;
  dynamicCards: string;
  output: string;
  multipleCase: boolean;
  transition: boolean;
}
