import { Component, HostListener } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "nb-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent {
  question: string;
  textYes: string;
  textNo: string;
  statusYes: string;
  statusNo: string;

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.dismiss();
  }

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {}
  dismiss() {
    this.ref.close(false);
  }
  onSubmit() {
    this.ref.close(true);
  }
}
