<nb-card style="margin-bottom: 0; height: 90vh">
  <nb-card-header style="padding: 0.25rem 1rem; padding-right: 0.25rem">
    <div class="d-flex justify-content-between" style="align-items: center">
      <div class="title-page-wrapper">
        <span>{{ name }}</span>
      </div>
      <div
        class="d-flex justify-content-between"
        style="align-items: center; gap: 0.5rem"
      >
        <button
          (click)="reload()"
          type="button"
          type="button"
          ghost
          size="medium"
          title="Reload Conversation"
          nbButton
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
        <button
          (click)="dismiss()"
          type="button"
          type="button"
          ghost
          size="medium"
          nbButton
        >
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div></div
  ></nb-card-header>
  <nb-card-body
    style="overflow: hidden; padding: 0; display: flex"
    [ngClass]="{ 'width-card': show }"
  >
    <nb-card style="margin-bottom: 0; border: 0; border-radius: 0">
      <nb-card-body
        style="
          border: 0;
          border-radius: 0;
          overflow: hidden;
          padding: 0;
          display: flex;
        "
      >
        <div
          #listElement
          style="
            height: 100%;
            overflow: scroll;
            padding: 1rem;
            padding-top: 0;
            width: 550px;
          "
        >
          <div
            *ngIf="message.length == 0"
            style="font-size: 13.5px; padding-top: 1rem; padding-left: 0.5rem"
          >
            <span>No content. Please sent anythings message to the </span>
            <span>{{ name }}</span>
          </div>
          <div
            class="chat-area"
            *ngFor="let msg of message"
            [ngClass]="{ 'chat-area-user': msg.type == 'user' }"
          >
            <div
              class="chat-box"
              [ngClass]="{ 'chat-box-user': msg.type == 'user' }"
            >
              <span class="chat-name" *ngIf="msg.type == 'user'">You</span>
              <span class="chat-name" *ngIf="msg.type == 'system'">{{
                name
              }}</span>
              <span
                class="chat-text"
                *ngIf="msg.type == 'system'"
                [ngClass]="{ 'chat-error': msg.status == 'error' }"
              >
                <pre
                  style="margin-bottom: 0 !important"
                  [innerHTML]="msg.content | prettyjson"
                ></pre>
              </span>
              <span class="chat-text-user" *ngIf="msg.type == 'user'">
                <pre
                  style="margin-bottom: 0 !important"
                  [innerHTML]="msg.content | prettyjson"
                ></pre>
              </span>
            </div>
          </div>
          <div class="chat-area" *ngIf="isLoading">
            <div class="chat-box">
              <span class="chat-name">{{ name }}</span>
              <div class="typing-indicator">
                <span></span>
              </div>
            </div>
          </div></div
      ></nb-card-body>
      <nb-card-footer style="display: flex; gap: 0.5rem; padding-right: 0.5rem">
        <input
          #inputString
          nbInput
          type="text"
          placeholder="Type a message here"
          fullWidth
          (keyup.enter)="send()"
          [(ngModel)]="msg"
          [disabled]="isLoading"
        />
        <button
          nbButton
          ghost
          status="primary"
          (click)="send()"
          [disabled]="isLoading"
        >
          <nb-icon icon="navigation-2"></nb-icon>
        </button>
      </nb-card-footer>
    </nb-card>
    <div style="width: 500px" *ngIf="show">
      <textarea
        style="
          height: 100%;
          resize: none;
          padding-top: 0.5rem;
          border-radius: 0;
          border: 0 !important;
          background-color: #edf1f7 !important;
        "
        #inputString
        nbInput
        type="text"
        [placeholder]="name"
        fullWidth
        [(ngModel)]="data.prompt"
        fieldSize="small"
        [mentionConfig]="mentionConfig"
      ></textarea>
    </div>
  </nb-card-body>
</nb-card>
