<nb-card
  style="width: 80vw; height: 90vh; margin-bottom: 0"
  [nbSpinner]="isLoadingUpdate"
  nbSpinnerSize="medium"
  nbSpinnerStatus="primary"
>
  <nb-card-header class="infor-header">
    <div class="d-flex justify-content-between">
      Media
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-tabset #tabset (changeTab)="yourFunc($event)">
      <nb-tab tabTitle="UPLOAD FILE">
        <div>
          <uppy-dashboard [uppy]="uppy"> </uppy-dashboard>
        </div>
      </nb-tab>
      <nb-tab tabTitle="FROM URL">
        <div class="media-wrapper">
          <div style="width: 100%">
            <label for="url-image" class="form-label label">URL</label>
            <input
              type="text"
              nbInput
              fullWidth
              [(ngModel)]="urlData"
              [mentionConfig]="mentionConfig"
              placeholder="https://"
            />
          </div>
          <div *ngIf="urlData">
            <img
              style="height: 400px; object-fit: cover"
              [src]="urlData || '../../../assets/images/default-error.jpg'"
              (error)="handleImageError($event)"
              alt=""
            />
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="FROM GALLERY">
        <div class="d-flex content-wrapper">
          <div class="content-left-wrapper">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 5px;
              "
            >
              <input
                type="text"
                fieldSize="tiny"
                nbInput
                placeholder="Search"
                name="search"
                autocomplete="off"
                style="margin-right: 8px"
                [(ngModel)]="searchValue"
                (input)="changeSearchValue($event)"
              />
              <div style="display: flex; gap: 0.5rem">
                <nb-select
                  *ngIf="allowUploadVideo"
                  placeholder="Select Media"
                  size="tiny"
                  [(ngModel)]="filterType"
                  (selectedChange)="selectFilterType($event)"
                >
                  <nb-option style="padding-left: 10px !important" value="all"
                    >All Media</nb-option
                  >
                  <nb-option style="padding-left: 10px !important" value="image"
                    >Images</nb-option
                  >
                  <nb-option style="padding-left: 10px !important" value="video"
                    >Videos</nb-option
                  >
                </nb-select>
                <div>
                  <input
                    [(ngModel)]="filter"
                    nbInput
                    status="basic"
                    placeholder="Pick Date Range"
                    fieldSize="tiny"
                    [nbDatepicker]="formpicker"
                    readonly
                  />
                  <nb-rangepicker
                    (rangeChange)="filterDate($event)"
                    #formpicker
                  ></nb-rangepicker>
                  <button
                    status="basic"
                    nbButton
                    size="tiny"
                    (click)="clearDatePickerFilter()"
                    [disabled]="!isFiltered"
                  >
                    <nb-icon icon="close-outline"></nb-icon>
                  </button>
                </div>
                <button
                  status="primary"
                  nbButton
                  size="tiny"
                  (click)="fileInput.click()"
                >
                  <nb-icon icon="plus-outline"></nb-icon>Create
                </button>
                <input
                  #fileInput
                  accept="{{ extensionUpload }}"
                  type="file"
                  style="display: none"
                  (change)="onFileSelected(fileInput.files)"
                />
              </div>
            </div>
            <div *ngIf="medias.length > 0" style="overflow: hidden">
              <div class="d-flex" style="padding: 1rem 0 0.5rem 0">
                <div class="content-left">
                  <div
                    *ngFor="let item of medias; let i = index"
                    class="image-container"
                  >
                    <img
                      *ngIf="item.type == 'image'"
                      [src]="
                        item.path
                          ? item.path
                          : '../../../assets/images/default-error.jpg'
                      "
                      alt="image"
                      class="template-image"
                      [ngClass]="{ active: checkItems[item.id] }"
                      (click)="showImageDetail(item)"
                      (error)="handleImageError($event)"
                    />
                    <img
                      *ngIf="item.type == 'video' && !item.path"
                      [src]="'../../../assets/images/default-video.jpg'"
                      (error)="handleImageError($event)"
                      alt="video-placeholder"
                      class="template-image"
                    />
                    <video
                      *ngIf="item.type == 'video' && item.path"
                      src="{{ item.path }}"
                      class="template-image"
                      controls
                      [ngClass]="{ active: checkItems[item.id] }"
                      (click)="showImageDetail(item)"
                    ></video>
                    <button
                      nbButton
                      class="tick-mark"
                      *ngIf="checkItems[item.id]"
                      type="button"
                      size="tiny"
                      status="primary"
                      shape="round"
                      style="pointer-events: none"
                    >
                      <nb-icon icon="checkmark"></nb-icon>
                    </button>
                    <a
                      data-fancybox="gallery"
                      [attr.data-caption]="
                        item.path > 200
                          ? (item.path | slice : 0 : 200) + '...'
                          : item.path.split('/').pop()
                      "
                      [href]="item.path"
                    >
                      <nb-icon
                        class="zoom-icon"
                        icon="expand-outline"
                      ></nb-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="medias.length == 0" class="no-media">
              <img
                [src]="'../../../assets/images/default_no_media.svg'"
                alt="no-media"
                style="width: 150px; height: 150px; object-fit: cover"
              />
              <strong>You don't have any media.</strong>
            </div>
          </div>
          <div class="content-right" *ngIf="medias.length > 0 && imgSelected">
            <div *ngIf="imgSelected && imgSelected?.type == 'image'">
              <span class="form-label label" style="font-size: 12px"
                >ATTACHMENT DETAILS</span
              >
              <a
                data-fancybox="gallery"
                [attr.data-caption]="
                  imgSelected.path > 200
                    ? (imgSelected.path | slice : 0 : 200) + '...'
                    : imgSelected.path.split('/').pop()
                "
                [href]="imgSelected.path"
              >
                <img
                  [src]="
                    imgSelected.path
                      ? imgSelected.path
                      : '../../../assets/images/default-error.jpg'
                  "
                  alt="image"
                  style="cursor: pointer"
                  (error)="handleImageError($event)"
                />
              </a>
              <span
                class="form-label label"
                style="display: block; word-break: break-all"
                >{{ imgSelected.name }}</span
              >
              <span class="img-detail">{{ imgSelected.created }}</span>
              <span class="img-detail">{{ imgSelected.size }} KB</span>
              <span class="img-detail"
                >{{ imgSelected.imageWidth }} by
                {{ imgSelected.imageHeight }} pixels</span
              >
              <span class="delete-image" (click)="deleteImage('item', imgSelected?.type)"
                >Delete Image</span
              >
            </div>
            <div *ngIf="imgSelected && imgSelected?.type == 'video'">
              <span class="form-label label" style="font-size: 12px"
                >ATTACHMENT DETAILS</span
              >
              <a
                data-fancybox="gallery"
                [attr.data-caption]="
                  imgSelected.path > 200
                    ? (imgSelected.path | slice : 0 : 200) + '...'
                    : imgSelected.path.split('/').pop()
                "
                [href]="imgSelected.path"
              >
                <video controls src="{{ imgSelected.path }}"></video>
              </a>
              <span
                class="form-label label"
                style="display: block; word-break: break-all"
                >{{ imgSelected.name }}</span
              >
              <span class="img-detail">{{ imgSelected.created }}</span>
              <span class="img-detail">{{ imgSelected.size }} KB</span>
              <span class="img-detail">Length: {{ imgSelected.length }}</span>
              <span class="delete-image" (click)="deleteImage('item', imgSelected?.type)"
                >Delete Video</span
              >
            </div>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; align-items: center">
      <div
        *ngIf="currentTab == 'FROM GALLERY'"
        style="
          display: flex;
          width: 840px;
          align-items: center;
          justify-content: space-between;
        "
      >
        <nb-checkbox
          (checkedChange)="allowSelectMany($event)"
          [checked]="selectMany"
          status="basic"
          >Delete Many</nb-checkbox
        >
        <div
          *ngIf="selectMany && amountImagesChoose > 0"
          style="gap: 0.5rem; display: flex; align-items: center"
        >
          <span>{{ amountImagesChoose }} item selected</span>
          <button
            type="button"
            outline
            size="small"
            status="basic"
            nbButton
            (click)="deleteImage('array', 'media')"
          >
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          width: 220px;
          justify-content: end;
          gap: 0.5rem;
        "
      >
        <button
          type="button"
          nbButton
          status="basic"
          size="small"
          (click)="dismiss()"
        >
          <nb-icon icon="close-outline"></nb-icon>Cancel
        </button>
        <button
          [disabled]="
            currentTab === 'FROM GALLERY' &&
            (amountImagesChoose > 1 || !imgSelected)
          "
          type="button"
          nbButton
          status="primary"
          size="small"
          (click)="save()"
        >
          <nb-icon icon="save-outline"></nb-icon> Save
        </button>
      </div>
    </div></nb-card-footer
  >
</nb-card>
