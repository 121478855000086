<nb-card
  style="width: 600px; max-height: 70vh; margin-bottom: 0"
  status="basic"
>
  <nb-card-body style="padding-bottom: 1.5rem; padding-top: 0.5rem">
    <div class="container-item">
      <div class="item-infor-col" style="width: 100%">
        <div class="label-script">
          <label class="form-label label" style="margin-bottom: 0"
            >Script</label
          >
          <button
            matTooltip="Copy"
            matTooltipPosition="left"
            nbButton
            status="basic"
            ghost
            size="small"
            class="copy-button"
            (click)="copy()"
          >
            <nb-icon icon="copy"></nb-icon>
          </button>
        </div>
        <textarea
          style="min-height: 250px; max-height: 400px"
          nbInput
          fullWidth
          [(ngModel)]="script"
        ></textarea>
      </div>
    </div>
  </nb-card-body>
</nb-card>
