<div class="action-config-wrapper" style="min-width: 500px; max-width: 550px">
  <div class="config-action">
    <div class="row mb-4">
      <label class="form-label label required">Flow</label>
      <form [formGroup]="formGroup">
        <div class="d-flex custom-gap">
          <ng-select
            formControlName="callflow"
            [nbSpinner]="isLoading"
            nbSpinnerSize="medium"
            nbSpinnerStatus="info"
            [items]="callflows"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="data.callFlowId"
            (change)="setFlow($event)"
            placeholder="Select Flow"
            [ngClass]="{
              isInvalid:
                formGroup.controls['callflow'].invalid &&
                (formGroup.controls['callflow'].dirty ||
                  formGroup.controls['callflow'].touched)
            }"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-index="index"
              let-search="searchTerm"
            >
              <div class="function-item">
                <span>{{ item.name }}</span>
                <nb-icon
                  icon="edit-2-outline"
                  style="font-size: 15px"
                  (click)="viewSubFlow(item.id)"
                ></nb-icon>
              </div>
            </ng-template>
          </ng-select>
          <button
            type="button"
            [disabled]="!data.callFlowId"
            size="small"
            status="primary"
            nbButton
            (click)="viewSubFlow(data.callFlowId)"
          >
            <nb-icon icon="edit-2-outline"></nb-icon>
          </button>
          <button
            type="button"
            size="small"
            status="primary"
            nbButton
            (click)="createFlow()"
          >
            <nb-icon icon="plus-outline"></nb-icon>
          </button>
        </div>
        <ng-container
          *ngIf="
            formGroup.controls['callflow'].invalid &&
            (formGroup.controls['callflow'].dirty ||
              formGroup.controls['callflow'].touched)
          "
        >
          <p
            class="caption status-danger mt-1"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['callflow'].hasError('required') ||
              formGroup.controls['callflow'].hasError('whitespace')
            "
          >
            Flow is required.
          </p>
        </ng-container>
      </form>
    </div>

    <nb-tabset *ngIf="data.callFlowId" class="form-group">
      <nb-tab tabTitle="Input">
        <div style="padding: 1rem 0 0 0">
          <div class="mb-2">
            <ngx-input-static
              [disabledAddNew]="true"
              [maxHeight]="false"
              [selectAttribute]="true"
              [propertyInput]="data.inputOption"
              propertyName="Sub Variable"
              propertyValue="Value"
              (propertyEmit)="getInputAttribute($event)"
              [filteredOptions$]="filteredOptions$"
              [allfilteredOptions]="subflowVariable"
            ></ngx-input-static>
          </div></div
      ></nb-tab>
      <nb-tab
        *ngIf="allowLoad"
        tabTitle="Output"
        style="overflow-x: hidden !important"
      >
        <div style="padding: 1rem 0 0 0">
          <div *ngFor="let variable of outputOption; let i = index">
            <div class="mb-2">
              <div class="row">
                <div class="col-sm-5">
                  <label class="form-label label">Variable</label>
                  <ngx-input-value-static
                    placeHolder="Variable"
                    selectAttribute="true"
                    [propertyInput]="variable.name"
                    (propertyEmit)="getVariableName($event, i)"
                  ></ngx-input-value-static>
                </div>
                <div class="col-sm-5">
                  <label class="form-label label">Sub Variable</label>
                  <ng-select
                    size="small"
                    [addTag]="addTagFn"
                    addTagText="Custom variable"
                    [items]="filteredOptions$ | async"
                    fullWidth
                    bindLabel="value"
                    bindValue="value"
                    placeholder="Sub Variable"
                    [(ngModel)]="variable.value"
                    [editableSearchTerm]="true"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                      >{{ item.value }}
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-md-1" style="margin-top: 1.7rem">
                  <button
                    (click)="removeOutput(i)"
                    type="button"
                    ghost
                    size="small"
                    nbButton
                  >
                    <nb-icon icon="minus-circle-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="mb-2">
              <button
                type="button"
                size="small"
                status="primary"
                (click)="addOutput()"
                class="small mt-2"
                nbButton
              >
                <nb-icon icon="plus-outline"></nb-icon>
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
