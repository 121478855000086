import { Component, Input, OnInit, Optional, ViewChild } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../../@core/utils/helpers";
import {
  CallFlowClient,
  RenameCallFlowCommand,
} from "../../../../../System-api";

@Component({
  selector: "ngx-rename-flow-modal",
  templateUrl: "./rename-flow-modal.component.html",
  styleUrls: ["./rename-flow-modal.component.scss"],
})
export class RenameFlowModalComponent implements OnInit {
  @Input() id: number;
  @Input() name: string;
  @ViewChild("inputName") inputName;
  oldName: string;
  renameFlowForm: UntypedFormGroup;
  loadingButton: boolean = false;
  constructor(
    @Optional() private ref: NbDialogRef<RenameFlowModalComponent>,
    private formBuilder: RxFormBuilder,
    private toastrService: NbToastrService,
    private callFlowClient: CallFlowClient
  ) {
    this.createForm();
  }

  createForm() {
    this.renameFlowForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          Validators.maxLength(50),
          RxwebValidators.pattern({
            expression: {
              regex: /^[^\\\/:*?"<>|]+$/,
            },
          }),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.oldName = this.name;
  }

  dismiss() {
    this.ref.close();
  }

  rename() {
    if (this.renameFlowForm.valid && this.name.trim() !== this.oldName) {
      this.loadingButton = true;
      const renameRequest = new RenameCallFlowCommand({
        id: this.id,
        name: this.name.trim(),
      });
      this.callFlowClient.renameFlow(renameRequest).subscribe({
        next: (rs) => {
          this.loadingButton = false;
          if (rs) {
            this.ref.close(rs);
          } else {
            this.showToast("Rename flow unsuccessfully", "danger");
          }
        },
        error: (error) => {
          if (error.status == 422) {
            this.setValidationErrors(error.response);
          }
          this.loadingButton = false;
          this.handleFocus();
        },
      });
    }
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.renameFlowForm.get(fieldName)) {
          const control = this.renameFlowForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  showToast(text, status) {
    this.toastrService.show(text, `Notification`, {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: status,
    });
  }
}
