<nb-card>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      Logs
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button></div
  ></nb-card-header>
  <nb-card-body style="padding-bottom: 0">
    <div
      class="mb-2 mt-2 d-flex flex-row-reverse justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center justify-content-end">
        <ng-select
          #callIdSelect
          [items]="callIdList"
          bindLabel="callId"
          bindValue="callId"
          [(ngModel)]="currentCallId"
          (change)="getLog()"
          placeholder="Select Call ID"
          class="custom-input"
          status="primary"
          (clear)="clearLog()"
          [editableSearchTerm]="true"
          [nbSpinner]="loading"
          nbSpinnerSize="small"
          nbSpinnerStatus="primary"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="function-item">
              <span>{{ item.callId }}</span>
            </div>
          </ng-template>
        </ng-select>

        <!-- <button
            nbButton
            status="basic"
            class="me-2 button-basic"
            (click)="export()"
            size="small"
            [nbSpinner]="isLoading"
            nbSpinnerSize="small"
            nbSpinnerStatus="primary"
            [disabled]="disabled || isLoading"
          >
            <nb-icon icon="log-out-outline"></nb-icon>Export
          </button> -->

        <button
          title="Clear all log"
          status="primary"
          type="button"
          nbButton
          size="small"
          (click)="clear()"
          [disabled]="isReadOnly == true"
          class="me-2"
        >
          Clear
        </button>
        <button
          title="Open CDR"
          status="primary"
          type="button"
          nbButton
          size="small"
          (click)="openCDR()"
          [disabled]="isReadOnly == true || cdrDisabled"
          class="me-2"
        >
          CDR
        </button>
        <button
          title="Reload"
          status="primary"
          type="button"
          nbButton
          size="small"
          (click)="reloadCallID()"
        >
          Reload
        </button>
      </div>
      <div
        *ngIf="from && this.currentCallId !== 'All Log'"
        class="ml-2 d-flex flex-row align-items-center"
        style="gap: 1rem; margin-left: 7px"
      >
        <div class="d-flex flex-row align-items-center">
          <div
            class="log-label"
            [title]="logType"
            style="
              font-size: 13px;
              display: flex;
              align-items: center;
              gap: 0.2rem;
            "
          >
            <span style="text-transform: uppercase">{{ logType }}</span>
          </div>
        </div>
        <div style="height: 25px; border-left: 2px solid #eeeff5"></div>
        <div class="d-flex flex-row align-items-center" style="gap: 0.25rem">
          <label class="log-label">From:</label>
          <span [title]="from" style="font-size: 13px"> {{ from }}</span>
        </div>
        <div style="height: 25px; border-left: 2px solid #eeeff5"></div>
        <div class="d-flex flex-rowalign-items-center" style="gap: 0.25rem">
          <label class="log-label">To:</label>
          <span [title]="to" style="font-size: 13px"> {{ to }}</span>
        </div>
        <!-- <div class="d-flex flex-row gap-3 align-items-center">
          <label class="log-label">Type</label>
          <span [title]="logType" style="font-size: 13px">{{ logType }}</span>
        </div> -->
      </div>
    </div>
    <log-list
      [data]="logList"
      (disabled)="setDisabledExport($event)"
      (updateLoading)="updateLoading($event)"
      (dataLogExport)="dataLogExport($event)"
      (updateLogInformation)="updateLogInformation($event)"
      class="flex-grow-1 w-100"
    >
    </log-list>
  </nb-card-body>
</nb-card>
