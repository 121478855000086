import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Directive,
  HostListener,
} from "@angular/core";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { Observable, Subject, Subscription, map, of } from "rxjs";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { VoiceMail } from "../../../../@core/model/voicemail";
import { DataService } from "../../../../@core/utils/data.service";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { TextToSpeechModalComponent } from "../../../../shared/text-to-speech-modal/text-to-speech-modal.component";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { AudioClient } from "../../../../System-api";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { ChangeDetectorRef } from "@angular/core";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
@Component({
  selector: "ngx-voice-mail",
  templateUrl: "./voice-mail.component.html",
  styleUrls: ["./voice-mail.component.scss"],
})
export class VoiceMailComponent implements OnInit, OnDestroy {
  @ViewChild("audioStartPlayer", {
    static: true,
  })
  audioStartPlayer: SimpleAudioPlayerComponent;

  @ViewChild("textToSpeechStart", {
    static: true,
  })
  textToSpeechStart: TextToSpeechComponent;

  @ViewChild("autoInput") input;

  cell: MxCell;
  graph: MxGraph;
  data: VoiceMail = new VoiceMail();
  dataTTSStart: any = [];
  dataTTSEnd: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech" },
  ];
  type: string = "select";
  selectedStartSubscription: Subscription;
  eventsSubjectStart: Subject<void> = new Subject<void>();
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<string[]>;
  cancelAction = false;
  errorMessage: string = "Audio Text is required.";
  formGroup: UntypedFormGroup;
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    public dataService: DataService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    private cdr: ChangeDetectorRef
  ) {
    this.createForm();
  }
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.text = "";
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      duration: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  allowSpeechToText($event) {
    this.data.speechtotext = $event;
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    this.filteredOptions$ = of(this.attributes);
    this.selectedStartSubscription =
      this.audioStartPlayer.selectedAudio.subscribe((audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      });
    this.selectedStartSubscription =
      this.textToSpeechStart.selectedTTS.subscribe((tts) => {
        this.data.content = JSON.stringify(tts);
      });

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }

    if (!this.data.option) {
      let audioStartId = this.cell.getAttribute("audioStartId");
      let audioStartPath = this.cell.getAttribute("audioStartPath");
      let textStart = this.cell.getAttribute("textStart");
      let optionStart = this.cell.getAttribute("optionStart");
      this.data.audioId = audioStartId ? audioStartId : "";
      this.data.audioPath = audioStartPath ? audioStartPath : "";
      this.data.text = textStart ? textStart : "";
      this.data.option = optionStart ? optionStart : 1;
    }

    if (this.data.audioId && this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioStartPlayer.listAudioInAction = audioVm.audios;
        this.audioStartPlayer.playSelectedAudio(this.data.audioId);
      });
    }

    if (this.data.option && this.data.option !== null) {
      this.handleAudioStart(Number(this.data.option));
    }
    if (this.data.timeout?.trim() == "" || !this.data.timeout) {
      this.data.timeout = "5";
    }
    this.convertToNewDataFormat();
    if (this.data.content) {
      this.textToSpeechStart.selectCurrentTTS(this.data.content);
    }
    if (this.data.speechtotext) {
      this.data.speechtotext = this.data.speechtotext.toString() === "true";
    } else {
      this.data.speechtotext = false;
    }
    if (this.data.playBeep === null || this.data.playBeep === undefined) {
      this.data.playBeep = true;
    }
    this.audioStartPlayer.showAddButton = true;
    this.audioStartPlayer.isCallFlowAction = true;

    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleOnlyIntegerNumber(event) {
    if (event.target?.value != null) {
      var number = event.target.value.toString();
      if (number.match("^[0-9]*$")) {
        this.data[event.target.attributes.id.value] = number;
      }
    }
  }
  handleAudioStart(value: number) {
    this.data.option = value;
  }
  handleSentiment($event) {
    this.data.sentiment = $event;
  }
  showModifyStart() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectStart.next(rs);
    });
  }
  ngOnDestroy() {
    this.selectedStartSubscription.unsubscribe();
  }

  submit() {
    if (!this.cancelAction) {
      if (
        this.data.maxDuration == "" ||
        !this.data.maxDuration ||
        parseFloat(this.data.maxDuration) < 0 ||
        !Number.isInteger(parseFloat(this.data.maxDuration))
      ) {
        this.data.maxDuration = "";
      }
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        if (key === "playBeep") {
          this.cell.setAttribute(key, value);
        } else {
          this.cell.setAttribute(key, value || "");
        }
      }
      this.windowRef.close();
      const voiceMailCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.maxDuration.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        voiceMailCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.cdr.detectChanges();
          this.formGroup.get("variable").setValue(rs);
        }
      });
  }
  private filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.attributes.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }

  onModelChange(value: string) {
    this.filteredOptions$ = of(this.filter(value));
  }

  getAttribute($event) {
    this.data.attribute = $event;
  }

  getPathAttribute($event) {
    this.data.pathAttribute = $event;
  }
}
