<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          class="choice-option mt-2"
          [(ngModel)]="data.option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div
          class="form-group"
          [ngClass]="{ show: data.option == 1 }"
          style="padding: 1rem 0; width: 100%"
        >
          <text-to-speech #textToSpeech></text-to-speech>
          <nb-card class="select-audio" [ngClass]="{ show: data.option == 1 }">
            <nb-card-header class="audio-list-style">
              <span>Audio List</span>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></nb-card-header>
            <nb-card-body>
              <simple-audio-player
                #audioPlayer
                [events]="eventsSubject.asObservable()"
              ></simple-audio-player>
              <button
                nbButton
                size="small"
                status="primary"
                (click)="showModify()"
              >
                <nb-icon icon="plus-outline"></nb-icon>
                Add
              </button>
            </nb-card-body>
          </nb-card>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="flex-title">
          <span class="title">Config</span>
          <span class="line"></span>
        </div>
        <div class="display-input mb-2 mt-2">
          <div class="input-width">
            <label
              for="initialSilence"
              class="form-label label d-flex"
              style="align-items: center"
            >
              <span>Initial Silence (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is maximum initial silence duration before greeting. 
                              If this is exceeded, the result is detection as a MACHINE"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="initialSilence"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Initial Silence"
                [(ngModel)]="data.initialSilence"
              />
            </div>
          </div>

          <div class="input-width">
            <label
              for="greeting"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Greeting (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the maximum length of a greeting.
                              If this is exceeded, the result is detection as a MACHINE"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="greeting"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Greeting"
                pattern="^[0-9]+$"
                [(ngModel)]="data.greeting"
              />
            </div>
          </div>
        </div>
        <div class="display-input mb-2">
          <div class="input-width">
            <label
              for="afterGreetingSilence"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>After Greeting Silence (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the silence after detecting a greeting.
                              If this is exceeded, the result is detection as a HUMAN"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="afterGreetingSilence"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="After Greeting Silence"
                [(ngModel)]="data.afterGreetingSilence"
              />
            </div>
          </div>
          <div class="input-width">
            <label
              for="totalAnalysisTime"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Total Analysis Time (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the maximum time allowed for the algorithm
                              to decide on whether the audio represents a HUMAN, or a MACHINE"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="totalAnalysisTime"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Total Analysis Time"
                [(ngModel)]="data.totalAnalysisTime"
              />
            </div>
          </div>
        </div>
        <div class="display-input mb-2">
          <div class="input-width">
            <label
              for="miniumWordLength"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Minimum Word Length (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the minimum duration of Voice considered to be a word"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="miniumWordLength"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Minimum Word Length"
                [(ngModel)]="data.miniumWordLength"
              />
            </div>
          </div>
          <div class="input-width">
            <label
              for="maximumWordLength"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Maximum Word Length (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the maximum duration of a word to accept.
                          If exceeded, then the result is detection as a MACHINE"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="maximumWordLength"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Maximum Word Length"
                [(ngModel)]="data.maximumWordLength"
              />
            </div>
          </div>
        </div>
        <div class="display-input mb-2">
          <div class="input-width">
            <label
              for="maximumNumberOfWords"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Maximum Number Of Words</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the maximum number of words in a greeting
                              If this is exceeded, then the result is detection as a MACHINE"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="maximumNumberOfWords"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Maximum Number Of Words"
                [(ngModel)]="data.maximumNumberOfWords"
              />
            </div>
          </div>
          <div class="input-width">
            <label
              for="betweenWordsSilence"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Between Words Silence (ms)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Is the minimum duration of silence after a word to consider the audio that follows to be a new word"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="betweenWordsSilence"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Between Words Silence"
                [(ngModel)]="data.betweenWordSilence"
              />
            </div>
          </div>
        </div>
        <div class="display-input mb-2">
          <div class="input-width">
            <label
              for="silenceThreshold"
              class="form-label label d-flex"
              style="align-items: center"
              ><span>Silence Threshold (dB)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="What is the average level of noise from 0 to 32767 which if not exceeded, should be considered silence?"
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <div class="display-input">
              <input
                id="silenceThreshold"
                type="number"
                fieldSize="small"
                nbInput
                fullWidth
                placeholder="Silence Threshold"
                [(ngModel)]="data.silenceThreshold"
              />
            </div>
          </div>
        </div>

        <div class="flex-title">
          <span class="title">Assign Variable</span>
          <span class="line"></span>
        </div>

        <div class="mb-2">
          <div class="form-label d-flex" style="align-items: center">
            <label class="label"> Status </label>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Assign the AMD STATUS to the variable."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </div>

          <ngx-input-value-static
            placeHolder="Select Status"
            selectAttribute="true"
            [propertyInput]="data.attribute"
            (propertyEmit)="getVariable($event)"
          >
          </ngx-input-value-static>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
