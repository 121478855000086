import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { Transfer } from "../../../../@core/model/transfer";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechModalComponent } from "../../../../shared/text-to-speech-modal/text-to-speech-modal.component";
import { AudioClient } from "../../../../System-api";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { DataService } from "../../../../@core/utils/data.service";

@Component({
  selector: "transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["./transfer.component.scss"],
})
export class TransferComponent implements OnInit {
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  selectedNumber: string;
  cell: MxCell;
  graph: MxGraph;
  selectedSubscription: Subscription;
  data: Transfer = new Transfer();
  dataTTS: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech" },
  ];
  eventsSubject: Subject<void> = new Subject<void>();
  cancelAction = false;
  formGroup: UntypedFormGroup;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    }
  }

  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    public dataService: DataService
  ) {
    this.createForm();
  }
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.text = "";
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      transferDestination: [
        "",
        [RxwebValidators.required(), noWhitespaceValidator],
      ],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );

    //set data to data object from cell xml
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.convertToNewDataFormat();
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.type !== null) {
      this.data.type = Number(this.data.type);
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleSentiment($event) {
    this.data.sentiment = $event;
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
  drop($event) {
    this.data.transferDestination +=
      "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }
  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  onInputChange(property: string) {
    this.data[property] = this.data[property].trim();
  }
  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      this.windowRef.close();
      const transferCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.transferDestination];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        transferCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
}
