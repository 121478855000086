<div class="action-config-wrapper" style="max-width: 550px; min-width: 490px" >
  <div class="config-action">
    <div class="input-caller mb-2">
      <div class="input-width">
        <label for="sourceLanguage" class="form-label label required"
          >Source Language</label
        >
        <input
          id="sourceLanguage"
          [ngModel]="data.sourceLanguage"
          (ngModelChange)="setSourceLanguage($event)"
          [formControl]="sourceLanguageText"
          type="text"
          fullWidth
          nbInput
          fieldSize="small"
          placeholder="Select Source Language"
          [nbAutocomplete]="sourceLanguageNgModel"
          (drop)="drop($event)"
          [mention]="attributes"
          [mentionConfig]="mentionConfig"
          [status]="
            sourceLanguageText.invalid && sourceLanguageText.touched
              ? 'danger'
              : 'basic'
          "
        />
        <nb-autocomplete #sourceLanguageNgModel>
          <nb-option
            *ngFor="let option of filteredSourceLanguageText$ | async"
            [value]="option.name"
            [ngClass]="{
              'bg-active': option.name == sourceLanguageText?.value
            }"
          >
            {{ option.name }}
          </nb-option>
        </nb-autocomplete>
        <ng-container
          *ngIf="sourceLanguageText.invalid && sourceLanguageText.touched"
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              sourceLanguageText.hasError('required') ||
              sourceLanguageText.hasError('whitespace')
            "
          >
            Source Language is required.
          </p>
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              sourceLanguageText.hasError('invalidLanguage')
            "
          >
            Source Language is invalid.
          </p>
        </ng-container>
      </div>
      <div class="input-width">
        <label for="targetLanguage" class="form-label label required"
          >Target Language</label
        >
        <input
          id="targetLanguage"
          [ngModel]="data.targetLanguage"
          (ngModelChange)="setTargetLanguage($event)"
          [formControl]="targetLanguageText"
          type="text"
          nbInput
          fullWidth
          fieldSize="small"
          placeholder="Select Target Language"
          [nbAutocomplete]="targetLanguageNgModel"
          (drop)="drop($event)"
          [mention]="attributes"
          [mentionConfig]="mentionConfig"
          [status]="targetLanguageText.invalid && targetLanguageText.touched 
            ? 'danger' 
            : 'basic'
          "
        />
        <nb-autocomplete #targetLanguageNgModel>
          <nb-option
            *ngFor="let option of filteredTargetLanguageText$ | async"
            [value]="option.name"
            [ngClass]="{
              'bg-active': option.name == targetLanguageText?.value
            }"
          >
            {{ option.name }}
          </nb-option>
        </nb-autocomplete>
        <ng-container *ngIf="targetLanguageText.invalid && targetLanguageText.touched"
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              targetLanguageText.hasError('invalidLanguage') &&
              !targetLanguageText.hasError('required') &&
              !targetLanguageText.hasError('whitespace')
            "
          >
            Target Language is invalid.
          </p>
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              targetLanguageText.hasError('required') ||
              targetLanguageText.hasError('whitespace')
            "
          >
            Target Language is required.
          </p>
        </ng-container>
      </div>
    </div>
    <div class="mb-2">
      <div class="form-label d-flex" style="align-items: center">
        <label for="content-translate" class="label required"> Input </label>
        <nb-icon
          class="ms-1"
          style="color: #929bb3; width: 15px"
          nbTooltip="Information that you want to translate."
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
        >
        </nb-icon>
      </div>
      <form [formGroup]="formGroup">
        <div class="d-flex">
        <textarea
          formControlName="textPrompt"
          nbInput
          fullWidth
          placeholder="Input"
          [(ngModel)]="data.text"
          fieldSize="small"
          style="display: block; overflow-y: scroll; height: 32.4px; max-height: 450px; min-height: 32.4px"
          (input)="onChangeContent()"
          id="content-translate"
          (drop)="drop($event)"
          [mentionConfig]="mentionConfig"
          [status]="
            formGroup.controls['textPrompt'].invalid &&
            (formGroup.controls['textPrompt'].dirty ||
              formGroup.controls['textPrompt'].touched)
              ? 'danger'
              : 'basic'
          "
        ></textarea>
        <div>
          <button
            class="ms-2"
            [nbSpinner]="isLoading"
            nbSpinnerStatus="primary"
            [disabled]="
              !sourceLanguageText.value ||
              !targetLanguageText.value ||
              !data.sourceLanguage ||
              !data.targetLanguage ||
              !data.text ||
              sourceLanguageText.invalid ||
              targetLanguageText.invalid ||
              isLoading
            "
            nbButton
            type="button"
            size="small"
            status="primary"
            (click)="translate()"
          >
            Translate
          </button>
        </div>
      </div>
      <ng-container
          *ngIf="
            formGroup.controls['textPrompt'].invalid &&
            (formGroup.controls['textPrompt'].dirty ||
              formGroup.controls['textPrompt'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['textPrompt'].hasError('required') ||
              formGroup.controls['textPrompt'].hasError('whitespace')
            "
          >
            Input is required.
          </p>
        </ng-container>
      </form>
    </div>
    <div 
      class="mb-2"
      *ngIf="translateResult.length > 0"
      >
      <label class="form-label label">Translation</label>
      <textarea
        type="text"
        nbInput
        fullWidth
        placeholder="Translation"
        style="
          display: block; 
          overflow-y: scroll;
          max-height: 450px;
          height: 32.4px;
          min-height: 32.4px;"
        id="translate-result"
        fieldSize="small"
        [(ngModel)]="translateResult"
        readonly
      ></textarea>
    </div>
    <div class="mb-2">
      <label class="form-label label">Assign Result To</label>
      <ngx-input-value-static
        placeHolder="Select Assign Result To"
        selectAttribute="true"
        [propertyInput]="data.attribute"
        (propertyEmit)="getAttribute($event)"
      >
      </ngx-input-value-static>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
