<div class="action-config-wrapper" style="min-width: 500px">
  <div class="config-action">
    <div class="row">
      <div class="mb-2">
        <label class="form-label label required">Function</label>
        <form [formGroup]="formGroup">
          <div class="d-flex custom-gap">
            <div class="select-style">
              <ng-select
                formControlName="function"
                [nbSpinner]="isLoading"
                nbSpinnerSize="medium"
                nbSpinnerStatus="info"
                [items]="listFunction"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="data.functionId"
                (change)="selectFunction($event)"
                placeholder="Select Function"
                [ngClass]="{
                  isInvalid:
                    formGroup.controls['function'].invalid &&
                    (formGroup.controls['function'].dirty ||
                      formGroup.controls['function'].touched)
                }"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div class="function-item">
                    <span>{{ item.name }}</span>
                    <div class="function-item-action">
                      <nb-icon
                        icon="edit-2-outline"
                        style="font-size: 15px"
                        (click)="handleEditFunction($event, item.id)"
                      ></nb-icon>
                      <nb-icon
                        *ngIf="data.functionId != item.id"
                        (click)="handleDeleteFunction($event, item)"
                        icon="trash-2-outline"
                        style="margin-left: 5px; font-size: 15px"
                      ></nb-icon>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <button
                nbButton
                [disabled]="!data.functionId"
                status="primary"
                size="small"
                (click)="handleEditFunction($event, data.functionId)"
              >
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
            </div>
            <button
              nbButton
              status="primary"
              size="small"
              (click)="createNewFunction()"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
          </div>
          <ng-container
            *ngIf="
              formGroup.controls['function'].invalid &&
              (formGroup.controls['function'].dirty ||
                formGroup.controls['function'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['function'].hasError('required') ||
                formGroup.controls['function'].hasError('whitespace')
              "
            >
              Function is required.
            </p>
          </ng-container>
        </form>
      </div>
    </div>
    <div *ngIf="selectedFunction?.param" style="max-width: 480px">
      <ngx-input-static
        #param
        [disabled]="true"
        [propertyInput]="selectedFunction.param"
        propertyValue="Param Value"
        (propertyEmit)="getParams($event)"
        propertyName="Param Name"
      >
      </ngx-input-static>
    </div>
    <div
      class="mb-2"
      *ngIf="selectedFunction?.id || data.functionId || data.attribute"
      style="max-width: 500px"
    >
      <label class="form-label label">Assign Result To</label>
      <ngx-input-value-static
        selectAttribute="true"
        [propertyInput]="data.attribute"
        placeHolder="Select Assign Result To"
        (propertyEmit)="getAttribute($event)"
      >
      </ngx-input-value-static>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
