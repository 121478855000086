<ngx-datatable
  #table
  class="bootstrap material custom-spinner w-100"
  [ngClass]="{ 'is-not-modal': !isModal }"
  [footerHeight]="50"
  [columnMode]="'flex'"
  [columns]="columns"
  rowHeight="'flex-grow'"
  [rows]="rows"
  [externalPaging]="true"
  [count]="page.totalElements"
  [externalSorting]="true"
  [offset]="page.pageNumber"
  [limit]="page.size"
  (page)="setPage($event)"
  (sort)="onSort($event)"
  [nbSpinner]="loading || loadingAttribute"
  nbSpinnerSize="giant"
  nbSpinnerStatus="info"
>
  <ng-template #header let-column="column">
    <header-table
      [column]="column"
      (handleFilter)="filterHeader($event)"
    ></header-table>
  </ng-template>
  <ng-template #headerDay let-column="column">
    <header-table
      [column]="column"
      [type]="'date'"
      (handleFilter)="filterHeader($event)"
    ></header-table>
  </ng-template>
  <ngx-datatable-footer>
    <ng-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
      ngx-datatable-footer-template
    >
      <footer-table
        *ngIf="rowCount > 0"
        [page]="curPage"
        [visiblePagesCount]="'5'"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="false"
        (change)="table.onFooterPage($event)"
      >
      </footer-table>
    </ng-template>
  </ngx-datatable-footer>
  <!-- <ngx-datatable-column
    *ngIf="showMoreContent"
    name="ID"
    prop="callId"
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span class="text-link" (click)="moveOnCallDetailRecord(row)">{{
        row.callId
      }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    *ngIf="showMoreContent"
    [draggable]="false"
    [flexGrow]="1.5"
    [resizeable]="false"
    name="From"
    prop="fromNumber"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.fromNumber | phone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    *ngIf="showMoreContent"
    [draggable]="false"
    [resizeable]="false"
    [flexGrow]="1.5"
    name="To"
    prop="toNumber"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.toNumber | phone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Type"
    [flexGrow]="1"
    prop="type"
    [headerTemplate]="header"
  >
  </ngx-datatable-column> -->

  <ngx-datatable-column
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    name="Created"
    prop="created"
    [headerTemplate]="headerDay"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.created | date : "MM/dd/yyyy HH:mm:ss" }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    name="Current Flow"
    prop="currentFlowName"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span
        title="{{ row.currentFlowName }}"
        class="text-link"
        (click)="actionButtonClick(row.currentFlowId)"
        >{{ row.currentFlowName }}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    name="Current Action"
    prop="actionName"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span title="{{ row.actionName }}">{{ row.actionName }} </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    [flexGrow]="1"
    name="Log Type"
    prop="logType"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span
        *ngIf="row.logType == 'error'"
        title="ERROR"
        class="status-code error"
      >
        ERROR
      </span>
      <span
        *ngIf="row.logType == 'action' || row.logType == 'debug'"
        title="DEBUG"
        class="status-code debug"
      >
        DEBUG
      </span>
      <span
        *ngIf="
          row.logType == 'info' ||
          (row.logType != 'error' &&
            row.logType != 'action' &&
            row.logType != 'debug')
        "
        title="INFO"
        class="status-code"
      >
        INFO
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Log Content"
    prop="logContent"
    [flexGrow]="6"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <span
        style="word-break: break-word"
        *ngFor="let line of row.logContent?.split('|'); let last = last"
      >
        {{ line }}<ng-container *ngIf="!last"><br /></ng-container>
      </span>
      <div class="text-link" (click)="showJSON(i)" *ngIf="row.jsonRefData">
        {{ row.show == false ? "View JSON" : "Hide JSON" }}
      </div>
      <div
        style="font-size: 13px; text-wrap: wrap"
        class="default-return view-json"
        *ngIf="row.show == true"
      >
        <div
          style="
            width: 100%;
            max-height: 500px;
            overflow-y: auto;
            background-color: #f9fbfe;
            padding-left: 0 !important;
          "
          class="px-2"
        >
          <div style="position: relative">
            <button
              style="position: absolute; right: 0; z-index: 1"
              title="Copy"
              (click)="copyJSON(row.jsonRefData)"
              type="button"
              ghost
              size="tiny"
              nbButton
            >
              <nb-icon icon="copy-outline"></nb-icon>
            </button>
          </div>
          <ngx-json-viewer
            [json]="row.jsonRefData"
            [expanded]="false"
          ></ngx-json-viewer>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Variables"
    prop="attribute"
    [headerTemplate]="header"
    [flexGrow]="1"
  >
    <ng-template
      let-row="row"
      let-rowIndex="rowIndex"
      ngx-datatable-cell-template
    >
      <button
        [nbPopover]="templateRef"
        nbButton
        size="tiny"
        nbPopoverTrigger="noop"
        style="height: 1rem; width: 2.8rem"
        (click)="showLog(row.id, rowIndex)"
      >
        Show
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<ng-template #templateRef>
  <nb-card
    id="model-variable"
    style="margin-bottom: 0"
    [nbSpinner]="loadingAttribute"
    nbSpinnerSize="medium"
    nbSpinnerStatus="info"
  >
    <nb-card-header style="padding: 0.25rem 1.25rem; padding-right: 0.25rem">
      <div
        class="d-flex justify-content-between"
        style="align-items: center; width: 100%"
      >
        <div class="title-page-wrapper">
          <span>Variables</span>
        </div>
        <div
          class="d-flex justify-content-between"
          style="align-items: center; gap: 0.5rem"
        >
          <button
            [title]="copyTitle"
            (click)="copyAttribute()"
            type="button"
            ghost
            size="medium"
            nbButton
          >
            <nb-icon icon="copy-outline"></nb-icon>
          </button>
          <button
            type="button"
            ghost
            size="medium"
            nbButton
            (click)="closeVariable()"
          >
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body style="padding: 0">
      <div class="log-content">
        <div class="fix">
          <ngx-json-viewer
            [json]="logContent"
            [expanded]="false"
          ></ngx-json-viewer>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
