<div class="action-config-wrapper" style="min-width: 480px; max-width: 500px">
  <div class="config-action">
    <div>
      <form [formGroup]="formGroup" class="mb-2">
        <label class="form-label label required">Dataset</label>
        <div class="d-flex custom-gap">
          <ng-select
            formControlName="dataset"
            [nbSpinner]="isLoading"
            nbSpinnerSize="medium"
            nbSpinnerStatus="info"
            [items]="datasets"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="datasetIdSelected"
            (change)="datasetChange($event)"
            placeholder="Select Dataset"
            [ngClass]="{
              isInvalid:
                formGroup.controls['dataset'].invalid &&
                (formGroup.controls['dataset'].dirty ||
                  formGroup.controls['dataset'].touched)
            }"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-index="index"
              let-search="searchTerm"
            >
              <div class="function-item">
                <div>{{ item.name }}</div>
                <div class="dataset-item-action">
                  <nb-icon
                    icon="edit-2-outline"
                    style="font-size: 15px"
                    (click)="updateDataset($event, item)"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="datasetIdSelected !== item.id"
                    icon="trash-2-outline"
                    style="margin-left: 5px; font-size: 15px"
                    (click)="removeDataset($event, item)"
                  ></nb-icon>
                </div>
              </div>
            </ng-template>
          </ng-select>
          <button
            type="button"
            [disabled]="!data.datasetId"
            size="small"
            status="primary"
            nbButton
            (click)="viewDataset(data.datasetId)"
          >
            <nb-icon icon="edit-2-outline"></nb-icon>
          </button>
          <button
            type="button"
            size="small"
            status="primary"
            nbButton
            (click)="addDataset()"
          >
            <nb-icon icon="plus-outline"></nb-icon>
          </button>
        </div>
        <ng-container
          *ngIf="
            formGroup.controls['dataset'].invalid &&
            (formGroup.controls['dataset'].dirty ||
              formGroup.controls['dataset'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['dataset'].hasError('required') ||
              formGroup.controls['dataset'].hasError('whitespace')
            "
          >
            Dataset is required.
          </p>
        </ng-container>
      </form>

      <div
        class="mb-2"
        *ngIf="data.datasetId && datasetSelected?.labels?.length > 0"
      >
        <label class="form-label label">Labels</label>
        <nb-select
          fullWidth
          [(ngModel)]="data.labels"
          placeholder="Select Labels"
          size="small"
        >
          <nb-option *ngFor="let option of labels" [value]="option">{{
            option
          }}</nb-option>
        </nb-select>
      </div>
      <div class="mb-2">
        <label for="yourBot" class="form-label label">Note</label>
        <textarea
          placeholder="Note"
          id="yourBot"
          style="min-height: 50px; max-height: 300px"
          value=""
          nbInput
          type="text"
          fieldSize="small"
          fullWidth
          [(ngModel)]="data.note"
          [mentionConfig]="mentionConfig"
        >
        </textarea>
      </div>
    </div>
  </div>

  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
