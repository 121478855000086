import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { AnswerDetect } from "../../../../@core/model/answer-detect";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { Subject, Subscription } from "rxjs";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { AudioClient } from "../../../../System-api";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-answer-detect",
  templateUrl: "./answer-detect.component.html",
  styleUrls: ["./answer-detect.component.scss"],
})
export class AnswerDetectComponent implements OnInit, AfterViewInit {
  @ViewChild("audioPlayer", { static: true })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", { static: true })
  textToSpeech: TextToSpeechComponent;
  graph: MxGraph;
  cell: MxCell;
  data: AnswerDetect = new AnswerDetect();
  selectedSubscription: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();

  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech or Chat Text" },
  ];
  audioList: string[] = [];
  audios: any;
  listLanguage;
  dataTTSCB: any = [];
  listVoice;
  dataTTS: any = [];
  cancelAction = false;
  errorMessage: string = "Variable is required";

  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private audioClient: AudioClient,
    private graphService: GraphHandlerService
  ) {}
  ngAfterViewInit(): void {
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }
  }
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.text = "";
    }
  }
  ngOnInit() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        let audioName = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
            audioName.push(obj.name);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioName = audio !== undefined ? audioName.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.convertToNewDataFormat();
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  handleAudio(value: number) {
    this.data.option = value;
  }

  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId?.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  submit() {
    if (!this.cancelAction) {
      if (
        this.data.initialSilence?.toString() == "" ||
        !this.data.initialSilence ||
        parseFloat(this.data.initialSilence?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.initialSilence?.toString()))
      ) {
        this.data.initialSilence = 2500;
      }
      if (
        this.data.greeting?.toString() == "" ||
        !this.data.greeting ||
        parseFloat(this.data.greeting?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.greeting?.toString()))
      ) {
        this.data.greeting = 1500;
      }
      if (
        this.data.afterGreetingSilence?.toString() == "" ||
        !this.data.afterGreetingSilence ||
        parseFloat(this.data.afterGreetingSilence?.toString()) < 0 ||
        !Number.isInteger(
          parseFloat(this.data.afterGreetingSilence?.toString())
        )
      ) {
        this.data.afterGreetingSilence = 800;
      }
      if (
        this.data.totalAnalysisTime?.toString() == "" ||
        !this.data.totalAnalysisTime ||
        parseFloat(this.data.totalAnalysisTime?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.totalAnalysisTime?.toString()))
      ) {
        this.data.totalAnalysisTime = 5000;
      }
      if (
        this.data.miniumWordLength?.toString() == "" ||
        !this.data.miniumWordLength ||
        parseFloat(this.data.miniumWordLength?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.miniumWordLength?.toString()))
      ) {
        this.data.miniumWordLength = 100;
      }
      if (
        this.data.maximumWordLength?.toString() == "" ||
        !this.data.maximumWordLength ||
        parseFloat(this.data.maximumWordLength?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.maximumWordLength?.toString()))
      ) {
        this.data.maximumWordLength = 500;
      }
      if (
        this.data.maximumNumberOfWords?.toString() == "" ||
        !this.data.maximumNumberOfWords ||
        parseFloat(this.data.maximumNumberOfWords?.toString()) < 0 ||
        !Number.isInteger(
          parseFloat(this.data.maximumNumberOfWords?.toString())
        )
      ) {
        this.data.maximumNumberOfWords = 3;
      }
      if (
        this.data.betweenWordSilence?.toString() == "" ||
        !this.data.betweenWordSilence ||
        parseFloat(this.data.betweenWordSilence?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.betweenWordSilence?.toString()))
      ) {
        this.data.betweenWordSilence = 50;
      }
      if (
        this.data.silenceThreshold?.toString() == "" ||
        !this.data.silenceThreshold ||
        parseFloat(this.data.silenceThreshold?.toString()) < 0 ||
        !Number.isInteger(parseFloat(this.data.silenceThreshold?.toString()))
      ) {
        this.data.silenceThreshold = 256;
      }

      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      var fieldsRequired = [];
      const AMDcell = this.graph.getModel().getCell(this.cell.getId());
      fieldsRequired = [];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        AMDcell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  getVariable($event) {
    this.data.attribute = $event;
  }
}
