import { Clipboard } from "@angular/cdk/clipboard";
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  NbDialogRef,
  NbDialogService,
  NbPopoverDirective,
} from "@nebular/theme";
import { Subscription } from "rxjs";
import { DataService } from "../../@core/utils/data.service";
import { EventBusService } from "../../@core/utils/eventbus.service";
import { SignalRService } from "../../@core/utils/signalR.service";
import { TraceLogClient } from "../../System-api";

@Component({
  selector: "debug-modal",
  templateUrl: "./debug-modal.component.html",
  styleUrls: ["./debug-modal.component.scss"],
})
export class DebugModalComponent implements OnInit, OnDestroy {
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  @ViewChild("listElement") listElement: ElementRef;
  data: any;
  recordPath: any = null;
  id = "";
  logSubscription: Subscription;
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.dismiss();
  }
  contents = [
    {
      actionKey: "init",
      actionName: "Incoming",
      content: [{ logContent: "Init the progress...", logType: "debug" }],
    },
  ];
  loadingAttribute = false;
  logContent = "";
  logContentString = "";
  copyTitle = "Copy to clipboard";
  timeout = null;
  constructor(
    protected ref: NbDialogRef<DebugModalComponent>,
    private eventBusService: EventBusService,
    private dialogService: NbDialogService,
    public dataService: DataService,
    private signalRService: SignalRService,
    private clipboard: Clipboard,
    private tracelogClient: TraceLogClient
  ) {
    this.eventBusService.on("closeDebugMode", (isTrue: boolean) => {
      this.dismiss();
    });

    this.eventBusService.on("restartDebug", () => {
      this.reload();
    });
  }
  @HostListener("click", ["$event"])
  onHostClick(event: Event): void {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  closeVariable() {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  calculatorIndex(i, z) {
    let globalIndex = 0;
    for (let j = 0; j < i; j++) {
      globalIndex += this.contents[j].content.length;
    }
    globalIndex += z;
    return globalIndex;
  }
  showLog(data, z, y) {
    let k = this.calculatorIndex(z, y);
    this.copyTitle = "Copy to clipboard";
    this.logContentString = "";
    this.logContent = "";
    this.loadingAttribute = true;
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
    this.tracelogClient.getVariable(data).subscribe({
      next: (rs) => {
        this.loadingAttribute = false;
        this.logContentString = rs;
        this.logContent = JSON.parse(rs);
        const popoverArray = this.popovers.toArray();
        if (popoverArray[k]) {
          popoverArray[k].show();
        }
      },
      error: () => {
        this.loadingAttribute = false;
        this.logContentString = "Empty";
        this.logContent = "Empty";
        this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
      },
    });
  }
  copyAttribute() {
    this.clipboard.copy(this.logContentString);
    this.copyTitle = "Copied to clipboard";
  }
  copyJSON(data) {
    this.clipboard.copy(JSON.stringify(data, undefined, 2));
  }
  ngOnDestroy(): void {
    this.logSubscription.unsubscribe();
  }
  scrollToBottom(): void {
    try {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.listElement.nativeElement.scrollTop =
          this.listElement.nativeElement.scrollHeight;
      }, 100);
    } catch (err) {}
  }
  subscribeMessage(uuId) {
    this.signalRService
      .startConnection()
      .then((rs) => {
        if (rs) {
          this.signalRService.debugLogListener(uuId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.logSubscription = this.signalRService.receiveDebugLog.subscribe({
      next: (rs) => {
        this.handleContent(rs);
        this.scrollToBottom();
      },
      error: (err) => {
        console.log(err);
        this.scrollToBottom();
      },
    });
  }
  handleContent(rs) {
    let parsedJSON = {};

    try {
      parsedJSON = JSON.parse(rs.jsonRefData);
    } catch (e) {
      delete rs.jsonRefData;
    }

    if (!Object.keys(parsedJSON).length) {
      delete rs.jsonRefData;
    } else {
      rs.jsonRefData = parsedJSON;
    }

    if (this.contents.length >= 1) {
      let lastItem = this.contents[this.contents.length - 1];
      if (
        rs.actionKey &&
        lastItem.actionKey == rs.actionKey &&
        lastItem.actionName == rs.actionName
      ) {
        this.contents[this.contents.length - 1].content.push(rs);
      } else {
        this.contents.push({
          actionKey: rs.actionKey,
          actionName: rs.actionName,
          content: [rs],
        });
      }
    } else {
      this.contents.push({
        actionKey: rs.actionKey,
        actionName: rs.actionName,
        content: [rs],
      });
    }
  }
  ngOnInit() {
    if (this.id) {
      this.subscribeMessage(this.id);
    }
  }
  dismiss() {
    this.ref.close();
  }
  reload() {
    this.contents = [];
  }
}
