import {
  UploadMusicOnHoldCommand,
  MusicOnHoldClient,
  UpdateMusicOnHoldCommand,
  CheckMusicOnHoldCommand,
  ServiceTTS,
  AudioClient,
  ConvertAudioCommand,
} from "../../../../../System-api";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbDialogRef,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { noWhitespaceValidator } from "../../../../../@core/utils/helpers";
import { Observable, map, startWith } from "rxjs";

@Component({
  selector: "upload-music",
  templateUrl: "./upload_music_on_hold.component.html",
  styleUrls: ["./upload_music_on_hold.component.scss"],
})
export class UploadMusicOnHoldComponent implements OnInit {
  @ViewChild("inputName") inputName: ElementRef<any>;
  @ViewChild("testMusicUpload", { static: true })
  public testMusicUpload: ElementRef;
  @ViewChild("testMusicTTS", { static: true })
  public testMusicTTS: ElementRef;
  @ViewChild("audioInput", { static: true }) audioInput: ElementRef;
  uploadMusicForm: UntypedFormGroup;
  isPlaying: boolean = false;
  dataMusicUpload: any = null;
  extensionAudio: any = null;
  maxSize: number;
  music: any;
  isLoading: boolean = false;
  musicOnHold: any;
  isUpdate: boolean = false;
  pathMusic: string;
  title: string;
  onAdd = new EventEmitter();
  previousMusic: any;
  dataMusicTTS: any = null;
  listLanguage;
  listVoice;
  listCountry;
  listService;
  filteredService: Observable<any[]>;
  filteredLanguage: Observable<any[]>;
  filteredVoice: Observable<any[]>;
  @Output() refeshList = new EventEmitter<number>();

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.dismiss();
  }

  constructor(
    private formBuilder: RxFormBuilder,
    private toastrService: NbToastrService,
    private musicOnHoldClient: MusicOnHoldClient,
    private audioClient: AudioClient,
    public ref: NbDialogRef<UploadMusicOnHoldComponent>
  ) {
    this.listService = [
      { name: "Primas", code: "0" },
      { name: "Soundhound", code: "1" },
      { name: "AmazonPolly", code: "2" },
      { name: "Google", code: "3" },
      { name: "Azure", code: "4" },
    ];
    this.maxSize = 20 * 1024 * 1024;
  }

  ngOnInit(): void {
    if (this.musicOnHold != null) {
      this.music = new UpdateMusicOnHoldCommand();
      this.music.isUpload = this.musicOnHold.isUpload;     
    } else {
      this.music = new UploadMusicOnHoldCommand();
      this.music.isUpload = false;
    }
    this.previousMusic = new UploadMusicOnHoldCommand();

    this.createForm(this.music.isUpload);
    this.getService();
    this.getDataMOHUpdate();
  }

  getDataMOHUpdate() {
    if(this.musicOnHold != null) {
      this.music.name = this.musicOnHold.name;
      this.pathMusic = this.musicOnHold.path;
      this.music.voiceId = this.musicOnHold.voiceId;
      if (!this.music.isUpload) {
        let nameService;
        this.listService.forEach((item) => {
          if (item.code == this.musicOnHold.service) {
            nameService = item.name;
          }
        });
        this.uploadMusicForm.controls["service"]?.setValue({
          name: nameService,
          code: this.musicOnHold.service,
        });
        this.onChangeService({
          name: nameService,
          code: this.musicOnHold.service,
        });
        this.uploadMusicForm.controls["language"]?.setValue({
          name: this.musicOnHold.languageName,
          code: this.musicOnHold.language,
        });
        this.setLanguage({
          name: this.musicOnHold.languageName,
          code: this.musicOnHold.language,
        });
        this.music.content = this.musicOnHold.content;    
        this.uploadMusicForm.controls["voiceId"]?.setValue({
          id: this.musicOnHold.voiceId,
          name: this.musicOnHold.voiceId,
          gender: this.musicOnHold.gender,
        });
        this.onChangeVoice({
          id: this.musicOnHold.voiceId,
          name: this.musicOnHold.voiceId,
          gender: this.musicOnHold.gender,
        });        
      } else {
        this.music.fileName = this.musicOnHold.fileName;
        this.uploadMusicForm.get('file')?.setValue(this.music.fileName);
        this.pathMusic = this.musicOnHold.path + "?" + Date.now();
      }
    }
  }

  createForm(event) {
    this.music.isUpload = event;
    if (this.isUpdate) {
      if (this.music.isUpload) {
        this.uploadMusicForm = this.formBuilder.group({
          file: [
            "",
            [
              RxwebValidators.required(),
            ],
          ],
        });
      } else {
        this.uploadMusicForm = this.formBuilder.group({
          service: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          language: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          voiceId: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          content: [
            "",
            [
              RxwebValidators.required(),
            ],
          ],
        });
      }
    } else {
      if (this.music.isUpload) {
        this.uploadMusicForm = this.formBuilder.group({
          name: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
              RxwebValidators.pattern({
                expression: {
                  regex: /^[a-zA-Z0-9]+$/,
                },
              }),
            ],
          ],
          file: [
            "",
            [
              RxwebValidators.required(),
            ],
          ],
        });
      } else {
        this.uploadMusicForm = this.formBuilder.group({
          name: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
              RxwebValidators.pattern({
                expression: {
                  regex: /^[a-zA-Z0-9]+$/,
                },
              }),
            ],
          ],
          service: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          language: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          voiceId: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
            ],
          ],
          content: [
            "",
            [
              RxwebValidators.required(),
            ],
          ],
        });
      }
    }
    if (this.testMusicTTS.nativeElement.currentTime > 0) {
      this.testMusicTTS.nativeElement.pause();
      this.testMusicTTS.nativeElement.currentTime = 0;
    }
    if (this.filteredService == undefined) {
      this.getService();
    }
    if(!this.music.isUpload) {
      this.music.fileName = null;
    }
  }

  getAllLanguage() {
    this.audioClient.getAllLanguage().subscribe((rs) => {
      this.listLanguage = rs?.languages;
      this.filteredLanguage = this.uploadMusicForm
        .get("language")
        .valueChanges.pipe(
          startWith(""),
          map((value) => {
            const name = typeof value === "string" ? value : value?.name;
            return name
              ? this._filterValue(name as string, "language")
              : this.listLanguage?.slice();
          })
        );
    });
  }

  displayValue(event: any) {
    return event && event.name ? event.name : "";
  }
  displayLanguage(language: any) {
    return language && language.name ? language.name : "";
  }
  displayService(service: any) {
    return service && service.name ? service.name : "";
  }
  displayVoice(voice: any) {
    return voice && voice.name ? voice.name : "";
  }
  private _filterValue(name: string, listName: string): any[] {
    const filterValue = name.toLowerCase();
    if (listName == "service") {
      return this.listService.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "language") {
      return this.listLanguage.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "voiceId") {
      return this.listVoice.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  checkInvalid(type: string, event: any) {
    let check = false;
    let name = event?.name || event;
    if (name) {
      switch (type) {
        case "service":
          this.listService?.forEach((service) => {
            if (service.name == name) {
              check = true;
            }
          });
          break;
        case "language":
          this.listLanguage?.forEach((language) => {
            if (language.name == name) {
              check = true;
            }
          });
          break;
        case "voice":
          this.listVoice?.forEach((voice) => {
            if (voice.name == name) {
              check = true;
            }
          });
          break;
        default:
          break;
      }
      return check;
    } else {
      return true;
    }
  }

  onChangeLanguage(event: any) {
    if (this.checkInvalid("language", event)) {
      this.music.language = event?.code;
      this.uploadMusicForm.controls["voiceId"]?.setValue(undefined);
      this.filteredVoice = new Observable<any[]>();
      const serviceCode = this.uploadMusicForm.controls["service"]?.value?.code;
      if (event || event != undefined) {
        switch (Number(serviceCode)) {
          case ServiceTTS.Primas:
            this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
            break;
          case ServiceTTS.AmazonPolly:
            this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
            break;
          case ServiceTTS.Soundhound:
            this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
            break;
          case ServiceTTS.Azure:
            this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
            break;
          case ServiceTTS.Google:
            this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
            break;
          default:
            break;
        }
      }
    } else {
      this.uploadMusicForm.controls["language"].setErrors({ invalid: true });
    }
  }
  setLanguage(event: any) {
    this.music.language = event?.code;
    this.uploadMusicForm.controls["voiceId"]?.setValue(undefined);
    this.filteredVoice = new Observable<any[]>();
    const serviceCode = this.uploadMusicForm.controls["service"]?.value?.code;
    if (event || event != undefined) {
      switch (Number(serviceCode)) {
        case ServiceTTS.Primas:
          this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
          break;
        case ServiceTTS.AmazonPolly:
          this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
          break;
        case ServiceTTS.Soundhound:
          this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
          break;
        case ServiceTTS.Azure:
          this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
          break;
        case ServiceTTS.Google:
          this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
          break;
        default:
          break;
      }
    }
  }
  onChangeVoice(event: any) {
    if (this.checkInvalid("voice", event)) {
      this.music.voiceId = event?.name;
    } else {
      this.uploadMusicForm.controls["voiceId"]?.setErrors({ invalid: true });
    }
  }
  convertTTS() {
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testMusicTTS.nativeElement.pause();
      this.testMusicTTS.nativeElement.currentTime = 0;
      return;
    }
    if (
      this.previousMusic.content == this.music.content &&
      this.previousMusic.service == this.music.service &&
      this.previousMusic.language == this.music.language &&
      this.previousMusic.voiceId == this.music.voiceId
    ) {
      this.testMusicTTS.nativeElement.play();
      this.isPlaying = true;
    }
    else {
      this.previousMusic.content = this.music.content;
      this.previousMusic.service = this.music.service;
      this.previousMusic.language = this.music.language;
      this.previousMusic.voiceId = this.music.voiceId;
      this.previousMusic.gender = this.uploadMusicForm.value?.voiceId?.gender;
      this.previousMusic.languageName = this.uploadMusicForm.value?.language?.name;
      this.isPlaying = true;
      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.previousMusic.content,
            service: this.previousMusic.service,
            language: this.previousMusic.language,
            voiceId: this.previousMusic.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataMusicTTS = result;
          this.testMusicTTS.nativeElement.src = 'data:audio/mp3;base64,' + this.dataMusicTTS;
          this.testMusicTTS.nativeElement.play();
          this.isPlaying = true;
        });
    }
  }
  getLanguage(service: ServiceTTS) {
    if (service == ServiceTTS.Google) {
      this.audioClient.getLanguageGoogle().subscribe((rs) => {
        const data = JSON.parse(rs);
        this.listLanguage = data?.languages;
        this.filteredLanguage = this.uploadMusicForm
          .get("language")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              const name = typeof value === "string" ? value : value?.name;
              return name
                ? this._filterValue(name as string, "language")
                : this.listLanguage?.slice();
            })
          );
      });
    } else {
      this.audioClient.getLanguage(service).subscribe((rs) => {
        this.listLanguage = rs?.languages;
        this.filteredLanguage = this.uploadMusicForm
          .get("language")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              const name = typeof value === "string" ? value : value?.name;
              return name
                ? this._filterValue(name as string, "language")
                : this.listLanguage?.slice();
            })
          );
      });
    }
  }
  getLanguageCode(service: ServiceTTS, languageName: string) {
    this.audioClient.getLanguage(service).subscribe((rs) => {
      this.listLanguage = rs?.languages;
      if (this.listLanguage) {
        this.listLanguage.forEach((item) => {
          if (item.name == languageName) {
            return item.code;
          }
        });
      }
    });
  }
  getVoiceByLanguage(service: ServiceTTS, languageCode: string) {
    if (languageCode) {
      this.listVoice = [];
      this.audioClient
        .getVoiceByLanguage(service, languageCode)
        .subscribe((rs) => {
          if (rs.voices[0].id == "{ }") {
            this.uploadMusicForm.controls["language"]?.setValue(undefined);
          }
          if (service == ServiceTTS.Google) {
            const x = JSON.parse(rs.voices[0].name);
            x.voices?.forEach((item) => {
              var id = { id: item.name };
              var gender = { gender: item.ssmlGender };
              item = { ...item, ...id, ...gender };
              delete item["ssmlGender"];
              delete item["naturalSampleRateHertz"];
              delete item["languageCodes"];
              const dataExist = this.listVoice.find((x) => x.name == item.name);
              if (!dataExist) {
                this.listVoice.push(item);
              }
            });
          } else {
            this.listVoice = rs?.voices;
          }
          this.filteredVoice = this.uploadMusicForm
            .get("voiceId")
            ?.valueChanges.pipe(
              startWith(""),
              map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name
                  ? this._filterValue(name as string, "voiceId")
                  : this.listVoice?.slice();
              })
            );
        });
    }
  }
  getService() {
    this.filteredService = this.uploadMusicForm
      .get("service")
      ?.valueChanges.pipe(
        startWith(""),
        map((value) => {
          const name = typeof value === "string" ? value : value?.name;
          return name
            ? this._filterValue(name as string, "service")
            : this.listService?.slice();
        })
      );
  }
  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  checkExistName() {
    if (!this.isUpdate) {
      let data = new CheckMusicOnHoldCommand();
      data.mohName = this.music.name?.trim();
      this.isLoading = true;
      this.musicOnHoldClient.checkExistMOHName(data).subscribe({
        next: (rs) => {
          this.isLoading = false;
          if (rs) {
            this.uploadMusicForm.controls["name"].setErrors({
              isExist: rs,
            });
            this.handleFocus();
          } else {
            this.onSubmit();
          }
        },
        error: (error) => {
          this.handleFocus();
          this.isLoading = false;
        },
      });
    } else {
      this.onSubmit();
    }
  }


  onChangeService(event: any) {
    if (this.checkInvalid("service", event)) {
      this.music.service = event?.code;
      this.uploadMusicForm.controls["language"]?.setValue(undefined);
      this.uploadMusicForm.controls["voiceId"]?.setValue(undefined);
      this.filteredLanguage = new Observable<any[]>();
      this.filteredVoice = new Observable<any[]>();
      switch (Number(event.code)) {
        case ServiceTTS.Primas:
          this.getLanguage(ServiceTTS.Primas);
          break;
        case ServiceTTS.AmazonPolly:
          this.getLanguage(ServiceTTS.AmazonPolly);
          break;
        case ServiceTTS.Soundhound:
          this.getLanguage(ServiceTTS.Soundhound);
          break;
        case ServiceTTS.Azure:
          this.getLanguage(ServiceTTS.Azure);
          break;
        case ServiceTTS.Google:
          this.getLanguage(ServiceTTS.Google);
          break;
        default:
          break;
      }
    } else {
      this.uploadMusicForm.controls["service"].setErrors({ invalid: true });
    }
  }

  onSubmit() {
    this.isLoading = true;
    this.music.name = this.music.name.trim();
    this.music.service = this.uploadMusicForm.value?.service?.code;
    this.music.language = this.uploadMusicForm.value?.language?.code;
    this.music.voiceId = this.uploadMusicForm.value?.voiceId?.name;
    this.music.gender = this.uploadMusicForm.value?.voiceId?.gender;
    this.music.languageName = this.uploadMusicForm.value?.language?.name;
    if (this.dataMusicUpload) {
      this.music.musicFile = this.extensionAudio + "," + this.dataMusicUpload;
      this.music.content = null;
    } else {
      this.music.musicFile = null;
    }
    if (this.music instanceof UpdateMusicOnHoldCommand) {
      this.music.id = this.musicOnHold.id;
      this.musicOnHoldClient.update(this.music).subscribe(
        (rs) => {
          this.isLoading = false;
          if (rs > 0) {
            this.showToast(true);
            this.onAdd.emit(rs);
            this.refeshList.emit(rs);
          } else this.showToast(false);
        },
        (err) => {
          this.isLoading = false;
          this.showToast(false);
        }
      )     
    } else {
      this.musicOnHoldClient.create(this.music).subscribe(
        (rs) => {
          this.isLoading = false;
          if (rs > 0) {
            this.showToast(true);
            this.onAdd.emit(rs);
            this.refeshList.emit(rs);
          } else this.showToast(false);
        },
        (err) => {
          this.isLoading = false;
          this.showToast(false);
        }
      );
    }
  }

  dismiss() {
    this.ref.close(true);
  }

  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }

  playAudioUpload() {
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testMusicUpload.nativeElement.pause();
      this.testMusicUpload.nativeElement.currentTime = 0;
      return;
    } else {
      this.testMusicUpload.nativeElement.src;
      this.testMusicUpload.nativeElement.play();
      this.isPlaying = true;
    }
  }

  setTouchedFile() {
    this.music.fileName = "";
    this.uploadMusicForm.controls["file"].setValue("");
    this.uploadMusicForm.controls["file"].markAllAsTouched();
  }

  processFile(files: any) {
    this.isPlaying = false;
    this.dataMusicUpload = null;
    if (files.length === 0) return;
    var mimeType = files[0]?.type;
    if (!mimeType.match(/audio\/(mp3|wav|mpeg)/)) {
      this.uploadMusicForm.get("file").setErrors({ wrongformat: true });
      this.music.fileName = files[0].name;
      return;
    } else if (!this.checkAllowSize(files[0]?.size)) {
      this.uploadMusicForm.get("file").setErrors({ maxSize: true });
      this.music.fileName = files[0].name;
    } else {
      this.music.fileName = files[0].name;
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        let temp = reader.result.toString().split(",");
        this.extensionAudio = temp[0];
        this.dataMusicUpload = temp[1];
      };
    }
  }

  checkAllowSize(allowSize) {
    if (allowSize > this.maxSize) {
      return false;
    }
    return true;
  }

  showToast(result) {
    if (result) {
      this.toastrService.show(
        "Save music on hold successfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "success",
        }
      );
    } else {
      this.toastrService.show(
        "Save music on hold unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
}
