import { MentionModule } from "angular-mentions";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NbCardModule,
  NbToggleModule,
  NbInputModule,
  NbTooltipModule,
  NbRadioModule,
  NbSelectModule,
  NbDatepickerModule,
  NbButtonModule,
  NbButtonGroupModule,
  NbIconModule,
  NbListModule,
  NbUserModule,
  NbMenuModule,
  NbCheckboxModule,
  NbBadgeModule,
  NbTimepickerModule,
  NbTreeGridModule,
  NbAccordionModule,
  NbFormFieldModule,
  NbAutocompleteModule,
  NbSpinnerModule,
  NbPopoverModule,
  NbStepperModule,
  NbTabsetModule,
  NbButtonGroupComponent,
} from "@nebular/theme";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SimpleAudioPlayerComponent } from "./simple-audio-player/simple-audio-player.component";

import { CreateSipaccountComponent } from "./create-sipaccount/create-sipaccount.component";

import { MainPipe } from "../@core/pipe/main-pipe.module";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { PlayAudioFileComponent } from "./play-audio-file/play-audio-file.component";
import { JwPaginationModule } from "jw-angular-pagination";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { FilterSelectComponent } from "./filter-select/filter-select.component";
import { TextToSpeechModalComponent } from "./text-to-speech-modal/text-to-speech-modal.component";

import { SimpleAudioPlayerModalComponent } from "./simple-audio-player-modal/simple-audio-player-modal.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SetMediaFileModalComponent } from "./set-media-file-modal/set-media-file-modal.component";
import { AutocompleteSelectComponent } from "./autocomplete-select/autocomplete-select.component";
import { TextToSpeechComponent } from "./text-to-speech/text-to-speech.component";
import { GenerateScriptComponent } from "./generate-script/generate-script.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { NgSelectModule } from "@ng-select/ng-select";
import { ColorPickerModule } from "ngx-color-picker";
import { ModalScriptComponent } from "./generate-script/modal-script/modal-script.component";
import { DataTablePagerComponent } from "./footer-table/footer-table.component";
import { MatDialogModule } from "@angular/material/dialog";
import { HeaderTableComponent } from "./header-table/header-table.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { KeyboardShortcutsComponent } from "./keyboard-shortcuts/keyboard-shortcuts.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { MediaModalComponent } from "./media-modal/media-modal.component";
import { DebugModalComponent } from "./debug-modal/debug-modal.component";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { PrettyJsonModule } from "angular2-prettyjson";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";

@NgModule({
  imports: [
    RxReactiveFormsModule,
    MainPipe,
    ReactiveFormsModule,
    NbInputModule,
    FormsModule,
    NbBadgeModule,
    NbCheckboxModule,
    NbCardModule,
    NbMenuModule,
    NbButtonModule,
    NbSelectModule,
    CommonModule,
    NbIconModule,
    NbUserModule,
    NbListModule,
    NbStepperModule,
    JwPaginationModule,
    Ng2SearchPipeModule,
    NbTreeGridModule,
    NbAccordionModule,
    DragDropModule,
    MentionModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    NbSpinnerModule,
    MatTooltipModule,
    ClipboardModule,
    NgSelectModule,
    ColorPickerModule,
    MatDialogModule,
    NbPopoverModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NbDatepickerModule,
    NbButtonGroupModule,
    NbTabsetModule,
    NbTooltipModule,
    NbDateFnsDateModule.forChild({ format: "MM/dd/yyyy" }),
    UppyAngularDashboardModule,
    NgxJsonViewerModule,
    PrettyJsonModule,
    PickerModule,
    EmojiModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ChangePasswordComponent,
    SimpleAudioPlayerComponent,
    CreateSipaccountComponent,
    PlayAudioFileComponent,
    FilterSelectComponent,
    TextToSpeechModalComponent,
    SimpleAudioPlayerModalComponent,
    AlertDialogComponent,
    SetMediaFileModalComponent,
    AutocompleteSelectComponent,
    TextToSpeechComponent,
    GenerateScriptComponent,
    ModalScriptComponent,
    DataTablePagerComponent,
    HeaderTableComponent,
    KeyboardShortcutsComponent,
    MediaModalComponent,
    DebugModalComponent,
  ],
  exports: [
    NbCheckboxModule,
    CommonModule,
    FormsModule,
    ConfirmDialogComponent,
    ReactiveFormsModule,
    JwPaginationModule,
    NbCardModule,
    RxReactiveFormsModule,
    NbInputModule,
    NbTooltipModule,
    NbRadioModule,
    NbUserModule,
    NbButtonGroupComponent,
    CreateSipaccountComponent,
    NbToggleModule,
    NbSelectModule,
    NbDatepickerModule,
    NbTimepickerModule,
    NbButtonModule,
    NbIconModule,
    NbStepperModule,
    MainPipe,
    SimpleAudioPlayerComponent,
    FilterSelectComponent,
    TextToSpeechModalComponent,
    SimpleAudioPlayerModalComponent,
    DragDropModule,
    AlertDialogComponent,
    AutocompleteSelectComponent,
    TextToSpeechComponent,
    NbAutocompleteModule,
    NbAccordionModule,
    NbSpinnerModule,
    GenerateScriptComponent,
    DataTablePagerComponent,
    HeaderTableComponent,
    KeyboardShortcutsComponent,
    MediaModalComponent,
    DebugModalComponent,
  ],
})
export class SharedModule {}
