import { saveAs } from "file-saver";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { HttpRequestData } from "../../../../@core/model/http-request";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbMenuService,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { Observable, filter, map, of } from "rxjs";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as ace from "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import { Clipboard } from "@angular/cdk/clipboard";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import {
  CallFlowClient,
  MusicOnHoldClient,
  MusicOnHoldDto,
  MakeTestModelLLMCommand,
  ChatGPTClient,
} from "../../../../System-api";
import { InputStaticComponent } from "../../other/input-static/input-static.component";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { Subscription } from "rxjs";
import { UploadMusicOnHoldComponent } from "../../../../portal/customer-role/queue/updatequeue/music/upload_music_on_hold.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "ngx-http-request",
  templateUrl: "./http-request.component.html",
  styleUrls: ["./http-request.component.scss"],
})
export class HttpRequestComponent implements OnInit, AfterViewInit {
  @ViewChild("mainMusic", { static: true })
  public mainMusic: ElementRef;
  @ViewChild("editor") private editor: ElementRef<HTMLElement>;
  @ViewChild("editorResponse") private editorResponse: ElementRef<HTMLElement>;
  @ViewChild("paramsInput")
  paramsInput: InputStaticComponent;
  @ViewChild("paramsHeaders")
  paramsHeaders: InputStaticComponent;
  @ViewChild("paramsBody")
  paramsBody: InputStaticComponent;
  @ViewChild("paramsAttribute")
  paramsAttribute: InputStaticComponent;
  event: Subscription;
  cell: MxCell;
  graph: MxGraph;
  data: HttpRequestData = new HttpRequestData();
  pressAndSpeechCases: {
    press: string;
    text: string;
    display: string;
  }[] = [];
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  cancelAction = false;
  headerParams: Observable<any[]> = of([
    { default: "Accept", value: "Accept" },
    { default: "Accept-Encoding", value: "Accept-Encoding" },
    { default: "Accept-Language", value: "Accept-Language" },
    { default: "Authorization", value: "Authorization" },
    { default: "Cache-Control", value: "Cache-Control" },
    { default: "Content-Type", value: "Content-Type" },
    { default: "Cookie", value: "Cookie" },
    { default: "Host", value: "Host" },
    { default: "Origin", value: "Origin" },
    { default: "Referer", value: "Referer" },
    { default: "User-Agent", value: "User-Agent" },
    { default: "ETag", value: "ETag" },
    { default: "If-Match", value: "If-Match" },
    { default: "If-None-Match", value: "If-None-Match" },
    { default: "Location", value: "Location" },
    { default: "Server", value: "Server" },
    { default: "WWW-Authenticate", value: "WWW-Authenticate" },
    { default: "X-Frame-Options", value: "X-Frame-Options" },
    { default: "X-Content-Type-Options", value: "X-Content-Type-Options" },
    { default: "X-XSS-Protection", value: "X-XSS-Protection" },
  ]);
  @ViewChild("selectComponents", { static: true })
  selectComponents: NgSelectComponent;
  @ViewChild("selectComponentsCode", { static: true })
  selectComponentsCode: NgSelectComponent;
  @ViewChild("selectComponentsHeader", { static: true })
  selectComponentsHeader: NgSelectComponent;
  filteredOptions$: Observable<any[]> = of(this.dataService.ivrAttribute);
  options: object[] = [
    { id: 1, name: "Key Pairs" },
    { id: 2, name: "Raw" },
  ];
  loadRaw = true;
  loadResponse = true;
  responseReview: string = "Incoming soon";
  showResponse = false;
  showStatus = false;
  typeResponse = "text";
  optionResponse = [
    { title: "Save Response To File" },
    { title: "Copy Response" },
    { title: "Clear Response" },
  ];
  selectedOptionLog = [];
  formGroup: UntypedFormGroup;
  LogData = [
    { name: "Params", value: "Params" },
    { name: "Headers", value: "Headers" },
    { name: "Body", value: "Body" },
    { name: "Response", value: "Response" },
  ];
  loadingRequest = false;
  responseData = null;
  method: string;
  duration: number;
  size: number;
  statusCode: number;
  colorStatus: string;
  url: any = "";
  listVariablesCreated: any = [];
  oldheaderResponse: string = null;
  oldresponse: string = null;
  oldstatusCode: string = null;
  musicOnHolds: MusicOnHoldDto[];
  loadingMOH: boolean = false;
  isPlaying: boolean;
  currentMusic: any = null;
  sampleValues: any[];
  generateSampleValuesPromt: any[] = [];
  addSampleValue: boolean = false;
  isGenerateLoading: boolean = false;
  generateSampleValues: any;

  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private dialogService: NbDialogService,
    private nbMenuService: NbMenuService,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private callflowClient: CallFlowClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    private musicOnHoldClient: MusicOnHoldClient,
    private chatGPTClient: ChatGPTClient
  ) {
    this.createForm();
  }
  addTagFn(term) {
    let newStr = term?.trim().replace(/[{}]/g, "");
    return newStr;
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      url: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  showModifyCreate(event) {
    if (event?.id == 0) {
      let uploadMusic = this.dialogService.open(UploadMusicOnHoldComponent, {
        closeOnBackdropClick: false,
        autoFocus: false,
        context: {
          isUpdate: false,
          musicOnHold: null,
          title: "Upload Music On Hold",
        },
      });
      uploadMusic.onClose.subscribe(() => {
        if (this.data.musicOnHold == 0) {
          this.data.musicOnHold = null;
        }
      });
      uploadMusic.componentRef.instance.onAdd.subscribe((rs) => {
        this.data.musicOnHold = rs;
        uploadMusic.close();
        this.getAllMusicOnHold();
      });
    } else {
      this.data.musicOnHoldName = event.name;
    }
  }
  getAllMusicOnHold() {
    this.loadingMOH = true;
    this.musicOnHoldClient.getAll().subscribe(
      (rs) => {
        this.loadingMOH = false;
        if (rs) {
          this.musicOnHolds = rs.musicOnHoldDtos;
          const defaultValue = new MusicOnHoldDto({ id: 0 });
          this.musicOnHolds.unshift(defaultValue);
          if (this.data.musicOnHold && this.data.musicOnHold != 0) {
            let dataExist = this.musicOnHolds?.find(
              (x) => x.id == Number(this.data.musicOnHold)
            );
            if (!dataExist) {
              this.data.musicOnHold = null;
            } else {
              this.data.musicOnHold = Number(this.data.musicOnHold);
              this.data.musicOnHold =
                this.data.musicOnHold == 0 || isNaN(this.data.musicOnHold)
                  ? null
                  : Number(this.data.musicOnHold);
              this.data.musicOnHoldName = dataExist.name;
            }
          } else {
            this.data.musicOnHold = null;
          }
        }
      },
      (error) => {
        this.loadingMOH = false;
      }
    );
  }
  updateMusicOnHold(e, itemSelected) {
    e.stopPropagation();
    let uploadMusic = this.dialogService.open(UploadMusicOnHoldComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        musicOnHold: itemSelected,
        isUpdate: true,
        title: "Update Music On Hold",
      },
    });
    uploadMusic.componentRef.instance.onAdd.subscribe(() => {
      uploadMusic.close();
      this.getAllMusicOnHold();
    });
  }

  deleteMusicOnHold(e, itemSelected) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question:
            "Music on hold '" + itemSelected.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.musicOnHoldClient.delete(itemSelected.id).subscribe((rs) => {
            this.showToast(
              rs,
              "Delete music on hold successfully",
              "Delete music on hold unsuccessfully"
            );
            this.getAllMusicOnHold();
          });
        }
      });
  }
  musicPlaying() {
    this.isPlaying = true;
  }
  musicEnded() {
    this.isPlaying = false;
  }
  showToast(result, successText, failText) {
    if (result) {
      this.toastrService.show(successText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(failText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  playMusic(path, event: MouseEvent) {
    event.stopPropagation();
    const audioElement = this.mainMusic.nativeElement;

    if (this.isPlaying) {
      if (this.currentMusic === path) {
        this.isPlaying = false;
        audioElement.pause();
        audioElement.currentTime = 0;
      } else {
        this.isPlaying = true;
        audioElement.src = path + "?" + Date.now();
        audioElement.load();
        audioElement.play();
        this.currentMusic = path;
      }
    } else {
      this.isPlaying = true;
      audioElement.src = path + "?" + Date.now();
      audioElement.load();
      audioElement.play();
      this.currentMusic = path;
    }
  }
  setResponseReview(type) {
    if (this.loadResponse) {
      ace.config.set(
        "basePath",
        "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
      );
      const aceEditor = ace.edit(this.editorResponse.nativeElement);
      aceEditor.getSession().setValue(this.responseReview);
      aceEditor.setTheme("ace/theme/xcode");
      aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
      aceEditor.getSession().setUseWorker(true);
      aceEditor.setOptions({
        wrap: true,
      });
      aceEditor.on("change", () => {
        this.responseReview = aceEditor.getValue();
      });
      this.loadResponse = false;
    }
  }
  setBodyRaw(type) {
    if (this.loadRaw) {
      ace.config.set(
        "basePath",
        "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
      );
      const aceEditor = ace.edit(this.editor.nativeElement);
      aceEditor.getSession().setValue(this.data.bodyRaw);
      aceEditor.setTheme("ace/theme/xcode");
      aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
      aceEditor.getSession().setUseWorker(true);
      aceEditor.setOptions({
        wrap: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
      });
      aceEditor.on("change", () => {
        this.data.bodyRaw = aceEditor.getValue();
      });
      this.loadRaw = false;
    }
  }
  selectedOption(value) {
    this.data.selectedOptionLog = JSON.stringify(value);
    this.selectedOptionLog = JSON.parse(this.data.selectedOptionLog);
  }
  handleTypeBody(type) {
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
  }
  handleTypeResponse(type) {
    const aceEditor = ace.edit(this.editorResponse.nativeElement);
    aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
  }
  ngAfterViewInit(): void {
    if (this.data.option == 2) {
      this.setBodyRaw(this.data.typeRaw);
    }
  }

  selectVariable(value, key: string) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [...options])
            );
            switch (key) {
              case "statusCode":
                this.selectComponentsCode.writeValue(rs);
                this.oldstatusCode = rs;
                break;
              case "headerResponse":
                this.selectComponentsHeader.writeValue(rs);
                this.oldheaderResponse = rs;
                break;
              case "response":
                this.selectComponents.writeValue(rs);
                this.oldresponse = rs;
                break;
              default:
                break;
            }
            this.data[key] = rs;
          } else {
            switch (key) {
              case "statusCode":
                this.data.statusCode = this.oldstatusCode;
                break;
              case "headerResponse":
                this.data.headerResponse = this.oldheaderResponse;
                break;
              case "response":
                this.data.response = this.oldresponse;
                break;
              default:
                break;
            }
          }
        });
    } else {
      switch (key) {
        case "statusCode":
          this.oldstatusCode = value?.value;
          break;
        case "headerResponse":
          this.oldheaderResponse = value?.value;
          break;
        case "response":
          this.oldresponse = value?.value;
          break;
        default:
          break;
      }
    }
  }
  handleBody(value: number) {
    this.data.option = value;
    if (this.data.option == 2) {
      this.setBodyRaw(this.data.typeRaw);
    }
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  onChange(event) {
    const textArea = document.getElementById("input-url");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = "32.4px";
      }
    }
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] =
        this.cell.getAttribute(key) != undefined
          ? this.cell.getAttribute(key)
          : "";
    }
    this.getAllMusicOnHold();
    if (
      this.data.timeout == "" ||
      !this.data.timeout ||
      parseFloat(this.data.timeout) < 0 ||
      !Number.isInteger(parseFloat(this.data.timeout))
    ) {
      this.data.timeout = "5";
    }
    if (
      !this.data.retry ||
      this.data.retry == "" ||
      parseFloat(this.data.retry) < 0 ||
      !Number.isInteger(parseFloat(this.data.retry))
    ) {
      this.data.retry = "0";
    }
    if (!this.data.params) {
      this.data.params = "";
    }
    if (!this.data.statusCode) {
      this.data.statusCode = null;
    }
    if (!this.data.headerResponse) {
      this.data.headerResponse = null;
    }

    if (!this.data.typeRaw) {
      this.data.typeRaw = "text";
    }
    if (!this.data.response) {
      this.data.response = null;
    }
    if (!this.data.bodyRaw) {
      this.data.bodyRaw = "";
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }
    if (this.data.option) {
      this.data.option = Number(this.data.option);
    } else {
      this.data.option = 1;
    }
    this.attributes.find((x) => {
      if (this.data.url.match(`{${x.value}}`)) {
        if (x.content != undefined) {
          this.url = this.data.url.replace(`{${x.value}}`, `${x.content}`);
        } else this.url = this.data.url.replace(`{${x.value}}`, "");
      }
      if (x.hasOwnProperty("content")) {
        this.listVariablesCreated.push(x);
      }
    });
    this.filteredOptions$ = this.filteredOptions$.pipe(
      map((options) => [{ default: "VARIABLE", value: "" }, ...options])
    );
    this.setVariableToList(this.data.response);
    this.setVariableToList(this.data.statusCode);
    this.setVariableToList(this.data.headerResponse);
    this.event = this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu"),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        switch (title) {
          case "Copy Response":
            this.clipboard.copy(this.responseReview);
            this.toastrService.show("Copied to Clipboard", "Notification", {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "success",
            });
            break;
          case "Clear Response":
            this.responseReview = "";
            this.showResponse = false;
            this.showStatus = false;
            break;
          case "Save Response To File":
            let blob = new Blob([this.responseReview], {
              type: "application/json",
            });
            let fileExtention = this.typeResponse;
            if (this.typeResponse == "text") fileExtention = "txt";
            saveAs(blob, `response.${fileExtention}`);
            break;
          default:
            break;
        }
      });
    if (this.data.selectedOptionLog) {
      this.selectedOptionLog = JSON.parse(this.data.selectedOptionLog);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
    this.generateSampleValues = {};
  }
  setVariableToList(data) {
    if (data && data != "") {
      let dataExist = this.dataService.ivrAttribute.find(
        (x) => x.value == data
      );
      if (!dataExist && data.trim() != "") {
        this.filteredOptions$ = this.filteredOptions$.pipe(
          map((options) => [...options, { default: data, value: data }])
        );
      }
    }
  }
  sendRequest() {
    this.loadingRequest = true;
    this.paramsInput.getValue();
    this.paramsHeaders.getValue();
    this.paramsBody.getValue();
    this.paramsAttribute.getValue();
    const datacopy = { ...this.data };

    var dataSend = Object.assign({}, this.setSampleValue(datacopy));

    this.callflowClient.testRequest(dataSend as any).subscribe(
      (rs) => {
        this.method = rs.method;
        this.responseReview = rs.response;
        this.duration = rs.duration;
        this.size = rs.size;
        this.statusCode = rs.statusCode;
        this.loadingRequest = false;
        this.showResponse = true;
        if (this.method != "") {
          this.showStatus = true;
          const color = this.statusCode / 100;
          if (color === 1) {
            this.colorStatus = "info";
          } else if (color === 2) {
            this.colorStatus = "success";
          } else if (color === 3) {
            this.colorStatus = "redirection";
          } else {
            this.colorStatus = "error";
          }
        } else this.showStatus = false;
        this.loadResponse = true;
        this.setResponseReview("text");
      },
      (error) => {
        this.loadingRequest = false;
      }
    );
  }
  getProperty(property) {
    this.listVariablesCreated.forEach((replacement) => {
      const regex = new RegExp(`\\{${replacement.value}\\}`, "g");
      property = property.replace(regex, replacement.content);
    });
    return property;
  }
  submit() {
    if (!this.cancelAction) {
      if (
        !this.data.retry ||
        this.data.retry == "" ||
        parseFloat(this.data.retry) < 0 ||
        !Number.isInteger(parseFloat(this.data.retry))
      ) {
        this.data.retry = "0";
      }
      if (parseFloat(this.data.retry) > 10) {
        this.data.retry = "10";
      }
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const httpRequestCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.url.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        httpRequestCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  getAttribute($event) {
    this.data.attribute = $event;
  }

  getHttpHeaders($event) {
    this.data.headers = $event;
  }

  getHttpBody($event) {
    this.data.body = $event;
  }
  getHttpParams($event) {
    this.data.params = $event;
  }
  drop($event) {
    this.data.url += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  filterSampleValues() {
    this.paramsInput.getValue();
    this.paramsHeaders.getValue();
    this.paramsBody.getValue();
    this.paramsAttribute.getValue();

    if (this.data.bodyRaw == null || this.data.bodyRaw == "") {
      this.data.bodyRaw = "{}";
    }

    let paramsTabValue = JSON.parse(this.data.params);
    let headerTabValue = JSON.parse(this.data.headers);

    let bodyTabValue;
    if (this.data.option == 2) {
      bodyTabValue = JSON.parse(this.data.bodyRaw);
    } else {
      bodyTabValue = JSON.parse(this.data.body);
    }

    let paramsValues = [];
    paramsTabValue.forEach((item) => {
      paramsValues = [...paramsValues, ...this.extractObjects(item)];
    });

    let headerValues = [];
    headerTabValue.forEach((item) => {
      headerValues = [...headerValues, ...this.extractObjects(item)];
    });

    const bodyValues = this.extractObjects(bodyTabValue);

    const urlValues = this.extractSampleValue(this.data.url);

    const mergedArray = [
      ...urlValues,
      ...paramsValues,
      ...headerValues,
      ...bodyValues,
    ];

    let arrayObj = mergedArray
      .filter((o) => this.isAbleSetVariable(o.value))
      .map((item) => {
        return {
          ...item,
          input: "",
        };
      });

    let dataFilterDuplicate = arrayObj.filter((obj, index) => {
      return index === arrayObj.findIndex((o) => obj.value === o.value);
    });

    if (this.sampleValues != null && this.sampleValues.length > 0) {
      const copyDataSampleValues = [...this.sampleValues];

      const isSameUser = (dataFilterDuplicate, copyDataSampleValues) =>
        dataFilterDuplicate.value === copyDataSampleValues.value;

      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      let newSampleValues = onlyInLeft(
        dataFilterDuplicate,
        copyDataSampleValues,
        isSameUser
      );
      let removeSampleValues = onlyInLeft(
        copyDataSampleValues,
        dataFilterDuplicate,
        isSameUser
      );

      if (newSampleValues.length > 0 || removeSampleValues.length > 0) {
        this.addSampleValue = true;
      }

      this.listVariablesCreated.forEach((replacement) => {
        newSampleValues.forEach((item) => {
          if (item.value == `{${replacement.value}}`) {
            item.input = replacement.content;
          }
        });
      });

      this.sampleValues = [
        ...this.sampleValues.filter((el) => !removeSampleValues.includes(el)),
        ...newSampleValues,
      ];
    } else {
      this.listVariablesCreated.forEach((replacement) => {
        arrayObj.forEach((item) => {
          if (item.value == `{${replacement.value}}`) {
            item.input = replacement.content;
          }
        });
      });

      this.sampleValues = arrayObj;
    }
  }

  isAbleSetVariable(str: string): boolean {
    return str?.length > 0 && str.startsWith("{") && str.endsWith("}");
  }

  extractObjects(obj: any): any[] {
    let result: any[] = [];
    const recursiveExtract = (currentObj: any) => {
      if (typeof currentObj === "object" && currentObj !== null) {
        Object.keys(currentObj).forEach((key) => {
          const value = currentObj[key];
          if (this.extractSampleValue(key)) {
            result = [...result, ...this.extractSampleValue(key)];
          }
          if (this.extractSampleValue(value)) {
            result = [...result, ...this.extractSampleValue(value)];
          }
          if (typeof value === "object" && value !== null) {
            recursiveExtract(value);
          }
        });
      }
    };

    recursiveExtract(obj);
    return result;
  }

  extractSampleValue(string: string): any[] {
    const result: any[] = [];
    const regex = /\{([^\}]+)\}/g;
    let match;
    while ((match = regex.exec(string)) !== null) {
      result.push({ name: match[1], value: match[0] });
    }
    return result;
  }
  setSampleValue(dataToSet) {
    this.sampleValues.forEach((item) => {
      if (item.input !== "") {
        dataToSet.params = dataToSet.params.replace(item.value, item.input);
        dataToSet.headers = dataToSet.headers.replace(item.value, item.input);
        dataToSet.body = dataToSet.body.replace(item.value, item.input);
        dataToSet.bodyRaw = dataToSet.bodyRaw.replace(item.value, item.input);
        dataToSet.url = dataToSet.url.replace(item.value, item.input);
      }
    });
    Object.keys(this.generateSampleValues).forEach((key) => {
      dataToSet.params = dataToSet.params.replace(`{${key}}`,this.generateSampleValues[key]);
      dataToSet.headers = dataToSet.headers.replace(`{${key}}`,this.generateSampleValues[key]);
      dataToSet.body = dataToSet.body.replace(`{${key}}`, this.generateSampleValues[key]);
      dataToSet.bodyRaw = dataToSet.bodyRaw.replace(`{${key}}`,this.generateSampleValues[key]);
      dataToSet.url = dataToSet.url.replace(`{${key}}`,this.generateSampleValues[key]);
    });
    
    return dataToSet;
  }

  generateSampleValue() {
    this.isGenerateLoading = true;
    let data = new MakeTestModelLLMCommand();
    this.sampleValues.forEach((item) => {
      if (item.input == "") {
        this.addSampleValue = true;
      }
    });
    if (
      this.addSampleValue ||
      this.generateSampleValuesPromt == null ||
      this.generateSampleValuesPromt.length == 0
    ) {
      this.generateSampleValuesPromt = [
        ...this.sampleValues.filter((o) => o.input == ""),
      ];
      this.addSampleValue = false;
    }
    try {
      data.input = ".";
      data.prompt = this.generateSampleValuesPromt.map((x) => x.value).toString() + "].And generate data sample that different to the statements: " + JSON.stringify(this.generateSampleValues);
      data.model = "gpt";
      data.temperature = 0.3;
      data.type = "generate-sampleValue";
      this.chatGPTClient.testLLM(data).subscribe((rs) => {
        try {
          const dataObject = JSON.parse(rs);
          if (Object.keys(dataObject).length > 0) {
            this.generateSampleValues = dataObject;
          } else {
            this.toastrService.show("Generate sample value unsuccessfully.", `Notification`, {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            });
          }
          this.isGenerateLoading = false;
        } catch (error) {
          this.isGenerateLoading = false;
          this.toastrService.show("Generate sample value unsuccessfully.", `Notification`, {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          });
        }
      });
    } catch (error) {
      this.isGenerateLoading = false;
      this.toastrService.show("Generate sample value unsuccessfully.", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  sampleValueChange(event, index) {
    this.sampleValues[index].input = event.target.value;
    this.addSampleValue = true;
  }
  ngOnDestroy() {
    this.event.unsubscribe();
  }
}
