<form [formGroup]="uploadMusicForm" novalidate (submit)="onSubmit()">
  <nb-card [ngClass]="{ 'modal-upload': music.isUpload}" style="width: 800px; margin-bottom: 0; ">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        {{ title }}
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12">
          <nb-radio-group
            (valueChange)="createForm($event)"
            class="choice-option"
            [(value)]="music.isUpload"
          >
            <nb-radio [value]="false">Text To Speech</nb-radio>
            <nb-radio [value]="true">Upload Music On Hold</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <hr />
      <div class="row mb-2">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6" *ngIf="!isUpdate" >
              <label for="inputName" class="form-label label required"
              >Name</label
              >
              <input
                formControlName="name"
                placeholder="Name"
                [(ngModel)]="music.name"
                fullWidth
                nbInput
                #inputName
                [status]="
                  uploadMusicForm.controls['name'].invalid &&
                  (uploadMusicForm.controls['name'].dirty ||
                  uploadMusicForm.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                "
              />
              <ng-container
                *ngIf="
                  uploadMusicForm.controls['name'].invalid &&
                  (uploadMusicForm.controls['name'].dirty ||
                  uploadMusicForm.controls['name'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  *ngIf="
                    uploadMusicForm.controls['name'].hasError('required')
                  "
                >
                  Name is required.
                </p>
                <p
                    class="caption status-danger"
                    *ngIf="uploadMusicForm.controls['name'].hasError('regex')"
                  >
                    Name contains only letters and digits.
                  </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="uploadMusicForm.controls['name'].errors.isExist"
                >
                  Name already exists.
                </p>
              </ng-container>
            </div>
            <div class="col-md-6" *ngIf="!music.isUpload">
              <label for="input-service" class="form-label label required"
                >Service</label
              >
              <input
                id="input-service"
                type="text"
                nbInput
                fullWidth
                formControlName="service"
                [status]="
                  uploadMusicForm.controls['service'].invalid &&
                  (uploadMusicForm.controls['service'].dirty ||
                    uploadMusicForm.controls['service'].touched)
                    ? 'danger'
                    : 'basic'
                "
                [matAutocomplete]="autoService"
                placeholder="Select Service"
                (ngModelChange)="onChangeService($event)"
              />
              <ng-container
                *ngIf="
                  uploadMusicForm.controls['service'].invalid &&
                  (uploadMusicForm.controls['service'].dirty ||
                    uploadMusicForm.controls['service'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  *ngIf="
                    uploadMusicForm.controls['service'].hasError(
                      'required'
                    ) ||
                    uploadMusicForm.controls['service'].hasError('whitespace')
                  "
                >
                  Service is required.
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="
                    uploadMusicForm.controls['service'].hasError('invalid')
                  "
                >
                  Service is invalid.
                </p>
              </ng-container>
              <mat-autocomplete
                #autoService="matAutocomplete"
                [displayWith]="displayValue"
              >
                <mat-option
                  style="background-color: white; border: 1px solid #edf1f7"
                  [ngClass]="{
                    'bg-active':
                      service.name == uploadMusicForm.value?.service?.name
                  }"
                  *ngFor="let service of filteredService | async"
                  [value]="service"
                >
                  {{ service.name }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="row" *ngIf="!music.isUpload">
            <div class="col-md-6">
              <label class="form-label label required" for="input-language"
                >Language
              </label>
              <input
                id="input-language"
                type="text"
                fullWidth
                nbInput
                [status]="
                  uploadMusicForm.controls['language'].invalid &&
                  (uploadMusicForm.controls['language'].dirty ||
                    uploadMusicForm.controls['language'].touched)
                    ? 'danger'
                    : 'basic'
                "
                formControlName="language"
                [matAutocomplete]="autoLanguage"
                placeholder="Select Language"
                (ngModelChange)="onChangeLanguage($event)"
              />
              <ng-container
                *ngIf="
                  uploadMusicForm.controls['language'].invalid &&
                  (uploadMusicForm.controls['language'].dirty ||
                    uploadMusicForm.controls['language'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  *ngIf="
                    uploadMusicForm.controls['language'].hasError(
                      'required'
                    ) ||
                    uploadMusicForm.controls['language'].hasError(
                      'whitespace'
                    )
                  "
                >
                  Language is required.
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="
                    uploadMusicForm.controls['language'].hasError('invalid')
                  "
                >
                  Language is invalid.
                </p>
              </ng-container>
              <mat-autocomplete
                #autoLanguage="matAutocomplete"
                [displayWith]="displayValue"
              >
                <mat-option
                  style="background-color: white; border: 1px solid #edf1f7"
                  *ngFor="let language of filteredLanguage | async"
                  [ngClass]="{
                    'bg-active':
                      language.name == uploadMusicForm.value?.language?.name
                  }"
                  [value]="language"
                >
                  {{ language.name }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-md-6">
              <div class="mb-2" >
                  <label class="form-label label required" for="input-voice"
                    >Voice</label
                  >
                  <input
                    id="input-voice"
                    type="text"
                    nbInput
                    fullWidth
                    [status]="
                      uploadMusicForm.controls['voiceId'].invalid &&
                      (uploadMusicForm.controls['voiceId'].dirty ||
                        uploadMusicForm.controls['voiceId'].touched)
                        ? 'danger'
                        : 'basic'
                    "
                    formControlName="voiceId"
                    [matAutocomplete]="autoVoiceId"
                    placeholder="Select Voice"
                    (ngModelChange)="onChangeVoice($event)"
                  />
                  <ng-container
                    *ngIf="
                      uploadMusicForm.controls['voiceId'].invalid &&
                      (uploadMusicForm.controls['voiceId'].dirty ||
                        uploadMusicForm.controls['voiceId'].touched)
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="
                        uploadMusicForm.controls['voiceId'].hasError(
                          'required'
                        ) ||
                        uploadMusicForm.controls['voiceId'].hasError(
                          'whitespace'
                        )
                      "
                    >
                      Voice is required.
                    </p>
                    <p
                      class="caption status-danger"
                      *ngIf="
                        uploadMusicForm.controls['voiceId'].hasError('invalid')
                      "
                    >
                      Voice is invalid.
                    </p>
                  </ng-container>
                  <mat-autocomplete
                    #autoVoiceId="matAutocomplete"
                    [displayWith]="displayValue"
                  >
                    <mat-option
                      style="background-color: white; border: 1px solid #edf1f7"
                      *ngFor="let voice of filteredVoice | async"
                      [value]="voice"
                      [ngClass]="{
                        'bg-active':
                          voice.name == uploadMusicForm.value?.voiceId?.name
                      }"
                    >
                      {{ voice.name }}
                    </mat-option>
                  </mat-autocomplete>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!music.isUpload" >
            <div class="col-md-10">
              <div class="mb-2">
                <label for="inputText" class="form-label label required"
                  >Text</label
                >
                <div>
                  <textarea
                    style="max-height: 10em; min-height: 5em"
                    id="inputText"
                    formControlName="content"
                    placeholder="Text"
                    [(ngModel)]="music.content"
                    nbInput
                    fullWidth
                    [status]="
                      uploadMusicForm.controls['content'].invalid &&
                      (uploadMusicForm.controls['content'].dirty ||
                        uploadMusicForm.controls['content'].touched)
                        ? 'danger'
                        : 'basic'
                    "
                  ></textarea>
                </div>
                <ng-container
                  *ngIf="
                    uploadMusicForm.controls['content'].invalid &&
                    (uploadMusicForm.controls['content'].dirty ||
                      uploadMusicForm.controls['content'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    *ngIf="
                      uploadMusicForm.controls['content'].errors.required ||
                      uploadMusicForm.controls['content'].hasError(
                        'whitespace'
                      )
                    "
                  >
                    Text is required
                  </p>
                </ng-container>
              </div>
            </div>
            <div
              class="col-lg-2 col-md-12 d-flex align-items-start"
              style="margin-top: 1.77rem"
            >
              <button
                [disabled]="uploadMusicForm.controls['content'].invalid"
                (click)="convertTTS()"
                type="button"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon
                  [icon]="
                    isPlaying ? 'stop-circle-outline' : 'play-circle-outline'
                  "
                ></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row" *ngIf="music.isUpload">
        <div class="col-md-6">
          <label for="file" class="form-label label required">File</label>
          <div
            [ngStyle]="{
              'border-color':
                uploadMusicForm.controls['file'].invalid &&
                  (uploadMusicForm.controls['file'].dirty ||
                  uploadMusicForm.controls['file'].touched)
                  ? 'red'
                  : '#edf1f7'
            }"
            class="mb-2 d-flex align-items-center p-2 bg-file"
            (click)="audioInput.click()"
          >
            <button type="button" status="primary" nbButton size="small">
              Choose file
              <input
                id="file"
                formControlName="file"
                #audioInput
                (click)="setTouchedFile()"
                (change)="processFile(audioInput.files)"
                accept=".mp3, .wav,"
                nbInput
                fullWidth
                type="file"
                class="mb-2-file"
                [status]="
                  uploadMusicForm.controls['file'].invalid &&
                  (uploadMusicForm.controls['file'].dirty ||
                  uploadMusicForm.controls['file'].touched)
                    ? 'danger'
                    : 'basic'
                "
                style="display: none"
                [class.invalid-input]="
                  uploadMusicForm.get('file').errors?.wrongformat
                "
              />
            </button>
            <span class="ms-2 text-no-wrap-2">{{
              music.fileName ? music.fileName : "No files selected"
            }}</span>
          </div>
          <ng-container
            *ngIf="
              uploadMusicForm.controls['file'].invalid &&
              (uploadMusicForm.controls['file'].dirty ||
              uploadMusicForm.controls['file'].touched)
            "
          >
            <p
              class="caption status-danger"
              *ngIf="
                uploadMusicForm.controls['file'].hasError('required')"
            >
              File is required.
            </p>
          </ng-container>
          <ng-container *ngIf="uploadMusicForm.get('file').errors?.wrongformat">
            <p class="caption status-danger">
              Only MP3, WAV formats are allowed
            </p>
          </ng-container>
          <ng-container *ngIf="uploadMusicForm.get('file').errors?.maxSize">
            <p class="caption status-danger">
              Maximum allowed file size is 20MB
            </p>
          </ng-container>
        </div>
        <div class="col-md-3" style="margin-top: 1.85rem">
          <button
            [disabled]="!music.fileName"
            (click)="playAudioUpload()"
            type="button"
            size="small"
            status="primary"
            nbButton
          >
            <nb-icon
              [icon]="isPlaying ? 'stop-circle-outline' : 'play-circle-outline'"
            ></nb-icon>
          </button>
        </div>
      </div>
      <div class="mb-2">
        <audio
          (playing)="audioPlaying()"
          (ended)="audioEnded()"
          #testMusicTTS
          class="col-12"
        >
          <source />
        </audio>
      </div>
      <div class="mb-2">
        <audio
          (playing)="audioPlaying()"
          (ended)="audioEnded()"
          #testMusicUpload
          [src]="dataMusicUpload ? 'data:audio/mp3;base64,' + dataMusicUpload : pathMusic"
          class="col-12"
        >
          <source />
        </audio>
      </div>
      
    </nb-card-body>
    <nb-card-footer>
      <button
        [disabled]="!uploadMusicForm.valid || isLoading"
        nbSpinnerSize="small"
        nbSpinnerStatus="primary"
        type="submit"
        status="primary"
        class="me-2 small"
        nbButton
        style="float: right"
        size="small"
        (click)="checkExistName()"
      >
        <nb-icon icon="save-outline"></nb-icon>
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</form>
