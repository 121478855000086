<div class="action-config-wrapper" style="min-width: 570px; max-width: 570px">
  <div class="config-action" #listElement>
    <nb-stepper [linear]="false" orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <form>
          <nb-radio-group
            [(ngModel)]="data.option"
            class="choice-option"
            (ngModelChange)="handleAudio($event)"
          >
            <nb-radio *ngFor="let option of options" [value]="option.id">{{
              option.name
            }}</nb-radio>
          </nb-radio-group>
        </form>

        <nb-tabset class="form-group">
          <nb-tab tabTitle="Main">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio Main Prompt</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when starting this action."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeech></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioPlayer
                      [events]="eventsSubject.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify()"
                    >
                      <nb-icon icon="plus-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div class="flex-title">
                  <span class="title">Buttons List</span>
                  <span class="line"></span>
                </div>
                <div
                  class="mt-3"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                  "
                >
                  <nb-checkbox
                    (checkedChange)="allowAnswerOption($event)"
                    [checked]="data.allowAnswerOption"
                  >
                    Enable
                  </nb-checkbox>
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px; margin-bottom: 2px"
                    nbTooltip="Allows you to configure the answer button for the user (only for Chatbot). &#13;&#10;
                     Value: when the user select this button, the flow will take value as their response instead of the button content. &#13;&#10; Button Text: the content is displayed on the button."
                    nbTooltipPlacement="end"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
                <div
                  *ngIf="data.allowAnswerOption === true"
                  class="answer-option-wrapper"
                >
                  <div style="display: flex; align-items: center">
                    <form>
                      <nb-radio-group
                        [(ngModel)]="data.answerOptionType"
                        (ngModelChange)="handleAnswerOptionType($event)"
                        class="choice-option"
                      >
                        <nb-radio [value]="1">Default</nb-radio>
                        <nb-radio [value]="2">Use Variable </nb-radio>
                      </nb-radio-group>
                    </form>
                    <nb-icon
                      class="ms-1"
                      style="
                        color: #929bb3;
                        width: 15px;
                        margin-left: -1rem !important;
                        z-index: 1;
                      "
                      [nbPopover]="templateRef2"
                      nbPopoverTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon>
                  </div>

                  <nb-list
                    *ngIf="data.answerOptionType == 1"
                    cdkDropList
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                  >
                    <nb-list-item
                      class="example-box"
                      style="border: none; margin-left: -16px"
                      *ngFor="let item of answer; let i = index"
                      cdkDrag
                    >
                      <div class="answer-item">
                        <nb-accordion style="flex: 1">
                          <nb-accordion-item>
                            <nb-accordion-item-header
                              style="padding: 0"
                              class="button-list-header"
                            >
                              <input
                                type="text"
                                fieldSize="small"
                                nbInput
                                style="
                                  border: none;
                                  padding: 4px;
                                  padding-left: 16px;
                                  padding-right: 4.5rem;
                                  background-color: white;
                                "
                                fullWidth
                                [(ngModel)]="item.name"
                                (keydown)="$event.stopPropagation()"
                                placeholder="Value"
                              />
                              <div
                                style="
                                  position: absolute;
                                  right: 2px;
                                  display: flex;
                                  align-items: center;
                                  gap: 0.5rem;
                                "
                              >
                                <button
                                  type="button"
                                  size="small"
                                  ghost
                                  (click)="removeAnswer(i)"
                                  nbButton
                                  title="Remove Button"
                                >
                                  <nb-icon icon="trash-2-outline"></nb-icon>
                                </button>
                                <div cdkDragHandle class="move-icon">
                                  <nb-icon
                                    style="margin-right: -14px"
                                    status="basic"
                                    icon="more-vertical-outline"
                                  ></nb-icon>
                                  <nb-icon
                                    status="basic"
                                    icon="more-vertical-outline"
                                  ></nb-icon>
                                </div>
                              </div>
                            </nb-accordion-item-header>
                            <nb-accordion-item-body>
                              <div *ngFor="let itemLanguage of item.value">
                                <label
                                  for="itemLanguage"
                                  class="form-label label"
                                  >{{ itemLanguage.name }}</label
                                >
                                <input
                                  id="itemLanguage"
                                  type="text"
                                  fieldSize="small"
                                  nbInput
                                  fullWidth
                                  [(ngModel)]="itemLanguage.value"
                                  placeholder="Button Text"
                                />
                              </div>
                            </nb-accordion-item-body>
                          </nb-accordion-item>
                        </nb-accordion>
                      </div>
                    </nb-list-item>
                  </nb-list>
                  <div *ngIf="data.answerOptionType == 1">
                    <button
                      type="button"
                      size="small"
                      status="primary"
                      class="small"
                      nbButton
                      (click)="addNewAnswer()"
                    >
                      <nb-icon icon="plus-outline"></nb-icon>
                      <span>Add</span>
                    </button>
                  </div>
                  <div
                    *ngIf="data.answerOptionType == 2"
                    style="padding-top: 0.25rem"
                  >
                    <ngx-input-value-static
                      selectAttribute="true"
                      [propertyInput]="data.answerCustom"
                      (propertyEmit)="getAnswerVariable($event)"
                    >
                    </ngx-input-value-static>
                  </div>
                </div>
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Main Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Main VideoBot"
                    (valueSelected)="handleSentiment($event, 'main')"
                    [value]="data.sentimentMain"
                    defaultValue="normal-speak"
                  ></sentiment-select>
                </div> -->
              </div>
            </div></nb-tab
          >
          <nb-tab tabTitle="No Input">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When No Input</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user doesn't say or send message within the timeout."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNoInput></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNoInputPlayer
                      [events]="eventsSubjectNoInput.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModifyNoInput()"
                    >
                      <nb-icon icon="plus-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose No Input Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose No Input Videobot"
                    (valueSelected)="handleSentiment($event, 'noInput')"
                    [value]="data.sentimentNoInput"
                    defaultValue="no-input"
                  ></sentiment-select>
                </div> -->
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Not Match">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When Input Not Match</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user's response is not as expected or GPT doesn't understand the user's question."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNotMatch></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNotMatchPlayer
                      [events]="eventsSubjectNotMatch.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModifyNotMatch()"
                    >
                      <nb-icon icon="plus-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Not Match Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Not Match Videobot"
                    (valueSelected)="handleSentiment($event, 'notMatch')"
                    [value]="data.sentimentNotMatch"
                    defaultValue="not-match"
                  ></sentiment-select>
                </div> -->
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Speech</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Minimum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Minimum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="timeout"
                [(ngModel)]="data.minLength"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Minimum Length"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Maximum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="repeat"
                [(ngModel)]="data.maxLength"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Maximum Length"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>No Input Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user's say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </label>
              <input
                id="timeout"
                [(ngModel)]="data.noInputTimeout"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="No Input Timeout"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>Max Duration (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum duration of time the user allowed to say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>

              <input
                id="repeat"
                [(ngModel)]="data.timeout"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Duration"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Repeat</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>Max Tries</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum number of times to allow the caller to answer if their answer then GPT doesn't understand or the minimum length invalid"
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="repeat"
                [(ngModel)]="data.repeat"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Tries"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <!-- <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Log</span>
          <span class="line"></span>
        </div>
        <div class="mb-3 mt-3 d-flex" style="align-items: center">
          <nb-checkbox (checkedChange)="allowLog($event)" [checked]="data.log">
            Write Log For Conversation
          </nb-checkbox>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px; margin-bottom: 2px"
            nbTooltip="Logging of GPT response and user questions information."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div> -->
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Training</ng-template>

        <div class="flex-title" style="margin-top: 1remm">
          <span class="title"> Training </span>
          <span class="line"></span>
          <button
            (click)="testBot()"
            nbButton
            size="tiny"
            status="primary"
            outline
          >
            <nb-icon icon="play-circle"></nb-icon>
            Test
          </button>
        </div>

        <div class="mb-2">
          <div class="flex-label" style="margin-bottom: 0.15rem">
            <label
              for="yourBot"
              class="form-label label d-flex"
              style="align-items: center; margin-bottom: 0"
            >
              <span>Prompt</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Type the prompt words for the virtual agent to get the corresponding answer."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </label>
            <button
              nbButton
              size="small"
              status="primary"
              ghost
              style="padding-left: 0; padding-right: 0"
              (click)="optimize()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                fill="currentColor"
                viewBox="0 0 24 24"
                class="vfui_1xa3nyx0"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.75 15.165a.75.75 0 0 1 .743.648l.007.102v.851h.917a.75.75 0 0 1 .101 1.494l-.101.007-.917-.001v.982a.75.75 0 0 1-1.493.102L6 19.248v-.982h-.917a.75.75 0 0 1-.101-1.492l.101-.007.917-.001v-.851a.75.75 0 0 1 .75-.75Zm8-4.679a.75.75 0 0 1 .75.75 3.163 3.163 0 0 0 3.163 3.162.75.75 0 0 1 .101 1.494l-.101.007A3.163 3.163 0 0 0 15.5 19.06a.75.75 0 0 1-.75.75l-.043-.001a.75.75 0 0 1-.794-.749 3.163 3.163 0 0 0-3.163-3.162.75.75 0 0 1 0-1.5 3.163 3.163 0 0 0 3.162-3.163.75.75 0 0 1 .75-.75l.044.001.044-.001Zm-.045 3.217-.034.056a4.687 4.687 0 0 1-1.384 1.39 4.682 4.682 0 0 1 1.42 1.445c.36-.58.846-1.074 1.419-1.446a4.682 4.682 0 0 1-1.42-1.445ZM7.75 5.417a.75.75 0 0 1 .75.75c0 1.196.97 2.166 2.167 2.166a.75.75 0 0 1 .101 1.494l-.101.006C9.47 9.833 8.5 10.803 8.5 12a.75.75 0 0 1-.75.75l-.041-.001A.75.75 0 0 1 6.917 12c0-1.197-.97-2.167-2.167-2.167a.75.75 0 0 1 0-1.5c1.197 0 2.167-.97 2.167-2.166a.75.75 0 0 1 .75-.75l.04.001a.762.762 0 0 1 .043-.001Zm-.042 2.916-.065.086c-.194.25-.42.473-.671.665.28.213.528.466.737.75.207-.284.456-.537.736-.75a3.67 3.67 0 0 1-.737-.751ZM15.917 4a.75.75 0 0 1 .743.648l.007.102-.001.991h.917a.75.75 0 0 1 .102 1.494l-.102.007-.917-.001v.842a.75.75 0 0 1-1.492.102l-.007-.102-.001-.842h-.916a.75.75 0 0 1-.102-1.493l.102-.006.916-.001V4.75a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span style="margin-left: 0.25rem">Optimize</span>
            </button>
          </div>

          <div
            class="d-flex custom-gap-2 llm-prompt-config"
            style="position: relative"
          >
            <textarea
              id="yourBot"
              style="
                height: 150px;
                max-height: 400px;
                min-height: 150px;
                padding-top: 0.5rem;
              "
              value=""
              nbInput
              type="text"
              fieldSize="small"
              fullWidth
              placeholder="Prompt"
              [(ngModel)]="data.prompt"
              [mentionConfig]="mentionConfig"
            >
            </textarea>
            <nb-icon
              class="button-expand"
              icon="expand-outline"
              status="basic"
              title="Expand"
              style="
                cursor: pointer;
                position: absolute;
                right: 8px;
                width: 17px;
              "
              (click)="expand()"
            ></nb-icon>
          </div>
        </div>
        <div class="mt-3 mb-2">
          <label
            class="form-label label d-flex mb-0"
            style="align-items: center"
            ><span>Temperature</span>
            <nb-icon
              class="ms-1"
              style="width: 15px"
              nbTooltip="What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div class="d-flex">
            <div class="progress-wrap me-4">
              <input
                type="range"
                class="progress"
                [(ngModel)]="data.temperature"
                min="0"
                max="2"
                step="0.1"
                (input)="setTemperature(duration.value)"
                #duration
                onlyDoubleNumber
              />
              <div
                class="progress-foreground"
                [style.width.%]="getTemperature()"
              ></div>
            </div>
            <input
              type="text"
              nbInput
              maxlength="3"
              onlyDoubleNumber
              fieldSize="tiny"
              style="width: 4rem; text-align: center"
              [(ngModel)]="data.temperature"
              (input)="handleMaxValueTemperature($event)"
            />
            <!-- <label class="form-label label">{{ data.temperature }}</label> -->
          </div>
        </div>
        <div class="mb-2">
          <label
            class="form-label label d-flex mb-0"
            style="align-items: center"
            ><span>Maximum Length</span>
            <nb-icon
              class="ms-1"
              style="width: 15px"
              nbTooltip="The maximum number of tokens to generate in the completion."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div class="d-flex">
            <div class="progress-wrap me-4">
              <input
                type="range"
                class="progress"
                [(ngModel)]="data.numberOutput"
                min="0"
                max="4000"
                step="1"
                (input)="setNumberOutput(numberOutput.value)"
                #numberOutput
                onlyIntNumber
              />
              <div
                class="progress-foreground"
                [style.width.%]="getNumberOutput()"
              ></div>
            </div>
            <input
              type="text"
              nbInput
              maxlength="4"
              onlyIntNumber
              fieldSize="tiny"
              style="width: 4rem; text-align: center"
              [(ngModel)]="data.numberOutput"
              (input)="handleMaxValueNumberOutput($event)"
            />
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.25rem;
                  "
                >
                  <nb-toggle [(checked)]="data.keepContext" status="basic"
                    >Chat History</nb-toggle
                  >
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="The context will automatically be carried into the conversation."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.25rem;
                  "
                >
                  <nb-toggle [(checked)]="data.customTrain" status="basic"
                    >Use Knowledge</nb-toggle
                  >
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Allows you to query content related to user questions from the Knowledge."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <div class="form-label d-flex" style="align-items: center">
            <label class="label required"> Knowledge </label>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Select the knowledge base."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </div>
          <form [formGroup]="formGroup">
            <div class="d-flex custom-gap-2">
              <div class="select-style">
                <ng-select
                  formControlName="QADataset"
                  [nbSpinner]="isLoading"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="info"
                  [items]="listDataGPT"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="data.idDataset"
                  placeholder="Select Knowledge"
                  [ngClass]="{
                    isInvalid:
                      formGroup.controls['QADataset'].invalid &&
                      (formGroup.controls['QADataset'].dirty ||
                        formGroup.controls['QADataset'].touched)
                  }"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <div class="function-item">
                      <span>{{ item.name }}</span>
                      <div class="function-item-action">
                        <span style="display: none"> - </span>
                        <span
                          class="btn-action success"
                          *ngIf="
                            item.status == 'TRAINED' || item.status == 'True'
                          "
                          >Trained</span
                        >
                        <span
                          class="btn-action not-train"
                          *ngIf="
                            item.status == 'NOT-TRAINED' || item.status == ''
                          "
                          >Not-Trained</span
                        >
                        <span
                          class="btn-action failed"
                          *ngIf="
                            item.status == 'FAILED' || item.status == 'False'
                          "
                          >Failed</span
                        >
                        <span
                          class="btn-action training"
                          *ngIf="
                            item.status == 'TRAINING' || item.status == null
                          "
                          >Training</span
                        >
                        <label
                          class="btn-action not-train"
                          *ngIf="item.status == 'WAITING'"
                          >Waiting</label
                        >
                        <button
                          (click)="updateData($event, item)"
                          ghost
                          class="tbl-action"
                          nbButton
                          size="small"
                        >
                          <nb-icon icon="edit-2-outline"></nb-icon>
                        </button>
                        <button
                          *ngIf="data.idDataset != item.id"
                          ghost
                          (click)="removeData($event, item)"
                          class="tbl-action"
                          nbButton
                          size="small"
                        >
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <button
                  nbButton
                  [disabled]="!data.idDataset"
                  status="primary"
                  size="small"
                  (click)="updateCurrentData($event, data.idDataset)"
                >
                  <nb-icon icon="edit-2-outline"></nb-icon>
                </button>
              </div>
              <button
                type="button"
                (click)="addChatGPTDataset()"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
            <ng-container
              *ngIf="
                formGroup.controls['QADataset'].invalid &&
                (formGroup.controls['QADataset'].dirty ||
                  formGroup.controls['QADataset'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  formGroup.controls['QADataset'].hasError('required') ||
                  formGroup.controls['QADataset'].hasError('whitespace')
                "
              >
                Knowledge is required.
              </p>
            </ng-container>
          </form>
        </div>

        <div
          class="mb-2"
          style="margin-bottom: 1.75rem !important"
          *ngIf="this.data.customTrain === true"
        >
          <label class="form-label label d-flex" style="align-items: center">
            <span>Agent Answer Mode</span>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="The answer is not just responding based on your knowledge data or only responding based on your knowledge data."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div
            class="d-flex justify-content-between align-items-center"
            style="gap: 1.5rem"
          >
            <nb-select
              style="flex: 1"
              class=""
              placeholder="Select Agent Answer Mode"
              size="small"
              fullWidth
              [(ngModel)]="data.responseMode"
            >
              <nb-option value="ONLY_DOC" style="padding-left: 10px !important"
                >Knowledge Only</nb-option
              >
              <nb-option value="DOC&GPT" style="padding-left: 10px !important"
                >Knowledge & External</nb-option
              >
            </nb-select>
          </div>
        </div>

        <div>
          <div class="flex-title" style="margin-top: 1rem">
            <span class="title">Execution Mode</span>
            <span class="line"></span>
          </div>
          <div class="mb-2 mt-2">
            <form>
              <nb-radio-group
                [(ngModel)]="data.enableCheckpoint"
                class="choice-option"
                style="gap: 3rem"
                (ngModelChange)="handleMode($event)"
              >
                <nb-radio *ngFor="let mode of modes" [value]="mode.value">
                  {{ mode.name }}
                </nb-radio>
              </nb-radio-group>
            </form>
          </div>
          <div
            *ngIf="data.enableCheckpoint == true"
            class="mt-2"
            style="font-size: 13px"
          >
            Use this option to detect user intent using Grammar. The
            conversation ends once the intent is detected. And go to the
            <span style="color: #3cc157; font-weight: 600">green</span>
            case
          </div>
          <div
            *ngIf="data.enableCheckpoint == false"
            class="mt-2"
            style="font-size: 13px"
          >
            Use this option to trigger functions at different times. The
            conversation ends if any function returns false. And go to the
            <span style="color: #3a62dc; font-weight: 600">blue</span>
            case
          </div>
          <div
            class="flex-title"
            style="margin-top: 1.5rem"
            *ngIf="data.enableCheckpoint == false"
          >
            <span class="title">Trigger</span>
            <span class="line"></span>
          </div>
          <div
            *ngIf="data.enableCheckpoint == false"
            class="mb-2 mt-2"
            style="font-size: 13px"
          >
            Trigger custom functions before sending the user input to the
            Virtual Agent.
          </div>
          <div class="mb-2 mt-2" *ngIf="data.enableCheckpoint == false">
            <div class="mb-2">
              <ngx-multiple-grammar
                #multipleGrammar
                [grammarArray]="beforeCustomFunctions"
                type="Function"
                [showTitle]="false"
                buttonText="Add a function"
                [showLabel]="false"
                [isLoadingGrammar]="isLoadingBefore"
                [phraseListGrammar]="false"
                [digitGrammar]="false"
                [customGrammar]="false"
                [LLMGrammar]="false"
                [builtInGrammar]="false"
                [defaultParamsCode]="true"
                [isVAcomponent]="true"
                [onlyOne]="true"
              ></ngx-multiple-grammar>
            </div>
          </div>

          <div
            *ngIf="data.enableCheckpoint == false"
            class="mb-2 mt-4"
            style="font-size: 13px"
          >
            Trigger custom functions after the system receives a response from
            the Virtual Agent.
          </div>
          <div class="mb-2 mt-2" *ngIf="data.enableCheckpoint == false">
            <div class="mb-2">
              <ngx-multiple-grammar
                #multipleGrammar
                [grammarArray]="afterCustomFunctions"
                [functionOutputParams]="true"
                type="Function"
                [showTitle]="false"
                buttonText="Add a function"
                [showLabel]="false"
                [isLoadingGrammar]="isLoadingAfter"
                [phraseListGrammar]="false"
                [digitGrammar]="false"
                [customGrammar]="false"
                [LLMGrammar]="false"
                [builtInGrammar]="false"
                [defaultParamsCode]="true"
                [isVAcomponent]="true"
                [onlyOne]="true"
              ></ngx-multiple-grammar>
            </div>
          </div>
          <div class="mb-2 mt-2" *ngIf="data.enableCheckpoint == true">
            <div class="mb-2">
              <ngx-multiple-grammar
                #multipleGrammar
                [grammarArray]="grammarArray"
                type="LLM"
                [isLoadingGrammar]="isLoadingGrammar"
                [phraseListGrammar]="false"
                [functionGrammar]="false"
                [digitGrammar]="false"
                [builtInGrammar]="false"
              ></ngx-multiple-grammar>
            </div>
          </div>

          <div
            class="flex-title"
            style="margin-top: 1rem"
            *ngIf="data.enableCheckpoint == true"
          >
            <span class="title">Assign Variable</span>
            <span class="line"></span>
          </div>
          <div class="mb-2" *ngIf="data.enableCheckpoint == true">
            <label class="form-label label d-flex" style="align-items: center">
              <span>User Response</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the user response to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select User Response"
              selectAttribute="true"
              [propertyInput]="data.userResponse"
              (propertyEmit)="getUserResponse($event)"
            >
            </ngx-input-value-static>
          </div>
          <div class="mb-2" *ngIf="data.enableCheckpoint == true">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Intent</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the intent of the user to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Intent"
              selectAttribute="true"
              [propertyInput]="data.attribute"
              (propertyEmit)="getAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
          <div class="mb-2" *ngIf="data.enableCheckpoint == true">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Entity</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the entity of the user to the variable.You can get the value of the entity at variable by writing the following syntax: {variable_name->entity_type->0->sourceText}"
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Entity"
              selectAttribute="true"
              [propertyInput]="data.entityAttribute"
              (propertyEmit)="getEntityAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
          <div class="mb-2" *ngIf="data.enableCheckpoint == true">
            <label class="form-label label d-flex" style="align-items: center"
              ><span>Sentiment</span>
              <nb-icon
                class="ms-1"
                style="width: 15px"
                nbTooltip="This is the emotion in the sentence. Its value is one of 'positive', 'neutral', 'negative'."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </label>
            <ngx-input-value-static
              placeHolder="Select Sentiment"
              selectAttribute="true"
              [propertyInput]="data.sentiment"
              (propertyEmit)="getSentiment($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
<ng-template #templateRef>
  <nb-card class="example-box-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    ><nb-card-header
      style="
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: space-between;
        padding-left: 1.5rem;
      "
    >
      <nb-form-field style="width: 100%">
        <input
          type="text"
          nbInput
          fullWidth
          placeholder="Search Grammar"
          fieldSize="small"
          [(ngModel)]="searchText"
          (keyup)="filterData($event)"
        />
        <button
          nbSuffix
          nbButton
          ghost
          size="tiny"
          (click)="clear()"
          *ngIf="searchText != ''"
        >
          <nb-icon icon="close-circle"></nb-icon>
        </button>
      </nb-form-field>
      <button
        nbButton
        size="small"
        status="primary"
        type="button"
        (click)="addGrammar()"
      >
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
      <button nbButton size="small" ghost type="button" (click)="closeModal()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="grammar-add">
      <div class="mb-2">
        <nb-list class="custom-list" style="max-height: 25rem">
          <nb-list-item
            class="custom-list-item"
            *ngIf="grammarsData.length <= 0 && isLoading == false"
          >
            <div class="grammar-name-wrapper">
              <span class="grammar-name">Empty</span>
            </div>
            <div class="action-wrapper">
              <button
                style="opacity: 0"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
          <nb-list-item
            class="custom-list-item"
            *ngFor="let item of grammarsData; let x = index"
            (click)="addToObject(item)"
          >
            <div
              class="grammar-name-wrapper"
              style="overflow: hidden; word-break: break-all"
            >
              <span class="grammar-name">{{ item.name }}</span>
            </div>
            <div class="action-wrapper">
              <div style="margin-right: 5px">
                <span
                  class="btn-action failed"
                  *ngIf="item.status == 'FAILED' || item.status == 'False'"
                  >Failed</span
                >
                <span
                  class="btn-action not-train"
                  *ngIf="item.status == 'NOT-TRAINED' || item.status == ''"
                  >Not Trained</span
                >
                <span
                  class="btn-action success"
                  *ngIf="item.status == 'TRAINED' || item.status == 'True'"
                  >Trained</span
                >
                <span
                  class="btn-action training"
                  *ngIf="item.status == 'TRAINING' || item.status == null"
                  >Training</span
                >
                <label
                  class="btn-action not-train"
                  *ngIf="item.status == 'WAITING'"
                  >Waiting</label
                >
              </div>
              <button
                (click)="updateCustomGrammar($event, item)"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
              <button
                ghost
                (click)="removeDigitGrammar($event, item)"
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
        </nb-list>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card style="max-width: 500px; margin-bottom: 0; max-height: 500px">
    <nb-card-body style="font-size: 13px">
      <div style="margin-bottom: 0.5rem">
        You can use variables with JSON formatted values in the form below:
      </div>
      <pre [innerHTML]="example | prettyjson" style="margin-bottom: 0"></pre>
    </nb-card-body>
    <nb-card-footer style="padding-top: 0.5rem; padding-bottom: 0.5rem">
      <div style="width: 100%; display: flex; justify-content: end">
        <button
          nbButton
          type="button"
          size="small"
          [title]="copyTitle"
          (click)="copyAttribute()"
        >
          <nb-icon icon="copy-outline"></nb-icon>
        </button></div
    ></nb-card-footer>
  </nb-card>
</ng-template>
