<div
  class="action-config-wrapper"
  style="max-width: 550px !important; min-width: 550px !important"
>
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Carousel</ng-template>
        <div>
          <nb-radio-group
            [(ngModel)]="data.option"
            (valueChange)="handleChangeCardsVariable($event)"
            class="choice-option mb-2"
          >
            <nb-radio value="static">Static</nb-radio>
            <nb-radio value="dynamic">Dynamic</nb-radio>
          </nb-radio-group>
          <div class="mb-2">
            <div class="form-label d-flex" style="align-items: center">
              <label class="label"> Aspect ratio </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                [nbPopover]="aspectRatioTemplateRef"
                nbPopoverPlacement="bottom"
                nbPopoverTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
            <div
              style="display: flex; justify-content: flex-start"
              class="mb-2"
            >
              <button
                (click)="handleChangeImageRatio($event, 'horizontal')"
                nbButton
                outline
                title="Horizontal image aspect ratio"
                [status]="data.imageRatio == 'horizontal' ? 'primary' : 'basic'"
                style="
                  margin-right: 1rem;
                  padding: 0.5rem !important;
                  height: fit-content;
                "
              >
                <i class="bi bi-card-image" style="margin-right: 0.5rem"></i>
                Horizontal
              </button>
              <button
                (click)="handleChangeImageRatio($event, 'square')"
                title="Square image aspect ratio"
                [status]="data.imageRatio == 'square' ? 'primary' : 'basic'"
                nbButton
                outline
                style="
                  margin-right: 1rem;
                  padding: 0.5rem !important;
                  height: fit-content;
                "
              >
                <i class="bi bi-person-square" style="margin-right: 0.5rem"></i>
                Square
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="data.option == 'static'"
          class="mb-4"
          class="mb-4"
          style="
            display: flex;
            align-items: center;
            gap: 0.2rem;
            margin-bottom: o;
          "
        >
          <div class="button-action-card" style="left: 30px">
            <button
              [disabled]="cardIndex == 0"
              nbButton
              outline
              size="small"
              shape="round"
              (click)="backCard()"
            >
              <nb-icon icon="arrow-ios-back-outline"></nb-icon>
            </button>
          </div>
          <div style="position: relative; width: 100%">
            <nb-card
              style="flex: 1; margin-top: 0.5rem"
              status="basic"
              class="card-display"
            >
              <nb-card-header
                class="card-list-header"
                style="padding: 0.5rem 1rem !important; font-size: 14px"
              >
                <span style="color: #3367d6">Card {{ cardIndex + 1 }}</span>
                <div style="display: flex; gap: 0.2rem">
                  <button
                    [disabled]="cards.length >= 10"
                    type="button"
                    size="small"
                    ghost
                    nbButton
                    status="primary"
                    title="Duplicate Card"
                    (click)="duplicateCard(cardIndex)"
                  >
                    <nb-icon icon="copy-outline"></nb-icon>
                  </button>
                  <button
                    [disabled]="cards.length <= 1"
                    type="button"
                    size="small"
                    ghost
                    nbButton
                    status="primary"
                    title="Remove Card"
                    (click)="remover(cardIndex)"
                  >
                    <nb-icon icon="trash-2-outline"></nb-icon>
                  </button></div
              ></nb-card-header>
              <nb-card-body>
                <div class="mb-2" *ngIf="!cards[cardIndex].image">
                  <label
                    class="form-label label d-flex"
                    style="align-items: center"
                  >
                    <span>Image Sources</span>
                  </label>
                </div>
                <div class="d-flex mb-2">
                  <div
                    [nbSpinner]="isLoadingUpdate"
                    nbSpinnerSize="medium"
                    nbSpinnerStatus="primary"
                    class="play-wrapper image-wrapper"
                    *ngIf="
                      cards[cardIndex].imageType == 1 ||
                      cards[cardIndex].imageType == 2
                    "
                  >
                    <div class="action-wrapper tbl-action-image">
                      <button
                        ghost
                        class="tbl-action"
                        title="Edit"
                        nbButton
                        size="small"
                        (click)="
                          handleImage(
                            cards[cardIndex].imageType,
                            cards[cardIndex].image
                          )
                        "
                      >
                        <nb-icon icon="edit-2-outline"></nb-icon>
                      </button>
                    </div>
                    <div class="image-overview">
                      <a
                        [ngClass]="{
                          'disable-click': !cards[cardIndex].image
                        }"
                        data-fancybox="gallery"
                        [attr.data-caption]="
                          cards[cardIndex].image?.length > 200
                            ? (cards[cardIndex].image | slice : 0 : 200) + '...'
                            : cards[cardIndex].image
                        "
                        [href]="cards[cardIndex].image"
                      >
                        <img
                          *ngIf="cards[cardIndex].imageType == 1"
                          [src]="
                            cards[cardIndex].image ||
                            '../../../assets/images/default-error.jpg'
                          "
                          (error)="handleImageError($event)"
                          alt=""
                          [ngStyle]="{
                            'aspect-ratio':
                              data.imageRatio == 'horizontal' ? '19/10' : '1/1'
                          }"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="mb-2">
                  <label
                    class="form-label label d-flex"
                    style="align-items: center"
                  >
                    <span>Title</span>
                  </label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    placeholder="Title"
                    fieldSize="small"
                    [mention]="attributes"
                    [(ngModel)]="cards[cardIndex].title"
                    [mentionConfig]="mentionConfig"
                  />
                </div>
                <div class="mb-2">
                  <label
                    class="form-label label d-flex"
                    style="align-items: center"
                  >
                    <span>Description</span>
                  </label>
                  <textarea
                    type="text"
                    nbInput
                    fullWidth
                    placeholder="Description"
                    fieldSize="small"
                    [mention]="attributes"
                    [(ngModel)]="cards[cardIndex].description"
                    style="
                      max-height: 70px;
                      min-height: 70px;
                      padding-top: 0.5rem;
                      resize: none;
                    "
                    [mentionConfig]="mentionConfig"
                  ></textarea>
                </div>
                <div class="mb-2">
                  <div>
                    <label
                      class="form-label label d-flex"
                      style="align-items: center"
                    >
                      <span>Buttons List</span>
                    </label>
                    <nb-list
                      cdkDropList
                      class="example-list"
                      (cdkDropListDropped)="dropButton($event, cardIndex)"
                    >
                      <nb-list-item
                        style="padding: 0.5rem 1rem"
                        class="example-box"
                        *ngFor="
                          let item of cards[cardIndex].buttons;
                          let k = index
                        "
                        cdkDrag
                      >
                        <div class="button-name-wrapper">
                          <nb-icon
                            icon="text-outline"
                            status="basic"
                            *ngIf="item.type == 'text'"
                          ></nb-icon>
                          <nb-icon
                            icon="phone-outline"
                            status="basic"
                            *ngIf="item.type == 'make-call'"
                          ></nb-icon>
                          <nb-icon
                            icon="globe-2-outline"
                            status="basic"
                            *ngIf="item.type == 'open-web'"
                          ></nb-icon>
                          <span class="button-name">{{ item.text }}</span>
                        </div>
                        <div class="action-wrapper">
                          <button
                            ghost
                            class="tbl-action"
                            nbButton
                            size="small"
                            [nbPopover]="templateRef2"
                            nbPopoverTrigger="noop"
                            (click)="editButton(k)"
                            nbPopoverPlacement="left"
                          >
                            <nb-icon icon="settings-2-outline"></nb-icon>
                          </button>
                          <button
                            ghost
                            class="tbl-action"
                            nbButton
                            size="small"
                            (click)="removeButton(cardIndex, k)"
                          >
                            <nb-icon icon="trash-2-outline"></nb-icon>
                          </button>
                          <div
                            cdkDragHandle
                            class="move-icon"
                            style="display: flex; align-items: center"
                          >
                            <nb-icon
                              style="margin-right: -14px"
                              status="basic"
                              icon="more-vertical-outline"
                            ></nb-icon>
                            <nb-icon
                              status="basic"
                              icon="more-vertical-outline"
                            ></nb-icon>
                          </div>
                        </div>
                      </nb-list-item>
                    </nb-list>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      margin-top: 1rem;
                    "
                  >
                    <button
                      *ngIf="cards[cardIndex].buttons.length < 3"
                      nbButton
                      size="small"
                      outline
                      (click)="addNewButton(cardIndex)"
                    >
                      <nb-icon icon="plus-outline"></nb-icon>
                      Add Button
                    </button>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
          <div
            class="button-action-card"
            style="right: 30px"
            *ngIf="cards.length > 1 && cardIndex != cards.length - 1"
          >
            <button
              nbButton
              size="small"
              outline
              shape="round"
              (click)="nextCard()"
            >
              <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
            </button>
          </div>
          <div
            class="button-action-card"
            style="right: 30px"
            *ngIf="cards.length == 1 || cardIndex == cards.length - 1"
          >
            <button
              [disabled]="cards.length >= 10"
              nbButton
              size="small"
              shape="round"
              outline
              title="Add New Card"
              (click)="newCard()"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div
          *ngIf="data.option == 'static'"
          class="pagination-card"
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            cdkDrag
            *ngFor="let item of cards; let k = index"
            class="pagination-card-item"
          >
            <button
              nbButton
              size="small"
              (click)="changePosition(k)"
              outline
              [status]="k == cardIndex ? 'primary' : 'basic'"
            >
              <nb-icon icon="browser-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div *ngIf="data.option == 'dynamic'">
          <div class="mb-2">
            <div class="form-label d-flex" style="align-items: center">
              <label class="label"> Variable </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                [nbPopover]="templateRef"
                nbPopoverPlacement="bottom"
                nbPopoverTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>

            <ngx-input-value-static
              selectAttribute="true"
              [propertyInput]="data.dynamicCards"
              (propertyEmit)="getVariableDynamic($event)"
              (valueChange)="getVariableDynamic($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="mb-2">
          <div class="form-label d-flex" style="align-items: center">
            <label class="label"> Output </label>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Assign the value of the button clicked by the user to the variable."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </div>

          <ngx-input-value-static
            selectAttribute="true"
            [propertyInput]="data.output"
            (propertyEmit)="getVariable($event)"
          >
          </ngx-input-value-static>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    previewable="true"
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
    (handleBackCardAction)="backCard()"
    (handleNextCardAction)="nextCard()"
    type="cards"
    title="Preview default value"
    [data]="{ imageRatio: data.imageRatio, cards, cardIndex }"
  >
  </footer-action>
</div>
<mat-menu #menu="matMenu">
  <div style="padding: 10px 5px; font-size: 12px; gap: 0.2rem">
    <div
      *ngFor="let item of buttonType"
      style="padding: 5px; cursor: pointer; display: flex; gap: 0.5rem"
      class="hover-item"
      (click)="setButton(item.value)"
    >
      <nb-icon style="scale: 0.8" status="basic" [icon]="item.icon"></nb-icon>
      <span>{{ item.display }}</span>
    </div>
  </div>
</mat-menu>
<ng-template #templateRef>
  <nb-card style="max-width: 500px; margin-bottom: 0; max-height: 500px">
    <nb-card-body style="font-size: 13px">
      <div style="margin-bottom: 0.5rem">
        Accepts up to 10 cards and 3 buttons per card.
      </div>
      <div style="margin-bottom: 0.5rem">
        For buttons, we support you with 3 types: text, open-web, make-call.
      </div>
      <div style="margin-bottom: 0.5rem">
        You can use variables with JSON formatted values in the form below:
      </div>
      <div><pre [innerHTML]="example | prettyjson"></pre></div>
    </nb-card-body>
    <nb-card-footer style="padding-top: 0.5rem; padding-bottom: 0.5rem">
      <div style="width: 100%; display: flex; justify-content: end">
        <button
          nbButton
          type="button"
          size="small"
          [title]="copyTitle"
          (click)="copyAttribute()"
        >
          <nb-icon icon="copy-outline"></nb-icon>
        </button></div
    ></nb-card-footer>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card
    style="
      width: 450px;
      margin-bottom: 0;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    "
  >
    <nb-card-header style="padding: 0.5rem 1.5rem; font-size: 14px">
      <div class="d-flex justify-content-between">
        Edit
        <button
          (click)="hiddenAll()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body style="font-size: 13px; padding: 1.5rem">
      <div class="col-md-12 mb-2">
        <div class="form-label d-flex" style="align-items: center">
          <label class="label">Button Label</label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="The content is displayed on the button."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <input
          type="text"
          fieldSize="small"
          nbInput
          fullWidth
          [(ngModel)]="cards[cardIndex].buttons[buttonIndex].text"
          placeholder="Button Label"
          [mention]="attributes"
          [mentionConfig]="mentionConfig"
        />
      </div>
      <div class="form-label d-flex" style="align-items: center">
        <label
          class="label"
          *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'text'"
          >Value</label
        >
        <label
          class="label"
          *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'open-web'"
          >Web URL</label
        >
        <label
          class="label"
          *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'make-call'"
          >Phone Number</label
        >
        <nb-icon
          class="ms-1"
          style="color: #929bb3; width: 15px"
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
          [nbTooltip]="
            cards[cardIndex].buttons[buttonIndex].type == 'text'
              ? 'When the user selects this button, the flow will take value as their response instead of the button label.'
              : cards[cardIndex].buttons[buttonIndex].type == 'open-web'
              ? 'When the user selects this button, the flow will popup a website window.'
              : 'When the user selects this button, the flow will make a phone call.'
          "
        >
        </nb-icon>
      </div>
      <div class="row">
        <div class="col-md-1">
          <button
            nbButton
            size="small"
            [matMenuTriggerFor]="menu"
            (click)="setCurrent(cardIndex, buttonIndex)"
            [title]="
              cards[cardIndex].buttons[buttonIndex].type == 'text'
                ? 'Text'
                : cards[cardIndex].buttons[buttonIndex].type == 'open-web'
                ? 'Open Website'
                : 'Open Make A Phone Call'
            "
          >
            <nb-icon
              *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'text'"
              status="basic"
              icon="text-outline"
            ></nb-icon>
            <nb-icon
              *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'open-web'"
              status="basic"
              icon="globe-2-outline"
            ></nb-icon>
            <nb-icon
              *ngIf="cards[cardIndex].buttons[buttonIndex].type == 'make-call'"
              status="basic"
              icon="phone-outline"
            ></nb-icon>
          </button>
        </div>
        <div class="col-md-11">
          <input
            type="text"
            fieldSize="small"
            nbInput
            fullWidth
            [(ngModel)]="cards[cardIndex].buttons[buttonIndex].value"
            [placeholder]="
              cards[cardIndex].buttons[buttonIndex].type == 'text'
                ? 'Value'
                : cards[cardIndex].buttons[buttonIndex].type == 'open-web'
                ? 'Web URL'
                : 'Phone Number'
            "
            [mention]="attributes"
            [mentionConfig]="mentionConfig"
          />
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #aspectRatioTemplateRef>
  <nb-card style="margin-bottom: 0 !important; width: 500px">
    <nb-card-body class="p-4 d-flex flex-column gap-2">
      <span><strong> Image aspect ratio </strong></span>
      <p>
        Aspect ratio refers to the width of the photo in relation to its height.
        It&#39;s expressed as a ratio, like
        <strong>1:1 (Square) or 9:16 (Horizontal)</strong>, where the first
        number represents the width and the second represents height.
      </p>
    </nb-card-body>
  </nb-card>
</ng-template>
