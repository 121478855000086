import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { map, Observable, of, startWith } from "rxjs";
import { DataService } from "../../../../../@core/utils/data.service";
import { ValidInput } from "../../../../../@core/utils/helpers";
import {
  AudioClient,
  Code,
  ConvertAudioCommand,
  CreateAudioLanguageCommand,
  LanguageAllService,
  ServiceTTS,
} from "../../../../../System-api";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { NbDialogService } from "@nebular/theme";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { languageCode } from "./language-code";

@Component({
  selector: "setting-language",
  templateUrl: "./setting-language.component.html",
  styleUrls: ["./setting-language.component.scss"],
})
export class SettingLanguageComponent implements OnInit {
  @ViewChild("testAudio", { static: true })
  public testAudio: ElementRef;
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  listLanguageSetting: any = [];
  languageDefault: any = [];
  newLanguageSetting: any = {
    name: "",
    service: "",
    language: "",
    voiceId: "",
    gender: "",
    defaultUser: false,
  };
  listService;
  listCountry;
  listLanguage;
  listVoice;
  filteredService: Observable<any[]>;
  filteredLanguage: Observable<any[]>;
  filteredVoice: Observable<any[]>;
  callSettingLanguageForm: UntypedFormGroup;
  languageText: FormControl;
  textPreview = "Test Prompt";
  listVoiceCompare = [
    {
      name: "English",
      service: "2",
      language: "en-US",
      voiceId: "Salli",
      gender: "Female",
    },
  ];
  listVoiceTest = [];
  listLanguageTest = languageCode;
  isPlaying: boolean;
  audioBefore: CreateAudioLanguageCommand;
  dataAudio;
  currentService = null;
  currentVoice = null;
  currentVoiceCompare = null;
  filteredLanguageTest$: Observable<any[]>;
  isLoadingLanguage = null;
  isLoadingVoice = null;
  constructor(
    private dataService: DataService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private dialogService: NbDialogService
  ) {
    this.createForm();
    this.listService = [
      { name: "AmazonPolly", code: "2" },
      { name: "Google", code: "3" },
      { name: "Azure", code: "4" },
      { name: "Primas", code: "0" },
      { name: "Soundhound", code: "1" },
    ];
    this.setDefaultVoiceTest();
  }
  setDefaultVoiceTest() {
    this.listVoiceTest = [
      {
        name: "AmazonPolly",
        code: "2",
        language: "",
        languageName: "",
        voice: [],
      },
      {
        name: "Google",
        code: "3",
        language: "",
        languageName: "",
        voice: [],
      },
      {
        name: "Azure",
        code: "4",
        language: "",
        languageName: "",
        voice: [],
      },
      {
        name: "Primas",
        code: "0",
        language: "",
        languageName: "",
        voice: [],
      },
      {
        name: "Soundhound",
        code: "1",
        language: "",
        languageName: "",
        voice: [],
      },
    ];
  }
  private filter(value: string): any[] {
    const language = this.listLanguageTest.find((x) => x.name == value);
    if (language) {
      this.handleGetListData(language);
    }
    const filterValue = value.toLowerCase();
    return this.listLanguageTest.filter((optionValue) =>
      optionValue.name?.toLowerCase().includes(filterValue)
    );
  }
  handleGetListData(language: any) {
    let languageData = new LanguageAllService();
    languageData.name = language.name;
    languageData.code = new Code();
    languageData.code.amazon = language.code.amazon;
    languageData.code.google = language.code.google;
    languageData.code.azure = language.code.azure;
    languageData.code.primas = language.code.primas;
    languageData.code.soundhound = language.code.soundhound;
    this.audioClient.getAllVoice(languageData).subscribe((rs) => {
      this.setDefaultVoiceTest();
      if (rs.allVoiceDtos.length > 0) {
        this.listVoiceTest.forEach((item, index) => {
          const data = rs.allVoiceDtos.find((x) => {
            return x.code == item.code;
          });
          if (data) {
            this.listVoiceTest[index] = data;
          }
        });
      }
    });
  }
  deleteCompareVoice(i) {
    if (i != 0) {
      this.listVoiceCompare.splice(i, 1);
    } else {
      this.listVoiceCompare.shift();
    }
  }
  handleAddToListCompare(i, j) {
    const newVoice = this.listVoiceTest[i];
    const dataExist = this.listVoiceCompare.find((x) => {
      return (
        x.name == newVoice.languageName &&
        x.service == newVoice.code &&
        x.language == newVoice.language &&
        x.voiceId == newVoice.voice[j].voiceId &&
        x.gender == newVoice.voice[j].gender
      );
    });
    if (!dataExist) {
      this.listVoiceCompare.push({
        name: newVoice.languageName,
        service: newVoice.code,
        language: newVoice.language,
        voiceId: newVoice.voice[j].voiceId,
        gender: newVoice.voice[j].gender,
      });
    }
  }
  handleAddToList(i, j) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Are you sure you want to add to Language Setting?",
          textYes: "Yes",
          textNo: "Cancel",
          statusYes: "primary",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          const newVoice = this.listVoiceTest[i];
          this.newLanguageSetting.service = newVoice.code;
          this.newLanguageSetting.language = newVoice.language;
          this.newLanguageSetting.voiceId = newVoice.voice[j].voiceId;
          this.newLanguageSetting.gender = newVoice.voice[j].gender;
          this.newLanguageSetting.name = newVoice.languageName;
          this.handleAddLanguageData(this.newLanguageSetting);
          this.callSettingLanguageForm.reset();
        }
      });
  }
  handleAddCompareToList(i) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Are you sure you want to add to Language Setting?",
          textYes: "Yes",
          textNo: "Cancel",
          statusYes: "primary",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          const newVoice = this.listVoiceCompare[i];
          this.newLanguageSetting.service = newVoice.service;
          this.newLanguageSetting.language = newVoice.language;
          this.newLanguageSetting.voiceId = newVoice.voiceId;
          this.newLanguageSetting.gender = newVoice.gender;
          this.newLanguageSetting.name = newVoice.name;
          this.handleAddLanguageData(this.newLanguageSetting);
          this.callSettingLanguageForm.reset();
        }
      });
  }
  handleDownloadTTS(data, serviceCode, voice) {
    let file = this.dataURLtoFile("data:application/wav;base64," + data);
    const service = this.listService.find((x) => x.code == serviceCode);
    if (serviceCode == "2" || serviceCode == "3") {
      this.downloadData(service.name + "-" + voice + ".mp3", file);
    } else {
      this.downloadData(service.name + "-" + voice + ".wav", file);
    }
  }
  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  downloadTTS(i, j, type) {
    let dataDownload = new ConvertAudioCommand();
    let voice = "";
    let code = "";
    if (type != null) {
      const audio = this.listVoiceCompare[i];
      dataDownload.content = this.textPreview;
      dataDownload.service = Number(audio.service);
      dataDownload.language = audio.language;
      dataDownload.voiceId = audio.voiceId;
      code = audio.service;
      voice = audio.voiceId;
    } else {
      const audio = this.listVoiceTest[i];
      dataDownload.content = this.textPreview;
      dataDownload.service = Number(audio.code);
      dataDownload.language = audio.language;
      dataDownload.voiceId = audio.voice[j].voiceId;
      code = audio.code;
      voice = audio.voice[j].voiceId;
    }
    this.audioClient.convert(dataDownload).subscribe((result) => {
      this.handleDownloadTTS(result, code, voice);
    });
  }
  convertTTS(i, j) {
    this.currentService = i;
    this.currentVoice = j;
    const audio = this.listVoiceTest[i];
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }
    if (
      this.audioBefore.content == this.textPreview &&
      this.audioBefore.service == Number(audio.code) &&
      this.audioBefore.language == audio.language &&
      this.audioBefore.voiceId == audio.voice[j].voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = this.textPreview;
      this.audioBefore.service = Number(audio.code);
      this.audioBefore.language = audio.language;
      this.audioBefore.voiceId = audio.voice[j].voiceId;
      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.textPreview,
            service: Number(audio.code),
            language: audio.language,
            voiceId: audio.voice[j].voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }
  convertTTSCompare(i) {
    this.currentVoiceCompare = i;
    const audio = this.listVoiceCompare[i];
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }
    if (
      this.audioBefore.content == this.textPreview &&
      this.audioBefore.service == Number(audio.service) &&
      this.audioBefore.language == audio.language &&
      this.audioBefore.voiceId == audio.voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = this.textPreview;
      this.audioBefore.service = Number(audio.service);
      this.audioBefore.language = audio.language;
      this.audioBefore.voiceId = audio.voiceId;

      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.textPreview,
            service: Number(audio.service),
            language: audio.language,
            voiceId: audio.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }
  handleAutoHeight(event) {
    const textArea = document.getElementById("text-preview");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight + 2}px`;
      } else {
        textArea.style.height = "32.4px";
      }
    }
  }
  ngOnInit() {
    this.filteredLanguageTest$ = of(this.listLanguageTest);
    this.audioBefore = new CreateAudioLanguageCommand();
    this.languageText = new FormControl();
    this.filteredLanguageTest$ = this.languageText.valueChanges.pipe(
      startWith(""),
      map((filterString) => this.filter(filterString))
    );
    this.languageText.setValue("English (United States)");

    const language = this.listLanguageTest.find(
      (x) => x.name == "English (United States)"
    );
    if (language) {
      this.handleGetListData(language);
    }
    this.loadNationSetting();
    this.getService();
  }
  displayValue(event: any) {
    return event && event.name ? event.name : "";
  }
  displayLanguage(language: any) {
    return language && language.name ? language.name : "";
  }
  displayService(service: any) {
    return service && service.name ? service.name : "";
  }
  displayVoice(voice: any) {
    return voice && voice.name ? voice.name : "";
  }
  private _filterValue(name: string, listName: string): any[] {
    const filterValue = name.toLowerCase();
    if (listName == "nationService") {
      return this.listService.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "nationLanguage") {
      return this.listLanguage.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "nationVoice") {
      return this.listVoice.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  getService() {
    this.filteredService = this.callSettingLanguageForm
      .get("nationService")
      .valueChanges.pipe(
        startWith(""),
        map((value) => {
          const name = typeof value === "string" ? value : value?.name;
          return name
            ? this._filterValue(name as string, "nationService")
            : this.listService?.slice();
        })
      );
  }
  getLanguage(service: ServiceTTS) {
    if (service == ServiceTTS.Google) {
      this.isLoadingLanguage = true;

      this.audioClient.getLanguageGoogle().subscribe(
        (rs) => {
          const data = JSON.parse(rs);
          this.listLanguage = data?.languages;

          this.filteredLanguage = this.callSettingLanguageForm
            .get("nationLanguage")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name
                  ? this._filterValue(name as string, "nationLanguage")
                  : this.listLanguage?.slice();
              })
            );
          this.isLoadingLanguage = null;
        },
        (error) => {
          this.isLoadingLanguage = null;
        }
      );
    } else {
      this.isLoadingLanguage = true;
      this.audioClient.getLanguage(service).subscribe(
        (rs) => {
          this.listLanguage = rs?.languages;

          this.filteredLanguage = this.callSettingLanguageForm
            .get("nationLanguage")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name
                  ? this._filterValue(name as string, "nationLanguage")
                  : this.listLanguage?.slice();
              })
            );
          this.isLoadingLanguage = null;
        },
        (error) => {
          this.isLoadingLanguage = null;
        }
      );
    }
  }
  getVoiceByLanguage(service: ServiceTTS, languageCode: string) {
    if (languageCode) {
      this.listVoice = [];
      this.isLoadingVoice = true;
      this.audioClient.getVoiceByLanguage(service, languageCode).subscribe(
        (rs) => {
          if (service == ServiceTTS.Google) {
            const x = JSON.parse(rs.voices[0].name);
            x.voices?.forEach((item) => {
              var id = { id: item.name };
              var gender = { gender: item.ssmlGender };
              item = { ...item, ...id, ...gender };
              delete item["ssmlGender"];
              delete item["naturalSampleRateHertz"];
              delete item["languageCodes"];
              const dataExist = this.listVoice.find((x) => x.name == item.name);
              if (!dataExist) {
                this.listVoice.push(item);
              }
            });
          } else {
            this.listVoice = rs?.voices;
          }
          this.filteredVoice = this.callSettingLanguageForm
            .get("nationVoice")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name
                  ? this._filterValue(name as string, "nationVoice")
                  : this.listVoice?.slice();
              })
            );
          this.isLoadingVoice = null;
        },
        (error) => {
          this.isLoadingVoice = null;
        }
      );
    }
  }
  createForm() {
    this.callSettingLanguageForm = this.formBuilder.group({
      nationService: ["", ValidInput.required],
      nationLanguage: ["", ValidInput.required],
      nationVoice: ["", ValidInput.required],
    });
  }
  loadNationSetting() {
    this.listLanguageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    this.languageDefault = JSON.parse(
      this.dataService.CallSetting.defaultLanguage
    );
  }
  addNewLanguageSetting() {
    this.newLanguageSetting.service =
      this.callSettingLanguageForm.value?.nationService?.code;
    this.newLanguageSetting.language =
      this.callSettingLanguageForm.value?.nationLanguage?.code;
    this.newLanguageSetting.voiceId =
      this.callSettingLanguageForm.value?.nationVoice?.name;
    this.newLanguageSetting.gender =
      this.callSettingLanguageForm.value?.nationVoice?.gender;
    this.newLanguageSetting.name =
      this.callSettingLanguageForm.value?.nationLanguage?.name;
    this.handleAddLanguageData(this.newLanguageSetting);
    this.callSettingLanguageForm.reset();
  }
  setDefaultLanguage(index: any) {
    this.listLanguageSetting.forEach((element, index) => {
      if (element.defaultUser == true) {
        this.listLanguageSetting[index].defaultUser = false;
      }
    });
    this.listLanguageSetting[index].defaultUser = true;
    this.handleDefaultUserTop();
    this.callSetting.languageSetting = JSON.stringify(this.listLanguageSetting);
  }
  handleDefaultUserTop() {
    this.listLanguageSetting.forEach((element) => {
      if (element.defaultUser === true) {
        this.listLanguageSetting = this.listLanguageSetting.filter(
          (item) => item !== element
        );
        this.listLanguageSetting.unshift(element);
      }
    });
  }
  getIcon(languageSetting: any) {
    if (languageSetting) {
      return "checkmark-circle-2";
    }
    return "checkmark-circle-2-outline";
  }
  handleEditLanguage(language: any) {
    if (language) {
      let nameService;
      this.listService.forEach((item) => {
        if (item.code == language.service) {
          nameService = item.name;
        }
      });
      this.callSettingLanguageForm.controls["nationService"].setValue({
        name: nameService,
        code: language.service,
      });
      this.onChangeService({
        name: nameService,
        code: language.service,
      });
      this.callSettingLanguageForm.controls["nationLanguage"].setValue({
        name: language.name,
        code: language.language,
      });
      this.setLanguage({
        name: language.name,
        code: language.language,
      });
      this.callSettingLanguageForm.controls["nationVoice"].setValue({
        id: language.voiceId,
        name: language.voiceId,
        gender: language.gender,
      });
      this.onChangeVoice({
        id: language.voiceId,
        name: language.voiceId,
        gender: language.gender,
      });
    }
  }
  removeLanguage(index: any, name: string) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "Are you sure you want to delete " + name + " ?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          if (this.listLanguageSetting.length > 1) {
            if (index != 0) {
              this.listLanguageSetting.splice(index, 1);
            } else {
              this.listLanguageSetting.shift();
            }
            this.callSetting.languageSetting = JSON.stringify(
              this.listLanguageSetting
            );
          }
        }
      });
  }
  handleAddLanguageData(data: any) {
    let check = 0;
    this.listLanguageSetting.forEach((item, index) => {
      const itemCode = item.language.split("-");
      const newItemCode = data.language.split("-");
      if (itemCode[0] == newItemCode[0] || item.name == data.name) {
        data.defaultUser = item.defaultUser;
        this.listLanguageSetting[index] = data;
        check = 1;
        this.handleDefaultUserTop();
        this.callSetting.languageSetting = JSON.stringify(
          this.listLanguageSetting
        );
      }
    });
    if (check == 0) {
      this.listLanguageSetting.push(data);
      this.handleDefaultUserTop();
      this.callSetting.languageSetting = JSON.stringify(
        this.listLanguageSetting
      );
    }
    this.newLanguageSetting = {
      name: "",
      service: "",
      language: "",
      voiceId: "",
      gender: "",
    };
  }
  checkInvalid(type: string, event: any) {
    let check = false;
    let name = event?.name || event;
    if (name) {
      switch (type) {
        case "service":
          this.listService?.forEach((service) => {
            if (service.name == name) {
              check = true;
            }
          });
          break;
        case "language":
          this.listLanguage?.forEach((language) => {
            if (language.name == name || language.name) {
              check = true;
            }
          });
          break;
        case "voice":
          this.listVoice?.forEach((voice) => {
            if (voice.name == name) {
              check = true;
            }
          });
          break;
        default:
          break;
      }
      return check;
    } else {
      return true;
    }
  }
  onChangeService(event: any) {
    if (this.checkInvalid("service", event)) {
      this.callSettingLanguageForm.controls["nationLanguage"].setValue(
        undefined
      );
      this.callSettingLanguageForm.controls["nationVoice"].setValue(undefined);
      switch (Number(event.code)) {
        case ServiceTTS.Primas:
          this.getLanguage(ServiceTTS.Primas);
          break;
        case ServiceTTS.AmazonPolly:
          this.getLanguage(ServiceTTS.AmazonPolly);
          break;
        case ServiceTTS.Soundhound:
          this.getLanguage(ServiceTTS.Soundhound);
          break;
        case ServiceTTS.Azure:
          this.getLanguage(ServiceTTS.Azure);
          break;
        case ServiceTTS.Google:
          this.getLanguage(ServiceTTS.Google);
          break;
        default:
          break;
      }
    } else {
      this.callSettingLanguageForm.controls["nationService"].setErrors({
        required: true,
      });
    }
  }
  setLanguage(event: any) {
    this.callSettingLanguageForm.controls["nationVoice"].setValue(undefined);
    const serviceCode =
      this.callSettingLanguageForm.controls["nationService"]?.value?.code;
    if (event || event != undefined) {
      switch (Number(serviceCode)) {
        case ServiceTTS.Primas:
          this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
          break;
        case ServiceTTS.AmazonPolly:
          this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
          break;
        case ServiceTTS.Soundhound:
          this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
          break;
        case ServiceTTS.Azure:
          this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
          break;
        case ServiceTTS.Google:
          this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
          break;
        default:
          break;
      }
    }
  }
  onChangeLanguage(event: any) {
    if (this.checkInvalid("language", event)) {
      this.callSettingLanguageForm.controls["nationVoice"].setValue(undefined);
      const serviceCode =
        this.callSettingLanguageForm.controls["nationService"]?.value?.code;
      if (event || event != undefined) {
        switch (Number(serviceCode)) {
          case ServiceTTS.Primas:
            this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
            break;
          case ServiceTTS.AmazonPolly:
            this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
            break;
          case ServiceTTS.Soundhound:
            this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
            break;
          case ServiceTTS.Azure:
            this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
            break;
          case ServiceTTS.Google:
            this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
            break;
          default:
            break;
        }
      }
    } else {
      this.callSettingLanguageForm.controls["nationLanguage"].setErrors({
        required: true,
      });
    }
  }
  onChangeVoice(event: any) {
    if (!this.checkInvalid("voice", event)) {
      this.callSettingLanguageForm.controls["nationVoice"].setErrors({
        required: true,
      });
    }
  }
  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
}
