import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { Observable, map, of } from "rxjs";
import { DataService } from "../../../../@core/utils/data.service";
import { EventBusService } from "../../../../@core/utils/eventbus.service";
import { DialogAttributeComponent } from "../dialog-attribute/dialog-attribute.component";
import { isJSON, ValidInput } from "../../../../@core/utils/helpers";
import { NgSelectComponent } from "@ng-select/ng-select";
import { FormControl } from "@angular/forms";

@Component({
  selector: "input-static-functions",
  templateUrl: "./input-static-functions.component.html",
  styleUrls: ["./input-static-functions.component.scss"],
})
export class InputStaticFunctions implements OnInit, OnDestroy, OnChanges {
  @Output() propertyEmit = new EventEmitter<string>();
  @Input() propertyInput: string;
  @Input() title: string;
  @Input() propertyName: string = "";
  @Input() propertyValue: string;
  @Input() selectAttribute: boolean;
  @Input() disabled: boolean;
  @Input() onlyValue: boolean = false;
  @Input() disabledAddNew: boolean = false;
  @Input() maxHeight: boolean = true;
  @Input() filteredOptions$: Observable<any[]> = of(
    this.dataService.ivrAttribute
  );

  @ViewChildren(NgSelectComponent)
  selectComponents: QueryList<NgSelectComponent>;

  properties: Property[] = [];
  ivrFunction: any = this.dataService.ivrFunction;
  attributes: any = this.dataService.ivrAttribute;
  oldPropertyInput: any[] = [];
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  listCondition = [
    { name: "Yes or No", description: "", value: "Yes or No" },
    { name: "Date (mm-dd-yyyy)", description: "", value: "Date (mm-dd-yyyy)" },
    { name: "Phone number", description: "", value: "Phone number" },
    { name: "Number", description: "", value: "Number" },
    { name: "Email", description: "", value: "Email" },
    { name: "To Lowercase", description: "", value: "toLowerCase" },
    { name: "To Uppercase", description: "", value: "toUpperCase" },
    { name: "Trim", description: "", value: "trim" },
    { name: "Length", description: "", value: "length" },
    { name: "Pop", description: "", value: "pop" },
    { name: "Shift", description: "", value: "shift" },
    { name: "Reverse", description: "", value: "reverse" },
    { name: "Sort", description: "", value: "sort" },
    { name: "Reverse Sort", description: "", value: "rsort" },
    { name: "To String", description: "", value: "toString" },
    { name: "To Int", description: "", value: "parseInt" },
    { name: "To Float", description: "", value: "parseFloat" },
    { name: "To Boolean", description: "", value: "parseBoolean" },
  ];

  filteredConditions$: Observable<any[]> = of(this.listCondition);
  constructor(
    public dataService: DataService,
    private eventBusService: EventBusService,
    private dialogService: NbDialogService
  ) {
    this.eventBusService.on("clickConfirmTestFunction", (isTrue: boolean) => {
      if (isTrue) {
        this.propertyEmit.emit(this.propertyToString());
      }
    });
  }

  addTagFn = (term) => {
    let newStr = term?.trim().replace(/[{}]/g, "");
    let validateNewStr = new FormControl(newStr, ValidInput.variable);
    if (validateNewStr.valid) {
      this.dataService.ivrAttribute.push({
        default: 0,
        value: newStr,
      });
      this.dataService.ivrAttributeArray.push(newStr);
    }
    return newStr;
  };
  onChangeContent(event, i) {
    const textArea = document.getElementById("textarea-value-" + i);
    if (textArea) {
      if (event?.target?.value?.trim()) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = "32.4px";
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.stringToProperty();
  }

  ngOnDestroy(): void {
    this.propertyEmit.emit(this.propertyToString());
  }
  getValue(): void {
    this.propertyEmit.emit(this.propertyToString());
  }
  getData() {
    return JSON.stringify(this.properties);
  }

  ngOnInit() {
    if (!this.disabledAddNew) {
      this.filteredOptions$ = this.filteredOptions$.pipe(
        map((options) => [{ default: "VARIABLE", value: "" }, ...options])
      );
    }
    if (this.propertyInput) {
      let data = [];
      try {
        data = JSON.parse(this.propertyInput);
      } catch (error) {}
      let customVariable = [];
      data?.forEach((element) => {
        let dataExist = this.dataService.ivrAttribute.find(
          (x) => x.value == element.name
        );
        if (
          !dataExist &&
          element?.name?.trim() != "" &&
          element?.name != null
        ) {
          if (!customVariable.includes(element.name?.trim())) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [
                ...options,
                { default: element.name, value: element.name },
              ])
            );
            customVariable.push(element.name?.trim());
          }
        }
      });
    }
    this.stringToProperty();
  }

  addProperty() {
    let property = new Property();
    property.name = null;
    if (this.onlyValue == true) {
      property.name = this.propertyName;
    }
    property.value = null;
    this.properties.push(property);
  }

  propertyToString() {
    for (const prop of this.properties) {
      prop.name = prop.name?.trim();
      prop.function = prop.function?.trim();
      prop.value = prop.value?.trim();
    }
    this.properties = this.properties.filter((prop) => {
      if (prop.name && prop.function && prop.value) {
        return true;
      }
    });

    return JSON.stringify(this.properties);
  }

  selectVariable(value, index) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [...options])
            );
            this.selectComponents.toArray()[index].writeValue(rs);
            this.properties[index].name = rs;
            this.oldPropertyInput[index] = rs;
          } else {
            this.selectComponents
              .toArray()
              [index].writeValue(this.oldPropertyInput[index]);
            this.properties[index].name = this.oldPropertyInput[index];
          }
        });
    } else {
      if (value && typeof value == "string") {
        this.oldPropertyInput[index] = value?.trim();
        this.properties[index].name = value?.trim(); //custom variable
      }
      if (value && typeof value == "object") {
        this.oldPropertyInput[index] = value?.value?.trim();
        this.properties[index].name = value?.value?.trim(); //custom variable
      }
    }
  }

  dropValue($event, index) {
    this.properties[index].value +=
      "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  removeProperty(index) {
    this.properties.splice(index, 1);
    this.oldPropertyInput.splice(index, 1);
  }

  stringToProperty() {
    this.properties = [];
    if (this.propertyInput != "") {
      if (isJSON(this.propertyInput)) {
        this.properties = JSON.parse(this.propertyInput);
      } else {
      }
    }
  }
}

export class Property {
  name: string;
  function: string;
  value: string;
}
