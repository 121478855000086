import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { NbWindowRef, NbDialogService } from "@nebular/theme";
import { MxCell } from "../../../../@core/model/mxgraph";
import { PlayAudioData } from "../../../../@core/model/playaudio";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { AudioClient } from "../../../../System-api";
@Component({
  selector: "callflows-playaudio",
  templateUrl: "./callflows-playaudio.html",
  styleUrls: ["./callflows-playaudio.scss"],
})
export class PlayAudioComponent implements OnInit, AfterViewInit {
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  selectedSubscription: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();
  data: PlayAudioData = new PlayAudioData();
  cell: MxCell;
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech or Chat Text" },
  ];
  optionsMode: object[] = [
    { id: 1, name: "Random" },
    { id: 2, name: "Sequential" },
  ];
  audioList: string[] = [];
  audios: any;
  listLanguage;
  dataTTSCB: any = [];
  listVoice;
  dataTTS: any = [];
  cancelAction = false;
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private audioClient: AudioClient,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {}
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.contentChatBot = this.data.textChatBot;
      this.data.text = "";
      this.data.textChatBot = "";
    }
  }
  ngOnInit() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        let audioName = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
            audioName.push(obj.name);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioName = audio !== undefined ? audioName.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeech.selectedTTSBot.subscribe(
      (tts) => {
        this.data.contentChatBot = JSON.stringify(tts);
      }
    );
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.convertToNewDataFormat();
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    if (this.data.contentChatBot) {
      this.textToSpeech.selectCurrentTTSBot(this.data.contentChatBot);
    }
    if (this.data.allowBreak) {
      this.data.allowBreak = this.data.allowBreak?.toString() === "true";
    } else {
      this.data.allowBreak = false;
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }

    this.textToSpeech.isChatBot = true;

    this.windowRef.onClose.subscribe(() => this.submit());
  }

  allowLog($event) {
    this.data.log = $event;
  }
  allowBreak($event) {
    this.data.allowBreak = $event;
  }
  handleSentiment($event) {
    this.data.sentiment = $event;
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }

  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
    }
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
}
