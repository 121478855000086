<div class="action-config-wrapper" style="min-width: 650px; max-width: 90vw">
  <div class="config-action">
    <nb-radio-group [(ngModel)]="data.type" class="choice-option mb-2">
      <nb-radio value="AND">AND</nb-radio>
      <nb-radio value="OR">OR</nb-radio>
    </nb-radio-group>
    <div *ngIf="data.type == 'AND'" class="mt-2" style="font-size: 13px">
      Use this option to return success if matches
      <span style="color: #3a62dc; font-weight: 600">all</span> conditions
      below.
    </div>
    <div *ngIf="data.type == 'OR'" class="mt-2" style="font-size: 13px">
      Use this option to return success if matches
      <span style="color: #3a62dc; font-weight: 600">any</span> conditions
      below.
    </div>
    <div class="flex-title" style="margin-top: 1rem">
      <span class="title">Condition(s)</span>
      <span class="line"></span>
    </div>
    <div style="padding-bottom: 200px">
      <input-static-conditions
        style="flex: 1"
        selectAttribute="true"
        [propertyInput]="data.attribute"
        propertyName="Variable"
        propertyValue="Value"
        (propertyEmit)="getAttribute($event)"
      ></input-static-conditions>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
