import { EventEmitter, Injectable } from "@angular/core";
import {
  MXUTILITIES,
  MxGraph,
  MxPoint,
  MxCellOverlay,
  MxImage,
  MxCodec,
  MxCell,
  MxGeometry,
  MxImageExport,
  MxSvgCanvas2D,
  mx,
  MxRectangle,
} from "../model/mxgraph";
import { EventData } from "../../shared/model/eventdata";
import { EventBusService } from "./eventbus.service";
import { RevisionIcon } from "../constants/revision-icon";
import { NbDialogService } from "@nebular/theme";
import { AlertDialogComponent } from "../../shared/alert-dialog/alert-dialog.component";

@Injectable()
export class GraphHandlerService {
  reloadMenu: EventEmitter<any> = new EventEmitter<any>();
  closeMenuAction: EventEmitter<any> = new EventEmitter<any>();
  firstChange: boolean = false;
  constructor(
    private eventBusService: EventBusService,
    private dialogService: NbDialogService
  ) {}

  addChild(graph: MxGraph, cell, application, connectionLabel = "") {
    var model = graph.getModel();
    var parent = graph.getDefaultParent();
    var vertex;
    model.beginUpdate();
    try {
      var doc = MXUTILITIES.mxUtils.createXmlDocument();
      var applicationValue = doc.createElement(application.id);
      for (let [key, value] of Object.entries(application.data)) {
        applicationValue.setAttribute(key, value);
      }
      applicationValue.setAttribute("applicationId", application.id);

      vertex = graph.insertVertex(
        parent,
        null,
        applicationValue,
        cell.getGeometry().getPoint().x,
        cell.getGeometry().getPoint().y + 150,
        130,
        50,
        `image=${application.iconImage};`
      );

      let edgeColor = "#3367D6";
      if (
        connectionLabel.indexOf("Press") !== -1 ||
        connectionLabel.indexOf("Say") !== -1
      ) {
        edgeColor = "#0DAA5C";
      } else if (connectionLabel.indexOf("Other") !== -1) {
        edgeColor = "#FF4332";
      } else if (connectionLabel == "valid-time") {
        edgeColor = "#00D68F";
        connectionLabel = "Valid time";
      } else if (connectionLabel == "invalid-time") {
        edgeColor = "#FF4332";
        connectionLabel = "Invalid time";
      } else if (connectionLabel == "selected") {
        edgeColor = "#00D68F";
        connectionLabel = "Selected";
      } else if (connectionLabel == "not-selected") {
        edgeColor = "#FF4332";
        connectionLabel = "Not selected";
      } else if (connectionLabel == "else") {
        edgeColor = "#FF4332";
        connectionLabel = "Else";
      } else if (connectionLabel == "agent-unavailable") {
        edgeColor = "#FF4332";
        connectionLabel = "Empty agent";
      }

      var edge = graph.insertEdge(
        parent,
        null,
        connectionLabel,
        cell,
        vertex,
        `strokeColor=${edgeColor};`
      );
      edge.geometry.offset = new MxPoint(
        edge.geometry.getCenterX(),
        edge.geometry.getCenterY() + 15
      );
      edge.geometry.relative = true;

      graph.refresh(edge);
      this.addOverlays(graph, vertex, true);
      this.addPortRef(graph, cell);
    } finally {
      model.endUpdate();
      // this.childCreated.emit(vertex);
    }
    return vertex;
  }

  /** Start of the drag drop application . */
  updateApplication(graph: MxGraph, element, application) {
    var doc = MXUTILITIES.mxUtils.createXmlDocument();
  }
  addApplication(graph: MxGraph, element, application) {
    graph.getModel().beginUpdate();
    var parent = graph.getDefaultParent();

    var doc = MXUTILITIES.mxUtils.createXmlDocument();
    var applicationValue = doc.createElement(application.id);
    for (let [key, value] of Object.entries(application.data)) {
      applicationValue.setAttribute(key, value);
    }
    applicationValue.setAttribute("applicationId", application.id);
    const cell = new mx.mxCell(
      application.name,
      new MxGeometry(0, 0, 130, 50),
      `image=${application.iconImage};`
    );

    cell.vertex = true;
    cell.value = applicationValue;

    this.addOverlays(graph, cell, true);
    this.addPortRef(graph, cell);
    this.createDragSource(
      element,
      this.createDropHandler([cell], true),
      this.createDragPreview(element),
      graph
    );

    graph.getModel().endUpdate();
    // this.childCreated.emit(cell);
  }

  createDragPreview(element) {
    const newDiv = element.cloneNode(true);
    newDiv.style.width = "190px";
    newDiv.style.height = "35px";
    newDiv.style.opacity = "0.8";
    newDiv.style.fontWeight = "700";
    newDiv.style.transform = "translateX(-100px) translateY(-20px)";
    newDiv.style.backgroundColor = "white";
    return newDiv;
  }

  createDragSource(elt, dropHandler, preview, graph: MxGraph) {
    var dragSource = MXUTILITIES.mxUtils.makeDraggable(
      elt,
      graph,
      dropHandler,
      preview,
      null,
      null,
      graph.autoScroll,
      true
    );
    dragSource.isGuidesEnabled = function () {
      return graph.graphHandler.guidesEnabled;
    };
    return dragSource;
  }

  createDropHandler(cells, allowSplit) {
    return (graph, evt, target, x, y) => {
      var graphCells = graph.getModel().cells;
      for (var cell in graphCells) {
        if (graphCells[cell].value && typeof(graphCells[cell].value) === "object") {
          if (
            graphCells[cell].value.tagName === "IntentRouting" &&
            cells[0].value.tagName === "IntentRouting"
          ) {
            this.dialogService.open(AlertDialogComponent, {
              autoFocus: false,
              context: {
                title: "Notifications",
                question: "You can only use one Intent Routing in the flow.",
                textYes: "OK",
                statusYes: "info",
              },
            });
            return;
          }
        }
      }
      if (graph.canImportCell(cells[0])) {
        var parent = graph.getDefaultParent();
        graph.getModel().beginUpdate();
        try {
          var selectedCell = graph.importCells(cells, x, y, parent);
          if (selectedCell != null && selectedCell.length > 0) {
            graph.scrollCellToVisible(cells[0]);
            graph.setSelectionCells(cells);
          }
        } finally {
          graph.getModel().endUpdate();
        }

        graph.setSelectionCells(selectedCell);
      }
    };
  }
  /** End of the drag drop application . */

  addPortRef(graph: MxGraph, cell: MxCell) {
    //define valid and invalid port
    var doc = MXUTILITIES.mxUtils.createXmlDocument();

    var portSuccess = doc.createElement("PortRef");
    portSuccess.setAttribute("applicationId", "PortRef");
    portSuccess.setAttribute("case", "Success");

    var portFail = doc.createElement("PortRef");
    portFail.setAttribute("applicationId", "PortRef");
    portFail.setAttribute("case", "Fail");

    var portOption = doc.createElement("PortRef");
    portOption.setAttribute("applicationId", "PortRef");
    portOption.setAttribute("case", "Option");
    if (cell.getAttribute("multipleTransition")) {
      const id = cell.getAttribute("applicationId");

      var responsed = graph.insertVertex(
        cell,
        "PortRef",
        portSuccess,
        0.5,
        1,
        8,
        8,
        `port;image=/assets/images/check.png;imageAlign=center;imageWidth=20;imageHeight=20`,
        true
      );
      responsed.geometry.offset = new MxPoint(-5, -5);

      var other = graph.insertVertex(
        cell,
        "PortRef",
        portFail,
        0.2,
        1,
        8,
        8,
        `port;image=/assets/images/error.png;imageAlign=center;imageWidth=12;imageHeight=12`,
        true
      );
      other.geometry.offset = new MxPoint(-5, -5);
      if (id.includes("ChatGPT")) {
        var otherOption = graph.insertVertex(
          cell,
          "PortRef",
          portOption,
          0.8,
          1,
          8,
          8,
          `port;image=/assets/images/trigger-check.png;imageAlign=center;imageWidth=16.5;imageHeight=16.5`,
          true
        );
      }
      if (id.includes("Dial")) {
        var otherOption = graph.insertVertex(
          cell,
          "PortRef",
          portOption,
          0.8,
          1,
          8,
          8,
          `port;image=/assets/images/redirect.png;imageAlign=center;imageWidth=12;imageHeight=12`,
          true
        );
      }
      otherOption.geometry.offset = new MxPoint(-5, -5);
    } else if (cell.getAttribute("transition")) {
      var responsed = graph.insertVertex(
        cell,
        "PortRef",
        portSuccess,
        0.7,
        1,
        8,
        8,
        `port;image=/assets/images/check.png;imageAlign=center;imageWidth=20;imageHeight=20`,
        true
      );
      responsed.geometry.offset = new MxPoint(-5, -5);

      var other = graph.insertVertex(
        cell,
        "PortRef",
        portFail,
        0.3,
        1,
        8,
        8,
        `port;image=/assets/images/error.png;imageAlign=center;imageWidth=12;imageHeight=12`,
        true
      );
      other.geometry.offset = new MxPoint(-5, -5);
    }
  }

  addOverlays(graph: MxGraph, cell, addOverlay) {
    //add delete icon for action
    if (addOverlay) {
      var overlay = new MxCellOverlay(
        new MxImage("/assets/images/delete_60px.png", 10, 10),
        "Delete"
      );
      overlay.cursor = "hand";
      overlay.offset = new MxPoint(-12, 8);
      overlay.align = MXUTILITIES.mxConstants.ALIGN_RIGHT;
      overlay.verticalAlign = MXUTILITIES.mxConstants.ALIGN_TOP;
      overlay.addListener(
        MXUTILITIES.mxEvent.CLICK,
        MXUTILITIES.mxUtils.bind(this, (sender, evt) => {
          const currentCell = evt.getProperty("cell");
          graph.removeCells([graph.model.getCell(currentCell.id)]);
          this.eventBusService.emit(new EventData("DeletedActionEvent", true));
          if (this.firstChange == false) {
            this.eventBusService.emit(new EventData("MxGraphChanges", graph));
            this.firstChange = true;
          }
        })
      );
      graph.addCellOverlay(cell, overlay);

      //add icon warning for action
      if (
        cell.getAttribute("isRequired") == "true" &&
        cell.getAttribute("checkFields") != "true"
      ) {
        var overlayRequired = new MxCellOverlay(
          new MxImage("/assets/images/warning.png", 14, 14),
          "Missing required field"
        );
        overlayRequired.align = MXUTILITIES.mxConstants.ALIGN_LEFT;
        overlayRequired.verticalAlign = MXUTILITIES.mxConstants.ALIGN_TOP;
        graph.addCellOverlay(cell, overlayRequired);
      }
    }
  }

  parseFromXmlToGraph(graph: MxGraph, xml: any) {
    var doc = MXUTILITIES.mxUtils.parseXml(xml);
    try {
      //begin replacing xml to current graph
      graph.getModel().beginUpdate();

      // get necessary variables for replacing
      var codec = new MxCodec(doc);
      var model = graph.getModel();
      // var parent = model.cells[1];
      // the result is the new replaced model
      const result = codec.decode(doc.documentElement, model);

      // get all vertices to add deleteIcon and addIcon
      var vertices = graph.getChildVertices(model.cells[1]);

      vertices.forEach((cell) => {
        // add addIcon for every cells, add deleteIcon for every cells except root cell
        let addOverlay = cell.getId() != "treeRoot";
        if (addOverlay != false) {
          addOverlay = cell.getId() != "eventRoot";
        }
        if (addOverlay != false) {
          addOverlay = cell.getId() != "errorRoot";
        }
        if (addOverlay != false) {
          addOverlay = cell.getId() != "inHookRoot";
        }
        if (addOverlay != false) {
          addOverlay = cell.getId() != "outHookRoot";
        }
        if (addOverlay != false) {
          addOverlay = !cell.style.includes("shape=note");
        }
        this.addOverlays(graph, cell, addOverlay);
      });
    } finally {
      //end replacing xml to current graph
      graph.getModel().endUpdate();
      return doc;
    }
  }

  getAllAction(cell: MxCell) {
    let listAction = [];
    cell.parent.children.forEach((action) => {
      if (
        action.value != "" &&
        action.id != "treeRoot" &&
        action.id != cell.id &&
        action.edge == false
      ) {
        listAction.push({
          id: action.id,
          value: action.getAttribute("name"),
          vertex: action,
        });
      }
    });
    return listAction;
  }

  handleRemoveEdgeByStatus(graph: MxGraph, cell: MxCell, status, event) {
    let maxStatus = 0;
    let allMxCell = cell.parent.children;
    allMxCell.forEach((obj) => {
      if (obj.value == status) {
        maxStatus++;
      }
    });

    if (maxStatus >= 2) {
      allMxCell.forEach((obj) => {
        if (obj?.source?.id == cell?.id && obj?.target?.id != event) {
          graph.removeCells([graph.model.getCell(obj.id)]);
        }
      });
    }
  }

  handleRemoveEdgeByTarget(graph: MxGraph, cell: MxCell, status, event) {
    let maxTarget = 0;
    let allMxCell = cell.parent.children;
    allMxCell.forEach((obj) => {
      if (obj?.target?.id == event && obj?.source?.id == cell.id) {
        maxTarget++;
      }
    });
    if (maxTarget >= 2) {
      allMxCell.forEach((obj) => {
        if (
          obj?.target?.id == event &&
          obj?.source?.id == cell.id &&
          obj?.value != status
        ) {
          graph.removeCells([graph.model.getCell(obj.id)]);
        }
      });
    }
  }

  handleRemoveEdge(graph: MxGraph, cell: MxCell, status, event) {
    let allMxCell = cell.parent.children;
    allMxCell.forEach((obj) => {
      if (
        (obj?.source?.id == cell?.id && obj?.target?.id == event) ||
        (obj?.source?.id == cell?.id && obj?.value == status)
      ) {
        graph.removeCells([graph.model.getCell(obj.id)]);
      } else return;
    });
  }

  handleRemoveEdgeGotoStep(graph: MxGraph, cell: MxCell) {
    let allMxCell = cell.parent.children;
    allMxCell.forEach((obj) => {
      if (obj?.source?.id == cell.id) {
        graph.removeCells([graph.model.getCell(obj.id)]);
      } else return;
    });
  }

  handleGetEdge(cell: MxCell) {
    let listEdge = [];
    cell.parent.children.forEach((action) => {
      if (
        action.id != "treeRoot" &&
        action?.source?.id == cell.id &&
        action.edge == true
      ) {
        listEdge.push({
          status: action.value,
          idTarget: action?.target?.id,
        });
      }
    });
    return listEdge;
  }

  handleUpdateData(data, cell: MxCell) {
    const action = cell.value.getAttribute("applicationId");
    const listEdge = this.handleGetEdge(cell);
    if (action == "GoToStep") {
      if (listEdge.length >= 0) {
        data.actionStep = listEdge[0]?.idTarget;
      }
    } else if (action == "HttpRequest") {
      data.actionSuccess = "";
      data.actionFail = "";
      listEdge.forEach((obj) => {
        if (obj?.status == "Success") {
          data.actionSuccess = obj?.idTarget;
        }
        if (obj?.status == "Fail") {
          data.actionFail = obj?.idTarget;
        }
      });
    } else if (action == "CheckTime") {
      data.validTime = "";
      data.invalidTime = "";
      listEdge.forEach((obj) => {
        if (obj?.status == "Valid Time") {
          data.validTime = obj?.idTarget;
        }
        if (obj?.status == "Invalid Time") {
          data.invalidTime = obj?.idTarget;
        }
      });
    } else return;
  }

  handleGetAllMx(cell: MxCell) {
    return cell.parent.children;
  }

  removeCellFromHangUpAction(graph: MxGraph) {
    if (
      graph.model.getCell("eventRoot").edges != undefined &&
      graph.model.getCell("eventRoot").edges.length > 0
    ) {
      graph.removeCells([graph.model.getCell("eventRoot").edges[0].target]);
    }
  }

  handleRevisionImage(graph: MxGraph) {
    //Defind Regex
    const regexImage = /(?<= xlink:href=")(.*?)(?=" )/g;

    //Get min x and min y of graph
    var bounds = graph.getGraphBounds();
    var minX = bounds.x,
      minY = bounds.y;

    //Move graph to min x and min y
    graph.getModel().beginUpdate();
    try {
      var ver = graph.getChildVertices(graph.getDefaultParent());
      graph.moveCells(
        ver,
        minX > 0 ? 0 : Math.abs(minX),
        minY > 0 ? 0 : Math.abs(minY),
        false
      );
    } finally {
      graph.getModel().endUpdate();
      graph.refresh();
    }

    //define svg string
    var svgDoc = MXUTILITIES.mxUtils.createXmlDocument();
    var root =
      svgDoc.createElementNS != null
        ? svgDoc.createElementNS(MXUTILITIES.mxConstants.NS_SVG, "svg")
        : svgDoc.createElement("svg");

    if (svgDoc.createElementNS == null) {
      root.setAttribute("xmlns", MXUTILITIES.mxConstants.NS_SVG);
      root.setAttribute("xmlns:xlink", MXUTILITIES.mxConstants.NS_XLINK);
    } else {
      root.setAttributeNS(
        "http://www.w3.org/2000/xmlns/",
        "xmlns:xlink",
        MXUTILITIES.mxConstants.NS_XLINK
      );
    }

    //get new bounds graph after move graph
    bounds = graph.getGraphBounds();
    root.setAttribute("width", bounds.x + bounds.width + 4 + "px");
    root.setAttribute("height", bounds.y + bounds.height + 4 + "px");
    root.setAttribute("version", "1.1");

    svgDoc.appendChild(root);
    var svgCanvas = new MxSvgCanvas2D(root);
    const imgExport = new MxImageExport();
    imgExport.drawState(graph.getView().getState(graph.model.root), svgCanvas);
    const xml2 = MXUTILITIES.mxUtils.getXml(svgCanvas.root);
    var dataSvg =
      '<?xml version="1.0" encoding="UTF-8"?>\n' +
      '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n' +
      xml2;

    //Revert initial graph
    graph.getModel().beginUpdate();
    try {
      var ver = graph.getChildVertices(graph.getDefaultParent());
      graph.moveCells(ver, minX > 0 ? 0 : minX, minY > 0 ? 0 : minY, false);
    } finally {
      graph.getModel().endUpdate();
      graph.refresh();
    }

    //Convert local image local to base64
    let dataRegexImage = dataSvg.match(regexImage);
    for (let i = 0; i < dataRegexImage.length; i++) {
      let item = {};
      let image = dataRegexImage[i].split("/").slice(-1).toString();
      item["image"] = dataRegexImage[i];
      let a = RevisionIcon.find((b) => b["key"] == image);
      if (a !== undefined) {
        item["base64"] = a["value"];
        dataSvg = dataSvg.replace(item["image"], item["base64"]);
      }
    }
    return dataSvg;
  }

  addNote(graph: MxGraph, x, y) {
    var parent = graph.getDefaultParent();
    graph.getModel().beginUpdate();
    try {
      var note = graph.insertVertex(
        parent,
        null,
        "Take a note...",
        x,
        y,
        150,
        50,
        "shape=note;verticalAlign=top;align=left;fillColor=#FFED8A;spacingLeft=4;spacingTop=4;rounded=0;fontColor=#555555;strokeColor=#FFD915;fontSize=11"
      );
    } finally {
      graph.getModel().endUpdate();
    }
  }
  checkIsRequired(graph: MxGraph, fields, cell) {
    if (cell) {
      var overlayRequired: MxCellOverlay;
      var overlayRequiredIndex: number;

      var isEmpty = fields.some((field) => field == "" || field == null);
      overlayRequired = cell.overlays?.find(
        (overlay) => overlay.tooltip === "Missing required field"
      );

      if (!isEmpty) {
        overlayRequiredIndex = cell.overlays.indexOf(overlayRequired);
        if (overlayRequiredIndex !== -1) {
          cell.overlays.splice(overlayRequiredIndex, 1);
        }
        return true;
      } else if (overlayRequired == undefined && isEmpty) {
        overlayRequired = new MxCellOverlay(
          new MxImage("/assets/images/warning.png", 14, 14),
          "Missing required field"
        );
        overlayRequired.align = MXUTILITIES.mxConstants.ALIGN_LEFT;
        overlayRequired.verticalAlign = MXUTILITIES.mxConstants.ALIGN_TOP;
        graph.addCellOverlay(cell, overlayRequired);
        return false;
      }
    }
  }
}
