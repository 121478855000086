import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { DataService } from "../../../../../@core/utils/data.service";
import {
  FilterData,
  GetListCallLog,
  Page,
  TraceLogClient,
} from "../../../../../System-api";
import { Clipboard } from "@angular/cdk/clipboard";
import {
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import { Router } from "@angular/router";

@Component({
  selector: "log-list",
  templateUrl: "log-list.component.html",
  styleUrls: ["./log-list.component.scss"],
})
export class LogListComponent implements OnInit {
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() isModal = true;
  @Input() data: any[] = [];
  @Output() dataLogExport = new EventEmitter<any[]>();
  @Output() disabled = new EventEmitter<boolean>();
  @Output() updateLoading = new EventEmitter<any>();
  @Output() updateLogInformation = new EventEmitter<any>();
  logContent = "";
  logContentString = "";
  loading = false;
  callId: any;
  columns = [];
  filteredData = [];
  fromNumber: any;
  toNumber: any;
  page: Page = new Page();
  rows = [];
  timeout: any = null;
  searchText: string = null;
  fromNumberSearch: string = null;
  callFlowId = this.dataService?.CallFlow?.id || null;
  showMoreContent = true;
  copyTitle = "Copy to clipboard";
  filterObject: FilterData[] = [];
  isLoadingExport = false;
  loadingAttribute = false;

  constructor(
    private dataService: DataService,
    private tracelogClient: TraceLogClient,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private router: Router
  ) {
    this.columns = [
      // { prop: "id", name: "ID" },
      // { prop: "fromNumber", name: "From" },
      // { prop: "toNumber", name: "To" },
      // { prop: "logContent", name: "Log Content" },
      // { prop: "dateCreate", name: "Created" },
    ];
    this.page.pageNumber = 0;
    this.page.size = Math.floor((window.innerHeight * 0.55) / Number(34));
    this.page.sortBy = "id";
  }
  @HostListener("click", ["$event"])
  onHostClick(event: Event): void {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  closeVariable() {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  copyJSON(data) {
    this.clipboard.copy(JSON.stringify(data, undefined, 2));
  }
  filterHeader(data: FilterData) {
    let indexExist = this.filterObject.findIndex((x) => x.prop == data.prop);
    if (indexExist >= 0) {
      if (
        this.filterObject[indexExist]?.value != data.value ||
        this.filterObject[indexExist]?.filter != data.filter
      ) {
        this.filterObject[indexExist] = data;
        this.setPage({ offset: 0 });
      }
    } else {
      this.filterObject.push(data);
      if (data.value) {
        this.setPage({ offset: 0 });
      }
    }
  }
  showJSON(index) {
    this.rows[index].show = !this.rows[index].show;
    this.rows = [...this.rows];
  }
  setPage($event) {
    this.page.pageNumber = $event.offset;
    this.loading = true;
    let dataRequest = new GetListCallLog();
    dataRequest.page = this.page;
    dataRequest.callId = this.callId;
    dataRequest.searchText = this.searchText;
    dataRequest.fromNumber = this.fromNumberSearch;
    dataRequest.id = this.callFlowId;
    dataRequest.filterData = this.filterObject;
    this.tracelogClient.getAllInTable(dataRequest).subscribe(
      (pagedData) => {
        this.loading = false;
        let finalData = pagedData?.callLog.map((x) => {
          try {
            return {
              ...x,
              show: false,
              jsonRefData: JSON.parse(x.jsonRefData),
            };
          } catch (error) {
            return {
              ...x,
              show: false,
            };
          }
        });
        this.rows = finalData || [];
        this.page = pagedData?.page || new Page();
        if (this.rows?.length > 0) {
          this.updateLogInformation.emit({
            from: this.rows[0].fromNumber,
            to: this.rows[0].toNumber,
            logType: this.rows[0].type,
          });
          this.disabled.emit(false);
        } else {
          this.disabled.emit(true);
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  actionButtonClick(id) {
    this.router.navigate([]).then((result) => {
      window.open(`callflow-design/${id}`, "_blank");
    });
  }
  onSort(event) {
    const currentPage = this.page.pageNumber + 1;
    this.page.sortAsc = event.sorts[0].dir == "asc" ? true : false;
    this.page.sortBy = event.sorts[0].prop;
    this.setPage({ offset: this.page.pageNumber });
    this.table.onFooterPage({ page: currentPage });
  }
  ngOnInit() {}
  showLog(data, k) {
    this.copyTitle = "Copy to clipboard";
    this.logContentString = "";
    this.logContent = "";
    this.loadingAttribute = true;
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
    this.tracelogClient.getVariable(data).subscribe({
      next: (rs) => {
        this.loadingAttribute = false;
        this.logContentString = rs;
        this.logContent = JSON.parse(rs);
        const popoverArray = this.popovers.toArray();
        if (popoverArray[k]) {
          popoverArray[k].show();
        }
        this.hiddenArrow();
      },
      error: () => {
        this.loadingAttribute = false;
        this.logContentString = "Empty";
        this.logContent = "Empty";
        this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
        this.hiddenArrow();
      },
    });
  }
  hiddenArrow() {
    const idTemplate = document.getElementById("model-variable");
    if (idTemplate) {
      const overlay = idTemplate.closest(".cdk-overlay-pane");
      overlay.classList.add("custom-modal");
    }
  }
  resetArrow() {
    const overlayPane = document.querySelector(".cdk-overlay-container");
    if (overlayPane) {
      overlayPane.classList.remove("custom-modal");
    }
  }
  closeModal() {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  copyAttribute() {
    this.clipboard.copy(this.logContentString);
    this.copyTitle = "Copied to clipboard";
  }
  searchFromNumber(event) {
    this.fromNumberSearch = event?.target?.value?.toLowerCase();
  }
  searchDataSearch(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchText = event?.target?.value?.toLowerCase();
      this.setPage({ offset: 0 });
    }, 300);
  }
  exportLog() {
    this.updateLoading.emit(true);
    this.isLoadingExport = true;
    this.tracelogClient
      .getAll(
        this.callFlowId,
        0,
        1000,
        this.page.sortBy,
        this.page.sortAsc,
        this.callId,
        this.searchText,
        this.fromNumberSearch
      )
      .subscribe(
        (pagedData) => {
          this.updateLoading.emit(false);
          this.isLoadingExport = false;
          this.dataLogExport.emit(pagedData?.callLog.reverse());
        },
        (error) => {
          this.updateLoading.emit(false);
          this.isLoadingExport = false;
        }
      );
  }
  ngAfterViewInit(): void {}
  moveOnCallDetailRecord(row) {
    window.open(`portal/call/cdr/${row.callId}`, "_blank");
  }
}
