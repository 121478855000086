import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { WaitAction } from "../../../../@core/model/wait-action";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { Cards } from "../../../../@core/model/cards";
import { DataService } from "../../../../@core/utils/data.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CMSClient, CallFlowClient } from "../../../../System-api";
import { Uppy } from "@uppy/core";
import { MediaModalComponent } from "../../../../shared/media-modal/media-modal.component";
import { Fancybox } from "@fancyapps/ui";
import { Clipboard } from "@angular/cdk/clipboard";
@Component({
  selector: "cards-action",
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.scss"],
})
export class CardsActionComponent implements OnInit, OnDestroy {
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  cell: MxCell;
  graph: MxGraph;
  data: Cards = new Cards();
  cancelAction = false;
  formGroup: UntypedFormGroup;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  cards = [];
  listNation = [];
  cmsList = [];
  languagesSetting: any = [];
  isLoading = false;
  buttonType = [
    { icon: "text-outline", value: "text", display: "Text" },
    { icon: "globe-2-outline", value: "open-web", display: "Open Website" },
    { icon: "phone-outline", value: "make-call", display: "Make A Phone Call" },
  ];
  currentCard = -1;
  currentCardButton = -1;
  cardIndex = 0;
  buttonIndex = 0;

  example = [
    {
      image: "https://url/image.jpg",
      title: "Primas Card",
      description: "Lorem Ipsum is simply dummy text of th...",
      buttons: [
        {
          value: "buy",
          text: "Buy",
          type: "text",
        },
      ],
    },
    {
      image: "https://url/image2.jpg",
      title: "Primas Card 2",
      description: "Lorem Ipsum is simply dummy text of th...",
      buttons: [
        {
          value: "buy",
          text: "Buy",
          type: "text",
        },
      ],
    },
  ];
  optionsMode: object[] = [
    { id: 1, name: "Image" },
    { id: 2, name: "Video" },
  ];
  isLoadingUpdate = false;
  copyTitle = "Copy to clipboard";
  constructor(
    protected windowRef: NbWindowRef,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    public dataService: DataService,
    private cmsClient: CMSClient,
    private cd: ChangeDetectorRef,
    private callFlowClient: CallFlowClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private elRef: ElementRef,
    private clipboard: Clipboard
  ) {
    this.createForm();
  }
  ngOnDestroy(): void {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }
  uppy: Uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      maxFileSize: 90000000,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: [".jpg", ".jpeg", ".gif", ".png"],
    },
  });
  handleImageError(event: any) {
    event.target.src = "../../../../../assets/images/default-image-small.jpg";
  }
  expanded($event) {
    $event.preventDefault();
  }
  nextCard() {
    if (this.cardIndex < this.cards.length - 1) {
      this.cardIndex = this.cardIndex + 1;
      const cardElements = document.querySelectorAll(".card-display");
      cardElements.forEach((cardElement) =>
        cardElement.classList.add("card-display-next")
      );
      setTimeout(() => {
        cardElements.forEach((cardElement) => {
          cardElement.classList.remove("card-display-next");
          cardElement.classList.remove("card-display-back");
        });
      }, 100);
    }
  }
  backCard() {
    if (this.cardIndex > 0) {
      this.cardIndex = this.cardIndex - 1;
      const cardElements = document.querySelectorAll(".card-display");
      cardElements.forEach((cardElement) =>
        cardElement.classList.add("card-display-back")
      );
      setTimeout(() => {
        cardElements.forEach((cardElement) => {
          cardElement.classList.remove("card-display-next");
          cardElement.classList.remove("card-display-back");
        });
      }, 100);
    }
  }
  setCurrent(i, k) {
    this.currentCard = i;
    this.currentCardButton = k;
  }
  setButton(type) {
    this.cards[this.currentCard].buttons[this.currentCardButton].type = type;
    this.currentCard = -1;
    this.currentCardButton = -1;
  }
  getVariable($event) {
    this.data.output = $event;
  }
  getVariableDynamic($event) {
    this.data.dynamicCards = $event;
    this.handleChangeCardsVariable(this.data.option);
  }
  remover(z) {
    if (this.cards.length > 1) {
      if (z != 0) {
        this.cards.splice(z, 1);
      } else {
        this.cards.shift();
      }
      this.backCard();
    }
  }
  hiddenAll() {
    this.popovers.forEach((popover: NbPopoverDirective) => popover?.hide());
  }
  editButton(k) {
    this.hiddenAll();
    this.buttonIndex = k;
    const popoverArray = this.popovers.toArray();
    if (popoverArray[k + 1]) {
      popoverArray[k + 1].show();
    }
  }
  removeButton(i, z) {
    if (z != 0) {
      this.cards[i].buttons.splice(z, 1);
    } else {
      this.cards[i].buttons.shift();
    }
  }
  changePositionAnimation(oldIndex, index) {
    const cardElements = document.querySelector(".card-display");
    if (oldIndex > index) {
      cardElements.classList.add("card-display-back");
    } else {
      cardElements.classList.add("card-display-next");
    }
    setTimeout(() => {
      cardElements.classList.remove("card-display-next");
      cardElements.classList.remove("card-display-back");
    }, 100);
  }
  changePosition(index) {
    const oldIndex = this.cardIndex;
    this.cardIndex = index;
    this.changePositionAnimation(oldIndex, index);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cards, event.previousIndex, event.currentIndex);
    this.cardIndex = event.currentIndex;
    this.changePositionAnimation(event.previousIndex, event.currentIndex);
  }
  dropButton(event: CdkDragDrop<string[]>, i) {
    moveItemInArray(
      this.cards[i].buttons,
      event.previousIndex,
      event.currentIndex
    );
  }
  handleImage(type, value) {
    this.dialogService
      .open(MediaModalComponent, {
        autoFocus: false,
        context: {
          urlData: value,
          allowUploadVideo: false,
          type: "image",
          files: [".jpg", ".jpeg", ".gif", ".png"],
        },
      })
      .onClose.subscribe((rs) => {
        if (rs && rs?.status == true) {
          this.cards[this.cardIndex].image = rs.data;
        }
      });
  }
  showToast(result) {
    if (result == true) {
      this.toastrService.show("Upload image successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Upload image unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  newCard() {
    if (this.cards.length < 10) {
      this.cards.push({
        image: "",
        imageType: 1,
        title: "",
        description: "",
        buttons: [
          {
            value: "",
            text: "Button #1",
            type: "text",
          },
        ],
      });
      this.cardIndex = this.cards.length - 1;
      const cardElements = document.querySelector(".card-display");
      cardElements.classList.add("card-display-next");
      setTimeout(() => {
        cardElements.classList.remove("card-display-next");
        cardElements.classList.remove("card-display-back");
      }, 100);
    }
  }
  duplicateCard(i) {
    if (this.cards.length < 10) {
      let newCard = { ...this.cards[i] };
      newCard.buttons = this.cards[i].buttons.map((button) => ({ ...button }));
      this.cards.push(newCard);
      this.cardIndex = this.cards.length - 1;
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      second: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Toolbar: {
        display: {
          left: [],
          right: ["zoomIn", "zoomOut", "close"],
          middle: [],
        },
      },
      Thumbs: {
        type: "modern",
      },
      Images: {
        zoom: true,
      },
    });

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    if (!this.data.imageRatio) this.data.imageRatio = "horizontal";
    this.handleChangeCardsVariable(this.data.option);
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    this.convertFormatField();
    this.windowRef.onClose.subscribe(() => this.submit());
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Toolbar: {
        display: {
          left: [],
          right: ["zoomIn", "zoomOut", "close"],
          middle: [],
        },
      },
      Thumbs: {
        type: "modern",
      },
    });
  }
  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  convertFormatField() {
    if (this.cards.length <= 0) {
      this.cards = [
        {
          image: "",
          imageType: 1,
          title: "",
          description: "",
          buttons: [
            {
              value: "",
              text: "Button #1",
              type: "text",
            },
          ],
        },
      ];
    }
  }
  handleBindingData(data: any) {
    let answerOption = data;
    if (answerOption && answerOption.length > 0) {
      this.listNation.forEach((obj) => {
        for (let i = 0; i < answerOption.length; i++) {
          let check = 0;
          for (let j = 0; j < answerOption[i].value.length; j++) {
            const languageSetting = obj.language.split("-");
            const languageTTS = answerOption[i].value[j].language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              answerOption[i].value[j].name = obj.name;
              answerOption[i].value[j].language = obj.language;
              check = 1;
            }
          }
          if (check == 0) {
            answerOption[i].value.push({
              name: obj.name,
              language: obj.language,
              value: "",
            });
          }
        }
      });
      for (let k = 0; k < answerOption.length; k++) {
        for (let z = 0; z < answerOption[k].value.length; z++) {
          let check = 0;
          this.listNation.forEach((item) => {
            const languageSetting =
              answerOption[k].value[z].language.split("-");
            const languageTTS = item.language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              check = 1;
            }
          });
          if (check == 0) {
            if (z != 0) {
              answerOption[k].value.splice(z, 1);
              z--;
            } else {
              answerOption[k].value.shift();
              z--;
            }
          }
        }
      }
    }
    return answerOption;
  }
  backward(index) {
    if (index < this.cards.length - 1) {
      this.swapItems(index, index + 1);
      this.cardIndex = index + 1;
    }
  }

  forward(index) {
    if (index < this.cards.length) {
      this.swapItems(index, index - 1);
      this.cardIndex = index - 1;
    }
  }

  swapItems(index1: number, index2: number) {
    const temp = this.cards[index1];
    this.cards[index1] = this.cards[index2];
    this.cards[index2] = temp;
  }
  addNewButton(index) {
    if (this.cards[index].buttons.length < 3) {
      const indexButton = this.cards[index].buttons.length + 1;
      this.cards[index].buttons.push({
        value: "",
        text: "Button #" + indexButton,
        type: "text",
      });
    }
  }
  deleteImage(i) {
    this.cards[i].image = "";
  }
  handleAnswerOption() {
    this.cards.forEach((i, x) => {
      try {
        this.cards[x].image = this.cards[x].image?.trim();
        this.cards[x].title = this.cards[x].title?.trim();
        this.cards[x].description = this.cards[x].description?.trim();
        this.cards[x].buttons.forEach((j, k) => {
          this.cards[x].buttons[k].value =
            this.cards[x].buttons[k].value?.trim();
          this.cards[x].buttons[k].text = this.cards[x].buttons[k].text?.trim();
        });
        // if (this.cards[x].buttons && this.cards[x].buttons?.length > 0) {
        //   let finalData = this.cards[x].buttons.filter(
        //     (x) => x.name?.trim() != ""
        //   );
        //   if (finalData.length > 0) {
        //     const trimmedData = finalData.map((item) => {
        //       return {
        //         ...item,
        //         name: item.name.trim(),
        //       };
        //     });
        //     trimmedData.forEach((item, index) => {
        //       item.value.forEach((value, indexValue) => {
        //         trimmedData[index].value[indexValue].value =
        //           trimmedData[index].value[indexValue].value.trim();
        //       });
        //     });
        //     this.cards[x].buttons = trimmedData;
        //   } else {
        //     this.cards[x].buttons = [];
        //   }
        // } else {
        //   this.cards[x].buttons = [];
        // }
      } catch (error) {
        // this.cards[x].buttons = [];
      }
    });
  }
  submit() {
    if (!this.cancelAction) {
      this.handleAnswerOption();
      if (this.data.option === "static")
        this.data.cards = JSON.stringify(this.cards);
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      const waitActionCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        waitActionCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  copyAttribute() {
    this.clipboard.copy(JSON.stringify(this.example, undefined, 2));
    this.copyTitle = "Copied to clipboard";
  }
  handleChangeImageRatio(event: any, imageRatio: string) {
    event.target.blur();
    this.data.imageRatio = imageRatio;
  }
  handleChangeCardsVariable(option) {
    if (option === "static") {
      this.cards = JSON.parse(this.data.cards);
      this.convertFormatField();
      return;
    }
    try {
      const foundAttr = this.attributes.find(
        (attribute) => attribute.value === this.data.dynamicCards
      );
      this.cards = JSON.parse(foundAttr.content);
    } catch (e) {
      this.cards = [];
    }
  }
}
