import { IApplicationData } from "./common";
export class BuiltInFunction implements IApplicationData {
  constructor() {
    this.name = "Built-In Function";
    this.attribute = "";
    this.transition = true;
  }
  name: string;
  attribute: string;
  transition: boolean;
}
