<form
  [formGroup]="grammarForm"
  novalidate
  (submit)="onSubmit()"
  [ngClass]="{ 'form-width': typeGrammar == 'Custom' }"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-card
    [ngClass]="{
      'card-digit': typeGrammar == 'Digit' || typeGrammar == 'PhraseList'
    }"
  >
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <div *ngIf="typeGrammar == 'Custom'">
          {{ title }} - Status:
          <span
            class="btn-action failed"
            *ngIf="trainingStatus == 'FAILED' || trainingStatus == 'False'"
            >Failed</span
          >
          <span
            class="btn-action not-train"
            *ngIf="trainingStatus == 'NOT-TRAINED' || trainingStatus == ''"
            >Not Trained</span
          >
          <span
            class="btn-action success"
            *ngIf="trainingStatus == 'TRAINED' || trainingStatus == 'True'"
            >Trained</span
          >
          <span
            class="btn-action training"
            *ngIf="trainingStatus == 'TRAINING' || trainingStatus == null"
            >Training</span
          >
        </div>
        <div *ngIf="typeGrammar != 'Custom'">{{ title }}</div>
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body
      *ngIf="typeGrammar == 'Digit'"
      [ngClass]="{
        'card-digit-body': typeGrammar == 'Digit'
      }"
    >
      <nb-tabset>
        <nb-tab tabTitle="Pattern">
          <div class="row">
            <div class="mb-2">
              <label for="input-name" class="form-label label required"
                >Grammar Name</label
              >
              <div
                [nbSpinner]="isNameLoading"
                nbSpinnerSize="medium"
                nbSpinnerStatus="info"
              >
                <input
                  #inputName
                  type="text"
                  formControlName="name"
                  nbInput
                  [(ngModel)]="createGrammar.name"
                  [attr.disabled]="
                    isNameLoading == false ? null : isNameLoading
                  "
                  fullWidth
                  [status]="
                    grammarForm.controls['name'].invalid &&
                    (grammarForm.controls['name'].dirty ||
                      grammarForm.controls['name'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  id="input-name"
                  placeholder="Grammar Name"
                />
              </div>
              <ng-container
                *ngIf="
                  grammarForm.controls['name'].invalid &&
                  (grammarForm.controls['name'].dirty ||
                    grammarForm.controls['name'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    grammarForm.controls['name'].hasError('required') ||
                    grammarForm.controls['name'].hasError('whitespace')
                  "
                >
                  Grammar Name is required
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="grammarForm.controls['name'].errors.isExist"
                >
                  Grammar Name already exist.
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    grammarForm.controls['name'].errors.maxlength &&
                    !grammarForm.controls['name'].hasError('required') &&
                    !grammarForm.controls['name'].hasError('whitespace')
                  "
                >
                  Maximum length is 50 characters.
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    grammarForm.controls['name'].errors.regex &&
                    !grammarForm.controls['name'].errors.maxlength &&
                    !grammarForm.controls['name'].hasError('required') &&
                    !grammarForm.controls['name'].hasError('whitespace')
                  "
                >
                  Grammar Name cannot contain any of the following characters: \
                  / : *? " < > |
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="grammarForm.controls['name'].errors.serverError"
                >
                  {{ grammarForm.controls["name"].errors.serverError }}
                </p>
              </ng-container>
            </div>
            <div class="mb-2">
              <label for="input-pattern" class="form-label label required"
                >Pattern</label
              >
              <input
                type="text"
                formControlName="pattern"
                nbInput
                [(ngModel)]="checkGrammar.pattern"
                (input)="checkPatternGrammar($event.target.value)"
                fullWidth
                [status]="
                  grammarForm.controls['pattern'].invalid &&
                  (grammarForm.controls['pattern'].dirty ||
                    grammarForm.controls['pattern'].touched)
                    ? 'danger'
                    : 'basic'
                "
                id="input-pattern"
                placeholder="Ex: 09X03N"
              />
              <ng-container
                *ngIf="
                  grammarForm.controls['pattern'].invalid &&
                  (grammarForm.controls['pattern'].dirty ||
                    grammarForm.controls['pattern'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  *ngIf="
                    grammarForm.controls['pattern'].hasError('required') ||
                    grammarForm.controls['pattern'].hasError('whitespace')
                  "
                >
                  Pattern is required
                </p>
              </ng-container>
            </div>
            <div class="mb-2">
              <label for="input-value" class="form-label label"
                >Test Value</label
              >
              <input
                type="text"
                formControlName="testValue"
                nbInput
                (input)="checkPatternGrammar($event.target.value)"
                [(ngModel)]="checkGrammar.value"
                fullWidth
                [status]="
                  !isMatched && checkGrammar.value?.length > 0
                    ? 'danger'
                    : 'basic'
                "
                id="input-value"
                placeholder="Ex: 095036"
              />
              <ng-container
                *ngIf="!isMatched && checkGrammar.value?.length > 0"
              >
                <p class="caption status-danger" *ngIf="!isMatched">
                  Value not match with pattern
                </p>
              </ng-container>
              <ng-container *ngIf="isMatched && checkGrammar.value?.length > 0">
                <p class="caption status-success" *ngIf="isMatched">Matched</p>
              </ng-container>
            </div>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Guide">
          <p><b>Special Characters Used</b></p>
          <ul>
            <li>The letter <b>X</b> represents a single digit from 0 to 9.</li>
            <li>The letter <b>Z</b> represents any digit from 1 to 9.</li>
            <li>The letter <b>N</b> matches any digit from 2-9.</li>
          </ul>
          <p><b>Ranges used</b></p>
          <p>This pattern matches any digit in the brackets.</p>
          <ul>
            Example
            <li>[1-4]: The pattern will match: 1,2,3,4</li>
            <li>[235]: The pattern will match: 2,3,5</li>
            <li>[246-8]: The pattern will match: 2,4,6,7,8</li>
          </ul>
          <p><b>Wildcards used</b></p>
          <ul>
            <li><b>.</b> The '.' character matches one or more characters</li>
            <li>
              <b>!</b> The '!' character matches zero or more characters
              immediately
            </li>
          </ul>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-body *ngIf="typeGrammar == 'Custom'">
      <div class="row grammar-wrap">
        <div class="col-md-4 mb-4">
          <label for="input-createGrammar" class="form-label label required"
            >Grammar Name</label
          >
          <input
            #inputName
            type="text"
            formControlName="name"
            nbInput
            fieldSize="small"
            [(ngModel)]="createGrammar.name"
            [attr.disabled]="isNameLoading == false ? null : isNameLoading"
            fullWidth
            [status]="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
                ? 'danger'
                : 'basic'
            "
            id="input-createGrammar"
            placeholder="Grammar Name"
          />
          <ng-container
            *ngIf="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].hasError('required') ||
                grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name is required
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="grammarForm.controls['name'].errors.isExist"
            >
              Grammar Name already exist.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Maximum length is 50 characters.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.regex &&
                !grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name cannot contain any of the following characters: \ / :
              *? " < > |
            </p>
            <p
              class="caption status-danger"
              *ngIf="grammarForm.controls['name'].errors.serverError"
            >
              {{ grammarForm.controls["name"].errors.serverError }}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md-10 flex">
          <div class="col-md-3 flex">
            <div class="mb-2" style="display: flex; align-items: center">
              <nb-toggle [(checked)]="translateGrammar" status="basic"
                >Auto Translate</nb-toggle
              >
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Automatically translate user response to match the language of the grammar."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
          <div class="col-md-5 flex" style="margin-left: -0.6rem">
            <div class="flex">
              <div class="mb-2" style="display: flex; align-items: center">
                <nb-toggle [(checked)]="engine" status="basic"
                  >OpenAI Model</nb-toggle
                >
                <nb-icon
                  class="ms-2"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Allows training data by OpenAI model."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
            </div>
            <div
              *ngIf="engine"
              class="mb-2"
              style="display: flex; gap: 0.5rem; align-items: center"
            >
              <nb-select
                fullWidth
                placeholder="Select Type"
                [(ngModel)]="engineType"
                formControlName="engineType"
                size="tiny"
              >
                <nb-option value="EMBEDDING">Embedding</nb-option>
                <nb-option value="FINETUNING">Fine-Tuning</nb-option>
              </nb-select>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                [nbPopover]="templateRef2"
                nbPopoverPlacement="bottom"
                nbPopoverTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
        </div>
        <div class="col-md-2 flex justify-content-end mb-2" style="gap: 0.5rem">
          <button
            type="button"
            status="basic"
            (click)="fileGrammarInput.click()"
            size="tiny"
            nbButton
            class="secondary"
          >
            Import
          </button>
          <input
            accept=".json"
            #fileGrammarInput
            type="file"
            (click)="fileGrammarInput.value = null"
            value=""
            (change)="handleConfirmImport(fileGrammarInput.files)"
            style="display: none"
            nbInput
          />
          <button
            type="button"
            status="basic"
            (click)="exportGrammar()"
            size="tiny"
            nbButton
            class="secondary"
          >
            Export
          </button>
        </div>
      </div>
      <hr />
      <div class="wrap-input" style="overflow: scroll">
        <span *ngIf="listIntent.length == 0">You don't have grammar</span>
        <nb-accordion>
          <nb-accordion-item
            [expanded]="defaultLanguageCode == intent.code"
            *ngFor="let intent of listIntent; let index = index"
          >
            <nb-accordion-item-header class="is-call-flow-name">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 97.5%;
                "
              >
                <div style="display: flex; align-items: center; gap: 1.5rem">
                  <span>{{ intent.name }}</span>
                  <span
                    *ngIf="defaultLanguageCode == intent.code"
                    class="btn-tag primary btn-tag-default"
                    nbTooltipTrigger="hover"
                    nbTooltip="Default language when starting the flow. You can change the default language in Settings."
                    nbTooltipPlacement="end"
                    nbTooltipStatus="basic"
                    >DEFAULT</span
                  >
                </div>
                <button
                  nbButton
                  type="button"
                  size="tiny"
                  ghost
                  (click)="handleUpload($event, index)"
                  nbPopoverPlacement="bottom"
                  [nbPopover]="templateRef"
                  status="basic"
                  matTooltip="Upload intent file"
                >
                  <nb-icon status="primary" icon="upload-outline"></nb-icon>
                </button>
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body style="border-bottom: 1px solid #edf1f7">
              <div *ngFor="let item of intent.value; let i = index">
                <div class="row">
                  <div class="col-md-9">
                    <form class="mb-2">
                      <label class="form-label label">Utterances</label>
                      <app-textarea-highlight
                        placeHolder="Utterances"
                        [entity]="entityData"
                        (textChange)="handleText($event, index, i)"
                        (handleAddEntity)="handleAddEntity($event)"
                      ></app-textarea-highlight>
                    </form>
                  </div>
                  <div class="col-md-3" style="display: flex; gap: 8px">
                    <form class="mb-2" style="flex: 1">
                      <div
                        style="
                          display: flex;
                          align-items: end;
                          justify-content: space-between;
                        "
                      >
                        <label class="form-label label" style="">Intent</label>
                        <button
                          (click)="generateUserExpression(index, i)"
                          matTooltip="Generate Utterances from your intent"
                          nbButton
                          size="tiny"
                          ghost
                          status="primary"
                          style="
                            font-size: 0.7rem !important;
                            padding-left: 0;
                            padding-right: 0;
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17px"
                            height="17px"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            class="vfui_1xa3nyx0"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6.75 15.165a.75.75 0 0 1 .743.648l.007.102v.851h.917a.75.75 0 0 1 .101 1.494l-.101.007-.917-.001v.982a.75.75 0 0 1-1.493.102L6 19.248v-.982h-.917a.75.75 0 0 1-.101-1.492l.101-.007.917-.001v-.851a.75.75 0 0 1 .75-.75Zm8-4.679a.75.75 0 0 1 .75.75 3.163 3.163 0 0 0 3.163 3.162.75.75 0 0 1 .101 1.494l-.101.007A3.163 3.163 0 0 0 15.5 19.06a.75.75 0 0 1-.75.75l-.043-.001a.75.75 0 0 1-.794-.749 3.163 3.163 0 0 0-3.163-3.162.75.75 0 0 1 0-1.5 3.163 3.163 0 0 0 3.162-3.163.75.75 0 0 1 .75-.75l.044.001.044-.001Zm-.045 3.217-.034.056a4.687 4.687 0 0 1-1.384 1.39 4.682 4.682 0 0 1 1.42 1.445c.36-.58.846-1.074 1.419-1.446a4.682 4.682 0 0 1-1.42-1.445ZM7.75 5.417a.75.75 0 0 1 .75.75c0 1.196.97 2.166 2.167 2.166a.75.75 0 0 1 .101 1.494l-.101.006C9.47 9.833 8.5 10.803 8.5 12a.75.75 0 0 1-.75.75l-.041-.001A.75.75 0 0 1 6.917 12c0-1.197-.97-2.167-2.167-2.167a.75.75 0 0 1 0-1.5c1.197 0 2.167-.97 2.167-2.166a.75.75 0 0 1 .75-.75l.04.001a.762.762 0 0 1 .043-.001Zm-.042 2.916-.065.086c-.194.25-.42.473-.671.665.28.213.528.466.737.75.207-.284.456-.537.736-.75a3.67 3.67 0 0 1-.737-.751ZM15.917 4a.75.75 0 0 1 .743.648l.007.102-.001.991h.917a.75.75 0 0 1 .102 1.494l-.102.007-.917-.001v.842a.75.75 0 0 1-1.492.102l-.007-.102-.001-.842h-.916a.75.75 0 0 1-.102-1.493l.102-.006.916-.001V4.75a.75.75 0 0 1 .75-.75Z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <span>Generate</span>
                        </button>
                      </div>

                      <input
                        type="text"
                        nbInput
                        fullWidth
                        [(ngModel)]="item.intent"
                        placeholder="Intent"
                      />
                    </form>
                    <div class="mb-2" style="margin-top: 29px">
                      <button
                        title="Remove Intent"
                        type="button"
                        (click)="handleRemoveWordInList(i, intent.code, index)"
                        nbButton
                        ghost
                      >
                        <nb-icon icon="minus-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="mb-2">
                  <button
                    type="button"
                    (click)="handleAddWord(intent.code)"
                    size="small"
                    status="primary"
                    class="small mt-2"
                    nbButton
                  >
                    <nb-icon icon="plus-outline"></nb-icon>
                    <span>Add</span>
                  </button>
                </div>
              </div>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
    </nb-card-body>
    <nb-card-body *ngIf="typeGrammar == 'PhraseList'" class="card-list">
      <div class="row grammar-wrap">
        <div class="col-md-4 mb-4">
          <label for="input-createGrammar" class="form-label label required"
            >Grammar Name</label
          >
          <input
            #inputName
            type="text"
            formControlName="name"
            nbInput
            fieldSize="small"
            [(ngModel)]="createGrammar.name"
            [attr.disabled]="isNameLoading == false ? null : isNameLoading"
            fullWidth
            [status]="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
                ? 'danger'
                : 'basic'
            "
            id="input-createGrammar"
            placeholder="Grammar Name"
          />
          <ng-container
            *ngIf="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].hasError('required') ||
                grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name is required
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="grammarForm.controls['name'].errors.isExist"
            >
              Grammar Name already exist.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Maximum length is 50 characters.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.regex &&
                !grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name cannot contain any of the following characters: \ / :
              *? " < > |
            </p>
            <p
              class="caption status-danger"
              *ngIf="grammarForm.controls['name'].errors.serverError"
            >
              {{ grammarForm.controls["name"].errors.serverError }}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 flex" style="margin-left: 0.4rem">
          <label class="form-label label" style="margin-bottom: 0">
            High Precision
          </label>
          <nb-toggle [(checked)]="engine"></nb-toggle>
        </div>
        <div class="col-md-5 flex" style="opacity: 0; pointer-events: none">
          <label class="form-label label" style="margin-bottom: 0"
            >Auto Translate</label
          >
          <nb-toggle [(checked)]="translateGrammar"></nb-toggle>
        </div>
        <div class="col-md-1 flex" style="gap: 0.5rem; margin-left: -3.2rem">
          <button
            type="button"
            status="basic"
            (click)="fileGrammarInput.click()"
            size="tiny"
            nbButton
            class="secondary"
          >
            Import
          </button>
          <input
            accept=".json"
            #fileGrammarInput
            type="file"
            (click)="fileGrammarInput.value = null"
            value=""
            (change)="handleConfirmImport(fileGrammarInput.files)"
            style="display: none"
            nbInput
          />
          <button
            type="button"
            status="basic"
            (click)="exportGrammar()"
            size="tiny"
            nbButton
            class="secondary"
          >
            Export
          </button>
        </div>
      </div>
      <hr />
      <div class="wrap-input" style="overflow: scroll">
        <span *ngIf="listIntent.length == 0">You don't have grammar</span>
        <nb-accordion>
          <nb-accordion-item
            style
            [expanded]="defaultLanguageCode == intent.code"
            *ngFor="let intent of phraseList; let index = index"
            >>
            <nb-accordion-item-header class="is-call-flow-name">
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 97.5%;
                  gap: 1.5rem;
                "
              >
                <span>{{ intent.name }}</span>
                <span
                  *ngIf="defaultLanguageCode == intent.code"
                  class="btn-tag primary btn-tag-default"
                  nbTooltipTrigger="hover"
                  nbTooltip="Default language when starting the flow. You can change the default language in Settings."
                  nbTooltipPlacement="end"
                  nbTooltipStatus="basic"
                  >DEFAULT</span
                >
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body style="border-bottom: 1px solid #edf1f7">
              <form>
                <label class="form-label label">Intent List</label>
                <tag-input
                  style="width: 100%"
                  addOnBlur="true"
                  [(ngModel)]="intent.value"
                  secondaryPlaceholder=""
                  placeholder=""
                  [editable]="true"
                  [trimTags]="true"
                  [allowDupes]="true"
                >
                </tag-input>
              </form>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex justify-content-end" style="gap: 1rem">
        <button
          nbButton
          size="small"
          [disabled]="!grammarForm.valid || isNameLoading || isExist || loading"
          status="primary"
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
<ng-template #templateRef>
  <nb-card style="width: 650px; margin-bottom: 0">
    <nb-card-header>Upload Intent File</nb-card-header>
    <nb-card-body>
      <nb-stepper orientation="horizontal">
        <nb-step [label]="labelOne">
          <ng-template #labelOne>Sample File</ng-template>
          <div style="margin-top: 1rem; margin-bottom: 0.5rem">
            <span
              >Note: Our system supports uploading intent files in .csv and
              .json formats.</span
            >
          </div>
          <div style="margin-bottom: 0.25rem">
            <i class="text-hover" (click)="downloadFile('csv')"
              >Download Sample File (.csv)</i
            >
          </div>
          <div>
            <i (click)="downloadFile('json')" class="text-hover"
              >Download Sample File (.json)</i
            >
          </div>
        </nb-step>
        <nb-step [label]="labelTwo">
          <ng-template #labelTwo>Upload File</ng-template>
          <p style="font-size: 14px; margin-top: 1rem">
            <i
              >Choose a file to upload. Please note that the file must have the
              same format as the Sample file.</i
            >
          </p>
          <div class="d-flex">
            <button
              (click)="fileInput.click()"
              class="mt-3 me-3"
              nbButton
              size="small"
            >
              <nb-icon icon="attach-2-outline"></nb-icon>
              Choose File
              <input
                accept=".csv,.json"
                #fileInput
                type="file"
                (click)="fileInput.value = null"
                value=""
                (change)="importIntent(fileInput.files)"
                style="display: none"
                nbInput
              />
            </button>

            <button
              [disabled]="fileError || !fileName || fileMaximum"
              (click)="confirmUpload()"
              class="mt-3"
              status="primary"
              nbButton
              size="small"
            >
              <nb-icon icon="cloud-upload-outline"></nb-icon>Upload
            </button>
            <div
              class="d-flex align-items-end ms-3 mb-2 flex-fill text-no-wrap-2"
            >
              <nb-icon class="me-2=" icon="file-text-outline"></nb-icon
              ><i class="flex-fill text-no-wrap-2 w-100">{{
                fileName ? fileName : "No files selected"
              }}</i>
            </div>
          </div>
          <ng-container>
            <p *ngIf="fileError" class="caption status-danger mt-2">
              Only CSV, JSON formats are allowed
            </p>
            <p *ngIf="fileMaximum" class="caption status-danger mt-2">
              Maximum allowed file size is 20 MB
            </p>
          </ng-container>
        </nb-step>
      </nb-stepper>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card style="max-width: 500px; margin-bottom: 0; padding: 0">
    <nb-card-body
      style="
        font-size: 13px;
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem;
      "
    >
      <span>
        <b>Embedding: </b> uses a previously trained language model and
        transforms text data into vectors. Easy to use with fewer resources and
        small training data.</span
      >
      <span>
        <b>Fine-Tuning: </b> leverages knowledge from a previously trained
        language model, adapting it to your own specific task. Provides better
        performance with large and context-specific data. You need to train at
        least 10 utterances for this model.</span
      >
    </nb-card-body>
  </nb-card>
</ng-template>
