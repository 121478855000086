<nb-card style="margin-bottom: 0; width: 450px; max-height: 70vh">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      {{ title }}
      <button
        (click)="dismiss(allowCancel ? undefined : false)"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body style="word-break: break-word">
    <div *ngIf="questionAdd" style="margin-bottom: 0.25rem">
      <span>{{ questionAdd }}</span>
    </div>
    <div
      *ngIf="questionAddList.length > 0"
      style="margin-bottom: 0.25rem; display: flex; flex-direction: column"
    >
      <span *ngFor="let item of questionAddList">- {{ item }}</span>
    </div>
    <div [innerHTML]="question"></div>
  </nb-card-body>
  <nb-card-footer>
    <div class="float-end">
      <button
        (click)="onSubmit()"
        type="submit"
        size="small"
        class="me-2"
        status="{{ statusYes }}"
        nbButton
      >
        {{ textYes }}
      </button>
      <button
        *ngIf="textNo"
        (click)="dismiss(false)"
        size="small"
        status="{{ statusNo }}"
        nbButton
      >
        {{ textNo }}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
