import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { ChatGPT } from "../../../../@core/model/chatgpt";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import {
  AudioClient,
  ChatGPTClient,
  GPTDataDto,
  GrammarClient,
} from "../../../../System-api";
import { Observable, Subject, Subscription, map, of } from "rxjs";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { CreateGrammarComponent } from "../get-input/create-grammar/create-grammar.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { SignalRService } from "../../../../@core/utils/signalR.service";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UpdateDatasetComponent } from "../../../../portal/customer-role/studio/datasets/update/update-dataset.component";
import { MultipleGrammarComponent } from "../../other/mutiple-grammar/multiple-grammar.component";
import { TestActionComponent } from "../../other/test-action/test-action.component";
import { ExpandTextComponent } from "../../other/expand-text/expand-text.component";
import { OptimizePromptComponent } from "../../other/optimize-prompt/optimize-prompt.component";
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: "ngx-chatgpt",
  templateUrl: "./chatgpt.component.html",
  styleUrls: ["./chatgpt.component.scss"],
})
export class ChatGPTComponent implements OnInit, OnDestroy {
  @ViewChild("listElement") listElement: ElementRef;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("item") accordion;
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioNotMatchPlayer", {
    static: true,
  })
  audioNotMatchPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioNoInputPlayer", {
    static: true,
  })
  audioNoInputPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  @ViewChild("textToSpeechNotMatch", {
    static: true,
  })
  textToSpeechNotMatch: TextToSpeechComponent;
  @ViewChild("textToSpeechNoInput", {
    static: true,
  })
  textToSpeechNoInput: TextToSpeechComponent;
  @ViewChild("autoInput") input;
  listCondition;
  @ViewChild("multipleGrammar", {
    static: true,
  })
  multipleGrammar: MultipleGrammarComponent;
  cell: MxCell;
  graph: MxGraph;
  data: ChatGPT = new ChatGPT();
  listDataGPT: GPTDataDto[];
  dataTTS: any = [];
  dataTTSCB: any = [];
  dataTTSNotMatch: any = [];
  dataTTSNoInput: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech or Chat Text" },
  ];
  modes: object[] = [
    { name: "Intent Detection", value: true, status: "success" },
    { name: "Conditional Trigger", value: false, status: "primary" },
  ];
  selectedSubscription: Subscription;
  grammarsData: any = [];
  intentSelected: any[] = [];
  eventsSubject: Subject<void> = new Subject<void>();
  eventsSubjectNotMatch: Subject<void> = new Subject<void>();
  eventsSubjectNoInput: Subject<void> = new Subject<void>();
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<string[]>;
  gptStatusChangedSubscription: Subscription;
  grammarStatusChangedSubscription: Subscription;
  isLoading = false;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  searchText: any = "";
  grammarArray = [];
  beforeCustomFunctions = [];
  afterCustomFunctions = [];
  grammarDataFilter = [];
  isLoadingGrammar = false;
  isLoadingBefore = false;
  isLoadingAfter = false;
  icon = "plus-outline";
  timeout = null;
  attributesx: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributesx,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  answer: any[] = [];
  listNation = [];
  example = [
    {
      buttonValue: "yes",
      buttonText: [
        { language: "en", value: "Yes" },
        { language: "vi", value: "Vâng" },
      ],
    },
    {
      buttonValue: "no",
      buttonText: [
        {
          language: "en",
          value: "No, thanks!",
        },
        {
          language: "vi",
          value: "Không, cảm ơn!",
        },
      ],
    },
  ];
  copyTitle = "Copy to clipboard";
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private chatGPTClient: ChatGPTClient,
    private grammarClient: GrammarClient,
    private toastrService: NbToastrService,
    private signalRService: SignalRService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    private clipboard: Clipboard
  ) {
    this.createForm();
  }
  removeAnswer(z) {
    if (z != 0) {
      this.answer.splice(z, 1);
    } else {
      this.answer.shift();
    }
  }
  copyAttribute() {
    this.clipboard.copy(JSON.stringify(this.example, undefined, 2));
    this.copyTitle = "Copied to clipboard";
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.answer, event.previousIndex, event.currentIndex);
  }
  handleAnswerOptionType(value: number) {
    this.data.answerOptionType = value;
  }
  handleAnswerOption() {
    try {
      let data = this.answer;
      if (data && data?.length > 0) {
        let finalData = data.filter((x) => x.name?.trim() != "");
        if (finalData.length > 0) {
          const trimmedData = finalData.map((item) => {
            return {
              ...item,
              name: item.name.trim(),
            };
          });
          trimmedData.forEach((item, index) => {
            item.value.forEach((value, indexValue) => {
              trimmedData[index].value[indexValue].value =
                trimmedData[index].value[indexValue].value.trim();
            });
          });
          this.data.answer = JSON.stringify(trimmedData);
        } else {
          this.data.answer = "";
        }
      } else {
        this.data.answer = "";
      }
    } catch (error) {
      this.data.answer = "";
    }
  }
  addNewAnswer() {
    let newLanguage = { name: "", value: [] };
    this.listNation.forEach((nation) => {
      newLanguage.value.push({
        name: nation.name,
        language: nation.language,
        value: "",
      });
    });
    this.answer.push(newLanguage);
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.listElement.nativeElement.scrollTop =
          this.listElement.nativeElement.scrollHeight;
      }, 10);
    } catch (err) {}
  }
  allowAnswerOption($event) {
    if ($event && this.answer.length == 0) {
      this.addNewAnswer();
    } else {
      this.scrollToBottom();
    }
    this.data.allowAnswerOption = $event;
  }
  optimize() {
    if (this.data.prompt && this.data.prompt?.trim()) {
      this.dialogService
        .open(OptimizePromptComponent, {
          autoFocus: false,
          context: { data: this.data.prompt, model: "gpt" },
        })
        .onClose.subscribe((rs) => {
          if (rs?.status == "save") {
            this.data.prompt = rs.data;
          }
        });
    } else {
      this.toastrService.show("Please enter your prompt.", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  expand() {
    this.dialogService
      .open(ExpandTextComponent, {
        autoFocus: false,
        closeOnBackdropClick: false,
        context: { data: this.data.prompt, name: "Prompt" },
      })
      .onClose.subscribe((rs) => {
        if (rs.status == "save") {
          this.data.prompt = rs.data;
        }
      });
  }
  getFirstPrompt() {
    this.textToSpeech.handleCheckEmptyData();
    try {
      let rs = "";
      let data = JSON.parse(this.data.content);
      let dataFind = data.find((x) => x.isDefault == true);
      if (dataFind != null) {
        dataFind.content[0].forEach((element) => {
          if (element.type == "text") {
            rs = rs + element.value + " . ";
          }
        });
      }
      rs = rs?.trim();
      rs = rs.slice(0, -1);
      rs = rs?.trim();
      return rs;
    } catch (error) {
      return "";
    }
  }
  testBot() {
    this.dialogService
      .open(TestActionComponent, {
        autoFocus: false,
        context: {
          data: this.data,
          name: this.data.name,
          show: true,
          firstPrompt: this.getFirstPrompt(),
        },
      })
      .onClose.subscribe((rs) => {});
  }
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.contentChatBot = this.data.textChatBot;
      this.data.contentNotMatch = this.data.textNotMatch;
      this.data.contentNoInput = this.data.textNoInput;
      this.data.text = "";
      this.data.textChatBot = "";
      this.data.textNotMatch = "";
      this.data.textNoInput = "";
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      QADataset: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnDestroy(): void {
    this.grammarStatusChangedSubscription.unsubscribe();
    this.gptStatusChangedSubscription.unsubscribe();
  }
  handleSentiment($event, type) {
    switch (type) {
      case "main":
        this.data.sentimentMain = $event;
        break;
      case "noInput":
        this.data.sentimentNoInput = $event;
        break;
      case "notMatch":
        this.data.sentimentNotMatch = $event;
        break;
      default:
        break;
    }
  }
  getGPTData() {
    this.isLoading = true;
    this.listDataGPT = [];
    this.chatGPTClient.getAll().subscribe((rs) => {
      this.listDataGPT = [...rs.gptDataList];
      if (this.data.idDataset) {
        let dataExist = this.listDataGPT?.find(
          (x) => x.id == this.data.idDataset
        );
        if (dataExist) {
          this.data.idDataset =
            Number(this.data.idDataset) == 0 ||
            isNaN(parseInt(this.data.idDataset))
              ? null
              : this.data.idDataset;
        } else {
          this.data.idDataset = null;
        }
      } else {
        this.data.idDataset = null;
      }
      this.isLoading = false;
    });
  }

  ngOnInit() {
    this.gptStatusChangedSubscription =
      this.signalRService.statusGPTChanged.subscribe((rs) => {
        this.notify(rs);
        this.getGPTData();
      });
    this.grammarStatusChangedSubscription =
      this.signalRService.statusGrammarChanged.subscribe((rs) => {
        this.notify(rs);
        this.multipleGrammar.getGrammarData();
      });

    this.getGPTData();
    this.filteredOptions$ = of(this.attributes);
    this.handleSubscribeAudio();
    this.handleSubscribeTTS();

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] =
        this.cell.getAttribute(key) != "undefined"
          ? this.cell.getAttribute(key)
          : "";
    }
    this.convertToNewDataFormat();
    this.handleSetTypeTTS();
    this.handleShowButtonAudio();
    this.getAnswerAction();
    if (
      this.data.audioId ||
      this.data.audioNotMatchId ||
      this.data.audioNoInputId
    ) {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;

        this.audioNotMatchPlayer.listAudioInAction = audioVm.audios;
        this.audioNoInputPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);

        this.audioNotMatchPlayer.playSelectedAudio(this.data.audioNotMatchId);
        this.audioNoInputPlayer.playSelectedAudio(this.data.audioNoInputId);
      });
    }
    if (this.data.option) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.enableCheckpoint) {
      this.data.enableCheckpoint =
        this.data.enableCheckpoint.toString() === "true";
    } else {
      this.data.enableCheckpoint = false;
    }
    if (this.data.customTrain) {
      this.data.customTrain = this.data.customTrain.toString() === "true";
    } else {
      this.data.customTrain = false;
    }

    if (!this.data.noInputTimeout || this.data.noInputTimeout?.trim() == "") {
      this.data.noInputTimeout = "5";
    }
    if (!this.data.minLength || this.data.minLength?.trim() == "") {
      this.data.minLength = "1";
    }
    if (!this.data.maxLength && this.data.maxLength?.trim() != "") {
      this.data.maxLength = "20";
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }
    if (this.data.idGrammar) {
      this.data.idGrammar =
        Number(this.data.idGrammar) == 0 || isNaN(this.data.idGrammar)
          ? null
          : Number(this.data.idGrammar);
    } else {
      this.data.idGrammar = null;
    }

    if (!this.data.keepContext) {
      this.data.keepContext = false;
    }

    if (this.data.intentQA) {
      let intentQA = this.data.intentQA.split(",").filter(Boolean);
      if (intentQA.length > 0) {
        intentQA.forEach((element) => {
          let obj = { display: element, value: element };
          this.intentSelected.push(obj);
        });
      }
    }
    if (this.data.grammarArray) {
      this.grammarArray = JSON.parse(this.data.grammarArray);
    }
    if (this.data.beforeCustomFunctions) {
      this.beforeCustomFunctions = JSON.parse(this.data.beforeCustomFunctions);
    }
    if (this.data.afterCustomFunctions) {
      this.afterCustomFunctions = JSON.parse(this.data.afterCustomFunctions);
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    if (this.data.contentChatBot) {
      this.textToSpeech.selectCurrentTTSBot(this.data.contentChatBot);
    }
    if (this.data.contentNotMatch) {
      this.textToSpeechNotMatch.selectCurrentTTS(this.data.contentNotMatch);
    } else {
      this.textToSpeechNotMatch.checkNull = true;
    }
    if (this.data.contentNoInput) {
      this.textToSpeechNoInput.selectCurrentTTS(this.data.contentNoInput);
    } else {
      this.textToSpeechNoInput.checkNull = true;
    }

    if (this.data.allowAnswerOption) {
      this.data.allowAnswerOption =
        this.data.allowAnswerOption.toString() === "true";
    } else {
      this.data.allowAnswerOption = false;
    }

    if (!this.data.answerOptionType) {
      this.data.answerOptionType = 1;
    }
    this.handleAnswerOptionType(Number(this.data.answerOptionType));
    this.textToSpeech.isChatBot = true;

    this.windowRef.onClose.subscribe(() => this.submit());
  }

  getAnswerVariable($event) {
    this.data.answerCustom = $event;
  }
  getAnswerAction() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    this.data.answer = this.data.answer || "[]";
    let answerOption = JSON.parse(this.data.answer);
    if (answerOption && answerOption.length > 0) {
      this.listNation.forEach((obj) => {
        for (let i = 0; i < answerOption.length; i++) {
          let check = 0;
          for (let j = 0; j < answerOption[i].value.length; j++) {
            const languageSetting = obj.language.split("-");
            const languageTTS = answerOption[i].value[j].language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              answerOption[i].value[j].name = obj.name;
              answerOption[i].value[j].language = obj.language;
              check = 1;
            }
          }
          if (check == 0) {
            answerOption[i].value.push({
              name: obj.name,
              language: obj.language,
              value: "",
            });
          }
        }
      });
      for (let k = 0; k < answerOption.length; k++) {
        for (let z = 0; z < answerOption[k].value.length; z++) {
          let check = 0;
          this.listNation.forEach((item) => {
            const languageSetting =
              answerOption[k].value[z].language.split("-");
            const languageTTS = item.language.split("-");
            if (languageSetting[0] == languageTTS[0]) {
              check = 1;
            }
          });
          if (check == 0) {
            if (z != 0) {
              answerOption[k].value.splice(z, 1);
              z--;
            } else {
              answerOption[k].value.shift();
              z--;
            }
          }
        }
      }
      this.answer = answerOption;
    }
  }
  allowLog($event) {
    this.data.log = $event;
  }
  allowKeepContext($event) {
    this.data.keepContext = $event;
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
  handleMode(value) {
    this.data.enableCheckpoint = value;
  }
  handleDataIntentQA() {
    let intentQA = [];
    if (this.intentSelected.length > 0) {
      this.intentSelected.forEach((element) => {
        intentQA.push(element.display);
      });
    }
    this.data.intentQA = intentQA.toString();
  }
  submit() {
    if (!this.cancelAction && !this.isLoading) {
      this.handleDataIntentQA();
      this.handleAnswerOption();
      const data = this.listDataGPT.find(
        (item) => item.id == this.data.idDataset
      );
      this.data.directory = data?.extenData || "";
      this.data.datasetName = data?.name || "";
      this.data.type = data?.type || "";
      this.data.idDataset = data?.id || "";
      if (
        this.data.timeout == "" ||
        !this.data.timeout ||
        parseFloat(this.data.timeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.timeout))
      ) {
        this.data.timeout = "10";
      }
      if (
        this.data.repeat == "" ||
        !this.data.repeat ||
        parseFloat(this.data.repeat) < 0 ||
        !Number.isInteger(parseFloat(this.data.repeat))
      ) {
        this.data.repeat = "2";
      }
      if (
        this.data.minLength == "" ||
        !this.data.minLength ||
        parseFloat(this.data.minLength) < 0 ||
        !Number.isInteger(parseFloat(this.data.minLength))
      ) {
        this.data.minLength = "1";
      }
      if (
        this.data.noInputTimeout == "" ||
        !this.data.noInputTimeout ||
        parseFloat(this.data.noInputTimeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.noInputTimeout))
      ) {
        this.data.noInputTimeout = "5";
      }
      if (
        parseFloat(this.data.maxLength) < 0 ||
        !Number.isInteger(parseFloat(this.data.maxLength))
      ) {
        this.data.maxLength = "";
      }
      this.data.grammarArray = JSON.stringify(this.grammarArray);
      this.data.beforeCustomFunctions = JSON.stringify(
        this.beforeCustomFunctions
      );
      this.data.afterCustomFunctions = JSON.stringify(
        this.afterCustomFunctions
      );
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }

      const chatgptCell = this.graph.getModel().getCell(this.cell.getId());
      var fieldsRequired = [];
      if (this.data.customTrain) {
        fieldsRequired = [this.data.idDataset];
      }
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        chatgptCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  addChatGPTDataset() {
    this.dialogService
      .open(UpdateDatasetComponent, {
        autoFocus: false,
        context: { isAction: true, title: "Create Knowledge" },
      })
      .onClose.subscribe((rs) => {
        if (rs?.rs == true) {
          this.addNewDataGPTToList(rs.name);
        }
      });
  }
  addNewDataGPTToList(name) {
    this.chatGPTClient.getAll().subscribe((rs) => {
      this.listDataGPT = [...rs.gptDataList];
      this.data.datasetName = name;
      this.data.idDataset = this.listDataGPT?.find((x) => x.name == name).id;
    });
  }

  showToast(result) {
    if (result) {
      this.toastrService.show("Delete knowledge successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(
        "Delete knowledge unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
  notify(result) {
    if (result) {
      this.toastrService.show("Train successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Train unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  handleMaxValueNumberOutput(data) {
    const inputValue = data?.target?.value?.trim();
    if (inputValue && !isNaN(inputValue)) {
      if (Number(inputValue) > 4000) {
        this.data.numberOutput = 4000;
      }
    } else {
      this.data.numberOutput = 0;
    }
  }

  handleMaxValueTemperature(data) {
    const inputValue = data?.target?.value?.trim();
    if (inputValue && !isNaN(inputValue)) {
      if (Number(inputValue) > 2) {
        this.data.temperature = 2;
      }
    } else {
      this.data.temperature = 0;
    }
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  addEntityAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.entityAttribute = rs;
        }
      });
  }
  addAttributeUser() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.userResponse = rs;
        }
      });
  }
  addAttributeSentiment() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.sentiment = rs;
        }
      });
  }

  updateCurrentData(e: any, id: any) {
    e.stopPropagation();
    let dataUpdate = this.listDataGPT.find((x) => x.id == id);
    if (dataUpdate) {
      this.updateData(e, dataUpdate);
    }
  }

  updateData(e: any, dataset: any) {
    e.stopPropagation();
    this.chatGPTClient.get(dataset.id).subscribe((rs) => {
      if (rs) {
        this.dialogService
          .open(UpdateDatasetComponent, {
            autoFocus: false,
            context: {
              data: rs,
              isAction: true,
              title: "Update Knowledge",
            },
          })
          .onClose.subscribe((rs) => {
            this.getGPTData();
          });
      }
    });
  }

  removeData(e: any, dataset: any) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "Knowledge '" + dataset.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.chatGPTClient.delete(Number(dataset.id)).subscribe((rs) => {
            this.showToast(rs);
            if (rs == true) {
              this.isLoading = false;
              this.getGPTData();
            }
          });
        }
      });
  }

  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }

  showModifyNotMatch() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioNotMatchId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectNotMatch.next(rs);
    });
  }
  showModifyNoInput() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioNoInputId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectNoInput.next(rs);
    });
  }

  handleShowButtonAudio() {
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.audioNotMatchPlayer.showAddButton = true;
    this.audioNotMatchPlayer.isCallFlowAction = true;
    this.audioNoInputPlayer.showAddButton = true;
    this.audioNoInputPlayer.isCallFlowAction = true;
  }
  handleSetTypeTTS() {
    this.textToSpeech.type = "main";
    this.textToSpeechNoInput.isNoInput = true;
    this.textToSpeechNoInput.type = "no-input";
    this.textToSpeechNotMatch.isNotMatch = true;
    this.textToSpeechNotMatch.type = "not-match";
  }

  handleSubscribeAudio() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );
    this.selectedSubscription =
      this.audioNotMatchPlayer.selectedAudio.subscribe((audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNotMatchId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNotMatchPath =
          audio !== undefined ? audioPath.toString() : "";
      });
    this.selectedSubscription = this.audioNoInputPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNoInputId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNoInputPath =
          audio !== undefined ? audioPath.toString() : "";
      }
    );
  }

  handleSubscribeTTS() {
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeech.selectedTTSBot.subscribe(
      (tts) => {
        this.data.contentChatBot = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNotMatch.selectedTTS.subscribe(
      (tts) => {
        this.data.contentNotMatch = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNoInput.selectedTTS.subscribe(
      (tts) => {
        this.data.contentNoInput = JSON.stringify(tts);
      }
    );
  }

  setTemperature(duration: number) {
    this.data.temperature = duration;
  }

  getTemperature(): number {
    return (this.data.temperature * 100) / 2;
  }

  setNumberOutput(duration: number) {
    this.data.numberOutput = duration;
  }

  getNumberOutput(): number {
    return (this.data.numberOutput * 100) / 4000;
  }

  getUserResponse(event) {
    this.data.userResponse = event;
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
  getEntityAttribute(event) {
    this.data.entityAttribute = event;
  }
  getSentiment(event) {
    this.data.sentiment = event;
  }
}
