import { filter } from "rxjs";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { TextToSpeech } from "../../@core/model/text-to-speech";
import { DataService } from "../../@core/utils/data.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AudioClient,
  CallFlowClient,
  ConvertAudioCommand,
  CreateAudioLanguageCommand,
  UploadImageCommand,
} from "../../System-api";
import { ppid } from "process";
import { Fancybox } from "@fancyapps/ui";
import { Uppy } from "@uppy/core";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { MediaModalComponent } from "../media-modal/media-modal.component";

@Component({
  selector: "text-to-speech",
  templateUrl: "./text-to-speech.component.html",
  styleUrls: ["./text-to-speech.component.scss"],
})
export class TextToSpeechComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("testAudio", { static: true })
  public testAudio: ElementRef;
  listNation;
  dataText = [];
  dataTextBot = [];
  data: TextToSpeech = new TextToSpeech();
  currentTTS = [];
  currentTTSBot = [];
  isNoInput: boolean = false;
  isNotMatch: boolean = false;
  previousLength = 0;
  isChatBot: boolean = false;
  checkNull: boolean = false;
  isPlaying: boolean;
  audioBefore: CreateAudioLanguageCommand;
  dataAudio;
  attributes: any = this.dataService.ivrAttribute;
  listAttributes = [];
  ivrFunction: any = this.dataService.ivrFunction;
  selectedTTS: EventEmitter<any> = new EventEmitter<any>();
  selectedTTSBot: EventEmitter<any> = new EventEmitter<any>();
  newPrompt = [];
  newPromptBot = [];
  currentText: string = "";
  currentSelect: any = {};
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
      {
        items: this.ivrFunction,
        triggerChar: ">",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return ">" + item.value;
        },
      },
    ],
  };
  type = "default";
  isMainPromptAndCollect = false;
  typePrompt = [
    { value: "text", name: "Text", icon: "text-outline" },
    { value: "image", name: "Image", icon: "image-outline" },
    { value: "video", name: "Video", icon: "video-outline" },
  ];
  showButtonType = false;
  @ViewChild("inputString", { static: false })
  inputString: ElementRef<any>;
  isEmoji = false;
  constructor(
    private dataService: DataService,
    private audioClient: AudioClient,
    private elRef: ElementRef,
    private cd: ChangeDetectorRef,
    private callFlowClient: CallFlowClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {}
  handleImageError(event: any) {
    event.target.src = "../../../assets/images/default-image-small.jpg";
  }
  setFocus() {
    setTimeout(() => {
      this.inputString.nativeElement.value = "";
      this.inputString.nativeElement.focus();
    }, 0);
  }
  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  handleImage(i, j, z, value, typePrompt, type) {
    this.dialogService
      .open(MediaModalComponent, {
        autoFocus: false,
        context: {
          urlData: value,
          allowUploadVideo: true,
          type: typePrompt,
          files:
            typePrompt == "video"
              ? [".mp4"]
              : [".jpg", ".jpeg", ".gif", ".png"],
        },
      })
      .onClose.subscribe((rs) => {
        if (rs && rs?.status == true) {
          if (type == "bot") {
            this.dataTextBot[i].content[j][z].value = rs.data?.trim();
          }
          if (type == "phone") {
            this.dataText[i].content[j][z].value = rs.data?.trim();
          }
        }
      });
  }
  showMedia(media) {}
  ngAfterViewInit(): void {}

  trackByFn(index, item) {
    return index;
  }
  trackByFnBot(index, item) {
    return index;
  }
  ngOnDestroy(): void {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
    this.handleCheckEmptyData();
  }
  handleCheckEmptyData() {
    this.dataText.forEach((item, index) => {
      item.content.forEach((x, y) => {
        this.dataText[index].content[y] = x.filter((i, k) => {
          if (i.value?.trim() !== "") {
            this.dataText[index].content[y][k].value = i.value.trim();
            return true;
          } else {
            return false;
          }
        });
      });
      this.dataText[index].content = item.content.filter((l, m) => {
        return l.length > 0;
      });
      if (this.dataText[index].content.length == 0) {
        this.dataText[index].content = [[{ value: "", type: "text" }]];
      }
    });
    this.selectedTTS.emit(this.dataText);
    if (this.isChatBot) {
      this.dataTextBot.forEach((item, index) => {
        item.content.forEach((x, y) => {
          this.dataTextBot[index].content[y] = x.filter((i, k) => {
            if (i.value?.trim() !== "") {
              this.dataTextBot[index].content[y][k].value = i.value.trim();
              return true;
            } else {
              return false;
            }
          });
        });
        this.dataTextBot[index].content = item.content.filter((l, m) => {
          return l.length > 0;
        });
        if (this.dataTextBot[index].content.length == 0) {
          this.dataTextBot[index].content = [[{ value: "", type: "text" }]];
        }
      });
      this.selectedTTSBot.emit(this.dataTextBot);
    }
  }
  ngOnInit() {
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Toolbar: {
        display: {
          left: [],
          right: ["zoomIn", "zoomOut", "close"],
          middle: [],
        },
      },
      Thumbs: {
        type: "modern",
      },
    });
    this.audioBefore = new CreateAudioLanguageCommand();
    this.getNationSetting();
    if (this.checkNull) {
      if (this.isNoInput) {
        this.setDefaultNoInput();
      }
      if (this.isNotMatch) {
        this.setDefaultNotMatch();
      }
    }
  }
  showToast(result) {
    if (result == true) {
      this.toastrService.show("Upload image successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Upload image unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  setDefaultNoInput() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].content[0][0].value == "" && language[0] == "en") {
        this.dataText[i].content = [
          [{ value: "Sorry, I didn't hear you.", type: "text" }],
          [
            {
              value: "I'm sorry, I'm having a hard time hearing you.",
              type: "text",
            },
          ],
        ];
      }
    }
    this.selectedTTS.emit(this.dataText);
  }
  setDefaultNotMatch() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].content[0][0].value == "" && language[0] == "en") {
        this.dataText[i].content = [
          [{ value: "Sorry, I didn't understand that.", type: "text" }],
          [
            {
              value: "I'm sorry, I didn't understand what you said.",
              type: "text",
            },
          ],
        ];
      }
    }
    this.selectedTTS.emit(this.dataText);
  }
  convertTTS(i, j, z, type) {
    let data = type == "bot" ? this.dataTextBot[i] : this.dataText[i];
    this.currentText = data.content[j][z].value;
    const audio = this.listNation.find((obj) => obj.name == data.name);
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }
    if (
      this.audioBefore.content == this.currentText &&
      this.audioBefore.service == audio.service &&
      this.audioBefore.language == audio.language &&
      this.audioBefore.voiceId == audio.voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = this.currentText;
      this.audioBefore.service = audio.service;
      this.audioBefore.language = audio.language;
      this.audioBefore.voiceId = audio.voiceId;

      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.currentText,
            service: audio.service,
            language: audio.language,
            voiceId: audio.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }
  getNationSetting() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    if (this.currentTTS.length > 0 || this.currentTTSBot.length > 0) {
      this.dataText = this.currentTTS;
      if (this.isChatBot) {
        this.dataTextBot = this.currentTTSBot;
      }
    }

    this.handleBindingData(this.dataText, "phone");
    this.newPrompt = new Array(this.dataText.length).fill("");
    if (this.isChatBot) {
      this.handleBindingData(this.dataTextBot, "bot");
      this.newPromptBot = new Array(this.dataTextBot.length).fill("");
    }
  }
  handleBindingData(data: any, type: string) {
    this.listNation.forEach((obj) => {
      let check = 0;
      for (let i = 0; i < data.length; i++) {
        const languageSetting = obj.language.split("-");
        const languageTTS = data[i].language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          data[i].name = obj.name;
          data[i].language = obj.language;
          data[i].isDefault = obj.defaultUser;
          check = 1;
        }
      }
      if (check == 0) {
        let objText = {
          content: [[{ value: "", type: "text" }]],
          isDefault: obj["defaultUser"],
        };
        obj = { ...obj, ...objText };
        delete obj["service"];
        delete obj["voiceId"];
        delete obj["gender"];
        delete obj["defaultUser"];
        data.push(obj);
      }
    });
    for (let j = 0; j < data.length; j++) {
      let check = 0;
      this.listNation.forEach((item) => {
        const languageSetting = data[j].language.split("-");
        const languageTTS = item.language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          check = 1;
        }
      });
      if (check == 0) {
        if (j != 0) {
          data.splice(j, 1);
          j--;
        } else {
          data.shift();
          j--;
        }
      }
    }
  }
  selectCurrentTTS(text) {
    try {
      this.currentTTS = JSON.parse(text);
      this.currentTTS.forEach((item, index) => {
        if (item?.text) {
          if (typeof item.text == "string") {
            const textData = item.text.split("\u2022").filter(Boolean);
            this.currentTTS[index].text = textData;
          }
          if (Array.isArray(this.currentTTS[index].text)) {
            this.currentTTS[index].text = this.currentTTS[index].text.map(
              (textData) => {
                if (typeof textData == "string") {
                  return { value: textData, type: "text" };
                } else {
                  return textData;
                }
              }
            );
          }
          this.currentTTS[index].content = [];
          if (this.currentTTS[index].text.length > 0) {
            this.currentTTS[index].content = this.currentTTS[index].text.map(
              (item) => [item]
            );
          } else {
            this.currentTTS[index].content.push([{ value: "", type: "text" }]);
          }
          delete this.currentTTS[index]["text"];
        }
        if (this.currentTTS[index].hasOwnProperty("defaultUser")) {
          this.currentTTS[index].isDefault = this.currentTTS[index].defaultUser;
          delete this.currentTTS[index]["defaultUser"];
        }
      });
    } catch (e) {
      this.currentTTS = [];
    }
  }
  selectCurrentTTSBot(text) {
    try {
      this.currentTTSBot = JSON.parse(text);
      this.currentTTSBot.forEach((item, index) => {
        if (item?.textChatBot || item?.text) {
          if (typeof item.textChatBot == "string") {
            const textData = item.textChatBot.split("\u2022").filter(Boolean);
            this.currentTTSBot[index].textChatBot = textData;
          }
          if (Array.isArray(this.currentTTSBot[index].textChatBot)) {
            this.currentTTSBot[index].textChatBot = this.currentTTSBot[
              index
            ].textChatBot.map((textData) => {
              if (typeof textData == "string") {
                return { value: textData, type: "text" };
              } else {
                return textData;
              }
            });
          }
          this.currentTTSBot[index].content = [];

          if (this.currentTTSBot[index].textChatBot.length > 0) {
            this.currentTTSBot[index].content = this.currentTTSBot[
              index
            ].textChatBot.map((item) => [item]);
          } else {
            this.currentTTSBot[index].content.push([
              { value: "", type: "text" },
            ]);
          }
          delete this.currentTTSBot[index]["textChatBot"];
          delete this.currentTTSBot[index]["text"];
        }
        if (this.currentTTSBot[index].hasOwnProperty("defaultUser")) {
          this.currentTTSBot[index].isDefault =
            this.currentTTSBot[index].defaultUser;
          delete this.currentTTSBot[index]["defaultUser"];
        }
      });
    } catch (e) {
      this.currentTTSBot = [];
    }
  }
  updateType(type) {
    if (
      this.currentSelect &&
      this.currentSelect.indexLanguage >= 0 &&
      this.currentSelect.indexText >= 0 &&
      this.currentSelect.type
    ) {
      if (this.currentSelect.type == "bot") {
        this.dataTextBot[this.currentSelect.indexLanguage].content[
          this.currentSelect.indexText
        ][this.currentSelect.indexTextContent].type = type;
      }
      if (this.currentSelect.type == "phone") {
        this.dataText[this.currentSelect.indexLanguage].content[
          this.currentSelect.indexText
        ][this.currentSelect.indexTextContent].type = type;
      }
    }
    this.currentSelect = {};
  }
  updateText(text) {
    if (
      this.currentSelect &&
      this.currentSelect.indexLanguage >= 0 &&
      this.currentSelect.indexText >= 0 &&
      this.currentSelect.type
    ) {
      if (this.currentSelect.type == "bot") {
        this.dataTextBot[this.currentSelect.indexLanguage].content[
          this.currentSelect.indexText
        ][this.currentSelect.indexTextContent].value =
          this.dataTextBot[this.currentSelect.indexLanguage].content[
            this.currentSelect.indexText
          ][this.currentSelect.indexTextContent].value +
          "{" +
          text +
          "}";
      }
      if (this.currentSelect.type == "phone") {
        this.dataText[this.currentSelect.indexLanguage].content[
          this.currentSelect.indexText
        ][this.currentSelect.indexTextContent].value =
          this.dataText[this.currentSelect.indexLanguage].content[
            this.currentSelect.indexText
          ][this.currentSelect.indexTextContent].value +
          "{" +
          text +
          "}";
      }
    }
    this.currentSelect = {};
  }
  menuOpened(i, j, z, type) {
    this.currentSelect = {
      indexLanguage: i,
      indexText: j,
      indexTextContent: z,
      type: type,
    };
  }
  menuOpenedVariable(i, j, z, type, isEmoji) {
    if (isEmoji) {
      this.isEmoji = true;
    }
    this.currentSelect = {
      indexLanguage: i,
      indexText: j,
      indexTextContent: z,
      type: type,
    };
    this.listAttributes = [...this.attributes];
    this.setFocus();
  }
  addEmoji(event) {
    if (event?.emoji?.native) {
      let emoji = event?.emoji?.native;
      if (
        this.currentSelect &&
        this.currentSelect.indexLanguage >= 0 &&
        this.currentSelect.indexText >= 0 &&
        this.currentSelect.type
      ) {
        if (this.currentSelect.type == "bot") {
          this.dataTextBot[this.currentSelect.indexLanguage].content[
            this.currentSelect.indexText
          ][this.currentSelect.indexTextContent].value =
            this.dataTextBot[this.currentSelect.indexLanguage].content[
              this.currentSelect.indexText
            ][this.currentSelect.indexTextContent].value + emoji;
        }
        if (this.currentSelect.type == "phone") {
          this.dataText[this.currentSelect.indexLanguage].content[
            this.currentSelect.indexText
          ][this.currentSelect.indexTextContent].value =
            this.dataText[this.currentSelect.indexLanguage].content[
              this.currentSelect.indexText
            ][this.currentSelect.indexTextContent].value + emoji;
        }
      }
    }
  }
  handleSearch(event) {
    let valueSearch = event?.target?.value;
    if (valueSearch) {
      this.listAttributes = this.attributes.filter((x) => {
        return x.value.toLowerCase().includes(valueSearch.toLowerCase());
      });
    } else {
      this.listAttributes = [...this.attributes];
    }
  }
  onChangeContentText(i, j, z) {
    const textArea = document.getElementById(
      this.type + "-text-item-" + i + "-" + j + "-" + z
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  resetContentText(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
    }
  }
  onChangeContentTextBot(i, j, z) {
    const textArea = document.getElementById(
      this.type + "-text-item-bot-" + i + "-" + j + "-" + z
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  resetContentTextBot(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-bot-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
    }
  }
  checkDefaultLanguageUser(data) {
    let check = false;
    if (data.defaultUser === true) {
      check = true;
    }
    return check;
  }
  handleAddNewPrompt(index, j) {
    this.dataText[index].content[j].push({
      value: "",
      type: "text",
    });
    this.selectedTTS.emit(this.dataText);
    const z = this.dataText[index].content[j].length - 1;
    setTimeout(() => {
      const textArea = document.getElementById(
        this.type + "-text-item-" + index + "-" + j + "-" + z
      );
      textArea.focus();
    });
  }
  handleAddNewPromptGroup(index) {
    this.dataText[index].content.push([
      {
        value: "",
        type: "text",
      },
    ]);
    this.selectedTTS.emit(this.dataText);
    const z = this.dataText[index].content.length - 1;
    setTimeout(() => {
      const textArea = document.getElementById(
        this.type + "-text-item-" + index + "-" + z + "-" + 0
      );
      textArea.focus();
    });
  }
  handleAddNewPromptBot(index, j) {
    this.dataTextBot[index].content[j].push({
      value: "",
      type: "text",
    });
    this.selectedTTSBot.emit(this.dataTextBot);
    const z = this.dataTextBot[index].content[j].length - 1;
    setTimeout(() => {
      const textArea = document.getElementById(
        this.type + "-text-item-bot-" + index + "-" + j + "-" + z
      );
      textArea.focus();
    });
  }
  handleAddNewPromptGroupBot(index) {
    this.dataTextBot[index].content.push([
      {
        value: "",
        type: "text",
      },
    ]);
    this.selectedTTSBot.emit(this.dataTextBot);
    const z = this.dataTextBot[index].content.length - 1;
    setTimeout(() => {
      const textArea = document.getElementById(
        this.type + "-text-item-bot-" + index + "-" + z + "-" + 0
      );
      textArea.focus();
    });
  }
  handleEdit(i, j, z) {
    this.onChangeContentText(i, j, z);
  }

  handleEditBot(i, j, z) {
    this.onChangeContentTextBot(i, j, z);
  }

  drop(event: CdkDragDrop<string[]>, index, j) {
    moveItemInArray(
      this.dataText[index].content[j],
      event.previousIndex,
      event.currentIndex
    );
    this.resetContentText(index, event.previousIndex);
    this.resetContentText(index, event.currentIndex);
    this.selectedTTS.emit(this.dataText);
  }
  dropContent(event: CdkDragDrop<string[]>, index) {
    moveItemInArray(
      this.dataText[index].content,
      event.previousIndex,
      event.currentIndex
    );
    this.selectedTTS.emit(this.dataText);
  }
  dropContentBot(event: CdkDragDrop<string[]>, index) {
    moveItemInArray(
      this.dataTextBot[index].content,
      event.previousIndex,
      event.currentIndex
    );
    this.selectedTTS.emit(this.dataText);
  }
  dropBot(event: CdkDragDrop<string[]>, index, j) {
    moveItemInArray(
      this.dataTextBot[index].content[j],
      event.previousIndex,
      event.currentIndex
    );
    this.resetContentTextBot(index, event.previousIndex);
    this.resetContentTextBot(index, event.currentIndex);
    this.selectedTTSBot.emit(this.dataTextBot);
  }
  handleRemovePrompt(index, i, z) {
    if (z != 0) {
      this.dataText[index].content[i].splice(z, 1);
    } else {
      this.dataText[index].content[i].shift();
    }
    this.dataText[index].content = this.dataText[index].content.filter(
      (x) => x.length > 0
    );
    this.selectedTTS.emit(this.dataText);
  }
  handleRemovePromptBot(index, i, z) {
    if (z != 0) {
      this.dataTextBot[index].content[i].splice(z, 1);
    } else {
      this.dataTextBot[index].content[i].shift();
    }
    this.dataTextBot[index].content = this.dataTextBot[index].content.filter(
      (x) => x.length > 0
    );
    this.selectedTTSBot.emit(this.dataTextBot);
  }
  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
}
