import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Directive,
  HostListener,
} from "@angular/core";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import {
  AudioClient,
  GetQueuesListQuery,
  Page,
  QueueClient,
  QueueDto,
} from "../../../../System-api";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { Subject, Subscription } from "rxjs";
import { GoToQueue } from "../../../../@core/model/go-to-queue";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechModalComponent } from "../../../../shared/text-to-speech-modal/text-to-speech-modal.component";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { Router } from "@angular/router";

@Component({
  selector: "go-to-queue",
  templateUrl: "./go-to-queue.component.html",
  styleUrls: ["./go-to-queue.component.scss"],
})
export class GoToQueueComponent implements OnInit {
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;

  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  selectedQueue: number = 0;
  selectedSubscription: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();
  queues: QueueDto[];
  cell: MxCell;
  graph: MxGraph;
  dataTTS: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech" },
  ];
  data: GoToQueue = new GoToQueue();
  isLoading = false;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  errorMessage: string = "Variable is required.";
  constructor(
    private dialogService: NbDialogService,
    private graphHandler: GraphHandlerService,
    protected windowRef: NbWindowRef,
    private queueClient: QueueClient,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
    private router: Router
  ) {
    this.createForm();
  }
  convertToNewDataFormat() {
    if (!this.data.content) {
      this.data.content = this.data.text;
      this.data.text = "";
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      queue: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  getQueues() {
    this.isLoading = true;
    let dataRequest = new GetQueuesListQuery();
    let page = new Page();
    page.sortBy = "id";
    page.sortAsc = false;
    page.pageNumber = 0;
    page.size = 1000;
    dataRequest.page = page;
    dataRequest.searchText = null;
    dataRequest.filterData = null;
    this.queueClient.getAll(dataRequest).subscribe((item) => {
      this.queues = item.queues;
      if (this.data.queueId) {
        let dataExist = this.queues?.find(
          (x) => x.id.toString() == this.data.queueId
        );
        if (dataExist) {
          this.selectedQueue =
            Number(this.data.queueId) == 0 || isNaN(Number(this.data.queueId))
              ? null
              : Number(this.data.queueId);
        } else {
          this.selectedQueue = null;
        }
      } else {
        this.selectedQueue = null;
      }
      this.isLoading = false;
    });
  }
  ngOnInit() {
    this.getQueues();
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );

    //set data to data object from cell xml
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    if (this.data.isVariable) {
      this.data.isVariable = this.data.isVariable.toString() === "true";
    } else {
      this.data.isVariable = false;
    }
    this.convertToNewDataFormat();
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.content = JSON.stringify(tts);
      }
    );
    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.content) {
      this.textToSpeech.selectCurrentTTS(this.data.content);
    }
    this.getDefaultSelectedQueue();
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  ngOnDestroy() {
    this.selectedSubscription.unsubscribe();
  }
  handleSentiment($event) {
    this.data.sentiment = $event;
  }
  getVariable($event) {
    this.data.attribute = $event;
  }
  createQueue() {
    this.router.navigate([]).then((result) => {
      window.open("/portal/queue/update", "_blank");
    });
  }
  handleEdit(id) {
    this.router.navigate([]).then((result) => {
      window.open(`/portal/queue/update/${id}`, "_blank");
    });
  }

  submit() {
    if (!this.cancelAction && !this.isLoading) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof value == "boolean" && value == false) {
          value = "false";
        }
        this.cell.setAttribute(key, value || "");
      }
      const goToQueueCell = this.graph.getModel().getCell(this.cell.getId());
      let fieldsRequired = [];
      if (this.data.isVariable == true) {
        fieldsRequired = [this.data.attribute];
      } else {
        fieldsRequired = [this.data.queueName];
      }
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        goToQueueCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  queueSelectedChange(event) {
    if (event != null) {
      let selectedQueue = this.queues.find(
        (queue) => queue.id.toString() == event.id.toString()
      );
      this.data.queue = selectedQueue.queueId;
      this.data.queueId = selectedQueue.id.toString();
      this.data.queueName = selectedQueue.name;
    } else {
      this.data.queue = null;
      this.data.queueId = null;
      this.data.queueName = null;
    }
  }
  getDefaultSelectedQueue() {
    if (this.data.queueId == "") {
      this.selectedQueue = this.queues.length > 0 ? this.queues[0].id : 0;
    }
    this.selectedQueue = Number(this.data.queueId);
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
}
