import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import { CallFlowClient, ImportCallFlowCommand } from "../../../../System-api";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { SignalRService } from "../../../../@core/utils/signalR.service";
import { Subscription } from "rxjs";
import { AlertDialogComponent } from "../../../../shared/alert-dialog/alert-dialog.component";

@Component({
  selector: "import-flow-result.component",
  templateUrl: "./import-flow-result.component.html",
  styleUrls: ["./import-flow-result.component.scss"],
})
export class ImportFlowResultComponent implements OnInit, OnDestroy {
  @ViewChild("listElement") listElement: ElementRef;
  callFlowData = null;
  path = null;
  isLoading = true;
  isError = false;
  isSuccess = false;
  isClose = false;
  onComplete = new EventEmitter();
  result = [];
  countSuccess = 0;
  countError = 0;
  messageSubscription: Subscription;
  uuId: string = null;
  logList = [];
  isShow = false;
  progressBar = null;
  totalProgress = 0;
  valueProgress = 0;
  constructor(
    public ref: NbDialogRef<ImportFlowResultComponent>,
    private toastrService: NbToastrService,
    private callFlowClient: CallFlowClient,
    private dialogService: NbDialogService,
    private signalRService: SignalRService
  ) {}
  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }
  subscribeMessage(uuId) {
    this.signalRService.startConnection().then((rs) => {
      if (rs) {
        this.signalRService.messageServerListener(uuId);
        this.importFlow();
      } else {
        this.importFlow();
      }
    });
    this.messageSubscription = this.signalRService.receiveMessage.subscribe(
      (rs) => {
        this.logList.push(rs);
        if (rs?.progress && rs.progress != null) {
          this.settingBar(rs.progress);
        }
        this.scrollToBottom();
      }
    );
  }
  settingBar(progress) {
    this.progressBar = progress;
    const parts = progress.split("/");
    let value = Number(parts[0]);
    let total = Number(parts[1]);
    if (value <= total) {
      this.valueProgress = Math.round((value / total) * 100);
    } else {
      this.valueProgress = 100;
    }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.listElement.nativeElement.scrollTop =
          this.listElement.nativeElement.scrollHeight;
      }, 10);
    } catch (err) {}
  }
  importFlow() {
    this.callFlowClient
      .import(
        new ImportCallFlowCommand({
          callFlowData: this.callFlowData,
          path: this.path,
          uniqueId: this.uuId,
        })
      )
      .subscribe(
        (rs) => {
          setTimeout(() => {
            this.isLoading = false;
            this.result = this.changePathDisplay(rs.resultImport);
            this.countSuccess = this.countFileStatus(rs.resultImport, true);
            this.countError = this.countFileStatus(rs.resultImport, false);
            if (rs && rs.jsonFormat) {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(rs.jsonFormat, "text/xml");
              if (xmlDoc.documentElement.querySelector("mxCell")) {
                if (this.isClose == false) {
                  this.onComplete.emit(rs);
                }
              }
            }
          }, 250);
        },
        (error) => {
          setTimeout(() => {
            this.isLoading = false;
          }, 250);
        }
      );
  }
  ngOnInit() {
    this.uuId = Date.now().toString();
    this.subscribeMessage(this.uuId);
  }
  countFileStatus(data, status) {
    if (data && data.length > 0) {
      let dataList = data.filter((x) => x.isSuccess == status);
      return dataList.length;
    }
    return 0;
  }
  changePathDisplay(rs) {
    if (rs && rs.length > 0) {
      rs.forEach((element) => {
        const parts = element.path.split("/");
        element.path = parts.slice(3).join("/");
      });
    }
    return rs;
  }
  switch() {
    this.isShow = !this.isShow;
  }
  dismiss(isClose = false) {
    if (isClose && this.isLoading == true) {
      this.dialogService.open(AlertDialogComponent, {
        autoFocus: false,
        context: {
          title: "Warning",
          question:
            "Please do not leave here until the import process is complete. This may cause errors!",
          textYes: "Ok",
          statusYes: "primary",
          statusNo: "basic",
        },
      });
    } else {
      this.ref.close(null);
    }
  }
}
