import { IApplicationData } from "./common";
export class GoToQueue implements IApplicationData {
  constructor() {
    this.audioId = "";
    this.audioPath = "";
    this.text = "";
    this.content = "";
    this.option = 1;
    this.name = "Go To Queue";
    this.queue = "";
    this.queueId = "";
    this.queueName = "";
    this.sentiment = "";
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
    this.attribute = "";
    this.isVariable = false;
  }
  name: string;
  audioPath: string;
  audioId: string;
  text: string;
  content: string;
  option: number;
  queue: string;
  queueId: string;
  queueName: string;
  transition: boolean;
  sentiment: string;
  isRequired: boolean;
  checkFields: boolean;
  attribute: string;
  isVariable: boolean;
}
