<form
  [formGroup]="grammarForm"
  novalidate
  (submit)="onSubmit()"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-card class="card-digit">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <div>{{ title }}</div>
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body class="card-list">
      <div class="row grammar-wrap">
        <div class="col-md-6 mb-2">
          <label for="input-createGrammar" class="form-label label required"
            >Grammar Name</label
          >
          <input
            #inputName
            type="text"
            formControlName="name"
            nbInput
            fieldSize="small"
            [(ngModel)]="createGrammar.name"
            [attr.disabled]="isNameLoading == false ? null : isNameLoading"
            fullWidth
            [status]="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
                ? 'danger'
                : 'basic'
            "
            id="input-createGrammar"
            placeholder="Grammar Name"
          />
          <ng-container
            *ngIf="
              grammarForm.controls['name'].invalid &&
              (grammarForm.controls['name'].dirty ||
                grammarForm.controls['name'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].hasError('required') ||
                grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name is required
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="grammarForm.controls['name'].errors.isExist"
            >
              Grammar Name already exist.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Maximum length is 50 characters.
            </p>
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                grammarForm.controls['name'].errors.regex &&
                !grammarForm.controls['name'].errors.maxlength &&
                !grammarForm.controls['name'].hasError('required') &&
                !grammarForm.controls['name'].hasError('whitespace')
              "
            >
              Grammar Name cannot contain any of the following characters: \ / :
              *? " < > |
            </p>
            <p
              class="caption status-danger"
              *ngIf="grammarForm.controls['name'].errors.serverError"
            >
              {{ grammarForm.controls["name"].errors.serverError }}
            </p>
          </ng-container>
        </div>
        <div class="col-md-5 mb-2">
          <label class="form-label label required">Model</label>
          <nb-select
            placeholder="Select Model"
            size="small"
            fullWidth
            [(ngModel)]="engineType"
            class="method-option"
            formControlName="modal"
          >
            <nb-option value="gpt">GPT 3.5</nb-option>
            <nb-option value="gemini">Gemini 1.0 Pro</nb-option>
          </nb-select>
        </div>
        <div class="col-md-1 mb-2">
          <label class="form-label label">Test</label>
          <button
            (click)="testLLM()"
            nbButton
            size="small"
            status="primary"
            type="button"
          >
            <nb-icon icon="navigation-2"></nb-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-4">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.25rem;
            "
          >
            <label
              for="input-createGrammar"
              style="margin-bottom: 0"
              class="form-label label"
              >Description</label
            >
            <button
              (click)="generateIntent()"
              matTooltip="Generate intents from your Description"
              nbButton
              size="tiny"
              ghost
              status="primary"
              type="button"
              style="
                font-size: 0.7rem !important;
                padding-left: 0;
                padding-right: 0;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                fill="currentColor"
                viewBox="0 0 24 24"
                class="vfui_1xa3nyx0"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.75 15.165a.75.75 0 0 1 .743.648l.007.102v.851h.917a.75.75 0 0 1 .101 1.494l-.101.007-.917-.001v.982a.75.75 0 0 1-1.493.102L6 19.248v-.982h-.917a.75.75 0 0 1-.101-1.492l.101-.007.917-.001v-.851a.75.75 0 0 1 .75-.75Zm8-4.679a.75.75 0 0 1 .75.75 3.163 3.163 0 0 0 3.163 3.162.75.75 0 0 1 .101 1.494l-.101.007A3.163 3.163 0 0 0 15.5 19.06a.75.75 0 0 1-.75.75l-.043-.001a.75.75 0 0 1-.794-.749 3.163 3.163 0 0 0-3.163-3.162.75.75 0 0 1 0-1.5 3.163 3.163 0 0 0 3.162-3.163.75.75 0 0 1 .75-.75l.044.001.044-.001Zm-.045 3.217-.034.056a4.687 4.687 0 0 1-1.384 1.39 4.682 4.682 0 0 1 1.42 1.445c.36-.58.846-1.074 1.419-1.446a4.682 4.682 0 0 1-1.42-1.445ZM7.75 5.417a.75.75 0 0 1 .75.75c0 1.196.97 2.166 2.167 2.166a.75.75 0 0 1 .101 1.494l-.101.006C9.47 9.833 8.5 10.803 8.5 12a.75.75 0 0 1-.75.75l-.041-.001A.75.75 0 0 1 6.917 12c0-1.197-.97-2.167-2.167-2.167a.75.75 0 0 1 0-1.5c1.197 0 2.167-.97 2.167-2.166a.75.75 0 0 1 .75-.75l.04.001a.762.762 0 0 1 .043-.001Zm-.042 2.916-.065.086c-.194.25-.42.473-.671.665.28.213.528.466.737.75.207-.284.456-.537.736-.75a3.67 3.67 0 0 1-.737-.751ZM15.917 4a.75.75 0 0 1 .743.648l.007.102-.001.991h.917a.75.75 0 0 1 .102 1.494l-.102.007-.917-.001v.842a.75.75 0 0 1-1.492.102l-.007-.102-.001-.842h-.916a.75.75 0 0 1-.102-1.493l.102-.006.916-.001V4.75a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span>Intents</span>
            </button>
          </div>
          <textarea
            formControlName="prompt"
            nbInput
            [(ngModel)]="prompt"
            nbInput
            fieldSize="small"
            fullWidth
            placeholder="Please enter a description to help the LLM understand and recommend intent for you. Example: Healthcare, Bank, Appointment, Hotel booking,..."
            style="min-height: 100px; max-height: 500px"
          >
          </textarea>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 1.5rem">
        <form [formGroup]="intentForm" novalidate>
          <label class="form-label label"> Intents </label>
          <tag-input
            style="width: 100%"
            addOnBlur="true"
            secondaryPlaceholder=""
            [allowDupes]="true"
            formControlName="intent"
            [(ngModel)]="llm.intents"
            placeholder=""
            [editable]="true"
            [trimTags]="true"
            (onTagEdited)="tagChange($event)"
          >
          </tag-input>
        </form>
        <form [formGroup]="entityForm" novalidate>
          <label
            class="form-label label"
            style="
              display: flex;
              gap: 0.25rem;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span>Entities</span>
          </label>
          <tag-input
            style="width: 100%"
            addOnBlur="true"
            [(ngModel)]="llm.entities"
            [allowDupes]="true"
            formControlName="entity"
            secondaryPlaceholder=""
            placeholder=""
            [editable]="true"
            [trimTags]="true"
          >
          </tag-input>
        </form>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex justify-content-end" style="gap: 1rem">
        <button
          nbButton
          size="small"
          [disabled]="!grammarForm.valid || isNameLoading || isExist || loading"
          status="primary"
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
