<audio (playing)="audioPlaying()" (ended)="audioEnded()" #mainAudio>
  Your browser does not support the audio element.
</audio>
<nb-card
  class="row is-modal"
  style="width: 70vw; height: 70vh; margin-bottom: 0"
>
  <nb-card-header style="padding-top: 0">
    <div class="d-flex justify-content-between">
      <span>Audio Library</span>
      <button
        #closeButton
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button></div
  ></nb-card-header>
  <nb-card-body
    ><div class="col-md-12">
      <div class="flex-center-header" style="margin-bottom: 1rem">
        <button
          *ngIf="showAddButton"
          type="button"
          (click)="showModifyCreate()"
          status="primary"
          nbButton
          size="small"
          class="btn-create"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          Audio
        </button>
        <input
          #searchInput
          type="text"
          name="search"
          nbInput
          fieldSize="small"
          (keyup)="filterDatatable($event)"
          placeholder="Search"
        />
      </div>
      <div *ngIf="audios" class="audio-container">
        <nb-accordion>
          <nb-accordion-item
            *ngFor="let audio of audios"
            style="cursor: pointer"
            (click)="clickSelect(audio)"
          >
            <nb-accordion-item-header>
              <button
                status="primary"
                nbButton
                size="small"
                ghost
                (click)="setAudio($event, audio)"
                class="button-add-audio"
              >
                <nb-icon
                  [icon]="
                    audioCheckedState[audio.id]
                      ? 'checkmark-square-2'
                      : 'square'
                  "
                ></nb-icon>
              </button>
              <span>{{ audio.name }}</span>
              <nb-badge
                *ngIf="idNew == audio.id"
                status="danger"
                style="top: unset; margin-right: 75px"
                text="new"
              ></nb-badge>
              <button
                ghost
                nbButton
                status="primary"
                class="icon-action"
                (click)="clickSelect(audio)"
                (click)="showModify($event)"
                size="tiny"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <nb-list>
                <nb-list-item
                  *ngFor="let audioLanguage of audio.audioLanguages"
                  style="cursor: pointer; border: 0"
                  (click)="changeAudioSource(audioLanguage)"
                >
                  <div class="flex">
                    <div class="flex-audio" style="flex: 1 1 40%">
                      <div>
                        <button
                          class="float-end"
                          size="small"
                          status="primary"
                          nbButton
                          ghost
                        >
                          <nb-icon
                            [icon]="
                              audioLanguage.id == currentAudio.id && isPlaying
                                ? 'stop-circle'
                                : 'play-circle'
                            "
                          ></nb-icon>
                        </button>
                      </div>
                      <div class="audio-name">
                        <span>{{ audioLanguage?.languageName }}</span>
                        <span
                          *ngIf="!audioLanguage?.isUpload"
                          class="audio-name-voice"
                          >{{ audioLanguage?.service }} |
                          {{ audioLanguage?.voiceId }} |
                          {{ audioLanguage?.gender }}</span
                        >
                        <span
                          *ngIf="audioLanguage?.isUpload"
                          class="audio-name-voice"
                          >File upload</span
                        >
                      </div>
                    </div>
                    <span class="content">
                      <b>Content:</b> {{ audioLanguage.content }}
                    </span>
                    <nb-icon
                      icon="edit-2-outline"
                      style="font-size: 16px; color: #3367d6; cursor: pointer"
                      (click)="handleUpdateAudio($event, audioLanguage)"
                    ></nb-icon>
                  </div>
                </nb-list-item>
              </nb-list>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div></div
  ></nb-card-body>
  <nb-card-footer>
    <div class="flex flex-end" style="float: right">
      <button
        (click)="saveAudio()"
        status="primary"
        nbButton
        size="small"
        class="btn-create"
      >
        <nb-icon icon="save-outline"></nb-icon>
        Save
      </button>
    </div></nb-card-footer
  >
</nb-card>
