import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "nb-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
})
export class AlertDialogComponent {
  title: string;
  question: any;
  questionAdd: any;
  questionAddList = [];
  allowCancel = false;
  textYes: string;
  textNo: string;
  statusYes: string;
  statusNo: string;
  constructor(protected ref: NbDialogRef<AlertDialogComponent>) {}
  dismiss(value) {
    this.ref.close(value);
  }
  onSubmit() {
    this.ref.close(true);
  }
}
