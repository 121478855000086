<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0" [linear]="false">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div
          class="form-group"
          style="padding: 1rem 0; width: 100%; display: inline-grid"
        >
          <text-to-speech #textToSpeech></text-to-speech>
          <nb-card class="select-audio" [ngClass]="{ show: data.option == 1 }">
            <nb-card-header class="audio-list-style">
              <span>Audio List</span>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></nb-card-header>
            <nb-card-body>
              <simple-audio-player
                #audioPlayer
                [events]="eventsSubject.asObservable()"
              ></simple-audio-player>
              <button
                nbButton
                size="small"
                status="primary"
                (click)="showModify()"
              >
                <nb-icon icon="plus-outline"></nb-icon> Add
              </button>
            </nb-card-body>
          </nb-card>
        </div>

        <!-- <div
          class="mb-3"
          style="display: flex; flex-direction: column; margin-top: 0.5rem"
        >
          <label for="name" class="form-label label">Choose Videobot</label>
          <sentiment-select
            placeHolder="Choose Videobot"
            (valueSelected)="handleSentiment($event)"
            [value]="data.sentiment"
            defaultValue="normal-speak"
          ></sentiment-select>
        </div> -->
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Number Dial</ng-template>

        <div class="form-group">
          <div class="row mb-2">
            <div class="col">
              <div>
                <label
                  class="form-label label d-flex mt-2"
                  style="align-items: center"
                >
                  <span>Trunk Number</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Select the trunk number you want to use."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <ng-select
                  #selectComponents
                  [items]="trunksNumber"
                  [addTag]="true"
                  fullWidth
                  placeholder="Select Trunk Number"
                  [(ngModel)]="selectedNumber"
                  bindLabel="name"
                  bindValue="name"
                  (change)="phoneNumberSelectedChange($event)"
                  addTagText="Custom Trunk"
                  [editableSearchTerm]="true"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <div *ngIf="item.name == 'Internal'">
                      <span>Internal</span>
                    </div>
                    <div *ngIf="item.name != 'Internal'">
                      {{ item.name }}
                    </div>
                  </ng-template>
                  >
                </ng-select>
              </div>
            </div>
            <div class="col">
              <div>
                <label
                  for="callerNumber"
                  class="form-label label d-flex mt-2"
                  style="align-items: center"
                >
                  <span>Caller Number</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="The number will show up when the call gets forwarded to the destination number."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <input
                  id="callerNumber"
                  (drop)="drop($event)"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.callerNumber"
                  placeholder="Caller Number"
                  fieldSize="small"
                  [mention]="attributes"
                  [mentionConfig]="mentionConfig"
                />
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="form-label d-flex mt-2" style="align-items: center">
              <label for="dialDestination" class="label required">
                Dial Destination
              </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Destination you want the system attempt to dial."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
            <form [formGroup]="formGroup">
              <input
                [mention]="attributes"
                [mentionConfig]="mentionConfig"
                formControlName="dialDestination"
                (drop)="drop($event)"
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="data.dialDestination"
                id="dialDestination"
                placeholder="Dial Destination"
                fieldSize="small"
                [status]="
                  formGroup.controls['dialDestination'].invalid &&
                  (formGroup.controls['dialDestination'].dirty ||
                    formGroup.controls['dialDestination'].touched)
                    ? 'danger'
                    : 'basic'
                "
              />
              <ng-container
                *ngIf="
                  formGroup.controls['dialDestination'].invalid &&
                  (formGroup.controls['dialDestination'].dirty ||
                    formGroup.controls['dialDestination'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    formGroup.controls['dialDestination'].hasError(
                      'required'
                    ) ||
                    formGroup.controls['dialDestination'].hasError('whitespace')
                  "
                >
                  Dial Destination is required.
                </p>
              </ng-container>
            </form>
          </div>
          <div class="mb-2">
            <label
              for="timeout"
              class="form-label label d-flex mt-2"
              style="align-items: center"
            >
              <span>Timeout (s)</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Number of seconds the system attempts to dial the specified devices."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <input
              id="timeout"
              [(ngModel)]="data.timeout"
              type="number"
              min="0"
              nbInput
              fullWidth
              placeholder="Timeout"
              fieldSize="small"
            />
          </div>
          <div class="mt-3 sip-header">
            <label
              class="form-label label d-flex mt-2"
              style="align-items: center"
            >
              <span>SIP Headers</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Set the specified SIP Headers."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-static
              [propertyInput]="data.headers"
              (propertyEmit)="getSipHeaders($event)"
              propertyName="Name"
              propertyValue="PValue"
            ></ngx-input-static>
          </div>
        </div>
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Settings</ng-template>
        <div class="form-group">
          <div class="mb-2" style="margin-top: 0.75rem">
            <div class="mb-2" style="display: flex; align-items: center">
              <nb-toggle [(checked)]="data.record" status="basic"
                >Allow Recording</nb-toggle
              >
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Allows recording of conversations."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
          <div class="mb-2" *ngIf="data.record == true">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Audio Path</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the audio recording path to variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Audio Path"
              selectAttribute="true"
              [propertyInput]="data.pathAttribute"
              (propertyEmit)="getPathAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
        <div class="form-group">
          <div class="mb-2" *ngIf="data.record == true">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Call Transcription</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Convert recording audio to call transcription when the flow end and assign to variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Call Transcription"
              selectAttribute="true"
              [propertyInput]="data.transcriptionAttribute"
              (propertyEmit)="getTranscriptionAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
