import { IApplicationData } from "./common";
export class Transfer implements IApplicationData {
  constructor() {
    this.audioId = "";
    this.audioPath = "";
    this.text = "";
    this.content = "";
    this.option = 1;
    this.name = "Transfer";
    this.transferDestination = "";
    this.transition = true;
    this.sentiment = "";
    this.type = 1;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  audioPath: string;
  audioId: string;
  text: string;
  content: string;
  sentiment: string;
  option: number;
  transferDestination: string;
  transition: boolean;
  type: number;
  isRequired: boolean;
  checkFields: boolean;
}
