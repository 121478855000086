import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { EventBusService } from "../../../../../@core/utils/eventbus.service";
import {
  ValidInput,
  noWhitespaceValidator,
} from "../../../../../@core/utils/helpers";
import { EventData } from "../../../../../shared/model/eventdata";
import {
  CheckNameExistQuery,
  CreateCustomFunctionCommand,
  CustomFunctionClient,
  CustomFunctionDto,
  MakeTestCustomFunctionCommand,
  UpdateCustomFunctionCommand,
} from "../../../../../System-api";
import * as ace from "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import { TestParamComponent } from "../../../../../portal/customer-role/studio/functions/advanced-custom-function/test-param/test-param.component";
import { TestFunctionComponent } from "../../../../../portal/customer-role/studio/functions/test-function/test-function.component";
import { AlertDialogComponent } from "../../../../../shared/alert-dialog/alert-dialog.component";

@Component({
  selector: "create-custom-function",
  templateUrl: "./create-custom-function.component.html",
  styleUrls: ["./create-custom-function.component.scss"],
})
export class CreateCustomFunctionComponent implements OnInit, AfterViewInit {
  @ViewChild("editor") private editor: ElementRef<HTMLElement>;
  @ViewChild("inputName")
  inputName: ElementRef<any>;
  title: string;
  isLoading: boolean = false;
  canAdd: boolean = true;
  secondParams: boolean = false;
  isFunctionLoading: boolean = false;
  currentFunctionName: string = "";
  timeout: any = null;
  isExist: boolean = false;
  isCodeLoading: boolean = false;
  customFunction: CustomFunctionDto = new CustomFunctionDto();
  functionForm: UntypedFormGroup;
  onAdd = new EventEmitter();
  expand: boolean = false;
  errorForm: boolean = false;
  initialFormValue: string;
  initialParamName: string = "";
  updateFunction: CreateCustomFunctionCommand =
    new CreateCustomFunctionCommand();
  options = {
    theme: "myCustomTheme",
    language: "php",
    minimap: {
      enabled: true,
    },
  };
  languages = [
    {
      key: "PHP",
      language: "PHP",
      code: `<?php\n/**
    * You can use $pagiClient variable to code PAGI like:
    * $pagiClient->sayNumber(123);
    * Warning: Please don't use print or echo.
    * You can use variable $attribute to set optional values like:
    * $attribute['key'] = $value;
    * You can use variable use tracelog function like:
    * Helper::InsertTraceLog($log, $logLevel);
    * $logLevel is info(default), debug or error.
    */\n/* Code here */\n`,
    },
    {
      key: "JS",
      language: "JAVASCRIPT",
      code: `/**
* You can use Helper to set or get optional values like:
* Helper.setAttribute(key, value);
* Helper.getAttribute(key);
* You can use variable use tracelog function like:
* await Helper.insertLog(log);
* You can reuse another custom function like:
* await CustomFunctions.anotherFunction(param1, param2, ...);
* Write async function as follows:
* return (async() => {
* //Code here
* })();
*/\n/* Code here */\n`,
    },
  ];
  isLoadingRun = false;
  currentData: CustomFunctionDto = null;
  defaultParams = true;
  defaultParamsCode = false;
  isVAcomponent = false;
  defaultReturn = {
    message: "Hello, Can I help you?",
    buttons: [
      {
        buttonValue: "booking",
        buttonText: [
          {
            language: "en",
            value: "Booking",
          },
        ],
      },
    ],
  };
  constructor(
    private customFunctionClient: CustomFunctionClient,
    private formBuilder: RxFormBuilder,
    private eventBusService: EventBusService,
    private toastrService: NbToastrService,
    private ref: NbDialogRef<CreateCustomFunctionComponent>,
    private dialogService: NbDialogService
  ) {
    this.createForm();
  }
  ngAfterViewInit() {
    ace.config.set(
      "basePath",
      "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setValue(this.getCode("PHP"));
    aceEditor.setTheme("ace/theme/xcode");
    aceEditor.session.setMode("ace/mode/php");
    aceEditor.getSession().setUseWorker(true);
    aceEditor.setOptions({
      wrap: true,
      highlightActiveLine: true,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,
    });
    aceEditor.on("change", () => {
      this.customFunction.code = aceEditor.getValue();
      this.functionForm.controls["code"].setValue(aceEditor.getValue());
    });
    aceEditor.commands.addCommand({
      name: "save",
      bindKey: { win: "Ctrl-S", mac: "Cmd-S" },
      exec: (editor) => {
        if (this.functionForm.valid) {
          this.onSubmit();
        } else {
          this.functionForm.markAllAsTouched();
          this.toastrService.show("Function is not valid", `Notification`, {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          });
        }
      },
    });
  }
  ngOnInit(): void {
    if (this.defaultParamsCode) {
      let returnValue = "userInput;";
      if (this.isVAcomponent) {
        returnValue = "request;";
      }
      if (this.secondParams) {
        returnValue = "agentOutput;";
        if (this.isVAcomponent) {
          returnValue = "response['data'];";
        }
      }
      this.languages[0].code =
        this.languages[0].code + "\nreturn $" + returnValue;
      this.languages[1].code =
        this.languages[1].code + "\nreturn " + returnValue;
    }
    this.customFunction.code = this.getCode("PHP");
    this.customFunction.language = this.getLanguage("PHP");
    let input = "userInput";
    let output = "botOutput";
    if (this.isVAcomponent) {
      input = "request";
      output = "response";
    }
    if (this.defaultParams) {
      this.customFunction.param = input;
      this.initialParamName = input;
      if (this.secondParams) {
        this.customFunction.param = input + "|" + output;
        this.initialParamName = input + "|" + output;
      }
    }

    this.functionForm.controls["code"].setValue(this.customFunction.code);
    this.functionForm.controls["language"].setValue("PHP");
    this.initialFormValue = this.functionForm.value;
  }

  createForm() {
    this.functionForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          RxwebValidators.maxLength({
            value: 50,
            message: "Maximum length is 50 characters.",
          }),
        ],
      ],
      code: ["", ValidInput.required],
      language: [""],
    });
  }

  getParam($event) {
    this.customFunction.param = $event;
  }
  getError($event) {
    this.errorForm = $event;
  }

  fullScreen() {
    this.expand = !this.expand;
    const functionName = document.querySelector(
      ".function-name"
    ) as HTMLElement;
    const functionCode = document.querySelector(
      ".function-code"
    ) as HTMLElement;
    functionName.classList.toggle("hidden");
    functionCode.classList.toggle("col-md-12");
  }

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  checkFunctionName(value) {
    if (this.functionForm.controls["name"].valid) {
      const functionNamePattern = /^[a-zA-Z_][a-zA-Z0-9_]{0,49}$/;
      const valid = functionNamePattern.test(value);
      if (!valid) {
        this.functionForm.controls["name"].setErrors({
          functionInvalid:
            "Function Name must start with a letter or underscore, and can contain only letters, numbers, and underscores.",
        });
        return;
      }
    }
  }

  selectLanguage(value) {
    if (value == "PHP") {
      this.customFunction.code = this.getCode("PHP");
      this.customFunction.language = this.getLanguage("PHP");
      const aceEditor = ace.edit(this.editor?.nativeElement);
      aceEditor.session.setValue(this.customFunction.code);
      aceEditor.session.setMode("ace/mode/php");
    } else if (value == "JS") {
      this.customFunction.code = this.getCode("JS");
      this.customFunction.language = this.getLanguage("JS");
      const aceEditor = ace.edit(this.editor.nativeElement);
      aceEditor.session.setValue(this.customFunction.code);
      aceEditor.session.setMode("ace/mode/javascript");
    }
  }

  getCode(key: string): string {
    const language = this.languages.find((item) => item.key === key);
    return language ? language.code : "";
  }

  getLanguage(key: string): string {
    const language = this.languages.find((item) => item.key === key);
    return language ? language.key : "";
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.functionForm.get(fieldName)) {
          const control = this.functionForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }
  updateData(func) {
    if (func != null) {
      this.onAdd.emit({
        rs: true,
        function: func,
        name: this.updateFunction.name,
        param: this.updateFunction.param,
      });
    } else {
      this.onAdd.emit({
        rs: true,
        name: "",
        param: this.updateFunction.param,
      });
    }
  }
  dismiss() {
    this.eventBusService.emit(new EventData("ClickSubmitCustomFunction", true));
    if (this.functionForm.get("name").value == undefined) {
      this.functionForm.controls["name"]?.setValue("");
    }
    if (
      JSON.stringify(this.functionForm.value) ===
        JSON.stringify(this.initialFormValue) &&
      this.customFunction?.param == this.initialParamName
    ) {
      this.updateData(this.currentData);
      this.ref.close();
    } else {
      this.dialogService
        .open(AlertDialogComponent, {
          autoFocus: false,
          context: {
            title: "Notifications",
            question:
              "The function has changed. Do you want to exit without saving the changes?",
            textYes: "Yes",
            textNo: "Cancel",
            statusYes: "info",
            statusNo: "basic",
          },
        })
        .onClose.subscribe((isConfirm) => {
          if (isConfirm) {
            this.updateData(this.currentData);
            this.ref.close();
          }
        });
    }
  }
  create() {
    if (this.functionForm.valid) {
      this.eventBusService.emit(
        new EventData("ClickSubmitCustomFunction", true)
      );
      this.updateFunction.name = this.customFunction.name;
      this.updateFunction.code = this.customFunction.code;
      this.updateFunction.language = this.customFunction.language;
      this.updateFunction.param = this.customFunction.param;
      this.isLoading = true;
      this.customFunctionClient.create(this.updateFunction).subscribe({
        next: (func) => {
          this.isLoading = false;
          this.showToast(func != null ? true : false);
          if (func != null) {
            this.customFunction.id = func.id;
            this.currentData = func;
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status == 422) {
            this.setValidationErrors(error.response);
          }
          this.showToast(false);
        },
      });
    }
  }
  updateCustomFunction() {
    if (this.functionForm.valid) {
      this.eventBusService.emit(
        new EventData("ClickSubmitCustomFunction", true)
      );
      let updateFunction = new UpdateCustomFunctionCommand();
      updateFunction.name = this.customFunction.name;
      updateFunction.code = this.customFunction.code;
      if (this.customFunction.language == this.getLanguage("PHP")) {
        updateFunction.language = null;
      } else {
        updateFunction.language = this.customFunction.language;
      }
      updateFunction.param = this.customFunction.param;
      updateFunction.id = this.customFunction.id;
      this.isLoading = true;
      this.customFunctionClient.update(updateFunction).subscribe({
        next: (rs) => {
          this.isLoading = false;
          var saveFunctionResult = JSON.parse(rs);
          var result = saveFunctionResult?.result;
          if (result == true) {
            this.currentData.code = updateFunction.code;
            this.currentData.name = updateFunction.name;
            this.currentData.param = updateFunction.param;
            this.currentData.language = updateFunction.language;
            this.updateFunction.name = updateFunction.name;
            this.updateFunction.param = updateFunction.param;
          }
          this.showToast(result);
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status == 422) {
            this.setValidationErrors(error.response);
          }
          this.showToast(false);
        },
      });
    }
  }
  onSubmit() {
    if (this.customFunction.id) {
      this.updateCustomFunction();
    } else {
      this.create();
    }
    this.initialFormValue = this.functionForm.value;
    this.initialParamName = this.customFunction?.param;
  }
  runFunction(testData) {
    this.isLoadingRun = true;
    this.customFunctionClient.run(testData).subscribe({
      next: (result) => {
        this.isLoadingRun = false;
        if (result) {
          let runFunctionResponse = JSON.parse(result);
          runFunctionResponse.result = runFunctionResponse?.result;
          this.dialogService.open(TestFunctionComponent, {
            autoFocus: false,
            context: { runFunctionResponse: runFunctionResponse },
          });
        } else {
          this.toastrService.show(
            "Run function unsuccessfully",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      },
      error: () => {
        this.isLoadingRun = false;
        this.toastrService.show("Run function unsuccessfully", `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        });
      },
    });
  }
  run() {
    var testData = new MakeTestCustomFunctionCommand();
    testData.functionId = this.customFunction.id;
    testData.attributes = {};
    testData.params = {};
    testData.language = this.customFunction.language;
    testData.code = this.customFunction.code;
    testData.functionName = this.customFunction.name;
    if (this.customFunction.param && this.customFunction.param != "") {
      this.dialogService
        .open(TestParamComponent, {
          autoFocus: false,
          context: {
            params: this.customFunction.param,
          },
        })
        .onClose.subscribe((data) => {
          if (data) {
            testData.params = data;
            this.runFunction(testData);
          }
        });
    } else {
      this.runFunction(testData);
    }
  }

  showToast(result) {
    if (result) {
      this.toastrService.show(
        "Save custom function successfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "success",
        }
      );
    } else {
      this.toastrService.show(
        "Save custom function unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
}
