<audio
  (playing)="audioPlaying()"
  (ended)="audioEnded()"
  #testAudio
  [src]="dataAudio ? 'data:audio/mp3;base64,' + dataAudio : ''"
  autoplay
>
  <source />
</audio>
<div style="margin-bottom: 1rem">
  <nb-accordion>
    <nb-accordion-item
      [expanded]="data.isDefault === true"
      *ngFor="let data of dataText; let i = index"
    >
      <nb-accordion-item-header class="is-call-flow-name">
        <span>{{ data.name }}</span>
        <span
          *ngIf="data.isDefault === true"
          class="btn-tag primary btn-tag-default"
          nbTooltipTrigger="hover"
          nbTooltip="Default language when starting the flow. You can change the default language in Settings."
          nbTooltipPlacement="end"
          nbTooltipStatus="basic"
          >DEFAULT</span
        >
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <nb-list
          class="example-list"
          cdkDropList
          (cdkDropListDropped)="dropContent($event, i)"
        >
          <nb-list-item class="label-prompt"
            ><span>ALL CHANNELS</span>
            <nb-icon
              [nbPopover]="templateRef"
              nbPopoverPlacement="bottom"
              nbPopoverTrigger="hover"
              class="ms-1"
              style="color: #929bb3; width: 15px"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></nb-list-item>
          <nb-list-item
            style="
              padding: 0 !important;
              margin: 1rem !important;
              border: 0.0625rem solid #e4e9f2;
              border-radius: 4px !important;
              box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            "
            cdkDropList
            (cdkDropListDropped)="drop($event, i, j)"
            class="prompt-wrapper"
            *ngFor="let contentObj of data.content; let j = index"
            cdkDrag
          >
            <nb-list-item class="index-prompt" cdkDragHandle>
              <span>Content {{ j + 1 }}</span>
              <button
                title="Add Prompt"
                (click)="handleAddNewPrompt(i, j)"
                nbButton
                size="tiny"
                status="primary"
                ghost
              >
                <nb-icon icon="plus-circle-outline"></nb-icon>
              </button>
            </nb-list-item>
            <nb-list-item
              class="prompt-item"
              cdkDrag
              *ngFor="
                let contentData of contentObj;
                trackBy: trackByFn;
                let z = index
              "
            >
              <nb-list-item class="example-box">
                <div
                  class="play-wrapper"
                  *ngIf="dataText[i].content[j][z].type == 'text'"
                >
                  <textarea
                    class="text-item"
                    id="{{ type }}-text-item-{{ i }}-{{ j }}-{{ z }}"
                    fullWidth
                    [(ngModel)]="dataText[i].content[j][z].value"
                    (click)="handleEdit(i, j, z)"
                    [mentionConfig]="mentionConfig"
                    placeholder="Click to edit..."
                    (ngModelChange)="onChangeContentText(i, j, z)"
                  ></textarea>
                </div>
                <div
                  [nbSpinner]="isLoadingUpdate"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="primary"
                  class="play-wrapper image-wrapper"
                  *ngIf="
                    dataText[i].content[j][z].type == 'image' ||
                    dataText[i].content[j][z].type == 'video'
                  "
                >
                  <div class="image-overview">
                    <a
                      [ngClass]="{
                        'disable-click': !dataText[i].content[j][z].value
                      }"
                      data-fancybox="gallery"
                      [attr.data-caption]="
                        dataText[i].content[j][z].value?.length > 200
                          ? (dataText[i].content[j][z].value
                              | slice : 0 : 200) + '...'
                          : dataText[i].content[j][z].value
                      "
                      [href]="dataText[i].content[j][z].value"
                    >
                      <img
                        *ngIf="dataText[i].content[j][z].type == 'image'"
                        [src]="
                          dataText[i].content[j][z].value ||
                          '../../../assets/images/default-error.jpg'
                        "
                        (error)="handleImageError($event)"
                        alt=""
                      />
                      <img
                        *ngIf="
                          dataText[i].content[j][z].type == 'video' &&
                          !dataText[i].content[j][z].value
                        "
                        [src]="'../../../assets/images/default-video.jpg'"
                        (error)="handleImageError($event)"
                        alt=""
                      />
                      <video
                        *ngIf="
                          dataText[i].content[j][z].type == 'video' &&
                          dataText[i].content[j][z].value
                        "
                        src="{{ dataText[i].content[j][z].value }}"
                        controls
                      ></video>
                    </a>
                  </div>
                </div>
                <div class="action-wrapper action-wrapper-hidden">
                  <button
                    ghost
                    *ngIf="dataText[i].content[j][z].type == 'text'"
                    class="tbl-action"
                    title="play"
                    nbButton
                    (click)="convertTTS(i, j, z, 'phone')"
                    size="small"
                  >
                    <nb-icon
                      [icon]="
                        dataText[i].content[j][z].value == currentText &&
                        isPlaying
                          ? 'stop-circle-outline'
                          : 'play-circle-outline'
                      "
                    ></nb-icon>
                  </button>
                  <button
                    ghost
                    *ngIf="dataText[i].content[j][z].type != 'text'"
                    class="tbl-action"
                    title="edit"
                    nbButton
                    size="small"
                    (click)="
                      handleImage(
                        i,
                        j,
                        z,
                        dataText[i].content[j][z].value,
                        dataText[i].content[j][z].type,
                        'phone'
                      )
                    "
                  >
                    <nb-icon icon="edit-2-outline"></nb-icon>
                  </button>
                  <button
                    *ngIf="showButtonType || isChatBot"
                    ghost
                    [matMenuTriggerFor]="menu"
                    (menuOpened)="menuOpened(i, j, z, 'phone')"
                    class="tbl-action"
                    nbButton
                    size="small"
                    [title]="dataText[i].content[j][z].type"
                  >
                    <nb-icon
                      *ngIf="dataText[i].content[j][z].type == 'text'"
                      icon="text-outline"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="dataText[i].content[j][z].type == 'image'"
                      icon="image-outline"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="dataText[i].content[j][z].type == 'video'"
                      icon="video-outline"
                    ></nb-icon>
                  </button>
                  <button
                    *ngIf="
                      dataText[i].content.length != 1 ||
                      dataText[i].content[0].length != 1
                    "
                    ghost
                    (click)="handleRemovePrompt(i, j, z)"
                    class="tbl-action"
                    title="remove"
                    nbButton
                    size="small"
                  >
                    <nb-icon icon="trash-2-outline"></nb-icon>
                  </button>
                  <div
                    cdkDragHandle
                    class="move-icon"
                    style="display: flex; align-items: center"
                  >
                    <nb-icon
                      *ngIf="
                        dataText[i].content.length != 1 ||
                        dataText[i].content[0].length != 1
                      "
                      style="margin-right: -14px"
                      status="basic"
                      icon="more-vertical-outline"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="
                        dataText[i].content.length != 1 ||
                        dataText[i].content[0].length != 1
                      "
                      status="basic"
                      icon="more-vertical-outline"
                    ></nb-icon>
                  </div></div
              ></nb-list-item>
              <nb-list-item class="button-insert">
                <button
                  nbButton
                  ghost
                  size="tiny"
                  title="Insert Variables"
                  [matMenuTriggerFor]="menuAttribute"
                  (menuOpened)="menuOpenedVariable(i, j, z, 'phone')"
                >
                  <nb-icon icon="more-horizontal-outline"></nb-icon>
                </button>
                <button
                  nbButton
                  ghost
                  size="tiny"
                  title="Insert Emoji"
                  (menuOpened)="menuOpenedVariable(i, j, z, 'phone', true)"
                  [matMenuTriggerFor]="menuEmoji"
                >
                  <nb-icon icon="smiling-face-outline"></nb-icon>
                </button>
              </nb-list-item>
            </nb-list-item>
          </nb-list-item>
          <nb-list-item style="padding: 0 !important">
            <div class="input-prompt-wrapper input-prompt-wrapper-more">
              <button
                title="More Prompt"
                shape="round"
                status="basic"
                outline
                (click)="handleAddNewPromptGroup(i)"
                nbButton
                size="tiny"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
        </nb-list>
      </nb-accordion-item-body>
      <nb-accordion-item-body *ngIf="isChatBot">
        <nb-list
          class="example-list"
          cdkDropList
          (cdkDropListDropped)="dropContentBot($event, i)"
        >
          <nb-list-item class="label-prompt"
            ><span>CHAT BOT</span>
            <nb-icon
              [nbPopover]="templateRef2"
              nbPopoverPlacement="bottom"
              nbPopoverTrigger="hover"
              class="ms-1"
              style="color: #929bb3; width: 15px"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></nb-list-item>
          <nb-list-item
            style="
              padding: 0 !important;
              margin: 1rem !important;
              border: 0.0625rem solid #e4e9f2;
              border-radius: 4px !important;
              box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            "
            cdkDropList
            class="prompt-wrapper"
            (cdkDropListDropped)="dropBot($event, i, k)"
            *ngFor="let contentBotObj of dataTextBot[i].content; let k = index"
            cdkDrag
          >
            <nb-list-item class="index-prompt" cdkDragHandle>
              <span>Content {{ k + 1 }}</span>
              <button
                title="Add Prompt"
                (click)="handleAddNewPromptBot(i, k)"
                nbButton
                size="tiny"
                status="primary"
                ghost
              >
                <nb-icon icon="plus-circle-outline"></nb-icon>
              </button>
            </nb-list-item>

            <nb-list-item
              class="prompt-item"
              *ngFor="
                let textBot of contentBotObj;
                trackBy: trackByFnBot;
                let y = index
              "
              cdkDrag
            >
              <nb-list-item class="example-box">
                <div
                  class="play-wrapper"
                  *ngIf="dataTextBot[i].content[k][y].type == 'text'"
                >
                  <textarea
                    class="text-item"
                    id="{{ type }}-text-item-bot-{{ i }}-{{ k }}-{{ y }}"
                    fullWidth
                    (click)="handleEditBot(i, k, y)"
                    [mentionConfig]="mentionConfig"
                    [(ngModel)]="dataTextBot[i].content[k][y].value"
                    placeholder="Click to edit..."
                    (ngModelChange)="onChangeContentTextBot(i, k, y)"
                  ></textarea>
                </div>
                <div
                  [nbSpinner]="isLoadingUpdate"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="primary"
                  class="play-wrapper image-wrapper"
                  *ngIf="
                    dataTextBot[i].content[k][y].type == 'image' ||
                    dataTextBot[i].content[k][y].type == 'video'
                  "
                >
                  <div class="image-overview">
                    <a
                      data-fancybox="gallery"
                      [ngClass]="{
                        'disable-click': !dataTextBot[i].content[k][y].value
                      }"
                      [attr.data-caption]="
                        dataTextBot[i].content[k][y].value?.length > 200
                          ? (dataTextBot[i].content[k][y].value
                              | slice : 0 : 200) + '...'
                          : dataTextBot[i].content[k][y].value
                      "
                      [href]="dataTextBot[i].content[k][y].value"
                    >
                      <img
                        *ngIf="dataTextBot[i].content[k][y].type == 'image'"
                        [src]="
                          dataTextBot[i].content[k][y].value ||
                          '../../../assets/images/default-error.jpg'
                        "
                        (error)="handleImageError($event)"
                        alt=""
                      />
                      <img
                        *ngIf="
                          dataTextBot[i].content[k][y].type == 'video' &&
                          !dataTextBot[i].content[k][y].value
                        "
                        [src]="'../../../assets/images/default-video.jpg'"
                        (error)="handleImageError($event)"
                        alt=""
                      />
                      <video
                        *ngIf="
                          dataTextBot[i].content[k][y].type == 'video' &&
                          dataTextBot[i].content[k][y].value
                        "
                        src="{{ dataTextBot[i].content[k][y].value }}"
                        controls
                      ></video>
                    </a>
                  </div>
                </div>
                <div class="action-wrapper action-wrapper-hidden">
                  <button
                    ghost
                    *ngIf="dataTextBot[i].content[k][y].type == 'text'"
                    class="tbl-action"
                    title="play"
                    nbButton
                    size="small"
                    (click)="convertTTS(i, k, y, 'bot')"
                  >
                    <nb-icon
                      [icon]="
                        dataTextBot[i].content[k][y].value == currentText &&
                        isPlaying
                          ? 'stop-circle-outline'
                          : 'play-circle-outline'
                      "
                    ></nb-icon>
                  </button>
                  <button
                    *ngIf="dataTextBot[i].content[k][y].type != 'text'"
                    ghost
                    class="tbl-action"
                    title="edit"
                    nbButton
                    size="small"
                    (click)="
                      handleImage(
                        i,
                        k,
                        y,
                        dataTextBot[i].content[k][y].value,
                        dataTextBot[i].content[k][y].type,
                        'bot'
                      )
                    "
                  >
                    <nb-icon icon="edit-2-outline"></nb-icon>
                  </button>
                  <button
                    ghost
                    [matMenuTriggerFor]="menu"
                    (menuOpened)="menuOpened(i, k, y, 'bot')"
                    [title]="dataTextBot[i].content[k][y].type"
                    class="tbl-action"
                    nbButton
                    size="small"
                  >
                    <nb-icon
                      *ngIf="dataTextBot[i].content[k][y].type == 'text'"
                      icon="text-outline"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="dataTextBot[i].content[k][y].type == 'image'"
                      icon="image-outline"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="dataTextBot[i].content[k][y].type == 'video'"
                      icon="video-outline"
                    ></nb-icon>
                  </button>
                  <button
                    *ngIf="
                      dataTextBot[i].content.length != 1 ||
                      dataTextBot[i].content[0].length != 1
                    "
                    ghost
                    (click)="handleRemovePromptBot(i, k, y)"
                    class="tbl-action"
                    nbButton
                    title="remove"
                    size="small"
                  >
                    <nb-icon icon="trash-2-outline"></nb-icon>
                  </button>
                  <div
                    cdkDragHandle
                    class="move-icon"
                    style="display: flex; align-items: center"
                  >
                    <nb-icon
                      style="margin-right: -14px"
                      status="basic"
                      icon="more-vertical-outline"
                    ></nb-icon>
                    <nb-icon
                      status="basic"
                      icon="more-vertical-outline"
                    ></nb-icon>
                  </div>
                </div>
              </nb-list-item>
              <nb-list-item class="button-insert">
                <button
                  nbButton
                  ghost
                  size="tiny"
                  title="Insert Variables"
                  [matMenuTriggerFor]="menuAttribute"
                  (menuOpened)="menuOpenedVariable(i, k, y, 'bot')"
                >
                  <nb-icon icon="more-horizontal-outline"></nb-icon>
                </button>
                <button
                  nbButton
                  ghost
                  size="tiny"
                  title="Insert Emoji"
                  (menuOpened)="menuOpenedVariable(i, k, y, 'bot', true)"
                  [matMenuTriggerFor]="menuEmoji"
                >
                  <nb-icon icon="smiling-face-outline"></nb-icon>
                </button>
              </nb-list-item>
            </nb-list-item>
          </nb-list-item>
          <nb-list-item style="padding: 0 !important">
            <div class="input-prompt-wrapper input-prompt-wrapper-more">
              <button
                title="More Prompt"
                shape="round"
                status="basic"
                outline
                (click)="handleAddNewPromptGroupBot(i)"
                nbButton
                size="tiny"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
        </nb-list>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
<ng-template #templateRef>
  <nb-card style="max-width: 500px; margin-bottom: 0">
    <nb-card-body style="font-size: 13px">
      <div
        style="margin-bottom: 0.5rem"
        *ngIf="type == 'main' && isMainPromptAndCollect == false"
      >
        Play a text-to-speech message, or send a chat message. Messages will be
        played or sent randomly.
      </div>
      <div
        style="margin-bottom: 0.5rem"
        *ngIf="type != 'main' || isMainPromptAndCollect == true"
      >
        Play a text-to-speech message, or send a chat message. Messages will be
        played or sent in order.
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Using variables:</b> you can insert the variables to message by
        typing open curly braces '&#123;' and end by typing close curly braces
        '&#125;'. For example: Hello &#123;name_variable&#125;. How can I help
        you?
      </div>
      <div><b>Variable type:</b> if the type of variable is not a string:</div>
      <div style="margin-left: 1.5rem">
        &bull; Array or List: &#123;name_variable->0&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Object: &#123;name_variable->key&#125;
      </div>
      <div style="margin-top: 0.5rem">
        <b>Read:</b> you can also specify how the variable is read:
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Spell: &#123;name_variable->spell()&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Say digits: &#123;name_variable->sayDigits()&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Say number: &#123;name_variable->sayNumber()&#125;
      </div>
      <div style="margin-top: 0.5rem">
        <b>Operators:</b> you can also use some operators with variables:
        <a
          href="https://docs.primas.net/display/CAL/Variable"
          style="text-decoration: none"
          target="_blank"
          >Learn more</a
        >
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card style="max-width: 500px; margin-bottom: 0">
    <nb-card-body style="font-size: 13px">
      <span
        >With chatbot: The system will prioritize using messages at
        ChatBot.</span
      >
    </nb-card-body>
  </nb-card>
</ng-template>

<mat-menu #menu="matMenu">
  <nb-card style="display: flex; min-width: 150px; margin-bottom: 0; border: 0">
    <nb-card-body style="padding: 0">
      <nb-list>
        <nb-list-item
          (click)="updateType(item.value)"
          class="item-chat-us"
          style="border-top: 0; height: 40px"
          *ngFor="let item of typePrompt"
        >
          <div
            style="
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
            "
          >
            <nb-icon
              [icon]="item.icon"
              status="basic"
              style="margin-right: 1rem"
            ></nb-icon>
            <span style="font-weight: 600">{{ item.name }}</span>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</mat-menu>
<mat-menu #menuEmoji="matMenu">
  <emoji-mart
    *ngIf="isEmoji"
    [emojiSize]="20"
    [showPreview]="false"
    (click)="$event.stopPropagation()"
    (emojiClick)="addEmoji($event)"
  ></emoji-mart>
</mat-menu>
<mat-menu #menuAttribute="matMenu">
  <nb-card style="display: flex; width: 200px; margin-bottom: 0; border: 0">
    <nb-card-body style="padding: 0">
      <nb-list>
        <nb-list-item
          class="item-chat-attribute"
          style="padding: 0 !important; height: 33px"
        >
          <input
            #inputString
            type="text"
            style="background-color: white"
            nbInput
            placeholder="Search"
            fullWidth
            fieldSize="small"
            (keyup)="handleSearch($event)"
            (click)="$event.stopPropagation()"
          />
        </nb-list-item>
      </nb-list>
      <nb-list style="max-height: 250px" class="item-chat-attribute-list">
        <nb-list-item
          style="font-size: 13px; padding: 0.5rem 1rem"
          *ngIf="listAttributes.length <= 0"
          >Variable not found</nb-list-item
        >
        <nb-list-item
          (click)="updateText(item.value)"
          class="item-chat-attribute"
          style="border-top: 0; height: 33px"
          *ngFor="let item of listAttributes"
        >
          <div
            style="
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
            "
          >
            <span style="font-weight: 600">{{ item.value }}</span>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</mat-menu>
