import {
  Injectable,
  EventEmitter,
  Output,
  Inject,
  InjectionToken,
  Optional,
} from "@angular/core";
import * as signalR from "@microsoft/signalr";
import {
  AgentClient,
  AgentInfoDto,
  InvoiceDetail,
  TrunkListVm,
} from "../../System-api";
import {
  CallingDatasOnDashBoard,
  AgentSummaryDashBoard,
} from "../model/common";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";

export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  companyId: number;
  userName: string;
  private baseUrl: string;
  @Output() callStatusChanged: EventEmitter<CallingDatasOnDashBoard> =
    new EventEmitter();
  @Output() statusGPTChanged: EventEmitter<any> = new EventEmitter();
  @Output() statusGrammarChanged: EventEmitter<any> = new EventEmitter();
  @Output() agentStatusChanged: EventEmitter<AgentSummaryDashBoard> =
    new EventEmitter();
  @Output() userMakeIntroCall: EventEmitter<boolean> = new EventEmitter();
  @Output() agentInfoChanged: EventEmitter<AgentInfoDto> = new EventEmitter();
  @Output() queueInfoChanged: EventEmitter<any> = new EventEmitter();
  @Output() resourceStatus: EventEmitter<any> = new EventEmitter();
  @Output() currentInvoiceChanged: EventEmitter<InvoiceDetail> =
    new EventEmitter();
  @Output() trunkStatusChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() requestPermissionStatus: EventEmitter<any> = new EventEmitter();
  @Output() grantPermissionStatus: EventEmitter<any> = new EventEmitter();
  @Output() upgradeCallflow: EventEmitter<any> = new EventEmitter();
  @Output() receiveMessage: EventEmitter<any> = new EventEmitter();
  @Output() receiveDebugLog: EventEmitter<any> = new EventEmitter();
  constructor(
    private agentClient: AgentClient,
    private dataService: DataService,
    private http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  public summary: AgentSummaryDashBoard = new AgentSummaryDashBoard();

  private hubConnection: signalR.HubConnection;
  public async startConnection(): Promise<any> {
    Object.defineProperty(WebSocket, "OPEN", { value: 1 });
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl + "/dashboardHub", {
        transport:
          signalR.HttpTransportType.WebSockets |
          signalR.HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .build();
    return this.hubConnection
      .start()
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log("Error while starting connection: " + err);
        return false;
      });
  }

  public async startChatConnection(): Promise<any> {
    Object.defineProperty(WebSocket, "OPEN", { value: 1 });
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl + "/hubs/chat")
      .build();
    return this.hubConnection
      .start()
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log("Error while starting connection: " + err);
        return false;
      });
  }

  public addTransferAgentSummaryListener = () => {
    this.companyId = this.dataService.CompanyId;
    this.hubConnection.on(`transferAgentSummary${this.companyId}`, (data) => {
      this.agentStatusChanged.emit(data);
    });
  };
  public messageServerListener = (uuid) => {
    this.hubConnection.on(`sendMessage-${uuid}`, (data) => {
      this.receiveMessage.emit(data);
    });
  };
  public debugLogListener = (uuid) => {
    this.hubConnection.on(`debugLog-${uuid}`, (data) => {
      this.receiveDebugLog.emit(data);
    });
  };
  public addTransferCallStatusListener() {
    this.companyId = this.dataService.CompanyId;
    this.hubConnection.on(
      `transferCallingDataDashBoard${this.companyId}`,
      (data) => {
        this.callStatusChanged.emit(data);
      }
    );
  }

  public addUpdateStatusGPTListener() {
    this.userName = this.dataService.userName;
    this.hubConnection.on(`updateStatusGPT${this.userName}`, (data) => {
      this.statusGPTChanged.emit(data);
    });
  }

  public addUpgradeCallflowListener(idUpgrade) {
    this.hubConnection.on(`upgradeCallflowStatus${idUpgrade}`, (data) => {
      this.upgradeCallflow.emit(data);
    });
  }

  public requestPermisstionFlow(userName) {
    this.hubConnection.on(`requestPermisstionFlow${userName}`, (data) => {
      if (data) {
        const parts = data.split("|");
        if (parts[0] == "request") {
          this.requestPermissionStatus.emit(parts[1]);
        } else if (parts[0] == "grant") {
          this.grantPermissionStatus.emit("grant");
        } else if (parts[0] == "cancel") {
          this.grantPermissionStatus.emit("cancel");
        } else {
          this.grantPermissionStatus.emit("deny");
        }
      }
    });
  }

  public addUpdateStatusGrammarListener() {
    this.userName = this.dataService.userName;
    this.hubConnection.on(`updateStatusGrammar${this.userName}`, (data) => {
      this.statusGrammarChanged.emit(data);
    });
  }

  public addTransferAgentInfoSummary(companyId, extension) {
    this.hubConnection.on(
      `transferAgentInfoSummary${companyId}${extension}`,
      (data) => {
        this.agentInfoChanged.emit(data);
      }
    );
  }
  public addQueueInfoSummary(queue) {
    this.hubConnection.on(`queueStatus${queue}`, (data) => {
      this.queueInfoChanged.emit(data);
    });
  }
  public userIntroCall(number) {
    this.hubConnection.on(`userMakeIntroCall${number}`, (data) => {
      this.userMakeIntroCall.emit(data);
    });
  }

  public addResourceStatusListener() {
    this.hubConnection.on(`sendResourceStatus`, (data) => {
      this.resourceStatus.emit(data);
    });
  }

  public currentInvoiceListener() {
    this.companyId = this.dataService.CompanyId;
    this.hubConnection.on(`invoiceUsageDashboard${this.companyId}`, (data) => {
      this.currentInvoiceChanged.emit(data);
    });
  }
  public trunkStatusChangeListener() {
    this.hubConnection.on(`trunkStatusSummary`, (data) => {
      this.trunkStatusChanged.emit(data);
    });
  }
  public stopHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }
}
