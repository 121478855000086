import { AgentClient, CreateExtensionCommand, CreateSipAccountCommand, ExtensionClient } from "./../../../../System-api";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";

import { FormControl, UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";

@Component({
  selector: "create-extensions",
  templateUrl: "create-extensions.component.html",
  styleUrls: ["./create-extensions.component.scss"],
})
export class CreateExtensionsComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() loadingButton: boolean = false;
  @Output() run = new EventEmitter<any>();
  phoneNumbers: any[];
  selectedOutGoingNumber: string;
  callPhoneNumber: string;
  pass: string = "";
  makeCallForm: UntypedFormGroup;
  showPassword = false;
  phoneType: string;
  constructor(
    @Optional() private ref: NbDialogRef<CreateExtensionsComponent>,
    private formBuilder: RxFormBuilder,
    private extensionClient: ExtensionClient,
    private toastrService: NbToastrService
  ) {
    this.makeCallForm = this.formBuilder.group({
      extension: new FormControl("", [
        RxwebValidators.required(),
        RxwebValidators.maxLength({
          value: 15,
        }),
        RxwebValidators.digit(),
      ]),
      password: [""],
      type: new FormControl("udp", [
        RxwebValidators.required()
      ])
    });
  }
  getOne() {}
  ngOnDestroy(): void {}
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  ngOnInit() {
    this.phoneType = "udp";
  }
  dismiss() {
    this.ref.close(false);
  }
  create() {
    this.loadingButton = true;
    let data = new CreateExtensionCommand();
    data.extension = this.callPhoneNumber;
    data.type = this.phoneType;
    data.password = this.pass;
    this.extensionClient.createExtension(data).subscribe(
      (rs) => {
        this.loadingButton = false;
        if (rs === 'true') {
          this.showToast(true, "Create new extension successfully", "Create new extension unsuccessfully");
          this.ref.close(this.callPhoneNumber);
        } else if (rs === 'false') {
          this.showToast(false, "Create new extension successfully", "Create new extension unsuccessfully");
        } else {
          this.makeCallForm.controls['extension'].setErrors({ 'extensionExist': true});
        }
      },
      (error) => {
        this.loadingButton = false;
        this.showToast(false, "Create new extension successfully", "Create new extension unsuccessfully");
      },
    );
  }
  onSelectedChange(event) {}
  updatePhoneType(event) {
    this.phoneType = event[0];
  }
  showToast(result, successText, failText) {
    if (result) {
      this.toastrService.show(successText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(failText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
