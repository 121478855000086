import {
  Component,
  OnInit,
  Directive,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { ChatGPT } from "../../../../@core/model/chatgpt";
import {
  ChatGPTClient,
  DetectIntentEntityLLMCommand,
  MakeTestVirtualAgentCommand,
} from "../../../../System-api";

@Component({
  selector: "app-test-action",
  templateUrl: "./test-action.component.html",
  styleUrls: ["./test-action.component.scss"],
})
export class TestActionComponent implements OnInit {
  @ViewChild("inputString", { static: false })
  inputString: ElementRef<HTMLElement>;
  @ViewChild("listElement") listElement: ElementRef;
  data: ChatGPT = new ChatGPT();
  name = "Virtual Agent";
  message = [];
  msg = "";
  sessionId = "";
  isLoading = false;
  type = "gpt";
  isGrammar = false;
  intents = [];
  entities = [];
  show = false;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  firstPrompt = "";
  constructor(
    public dataService: DataService,
    protected ref: NbDialogRef<TestActionComponent>,
    public dialogService: NbDialogService,
    private chatGPTClient: ChatGPTClient
  ) {}
  pushMsg(content = "", type = "user", status = "default") {
    this.message.push({
      type: type,
      content: content,
      status: status,
    });
  }
  optimize() {}
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.listElement.nativeElement.scrollTop =
          this.listElement.nativeElement.scrollHeight;
      }, 10);
    } catch (err) {}
  }
  reload() {
    this.message = [];
    this.sessionId = "";
  }
  findLanguages() {
    try {
      let language = JSON.parse(this.dataService.CallSetting.languageSetting);
      let defaultLanguage = language.find((x) => {
        return x.defaultUser == true;
      });
      return defaultLanguage.language.split("-")[0];
    } catch (error) {
      return "en";
    }
  }
  findFistPrompt() {
    try {
      let rs = "";
      let content = JSON.parse(this.data.content);
      let defaultContent = content.find((x) => {
        return x.isDefault == true;
      });
      if (defaultContent.content.length > 0) {
        defaultContent.content[0].forEach((element) => {
          if (element.type == "text" && element.value?.trim()) {
            rs = rs + element.value + " . ";
          }
        });
      }
      return rs?.trim();
    } catch (error) {
      return "";
    }
  }
  send() {
    if (this.isGrammar == true) {
      this.sendLLM();
    } else {
      this.sendVM();
    }
  }
  handleUpdateMsg(rs) {
    if (rs.type == "error") {
      this.pushMsg(rs.content, "system", rs.type);
    } else {
      try {
        let content = JSON.parse(rs.content);
        if (content?.session_id) {
          this.sessionId = content?.session_id;
        }
        this.pushMsg(content.data, "system");
      } catch (error) {
        this.pushMsg(rs.content, "system");
      }
    }
    this.scrollToBottom();
    this.setFocus();
  }
  sendVM() {
    let msgSend = this.msg?.trim();
    if (msgSend) {
      let data = new MakeTestVirtualAgentCommand();
      data.input = msgSend;
      data.prompt = this.data.prompt;
      data.temperature = this.data.temperature;
      data.customTrain = this.data.customTrain;
      data.firstPrompt = this.firstPrompt;
      data.id = Number(this.data.idDataset) || 0;
      data.keepContext = this.data.keepContext;
      data.lang = this.findLanguages();
      data.numberOutput = this.data.numberOutput;
      data.responseMode = this.data.responseMode;
      data.sessionId = this.sessionId;
      this.pushMsg(msgSend);
      this.msg = "";
      this.isLoading = true;
      this.scrollToBottom();
      this.chatGPTClient.testVirtualAgent(data).subscribe(
        (rs) => {
          this.isLoading = false;
          this.handleUpdateMsg(rs);
        },
        (error) => {
          this.isLoading = false;
          this.scrollToBottom();
          this.setFocus();
        }
      );
    }
  }
  sendLLM() {
    let msgSend = this.msg?.trim();
    if (msgSend) {
      let data = new DetectIntentEntityLLMCommand();
      data.input = msgSend;
      data.model = this.type;
      data.intents = this.intents;
      data.entities = this.entities;
      this.pushMsg(msgSend);
      this.msg = "";
      this.isLoading = true;
      this.scrollToBottom();
      this.chatGPTClient.testIntentEntityLLM(data).subscribe(
        (rs) => {
          this.isLoading = false;
          this.handleUpdateMsg(rs);
        },
        (error) => {
          this.isLoading = false;
          this.scrollToBottom();
          this.setFocus();
        }
      );
    }
  }
  ngOnInit() {
    this.setFocus();
  }

  dismiss() {
    this.ref.close();
  }
  setFocus() {
    setTimeout(() => {
      this.inputString.nativeElement.focus();
    }, 0);
  }
}
