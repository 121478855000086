import {
  ChatGPTClient,
  MakeTestModelLLMCommand,
} from "./../../../../System-api";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { Observable, Subscription, map, of } from "rxjs";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { CheckAttribute } from "../../../../@core/model/check-attribute";
import { NgSelectComponent } from "@ng-select/ng-select";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";

import { LLM } from "../../../../@core/model/llm";
import { ExpandTextComponent } from "../../other/expand-text/expand-text.component";
import { OptimizePromptComponent } from "../../other/optimize-prompt/optimize-prompt.component";

@Component({
  selector: "llm-action",
  templateUrl: "./llm.component.html",
  styleUrls: ["./llm.component.scss"],
})
export class LLMComponent implements OnInit, OnDestroy {
  listCondition;
  cell: MxCell;
  graph: MxGraph;
  data: LLM = new LLM();
  @ViewChild(NgSelectComponent) selectComponents: NgSelectComponent;
  filteredOptions$: Observable<any[]> = of(this.dataService.ivrAttribute);
  cancelAction = false;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  grammarArray = [];
  formGroup: UntypedFormGroup;
  grammarStatusChangedSubscription: Subscription;
  errorMessage: string = "Output is required.";
  outputReview = "";
  isLoading = false;
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private graphService: GraphHandlerService,
    private formBuilder: RxFormBuilder,
    private toastrService: NbToastrService,
    private chatGPTClient: ChatGPTClient
  ) {
    this.createForm();
  }
  optimize() {
    if (this.data.prompt && this.data.prompt?.trim()) {
      this.dialogService
        .open(OptimizePromptComponent, {
          autoFocus: false,
          context: { data: this.data.prompt, model: this.data.model },
        })
        .onClose.subscribe((rs) => {
          if (rs?.status == "save") {
            this.data.prompt = rs.data;
          }
        });
    } else {
      this.toastrService.show("Please enter your prompt.", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  expand() {
    this.dialogService
      .open(ExpandTextComponent, {
        autoFocus: false,
        closeOnBackdropClick: false,
        context: {
          data: this.data.prompt,
          name: "Prompt",
          model: this.data.model,
        },
      })
      .onClose.subscribe((rs) => {
        if (rs.status == "save") {
          this.data.prompt = rs.data;
        }
      });
  }
  makeTest() {
    if (this.data.text && this.data.text?.trim() != "") {
      let data = new MakeTestModelLLMCommand();
      data.input = this.data.text;
      data.prompt = this.data.prompt;
      data.model = this.data.model;
      data.temperature = this.data.temperature;
      this.isLoading = true;
      this.chatGPTClient.testLLM(data).subscribe(
        (rs) => {
          this.isLoading = false;
          this.outputReview = rs;
        },
        (error) => {
          this.notify(false);
          this.outputReview = "";
          this.isLoading = false;
        }
      );
    }
  }
  ngOnDestroy(): void {}
  onChange(event) {
    const textArea = document.getElementById("input-text");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = "32.4px";
      }
    }
  }
  handleMaxValueTemperature(data) {
    const inputValue = data?.target?.value?.trim();
    if (inputValue && !isNaN(inputValue)) {
      if (Number(inputValue) > 2) {
        this.data.temperature = 2;
      }
    } else {
      this.data.temperature = 0;
    }
  }
  onChangePrompt(event) {
    const textArea = document.getElementById("input-text-prompt");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = "150px";
      }
    }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      text: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  notify(result) {
    if (result) {
      this.toastrService.show("Successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }

    if (this.data.attribute?.trim() == "" || !this.data.attribute) {
      this.data.attribute = null;
    }
    if (!this.data.model) {
      this.data.model = "gpt";
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  getAttribute(event) {
    this.data.attribute = event;
  }

  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const checkAttributeCell = this.graph
        .getModel()
        .getCell(this.cell.getId());
      const fieldsRequired = [this.data.text?.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        checkAttributeCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  selectVariable(value) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [...options])
            );
            this.selectComponents.writeValue(rs);
            this.data.attribute = rs;
          }
        });
    }
  }
  getVariable($event) {
    this.data.attribute = $event;
  }
}
