<nb-card style="width: 600px; height: 100vh; margin-bottom: 0">
  <nb-card-header style="padding: 0.25rem 1rem">
    <div class="d-flex justify-content-between" style="align-items: center">
      <div class="title-page-wrapper">
        <span>Debug Log</span>
      </div>
      <div
        class="d-flex justify-content-between"
        style="align-items: center; gap: 0.5rem"
      >
        <button
          (click)="reload()"
          type="button"
          type="button"
          ghost
          size="medium"
          title="Reload Conversation"
          nbButton
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
        <button
          (click)="dismiss()"
          type="button"
          type="button"
          ghost
          size="medium"
          nbButton
        >
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div></div
  ></nb-card-header>
  <nb-card-body>
    <div class="log-details log-details-list" #listElement>
      <nb-list style="overflow-x: hidden">
        <nb-list-item
          *ngFor="let item of contents; let i = index"
          class="item-animation"
        >
          <nb-accordion>
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header>
                {{ item.actionName }}
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <nb-list style="overflow-x: hidden">
                  <nb-list-item
                    *ngFor="let log of item.content; let j = index"
                    class="item-animation item-animation-2"
                  >
                    <div class="btn-action-wrap">
                      <div
                        *ngIf="log.logType == 'info'"
                        class="btn-action info"
                        title="INFO"
                      ></div>
                      <div
                        *ngIf="log.logType == 'debug'"
                        class="btn-action debug"
                        title="DEBUG"
                      ></div>
                      <div
                        *ngIf="log.logType == 'error'"
                        class="btn-action error"
                        title="ERROR"
                      ></div>
                    </div>
                    <div
                      class="content-log-wrap"
                      [ngClass]="{
                        'content-log-wrap-error': log.logType == 'error'
                      }"
                    >
                      <span
                        style="word-break: break-word"
                        *ngFor="
                          let line of log.logContent?.split('|');
                          let last = last
                        "
                      >
                        {{ line
                        }}<ng-container *ngIf="!last"><br /></ng-container>
                      </span>
                      <div
                        style="
                          margin-top: 0.5rem;
                          font-size: 13px;
                          text-wrap: wrap;
                        "
                        class="default-return view-json"
                        *ngIf="log.jsonRefData"
                      >
                        <nb-accordion style="width: 100%">
                          <nb-accordion-item>
                            <nb-accordion-item-header
                              class="rounded-md"
                              style="
                                padding: 0.25rem 1rem;
                                font-size: 12px !important;
                              "
                            >
                              View JSON
                            </nb-accordion-item-header>
                            <nb-accordion-item-body
                              style="
                                max-height: 50vh;
                                overflow-y: auto;
                                background-color: #f9fbfe;
                                padding-left: 0 !important;
                              "
                              class="px-2"
                            >
                              <div
                                style="position: absolute; right: 0; z-index: 1"
                              >
                                <button
                                  title="Copy"
                                  (click)="
                                    copyJSON(contents[i].content[j].jsonRefData)
                                  "
                                  type="button"
                                  ghost
                                  size="tiny"
                                  nbButton
                                >
                                  <nb-icon icon="copy-outline"></nb-icon>
                                </button>
                              </div>
                              <ngx-json-viewer
                                [json]="contents[i].content[j].jsonRefData"
                                [expanded]="false"
                              ></ngx-json-viewer>
                            </nb-accordion-item-body>
                          </nb-accordion-item>
                        </nb-accordion>
                      </div>
                    </div>
                    <div
                      class="hidden-button"
                      [ngClass]="{ 'show-button': log.id }"
                    >
                      <button
                        [nbPopover]="templateRef"
                        nbButton
                        size="tiny"
                        nbPopoverTrigger="noop"
                        (click)="showLog(log.id, i, j)"
                      >
                        Variables
                      </button>
                    </div>
                  </nb-list-item>
                </nb-list>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </nb-list-item>
      </nb-list>
    </div>
  </nb-card-body>
</nb-card>
<ng-template #templateRef>
  <nb-card
    id="model-variable"
    style="margin-bottom: 0"
    [nbSpinner]="loadingAttribute"
    nbSpinnerSize="medium"
    nbSpinnerStatus="info"
    ><nb-card-header style="padding: 0.25rem 1.25rem; padding-right: 0.25rem">
      <div
        class="d-flex justify-content-between"
        style="align-items: center; width: 100%"
      >
        <div class="title-page-wrapper">
          <span>Variables</span>
        </div>
        <div
          class="d-flex justify-content-between"
          style="align-items: center; gap: 0.5rem"
        >
          <button
            [title]="copyTitle"
            (click)="copyAttribute()"
            type="button"
            ghost
            size="medium"
            nbButton
          >
            <nb-icon icon="copy-outline"></nb-icon>
          </button>
          <button
            type="button"
            ghost
            size="medium"
            nbButton
            (click)="closeVariable()"
          >
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body style="padding: 0">
      <div class="log-content">
        <div class="fix">
          <ngx-json-viewer
            [json]="logContent"
            [expanded]="false"
          ></ngx-json-viewer>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
