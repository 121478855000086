import { NgModule } from "@angular/core";
import { CallflowsDesignComponent } from "./callflows-design.component";
import { CallFlowsGraphComponent } from "./design/callflows-graph.component";
import {
  NbAlertModule,
  NbMenuModule,
  NbLayoutModule,
  NbCardModule,
  NbToggleModule,
  NbActionsModule,
  NbListModule,
  NbSidebarModule,
  NbUserModule,
  NbWindowModule,
  NbChatModule,
  NbTabsetModule,
  NbAccordionModule,
  NbContextMenuModule,
  NbSpinnerModule,
  NbPopoverModule,
  NbFormFieldModule,
} from "@nebular/theme";
import { IvrAttributeComponent } from "./actions/other/ivr-attribute/ivr-attribute.component";
import { SharedModule } from "../shared/shared.module";
import { MenuAppComponent } from "./design/menu-app/menu-app.component";
import { ChatComponent } from "../portal/chat/chat.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SafePipe } from "../portal/chat/safepipe";
@NgModule({
  imports: [
    NbAlertModule,
    NbMenuModule,
    NbLayoutModule,
    NbCardModule,
    SharedModule,
    NbListModule,
    NbToggleModule,
    NbActionsModule,
    NbSpinnerModule,
    NbSidebarModule,
    NbListModule,
    NbTabsetModule,
    NbAccordionModule,
    NbUserModule,
    NbContextMenuModule,
    NbPopoverModule,
    Ng2SearchPipeModule,
    MatTooltipModule,
    NbFormFieldModule,
    NbWindowModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: "AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY",
    }),
  ],
  exports: [CallFlowsGraphComponent],
  declarations: [
    CallflowsDesignComponent,
    CallFlowsGraphComponent,
    IvrAttributeComponent,
    MenuAppComponent,
    ChatComponent,
    SafePipe,
  ],
})
export class CallFlowsDesignModule {}
