<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <div>
      <div class="mb-2">
        <label class="form-label label">Outreach Type</label>
        <nb-radio-group
          class="choice-option radio"
          [(ngModel)]="data.outreachType"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">
            {{ option.name }}
          </nb-radio>
        </nb-radio-group>
      </div>
      <div class="mb-2">
        <label class="form-label label d-flex" style="align-items: center"
          ><span>Language</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Set the default language for flow."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <nb-select
          size="small"
          fullWidth
          placeholder="Select Language"
          [(ngModel)]="data.language"
        >
          <nb-option *ngFor="let item of listlanguage" [value]="item?.language"
            >{{ item?.name }}
          </nb-option>
        </nb-select>
      </div>
      <form [formGroup]="formGroup">
        <div class="mb-2 mt-2">
          <div class="row">
            <label class="form-label label required">Flow</label>
            <div class="d-flex custom-gap">
              <ng-select
                formControlName="callflow"
                [nbSpinner]="isLoading"
                nbSpinnerSize="medium"
                nbSpinnerStatus="info"
                [items]="callflows"
                bindLabel="name"
                bindValue="id"
                fullWidth
                size="small"
                [(ngModel)]="callflowIdSelected"
                (change)="checkViewDisabled($event)"
                placeholder="Select Flow"
                [ngClass]="{
                  isInvalid:
                    formGroup.controls['callflow'].invalid &&
                    (formGroup.controls['callflow'].dirty ||
                      formGroup.controls['callflow'].touched)
                }"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div class="function-item">
                    <span>{{ item.name }}</span>
                    <nb-icon
                      icon="edit-2-outline"
                      style="font-size: 15px"
                      (click)="viewSubFlow(item.id)"
                    ></nb-icon>
                  </div>
                </ng-template>
              </ng-select>
              <button
                type="button"
                [disabled]="!data.callflowId"
                size="small"
                status="primary"
                nbButton
                (click)="viewSubFlow(data.callflowId)"
              >
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
              <button
                type="button"
                size="small"
                status="primary"
                nbButton
                (click)="createFlow()"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
            <ng-container
              *ngIf="
                formGroup.controls['callflow'].invalid &&
                (formGroup.controls['callflow'].dirty ||
                  formGroup.controls['callflow'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  formGroup.controls['callflow'].hasError('required') ||
                  formGroup.controls['callflow'].hasError('whitespace')
                "
              >
                Flow is required.
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mb-2">
          <div class="form-label d-flex" style="align-items: center">
            <label for="phoneNumber" class="label required">
              Phone Number
            </label>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="The destination you want to make outreach."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </div>
          <input
            formControlName="phoneNumber"
            id="phoneNumber"
            fieldSize="small"
            nbInput
            fullWidth
            placeholder="Phone Number"
            [(ngModel)]="data.phoneNumber"
            [status]="
              formGroup.controls['phoneNumber'].invalid &&
              (formGroup.controls['phoneNumber'].dirty ||
                formGroup.controls['phoneNumber'].touched)
                ? 'danger'
                : 'basic'
            "
          />
          <ng-container
            *ngIf="
              formGroup.controls['phoneNumber'].invalid &&
              (formGroup.controls['phoneNumber'].dirty ||
                formGroup.controls['phoneNumber'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['phoneNumber'].hasError('required') ||
                formGroup.controls['phoneNumber'].hasError('whitespace')
              "
            >
              Phone Number is required.
            </p>
          </ng-container>
        </div>
        <div class="input-caller mb-2">
          <div class="input-width">
            <div class="form-label d-flex" style="align-items: center">
              <label for="callerId" class="label required"> Caller </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the caller (trunk) you want to use."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
            <nb-select
              formControlName="caller"
              id="callerId"
              size="small"
              fullWidth
              placeholder="Select Caller"
              [(ngModel)]="data.callerId"
              [status]="
                formGroup.controls['caller'].invalid &&
                (formGroup.controls['caller'].dirty ||
                  formGroup.controls['caller'].touched)
                  ? 'danger'
                  : 'basic'
              "
            >
              <nb-option value="SIP">Local SIP</nb-option>
              <nb-option
                *ngFor="let trunk of trunksNumber"
                value="{{ trunk.id }}"
              >
                {{ trunk.name }}
              </nb-option>
            </nb-select>
            <ng-container
              *ngIf="
                formGroup.controls['caller'].invalid &&
                (formGroup.controls['caller'].dirty ||
                  formGroup.controls['caller'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  formGroup.controls['caller'].hasError('required') ||
                  formGroup.controls['caller'].hasError('whitespace')
                "
              >
                Caller is required.
              </p>
            </ng-container>
          </div>
          <div class="input-width">
            <label
              for="callerNumber"
              class="form-label label d-flex"
              style="align-items: center"
            >
              <span>Caller Number</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="The number will show up when the call gets forwarded to the destination number."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <input
              formControlName="callerNumber"
              id="callerNumber"
              fieldSize="small"
              nbInput
              fullWidth
              placeholder="Caller Number"
              [(ngModel)]="data.callerNumber"
              [mention]="attributes"
              [mentionConfig]="mentionConfig"
            />
          </div>
        </div>
      </form>
      <div class="mb-2">
        <div class="flex-title">
          <span class="title">Set Variable</span>
          <span class="line"></span>
        </div>
        <ngx-input-static
          selectAttribute="true"
          [propertyInput]="data.attribute"
          propertyName="Variable"
          propertyValue="Value"
          (propertyEmit)="getAttribute($event)"
        >
        </ngx-input-static>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
