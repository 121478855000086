<div>
  <form [formGroup]="formGroup">
    <ng-select
      formControlName="property"
      #selectComponents
      [items]="filteredOptions$ | async"
      [addTag]="addTagFn"
      fullWidth
      [placeholder]="placeHolder"
      [(ngModel)]="properties"
      bindLabel="value"
      bindValue="value"
      (change)="selectVariable($event)"
      addTagText="Custom variable"
      [editableSearchTerm]="true"
      [ngClass]="{
        isInvalid:
          errorMessage &&
          formGroup.controls['property'].invalid &&
          (formGroup.controls['property'].dirty ||
            formGroup.controls['property'].touched)
      }"
    >
      <input type="text" [value]="properties" />
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <div *ngIf="item.default == 'VARIABLE'" class="add-attribute">
          <nb-icon icon="plus-circle-outline"></nb-icon>
          <span>VARIABLE</span>
        </div>
        <div
          *ngIf="item.default != 'VARIABLE'"
          style="word-break: break-word; white-space: normal"
        >
          {{ item.value || properties }}
        </div>
      </ng-template>
      >
    </ng-select>
    <ng-container
      *ngIf="
        formGroup.controls['property'].invalid &&
        (formGroup.controls['property'].dirty ||
          formGroup.controls['property'].touched)
      "
    >
      <p
        class="caption status-danger"
        *ngIf="
          formGroup.controls['property'].hasError('required') ||
          formGroup.controls['property'].hasError('whitespace')
        "
      >
        {{ errorMessage }}
      </p>
    </ng-container>
  </form>
</div>
