import { tap } from 'rxjs';
import { Component, OnInit, Directive, HostListener, ChangeDetectorRef, SimpleChanges, ViewChildren, QueryList } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CheckTime } from "../../../../@core/model/check-time";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { timeRange } from "../../../../@core/utils/helpers";
import { timezoneList } from "./timzone-data";
import { DataService } from "../../../../@core/utils/data.service";
import { TagInputComponent } from 'ngx-chips';

@Component({
  selector: "ngx-check-time",
  templateUrl: "./check-time.component.html",
  styleUrls: ["./check-time.component.scss"],
})
export class CheckTimeComponent implements OnInit {
  data: CheckTime = new CheckTime();
  cell: MxCell;
  graph: MxGraph;
  options = [];
  cancelAction = false;
  listAction: any[];
  validators = [timeRange];
  errorMessages = {
    timerange: "Example: 02:00-22:00",
  };

  dayofweek: {
    name: string;
    time: any;
  }[] = [
    {
      name: "Monday",
      time: [],
    },
    {
      name: "Tuesday",
      time: [],
    },
    {
      name: "Wednesday",
      time: [],
    },
    {
      name: "Thursday",
      time: [],
    },
    {
      name: "Friday",
      time: [],
    },
    {
      name: "Saturday",
      time: [],
    },
    {
      name: "Sunday",
      time: [],
    },
  ];
  listTimezone = timezoneList;
  optionsTimezone: object[] = [
    { id: 1, name: "Static" },
    { id: 2, name: "Dynamic" },
  ];
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  constructor(
    public dataService: DataService,
    private graphHandler: GraphHandlerService,
    protected windowRef: NbWindowRef
  ) {}
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    if (this.data.timezone?.trim() == "" || !this.data.timezone) {
      const gmtOffsetString = this.getGMTOffsetString();

      let timeZone = this.listTimezone.find((x) =>
        x.name.includes(gmtOffsetString)
      );
      this.data.timezone = timeZone ? timeZone.value : "America/New_York";
    }

    if (!this.data.timezoneOption) {
      this.data.timezoneOption = 1;
    }
    if (this.data.timezoneCustom?.trim() == "" || !this.data.timezoneCustom) {
      const gmtOffsetString = this.getGMTOffsetString();
      let timeZone = this.listTimezone.find((x) =>
        x.name.includes(gmtOffsetString)
      );
      this.data.timezoneCustom = timeZone ? timeZone.value : "America/New_York";
    }
    this.handleTimezoneOption(Number(this.data.timezoneOption));
    this.graphHandler.handleUpdateData(this.data, this.cell);
    this.setTimePicker();
    this.listAction = this.graphHandler.getAllAction(this.cell);
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  getAttribute(event) {
    this.data.timezoneCustom = event;
  }

  handleTimezoneOption(value: number) {
    this.data.timezoneOption = value;
  }
  getGMTOffsetString() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;

    const sign = offsetHours > 0 ? "-" : "+";
    const hours = Math.abs(Math.floor(offsetHours));
    const minutes = Math.abs(offsetMinutes % 60);

    return `(GMT${sign}${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes})`;
  }
  submit() {
    if (!this.cancelAction) {
      this.data.time = this.getTimePicker();
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
    }
  }

  isSelectedAction(status) {
    let edges = this.cell.edges;

    if (edges != undefined) {
      for (let index = 1; index < edges.length; index++) {
        if (edges[index].value == status) {
          return false;
        }
      }
    }

    return true;
  }

  getTimePicker() {
    var array = new Array();
    this.dayofweek.forEach((element) => {
      if (element.name && element.time) {
        element.time.forEach((time) => {
          let value = time;
          let objString = element.name + "=" + value;
          array.push(objString);
        });
      }
    });
    return array.join("|");
  }

  setTimePicker() {
    if (this.data.time != "") {
      let arrayTime = this.data.time.split("|");

      arrayTime.forEach((element) => {
        let dto = element.split("=");

        for (var i = 0; i < this.dayofweek.length; i++) {
          if (this.dayofweek[i].name == dto[0]) {
            this.dayofweek[i].time.push(dto[1]);
          }
        }
      });
    } else {
      this.dayofweek.forEach((day) => {
        day.time = [
          "00:00-23:59"
        ];
      });
    }
  }
  timeRangeValidator(control: UntypedFormControl) {
    return timeRange(control) ? { timeRange: true } : null;
  }
  onTextChange(event: any, index: number): void {
    const control = new UntypedFormControl(event.tag);
    const validationResult = this.timeRangeValidator(control);
    if (validationResult !== null) {
      this.dayofweek[index] = { ...this.dayofweek[index], time: [...this.dayofweek[index].time] };
    }
  }

  getVertex(listAction, event) {
    let vertex: any;
    if (listAction) {
      listAction.forEach((obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (key == "id" && value == event) {
            vertex = obj;
          }
        });
      });
    }
    return vertex;
  }

}
