import { IApplicationData } from "./common";
export class MultipleConditions implements IApplicationData {
  constructor() {
    this.name = "Multiple Conditions";
    this.attribute = "";
    this.type = "AND";
    this.transition = true;
  }
  name: string;
  attribute: string;
  type: string;
  transition: boolean;
}
