<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <div style="padding-bottom: 200px">
      <ngx-input-static
        selectAttribute="true"
        [propertyInput]="data.attribute"
        propertyName="Variable"
        propertyValue="Value"
        (propertyEmit)="getAttribute($event)"
      ></ngx-input-static>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
