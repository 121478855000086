<div [formGroup]="makeCallForm">
  <nb-card
    style="max-width: 550px; min-width: 460px"
    [ngClass]="{ 'is-preview': isPreview }"
  >
    <nb-card-header *ngIf="isPreview == false">
      <div class="d-flex justify-content-between">
        Make A Test Call
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label class="form-label label required">Outgoing Number</label>
        <input
          *ngIf="this.selectedOutGoingNumber != null"
          #autoInput2
          type="text"
          [(ngModel)]="selectedOutGoingNumber"
          (ngModelChange)="onOutPhoneChange($event)"
          [nbAutocomplete]="autoComplete2"
          nbInput
          [status]="
            makeCallForm.controls['outGoingNumber'].invalid &&
            (makeCallForm.controls['outGoingNumber'].dirty ||
              makeCallForm.controls['outGoingNumber'].touched)
              ? 'danger'
              : 'basic'
          "
          formControlName="outGoingNumber"
          fullWidth
          placeholder="Select Outgoing Number"
        />
        <nb-autocomplete #autoComplete2>
          <nb-option
            *ngFor="let e of filteredOutgoingOptions$ | async"
            [value]="e.name"
          >
            {{ e.name }}
          </nb-option>
        </nb-autocomplete>

        <ng-container
          *ngIf="
            makeCallForm.controls['outGoingNumber'].invalid &&
            (makeCallForm.controls['outGoingNumber'].dirty ||
              makeCallForm.controls['outGoingNumber'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['outGoingNumber'].hasError('required') ||
              makeCallForm.controls['outGoingNumber'].hasError('whitespace')
            "
          >
            Outgoing Number is required.
          </p>
        </ng-container>
      </div>
      <div class="mb-2" *ngIf="selectedOutGoingNumber != '100'">
        <label for="callPhoneNumber" class="form-label label required"
          >Destination Number</label
        >
        <span
          *ngIf="selectedOutGoingNumber == 'Local SIP' && isPreview == false"
          class="text-link"
          style="font-size: 12px; margin-left: 0.5rem"
          (click)="getOne()"
        >
          + Get one</span
        >

        <input
          #autoInput
          id="callPhoneNumber"
          type="text"
          [(ngModel)]="callPhoneNumber"
          (ngModelChange)="onCallPhoneChange($event)"
          [nbAutocomplete]="autoComplete"
          nbInput
          [status]="
            makeCallForm.controls['number'].invalid &&
            (makeCallForm.controls['number'].dirty ||
              makeCallForm.controls['number'].touched)
              ? 'danger'
              : 'basic'
          "
          formControlName="number"
          fullWidth
          (keyup.enter)="makeCall()"
          placeholder="Destination Number"
        />
        <nb-autocomplete #autoComplete>
          <nb-option
            *ngFor="let e of filteredExtensionOptions$ | async"
            [value]="e.extension"
          >
            {{ e.extension }}
          </nb-option>
        </nb-autocomplete>
        <ng-container
          *ngIf="
            makeCallForm.controls['number'].invalid &&
            (makeCallForm.controls['number'].dirty ||
              makeCallForm.controls['number'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['number'].hasError('required') ||
              makeCallForm.controls['number'].hasError('whitespace')
            "
          >
            My Phone Number is required.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['number'].hasError('digit') &&
              !makeCallForm.controls['number'].hasError('maxLength')
            "
          >
            My Phone Number must be numeric.
          </p>
          <p
            class="caption status-danger"
            *ngIf="makeCallForm.controls['number'].hasError('maxLength')"
          >
            Maximum length is 15 characters.
          </p>
        </ng-container>
      </div>
      <button
        class="float-end mt-2"
        nbButton
        size="small"
        [nbSpinner]="loadingButton"
        nbSpinnerSize="small"
        nbSpinnerStatus="info"
        [disabled]="
          !callPhoneNumber ||
          !selectedOutGoingNumber ||
          !makeCallForm.valid ||
          loadingButton
        "
        type="button"
        (click)="makeCall()"
        status="success"
      >
        <i class="bi bi-telephone-fill" style="margin-right: 0.5rem"></i>
        Call
      </button>
      <button
        *ngIf="isDebug"
        class="float-end mt-2"
        style="margin-right: 0.5rem"
        nbButton
        size="small"
        [nbSpinner]="loadingButton"
        nbSpinnerSize="small"
        nbSpinnerStatus="info"
        [disabled]="
          !callPhoneNumber ||
          !selectedOutGoingNumber ||
          !makeCallForm.valid ||
          loadingButton
        "
        type="button"
        (click)="makeCall(true)"
        status="primary"
      >
        <i class="bi bi-bug" style="margin-right: 0.5rem"></i>
        Call & Debug
      </button>
    </nb-card-body>
  </nb-card>
</div>
