<div class="header-container">
  <a *ngIf="!isAgent" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>
  <div class="logo" style="max-height: 36px; height: 36px" >
    <img
      src="logo.png"
      style="height: inherit; width: 100%; object-fit: contain"
      (click)="navigateAgentworkspace()"
    />
  </div>
  <div *ngIf="canUpdate" style="margin-left: 30px">
    <span class="update-label">Upgrade to version {{ latestVersion }}</span>
    <button nbButton (click)="openUpgrade()" size="tiny">Upgrade</button>
  </div>
</div>

<div class="header-container">
  <button
    *ngIf="dataService.AgentInfo"
    nbButton
    outline
    size="small"
    [nbPopover]="templateRef"
    nbPopoverPlacement="bottom"
    nbPopoverTrigger="click"
    [status]="dataService.AgentInfo.isOnline ? 'success' : 'danger'"
  >
    <span>{{ dataService.AgentInfo.isOnline ? "Ready" : "Not Ready" }}</span>
  </button>
  <nb-actions size="small">
    <nb-action>
      <nb-icon
        icon="question-mark-circle-outline"
        matTooltip="Help Center"
        status="basic"
        style="cursor: pointer; height: 40px"
        (click)="helpCenter()"
      ></nb-icon>
    </nb-action>
    <nb-action>
      <nb-icon
        icon="message-square-outline"
        status="basic"
        matTooltip="Support Widget"
        style="cursor: pointer; height: 40px"
        [nbPopover]="templateRef2"
        nbPopoverPlacement="bottom"
        nbPopoverTrigger="click"
      ></nb-icon>
    </nb-action>
    <nb-action class="user-action" style="padding-right: 0">
      <nb-user
        [nbContextMenu]="userMenu"
        onlyPicture
        [title]="user?.unique_name"
        [name]="user?.unique_name"
        picture="{{
          user?.picture ||
            dataService?.AgentInfo?.avatar ||
            'assets/images/user.jpg'
        }}"
        style="width: 40px"
      >
      </nb-user>
      <nb-action
        style="position: absolute; bottom: 0; left: 20px; padding-right: 0"
      >
        <nb-badge
          [dotMode]="true"
          [status]="
            dataService.AgentInfo && dataService.AgentInfo.isOnline == false
              ? 'danger'
              : 'success'
          "
          position="bottom start"
        ></nb-badge>
      </nb-action>
    </nb-action>
  </nb-actions>
</div>
<ng-template #templateRef2>
  <nb-card
    style="display: flex; min-width: 160px; margin-bottom: 0; border: 0"
    id="box-menu-chat"
  >
    <nb-card-body style="padding: 0">
      <nb-list>
        <nb-list-item
          class="item-chat-us"
          style="border-top: 0; height: 48px"
          (click)="chatWithUs()"
        >
          <div
            style="
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
            "
          >
            <nb-icon
              icon="paper-plane"
              status="basic"
              style="margin-right: 1rem"
            ></nb-icon>
            <span style="font-weight: 600">Chat With Us</span>
          </div>
        </nb-list-item>
        <nb-list-item
          class="item-chat-us"
          style="border-bottom: 0; height: 48px"
        >
          <nb-toggle
            [(checked)]="dataService.openChatButton"
            (checkedChange)="change($event)"
            >Support Widget</nb-toggle
          >
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef>
  <nb-card style="display: flex; min-width: 250px; margin-bottom: 0">
    <nb-card-body style="padding: 0">
      <nb-list>
        <nb-list-item>
          <div
            style="
              color: #222b45;
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
            "
          >
            <nb-icon
              icon="hash-outline"
              status="basic"
              style="margin-right: 1.35rem"
            ></nb-icon>
            <span style="font-weight: 600">Extension: </span>
            <span>{{ this.dataService.AgentInfo.extension }}</span>
          </div>
        </nb-list-item>
        <nb-list-item>
          <div
            style="
              color: #222b45;
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
            "
          >
            <nb-icon
              icon="phone"
              status="basic"
              style="margin-right: 1.35rem"
            ></nb-icon>
            <span style="font-weight: 600">Phone Number: </span>
            <span>{{ this.dataService.AgentInfo.phoneNumber }}</span>
          </div>
        </nb-list-item>
        <nb-list-item *ngIf="dataService.AgentInfo.agentType == 'Dynamic'">
          <nb-toggle
            [status]="dataService.AgentInfo.isOnline ? 'success' : 'basic'"
            [(checked)]="dataService.AgentInfo.isOnline"
            (checkedChange)="updateIsOnline($event)"
            [disabled]="isLoading == true || isLoadingFollowMe == true"
            >{{
              dataService.AgentInfo.isOnline ? "Logout" : "Login"
            }}</nb-toggle
          >
        </nb-list-item>
        <nb-list-item
          *ngIf="dataService.AgentInfo.isOnline && isLoading == false"
        >
          <nb-toggle
            [(checked)]="dataService.AgentInfo.followMe"
            [disabled]="isLoadingFollowMe == true || isLoading == true"
            (checkedChange)="toggleFollowMe($event)"
            >Follow Me</nb-toggle
          >
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</ng-template>
