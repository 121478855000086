<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div
          class="form-group"
          style="padding: 1rem 0; width: 100%; display: inline-grid"
        >
          <text-to-speech #textToSpeech></text-to-speech>
          <nb-card class="select-audio" [ngClass]="{ show: data.option == 1 }">
            <nb-card-header class="audio-list-style">
              <span>Audio List</span>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></nb-card-header>
            <nb-card-body>
              <simple-audio-player
                #audioPlayer
                [events]="eventsSubject.asObservable()"
              ></simple-audio-player>
              <button
                nbButton
                size="small"
                status="primary"
                (click)="showModify()"
              >
                <nb-icon icon="plus-outline"></nb-icon> Add
              </button>
            </nb-card-body>
          </nb-card>
        </div>

        <!-- <div
          class="mb-3"
          style="display: flex; flex-direction: column; margin-top: 0.5rem"
        >
          <label for="name" class="form-label label">Choose Videobot</label>
          <sentiment-select
            placeHolder="Choose Videobot"
            (valueSelected)="handleSentiment($event)"
            [value]="data.sentiment"
            defaultValue="normal-speak"
          ></sentiment-select>
        </div> -->
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Number Transfer</ng-template>
        <div class="form-group" [ngClass]="{ show: data.type == 2 }">
          <div class="mb-2">
            <div class="form-label d-flex" style="align-items: center">
              <label for="transferDestination" class="label required">
                Transfer Destination
              </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Callers be transferred to a given destination."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
            <form [formGroup]="formGroup">
              <input
                formControlName="transferDestination"
                (drop)="drop($event)"
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="data.transferDestination"
                id="transferDestination"
                placeholder="Transfer Destination"
                fieldSize="small"
                (blur)="onInputChange('transferDestination')"
                [mention]="attributes"
                [mentionConfig]="mentionConfig"
                [status]="
                  formGroup.controls['transferDestination'].invalid &&
                  (formGroup.controls['transferDestination'].dirty ||
                    formGroup.controls['transferDestination'].touched)
                    ? 'danger'
                    : 'basic'
                "
              />
              <ng-container
                *ngIf="
                  formGroup.controls['transferDestination'].invalid &&
                  (formGroup.controls['transferDestination'].dirty ||
                    formGroup.controls['transferDestination'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    formGroup.controls['transferDestination'].hasError(
                      'required'
                    ) ||
                    formGroup.controls['transferDestination'].hasError(
                      'whitespace'
                    )
                  "
                >
                  Transfer Destination is required.
                </p>
              </ng-container>
            </form>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
