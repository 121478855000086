<div class="action-config-wrapper" style="min-width: 650px; max-width: 90vw">
  <div class="config-action">
    <div style="padding-bottom: 200px">
      <input-static-functions
        selectAttribute="true"
        [propertyInput]="data.attribute"
        propertyName="Output"
        propertyValue="Input"
        (propertyEmit)="getAttribute($event)"
      ></input-static-functions>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
