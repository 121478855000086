<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <form [formGroup]="formGroup" class="mb-2">
      <div class="form-label d-flex" style="align-items: center">
        <label class="label required"> Input </label>
        <nb-icon
          class="ms-1"
          style="color: #929bb3; width: 15px"
          nbTooltip="Information you want to detect intent and entity."
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
        >
        </nb-icon>
      </div>
      <textarea
        formControlName="text"
        type="text"
        id="input-text"
        nbInput
        fullWidth
        [(ngModel)]="data.text"
        (ngModelChange)="onChange($event)"
        placeholder="Input"
        fieldSize="small"
        [mention]="attributes"
        style="height: 32.4px; max-height: 150px; min-height: 32.4px"
        [mentionConfig]="mentionConfig"
        [status]="
          formGroup.controls['text'].invalid &&
          (formGroup.controls['text'].dirty ||
            formGroup.controls['text'].touched)
            ? 'danger'
            : 'basic'
        "
      ></textarea>
      <ng-container
        *ngIf="
          formGroup.controls['text'].invalid &&
          (formGroup.controls['text'].dirty ||
            formGroup.controls['text'].touched)
        "
      >
        <p
          class="caption status-danger"
          style="margin-bottom: 0"
          *ngIf="
            formGroup.controls['text'].hasError('required') ||
            formGroup.controls['text'].hasError('whitespace')
          "
        >
          Input is required.
        </p>
      </ng-container>
    </form>
    <ngx-multiple-grammar
      #multipleGrammar
      [grammarArray]="grammarArray"
      [type]="'Custom'"
      [phraseListGrammar]="false"
    ></ngx-multiple-grammar>
    <div class="flex-title" style="margin-bottom: 1.25rem">
      <span class="title">Assign Variable</span>
      <span class="line"></span>
    </div>
    <div class="row">
      <div class="mb-2">
        <div class="form-label" style="display: flex; align-items: center">
          <label class="label d-flex">Intent</label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Assign the intent of the user input to the variable."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>

        <ngx-input-value-static
          placeHolder="Select Intent"
          selectAttribute="true"
          [propertyInput]="data.attribute"
          (propertyEmit)="getAttribute($event)"
        >
        </ngx-input-value-static>
      </div>
    </div>
    <div class="row">
      <div class="mb-2">
        <label class="form-label label d-flex" style="align-items: center">
          <span>Entity</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Assign the entity of user input to the variable.You can get the value of the entity at variable by writing the following syntax: {variable_name->entity_type->0->sourceText}"
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <ngx-input-value-static
          placeHolder="Select Entity"
          selectAttribute="true"
          [propertyInput]="data.entityAttribute"
          (propertyEmit)="getEntityAttribute($event)"
        >
        </ngx-input-value-static>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
