<nb-card
  style="
    max-height: 50vh;
    min-width: 200px;
    overflow-y: auto !important;
    margin: 10px;
    margin-top: 0;
    border: none;
  "
  size="large"
  *ngIf="isAllowDesign"
>
  <nb-card-header
    style="
      padding: 5px 5px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span>List Variable</span>
    <button
      class="alert-circle-outline"
      type="button"
      ghost
      nbTooltip="You can store key-value pairs as variables. And you can using variable in your flow. Example: &#123;name_variable&#125;."
      nbTooltipPlacement="bottom"
      nbTooltipStatus="basic"
      nbTooltipTrigger="hover"
      size="small"
      nbButton
      (click)="routerVariable()"
    >
      <nb-icon icon="question-mark-circle-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body class="mb-2 mt-2" style="padding: 0 !important">
    <input
      type="text"
      nbInput
      [(ngModel)]="searchText"
      fullWidth
      (keyup)="filterVariable()"
      placeholder="Search Variable"
      fieldSize="small"
      class="mb-2"
    />
    <nb-list>
      <nb-list-item
        (click)="clickedRow(item)"
        [class.active]="item == selectedItem"
        (dblclick)="editAttribute(item)"
        (dragstart)="dragStart($event)"
        draggable="true"
        class="list-group-item-action justify-content-between align-items-center"
        *ngFor="let item of variableList"
      >
         {{ item.value }}
        <label *ngIf="item.default == 1" class="bg-light px-2 text-secondary" style="font-size: 9px;border-radius: 4px;">
          system
        </label>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
  <nb-card-footer style="padding: 0.5rem">
    <nb-actions size="small" fullWidth>
      <nb-action (click)="addAttribute()">
        <nb-icon status="primary" icon="plus-outline"></nb-icon>
      </nb-action>
      <nb-action (click)="deleteAttribute()">
        <nb-icon
          [ngClass]="{ 'change-cursor': selectedItem?.default == 2 }"
          [status]="selectedItem?.default == 2 ? 'basic' : 'primary'"
          icon="trash-2-outline"
        ></nb-icon>
      </nb-action>
    </nb-actions>
  </nb-card-footer>
</nb-card>
