import {
  GetAllIntentValueQuery,
  GrammarClient,
} from "./../../../../System-api";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseDefine } from "../../../../@core/model/response-define";
import { DataService } from "../../../../@core/utils/data.service";
import { MxCell } from "../../../../@core/model/mxgraph";
import { Observable, of } from "rxjs";

@Component({
  selector: "ngx-condition-define",
  templateUrl: "./condition-define.component.html",
  styleUrls: ["./condition-define.component.scss"],
})
export class ConditionDefineComponent implements OnInit, OnDestroy {
  data: ResponseDefine = new ResponseDefine();
  listCondition;
  type = "";
  title = "Case Condition";
  isUpdate: boolean = false;
  updateData = null;
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  previousValue: any = null;
  cell: MxCell;
  grammarArray = null;
  buttonList = null;
  cards = null;
  intentList: string[] = [];
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
    ],
  };
  action = "close";
  isLoading = false;
  filteredNgModelOptions$: Observable<string[]>;

  constructor(
    private ref: NbDialogRef<ConditionDefineComponent>,
    private dataService: DataService,
    private grammarClient: GrammarClient
  ) {}
  ngOnDestroy(): void {
    this.filteredNgModelOptions$ = null;
  }
  handleGrammar() {
    try {
      let grammar = JSON.parse(this.grammarArray);
      if (grammar.length > 0) {
        let grammarValid = grammar.filter((x) => {
          if (x.type == "Custom" || x.type == "LLM" || x.type == "PhraseList") {
            return true;
          }
          if (x.type == "BuiltIn" && x.name == "Yes or No") {
            this.intentList = ["yes", "no"];
            return false;
          }
          return false;
        });
        if (grammarValid.length > 0) {
          const grammarID = grammarValid.map((obj) => obj.id);
          this.getIntent(grammarID);
        }
      }
    } catch (error) {}
  }
  getIntent(list) {
    this.isLoading = true;
    let data = new GetAllIntentValueQuery();
    data.grammarIdList = list;
    this.grammarClient.getIntentList(data).subscribe({
      next: (rs) => {
        this.isLoading = false;
        if (rs && rs.intents.length > 0) {
          this.intentList.push(...rs.intents);
          this.filteredNgModelOptions$ = of(this.intentList);
        }
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }
  handleButtonList() {
    try {
      let buttons = JSON.parse(this.buttonList);
      if (buttons.length > 0) {
        buttons.forEach((element) => {
          if (element?.name?.trim()) {
            let find = this.intentList.find((x) => x == element?.name?.trim());
            if (!find) {
              this.intentList.push(element?.name?.trim());
            }
          }
        });
      }
    } catch (error) {}
  }
  handleCards() {
    try {
      let cards = JSON.parse(this.cards);
      if (cards.length > 0) {
        cards.forEach((element) => {
          if (element?.buttons.length > 0) {
            element?.buttons.forEach((button) => {
              if (button.type == "text" && button?.value?.trim()) {
                let find = this.intentList.find(
                  (x) => x == button?.value?.trim()
                );
                if (!find) {
                  this.intentList.push(button?.value?.trim());
                }
              }
            });
          }
        });
      }
    } catch (error) {}
  }
  ngOnInit() {
    if (
      this.type == "GetInput" ||
      this.type == "ChatGPT" ||
      this.type == "IntentRecognition" ||
      this.type == "IntentRouting"
    ) {
      this.title = "Check Intent";
      if (this.type == "GetInput" && this.buttonList) {
        this.handleButtonList();
      }
      if (this.grammarArray && this.grammarArray != "") {
        this.handleGrammar();
      }
    }
    if (this.type == "LLM" || this.type == "Cards") {
      this.title = "Check Output";
    }
    if (this.type == "Cards" && this.cards) {
      this.handleCards();
    }
    this.data.method = "Success";
    this.listCondition = [
      "Equal",
      "Not equal",
      "Is less than",
      "Is less than or equal",
      "Is greater than",
      "Is greater than or equal",
      "Starts with",
      "Ends with",
      "Contains",
      "Empty",
      "Exist",
    ];
    if (
      !this.type ||
      this.type == "CheckAttribute" ||
      this.type == "CustomEvent" ||
      this.type == "IntentRouting"
    ) {
      this.data.method = "Equal";
    } else {
      this.listCondition.unshift("Success");
    }
    if (this.isUpdate && this.updateData) {
      if (this.updateData == "Success") {
        this.data.method = this.updateData;
        this.data.data = "";
      } else {
        const firstColonIndex = this.updateData.indexOf(":");
        const firstPart = this.updateData.substring(0, firstColonIndex);
        const secondPart = this.updateData.substring(firstColonIndex + 1);
        this.data.method = firstPart;
        this.data.data = secondPart;
      }
      this.previousValue = this.data;
    }
  }

  drop($event) {
    this.data.data += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  dismiss() {
    this.ref.close(null);
  }
  save() {
    this.data.data = this.data.data?.trim();
    if (this.data.method == "Success") {
      this.data.data = "";
    }
    this.ref.close(this.data);
  }
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.intentList.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }

  onModelChange(value: string) {
    this.filteredNgModelOptions$ = of(this.filter(value));
  }
}
