import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../../../../@core/utils/data.service";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import { CustomFunctionClient, GrammarClient } from "../../../../System-api";
import { CreateGrammarComponent } from "../../action/get-input/create-grammar/create-grammar.component";
import { CreateCustomFunctionComponent } from "../../action/custom-function/create-custom-function/create-custom-function.component";
import { UpdateFunctionComponent } from "../../../../portal/customer-role/studio/functions/update/update-function.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { LLMGrammarComponent } from "../llm-grammar/llm-grammar.component";

@Component({
  selector: "ngx-multiple-grammar",
  templateUrl: "./multiple-grammar.component.html",
  styleUrls: ["./multiple-grammar.component.scss"],
})
export class MultipleGrammarComponent implements OnInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @Input() grammarArray: any = [];
  @Input() grammarDataFilter: any = [];
  @Input() functionOutputParams = false;
  @Input() defaultParamsCode = false;
  @Input() isVAcomponent = false;
  @Input() icon: string = "plus-outline";
  @Input() type: string = "BuiltIn";
  @Input() isLoadingGrammar = false;
  @Input() showLabel = true;
  @Input() buttonText = "Add a grammar";
  @Input() showTitle = true;
  @Input() customGrammar = true;
  @Input() phraseListGrammar = true;
  @Input() builtInGrammar = true;
  @Input() functionGrammar = true;
  @Input() digitGrammar = true;
  @Input() LLMGrammar = true;
  @Input() onlyOne = false;
  @Input() sizeButton = "tiny";
  grammarsData: any = [];
  searchText: string = "";
  isLoading = false;
  timeout = null;
  constructor(
    public dataService: DataService,
    private dialogService: NbDialogService,
    private grammarClient: GrammarClient,
    private customFunctionClient: CustomFunctionClient,
    private toastrService: NbToastrService
  ) {}
  ngOnInit() {
    if (this.grammarArray.length > 0) {
      let checkData = this.grammarArray.find((x) => x.type != "BuiltIn");
      if (checkData) {
        this.refreshNewData();
      }
    }
  }
  refreshNewData() {
    this.isLoadingGrammar = true;
    this.grammarClient.getAllGrammar().subscribe({
      next: (rs) => {
        if (rs) {
          for (let index = 0; index < this.grammarArray.length; index++) {
            let checkExist = rs.find(
              (x) =>
                this.grammarArray[index].id == x.id &&
                this.grammarArray[index].type == x.type
            );
            if (checkExist) {
              this.grammarArray[index].name = checkExist.name;
            } else {
              if (this.grammarArray[index].type != "BuiltIn") {
                this.grammarArray.splice(index, 1);
                index = index - 1;
              }
            }
          }
        }
        this.isLoadingGrammar = false;
      },
      error: () => {
        this.isLoadingGrammar = false;
      },
    });
  }
  showLog() {
    if (this.icon == "plus-outline") {
      if (this.grammarsData.length <= 0) {
        this.getGrammarData();
      }
      this.icon = "close-outline";
      this.popover?.show();
    } else {
      this.icon = "plus-outline";
      this.popover?.hide();
    }
  }
  filterData(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let val = "";
      if (event != null) {
        val = event.target.value.toLowerCase();
      }
      this.grammarsData = this.grammarDataFilter.filter((x) =>
        x.name.toLowerCase().includes(val)
      );
    }, 200);
  }
  clear() {
    this.searchText = "";
    this.filterData(null);
  }
  closeModal() {
    this.icon = "plus-outline";
    this.popover?.hide();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.grammarArray, event.previousIndex, event.currentIndex);
  }
  getGrammarData(newName: string = null) {
    this.searchText = "";
    this.grammarsData = [];
    this.grammarDataFilter = [];
    switch (this.type) {
      case "Digit":
        this.getDigitGrammars(newName);
        break;
      case "Function":
        this.getFunctionGrammars(newName);
        break;
      case "Custom":
        this.getCustomGrammars(newName);
        break;
      case "PhraseList":
        this.getPhraseListGrammars(newName);
        break;
      case "LLM":
        this.getLLMGrammars(newName);
        break;
      case "BuiltIn":
        this.getBuildInGrammars();
        break;
      default:
        break;
    }
  }
  updateCurrentData(e: any, item: any) {
    e.stopPropagation();
    this.updateGrammar(e, item, item.type);
  }

  updateGrammar(e: any, grammar: any, type = "Default") {
    e.stopPropagation();
    if (type == "Default") {
      type = this.type;
    }
    switch (type) {
      case "Digit":
        this.grammarClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updateDigitGrammar(rs);
          }
        });
        break;
      case "Function":
        this.customFunctionClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updateFunctionGrammar(rs);
          }
        });
        break;
      case "Custom":
        this.grammarClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updateCustomGrammar(rs);
          }
        });
        break;
      case "PhraseList":
        this.grammarClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updatePhraseListGrammar(rs);
          }
        });
        break;
      case "LLM":
        this.grammarClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updateLLMGrammar(rs);
          }
        });
        break;
      default:
        break;
    }
  }
  updateFunctionGrammar(grammar: any) {
    const newGrammar = { ...grammar };
    delete newGrammar["data"];
    this.dataService.CustomFunction = newGrammar;
    const updateGrammar = this.dialogService.open(UpdateFunctionComponent, {
      autoFocus: false,
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        isAction: true,
        canAdd: false,
        secondParams: this.functionOutputParams,
        isVAcomponent: this.isVAcomponent,
      },
    });
    updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        updateGrammar.close();
        this.customFunctionClient.get(grammar.id).subscribe((rs) => {
          if (rs) {
            this.updateDataObject("Function", grammar, rs);
          }
        });
        this.getGrammarData();
      }
    });
  }
  selectTypeChange() {
    this.getGrammarData();
  }
  updateCustomGrammar(grammar: any) {
    const updateGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Grammar`,
        isUpdate: true,
        updateGrammar: grammar,
        translateGrammar: grammar.autoTranslate,
        engine: grammar.engine,
        engineType: grammar.engineType,
        typeGrammar: grammar.type,
      },
    });
    updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        updateGrammar.close();
        this.updateDataObject("Custom", grammar, rs);
        this.getGrammarData();
      }
    });
  }
  updatePhraseListGrammar(grammar: any) {
    const updateGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Phrase List`,
        isUpdate: true,
        updateGrammar: grammar,
        translateGrammar: grammar.autoTranslate,
        engine: grammar.engine,
        typeGrammar: grammar.type,
      },
    });
    updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        updateGrammar.close();
        this.updateDataObject("PhraseList", grammar, rs);
        this.getGrammarData();
      }
    });
  }
  updateLLMGrammar(grammar: any) {
    const updateGrammar = this.dialogService.open(LLMGrammarComponent, {
      autoFocus: false,
      context: {
        title: `LLM Grammar`,
        isUpdate: true,
        updateGrammar: grammar,
        translateGrammar: grammar.autoTranslate,
        engine: grammar.engine,
        engineType: grammar.engineType,
        typeGrammar: grammar.type,
      },
    });
    updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        updateGrammar.close();
        this.updateDataObject("LLM", grammar, rs);
        this.getGrammarData();
      }
    });
  }
  updateDigitGrammar(grammar: any) {
    const updateGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Grammar Pattern Digit`,
        isUpdate: true,
        updateGrammar: grammar,
        typeGrammar: grammar.type,
      },
    });
    updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        updateGrammar.close();
        this.updateDataObject("Digit", grammar, rs);
        this.getGrammarData();
      }
    });
  }
  updateDataObject(type, oldData, newData, idDelete = false) {
    let updateData = this.grammarArray.findIndex((item) => {
      return item.id == oldData.id && item.type == type;
    });
    if (updateData >= 0) {
      if (idDelete) {
        this.grammarArray.splice(updateData, 1);
      } else {
        Object.keys(this.grammarArray[updateData]).forEach((key) => {
          if (newData.hasOwnProperty(key)) {
            this.grammarArray[updateData][key] = newData[key];
          }
        });
      }
    }
  }
  removeGrammar(e: any, grammar: any) {
    e.stopPropagation();
    switch (this.type) {
      case "Digit":
        this.removeDigitGrammar(grammar, "Digit");
        break;
      case "Function":
        this.removeFunctionGrammar(grammar);
        break;
      case "Custom":
        this.removeDigitGrammar(grammar, "Custom");
        break;
      case "LLM":
        this.removeDigitGrammar(grammar, "LLM");
        break;
      case "PhraseList":
        this.removeDigitGrammar(grammar, "PhraseList");
        break;
      default:
        break;
    }
  }
  removeDigitGrammar(grammar: any, type: string) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "Grammar '" + grammar.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.grammarClient.delete(grammar.id).subscribe(
            (rs) => {
              this.showToast(rs);
              if (rs) {
                this.updateDataObject(type, grammar, null, true);
                this.getGrammarData();
              }
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
            }
          );
        }
      });
  }
  removeFunctionGrammar(grammar: any) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "Grammar '" + grammar.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.customFunctionClient.delete(grammar.id).subscribe(
            (rs) => {
              this.showToast(rs);
              this.isLoading = false;
              if (rs) {
                this.updateDataObject("Function", grammar, null, true);
                this.getGrammarData();
              }
            },
            (error) => {
              this.isLoading = false;
            }
          );
        }
      });
  }
  addGrammar() {
    switch (this.type) {
      case "Digit":
        this.addDigitGrammar();
        break;
      case "Function":
        this.addFunctionGrammar();
        break;
      case "Custom":
        this.addCustomGrammars();
        break;
      case "PhraseList":
        this.addPhraseListGrammars();
        break;
      case "LLM":
        this.addLLMGrammars();
        break;
      default:
        break;
    }
  }
  addDigitGrammar() {
    const createGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Grammar Pattern Digit`,
        typeGrammar: this.type,
      },
    });
    createGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        createGrammar.close();
        this.getGrammarData();
      }
    });
  }
  addFunctionGrammar() {
    const createFunctionGrammar = this.dialogService.open(
      CreateCustomFunctionComponent,
      {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        autoFocus: false,
        context: {
          title: `Create Function`,
          canAdd: false,
          secondParams: this.functionOutputParams,
          defaultParamsCode: this.defaultParamsCode,
          isVAcomponent: this.isVAcomponent,
        },
      }
    );
    createFunctionGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs?.function) {
        createFunctionGrammar.close();
        this.getGrammarData();
      }
    });
  }
  addCustomGrammars() {
    const createGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Grammar`,
        typeGrammar: this.type,
        translateGrammar: false,
        engine: false,
      },
    });
    createGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        createGrammar.close();
        this.getGrammarData();
      }
    });
  }
  addPhraseListGrammars() {
    const createGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Phrase List`,
        typeGrammar: this.type,
        translateGrammar: true,
        engine: true,
      },
    });
    createGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        createGrammar.close();
        this.getGrammarData();
      }
    });
  }
  addLLMGrammars() {
    const createGrammar = this.dialogService.open(LLMGrammarComponent, {
      autoFocus: false,
      context: {
        title: `LLM Grammar`,
        typeGrammar: this.type,
        translateGrammar: true,
        engine: true,
      },
    });
    createGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        createGrammar.close();
        this.getGrammarData();
      }
    });
  }
  addToObject(item) {
    let dataExist = this.grammarArray.find((grammar) => {
      return grammar.id == item.id && grammar.type == this.type;
    });
    if (!dataExist) {
      let dataSubmit = {
        id: item.id,
        name: item.name,
        type: item.type,
      };
      this.grammarArray.push(dataSubmit);
    }
  }
  deleteGrammarInList(i) {
    this.grammarArray.splice(i, 1);
  }
  getDigitGrammars(newName: string = null) {
    this.isLoading = true;
    this.grammarClient.getByType(this.type).subscribe({
      next: (results) => {
        this.isLoading = false;
        if (results != null) {
          if (this.grammarsData == null) {
            this.grammarsData = [];
            this.grammarDataFilter = this.grammarsData;
          }
          results.grammarDtos.forEach((value) => {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: value.name,
                type: "Digit",
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          });
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  getFunctionGrammars(newName: string = null) {
    this.isLoading = true;
    this.customFunctionClient.getAll(0, 500, "Id", false, null).subscribe(
      (result) => {
        this.isLoading = false;
        if (result != null) {
          if (this.grammarsData == null) {
            this.grammarsData = [];
            this.grammarDataFilter = this.grammarsData;
          }
          result.functions.forEach((value) => {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: value.name,
                type: "Function",
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          });
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  getBuildInGrammars() {
    this.isLoading = true;
    this.customFunctionClient.getAllFunctionBuildIn().subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result != null) {
          if (this.grammarsData == null) {
            this.grammarsData = [];
            this.grammarDataFilter = this.grammarsData;
          }
          result.functions.forEach(function (value) {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: this.matchBuildinName(value.name),
                type: "BuiltIn",
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          }, this);
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  getCustomGrammars(newName: string = null) {
    this.isLoading = true;
    this.grammarClient.getByType(this.type).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result != null) {
          result.grammarDtos.forEach((value) => {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: value.name,
                type: "Custom",
                status: value.status,
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          });
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  getPhraseListGrammars(newName: string = null) {
    this.isLoading = true;
    this.grammarClient.getByType(this.type).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result != null) {
          result.grammarDtos.forEach((value) => {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: value.name,
                type: "PhraseList",
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          });
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  getLLMGrammars(newName: string = null) {
    this.isLoading = true;
    this.grammarClient.getByType(this.type).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result != null) {
          result.grammarDtos.forEach((value) => {
            this.grammarsData = [
              ...this.grammarsData,
              {
                id: value.id,
                name: value.name,
                type: "LLM",
              },
            ];
            this.grammarDataFilter = this.grammarsData;
          });
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  matchBuildinName(name) {
    switch (name) {
      case "biYesno": {
        return "Yes or No";
      }
      case "biDate": {
        return "Date (mm-dd-yyyy)";
      }
      case "biPhonenumber": {
        return "Phone number";
      }
      case "biDigit": {
        return "Number";
      }
      case "biEmail": {
        return "Email";
      }
      default: {
        return name;
      }
    }
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Delete grammar successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Delete grammar unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
