import { CustomFunctionClient } from "./../../../../System-api";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { CustomFunction } from "../../../../@core/model/custom-function";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { DataService } from "../../../../@core/utils/data.service";
import { UpdateFunctionComponent } from "../../../../portal/customer-role/studio/functions/update/update-function.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import {
  CustomFunctionDto,
  CustomFunctionLookupDto,
} from "../../../../System-api";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { CreateCustomFunctionComponent } from "./create-custom-function/create-custom-function.component";
import { Observable, map, of } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-custom-function",
  templateUrl: "./custom-function.component.html",
  styleUrls: ["./custom-function.component.scss"],
})
export class CustomFunctionComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  listFunction: CustomFunctionLookupDto[];
  selectedFunction: CustomFunctionDto = new CustomFunctionDto();
  data: CustomFunction = new CustomFunction();
  functionName = "";
  @ViewChild("autoInput") input;
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<string[]>;
  attributeTemp = "";
  isLoading = false;
  @ViewChild("param") param;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  constructor(
    private customFuctionClient: CustomFunctionClient,
    private windowRef: NbWindowRef,
    public dataService: DataService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      function: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit(): void {
    this.filteredOptions$ = of(this.attributes);
    this.getListFunction();
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  getListFunction() {
    this.isLoading = true;
    if (this.data.functionPath == "") {
      this.customFuctionClient
        .getAll(0, 1000, "id", false, null)
        .subscribe((rs) => {
          if (rs) {
            this.listFunction = rs.functions;
            for (let [key, value] of Object.entries(this.data)) {
              this.data[key] =
                this.cell.getAttribute(key) != "undefined"
                  ? this.cell.getAttribute(key)
                  : "";
            }
            if (this.data.functionId) {
              let dataExist = this.listFunction?.some(
                (item) => item.id == this.data.functionId
              );
              if (dataExist) {
                this.data.functionId =
                  Number(this.data.functionId) == 0 ||
                  isNaN(this.data.functionId)
                    ? null
                    : Number(this.data.functionId);
              } else {
                this.data.functionId = null;
              }
            } else {
              this.data.functionId = null;
            }
            if (this.data.params != "" || this.data.functionPath != "") {
              this.selectedFunction.param = this.data.params;
              this.selectedFunction.id = +this.data.functionId;
              if (this.data.functionPath) {
                const nameAndId = this.data.functionPath.split("/");
                this.selectedFunction.companyId = +nameAndId[0];
                this.selectedFunction.name = nameAndId[1];
              }
            }
            this.isLoading = false;
          }
        });
    }
  }

  selectFunction(value, type = null) {
    if (value) {
      this.isLoading = true;
      this.customFuctionClient.get(value.id).subscribe((rs) => {
        if (rs) {
          if (!this.selectedFunction) {
            this.selectedFunction = new CustomFunctionDto();
          }
          this.selectedFunction.companyId = rs.companyId;
          this.selectedFunction.name = rs.name;
          this.selectedFunction.code = rs.code;
          this.selectedFunction.language = rs.language;
          if (type == "Update" && value.id == this.data.functionId) {
            if (rs?.param) {
              let newParam = rs.param.split("|");
              let resultArray = newParam.map((item) => ({
                name: item,
                value: "",
              }));
              try {
                const currentParam = JSON.parse(this.param.getData());
                if (currentParam && currentParam.length > 0) {
                  currentParam?.forEach((param) => {
                    const foundObject = resultArray.find(
                      (obj) => obj.name == param.name
                    );
                    if (foundObject) {
                      const foundObjectIndex = resultArray.indexOf(foundObject);
                      resultArray[foundObjectIndex].value = param.value;
                    }
                  });
                }
                this.selectedFunction.param = JSON.stringify(resultArray);
              } catch (error) {
                this.selectedFunction.param = JSON.stringify(resultArray);
              }
            } else {
              this.selectedFunction.param = rs.param;
            }
          } else {
            if (rs?.param) {
              let newParam = rs.param.split("|");
              let resultArray = newParam.map((item) => ({
                name: item,
                value: "",
              }));
              this.selectedFunction.param = JSON.stringify(resultArray);
            } else {
              this.selectedFunction.param = rs.param;
            }
          }
          this.data.functionId = rs.id;
          this.data.language = rs.language;
          this.isLoading = false;
        }
      });
    } else {
      this.selectedFunction = null;
    }
  }
  handleDeleteFunction(e: any, item) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "Function " + item.name + " will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.customFuctionClient.delete(item.id).subscribe((rs) => {
            this.showToast(rs);
            this.isLoading = false;
            if (rs) {
              this.deleteItemInArray(this.listFunction, item.id);
              this.listFunction = [...this.listFunction];
            }
          });
        }
      });
  }
  deleteItemInArray(array, idIntent) {
    const indexToDelete = array.findIndex((obj) => obj.id == idIntent);
    if (indexToDelete != 0 && indexToDelete !== -1) {
      array.splice(indexToDelete, 1);
    } else {
      array.shift();
    }
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Delete function successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(
        "Delete function unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
  handleEditFunction(e: any, id) {
    e.stopPropagation();
    this.isLoading = true;
    this.customFuctionClient.get(id).subscribe((rs: CustomFunctionDto) => {
      this.isLoading = false;
      if (rs) {
        delete rs["data"];
        this.dataService.CustomFunction = rs;
        const updateFunction = this.dialogService.open(
          UpdateFunctionComponent,
          {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            autoFocus: false,
            context: { isAction: true, notHidden: true },
          }
        );
        updateFunction.componentRef.instance.onAdd.subscribe((rs) => {
          if (rs) {
            updateFunction.close();
            this.customFuctionClient
              .getAll(0, 1000, "id", false, null)
              .subscribe((rs) => {
                if (rs) {
                  this.listFunction = [...rs.functions];
                  this.isLoading = false;
                }
                if (id == this.data?.functionId) {
                  this.selectFunction({ id: id }, "Update");
                }
              });
          }
        });
      }
    });
  }
  submit() {
    if (!this.cancelAction && !this.isLoading) {
      if (this.selectedFunction?.name) {
        this.data.functionPath =
          this.selectedFunction.companyId.toString() +
          "/" +
          this.selectedFunction.name;
      } else {
        this.data.functionId = 0;
        this.data.functionPath = "";
        this.data.attribute = "";
        this.data.params = "";
      }
      if (this.data.functionId == null) this.data.functionId = 0;
      if (!this.selectedFunction?.param) {
        this.data.params = "";
      }
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      const customFuncCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.functionId];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        customFuncCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  getParams($event) {
    this.data.params = $event;
  }

  createNewFunction() {
    const createFunction = this.dialogService.open(
      CreateCustomFunctionComponent,
      {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        autoFocus: false,
        context: {
          title: `Create Function`,
          defaultParams: false,
        },
      }
    );
    createFunction.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs?.function) {
        createFunction.close();
        this.addNewFunctionToList(rs?.function);
      }
    });
  }
  addNewFunctionToList(data: any) {
    this.selectedFunction = { ...data };
    this.data.functionId = data.id;
    this.data.language = data.language;
    delete data["companyId"];
    this.listFunction = [data, ...this.listFunction];
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
}
