import {
  Component,
  OnInit,
  Directive,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../dialog-attribute/dialog-attribute.component";
import { SettingCallFlowsComponent } from "../setting-callflows/setting-callflows.component";

@Component({
  selector: "app-ivr-attribute",
  templateUrl: "./ivr-attribute.component.html",
  styleUrls: ["./ivr-attribute.component.scss"],
})
export class IvrAttributeComponent implements OnInit {
  @Input() isReadOnly: any = false;
  @Input() isAllowDesign: any = false;
  selectedItem: any;
  variableList: any;
  searchText: string = "";

  constructor(
    public dataService: DataService,
    public dialogService: NbDialogService
  ) {}
  routerVariable() {
    window.open("https://docs.primas.net/display/CAL/Variable");
  }
  ngOnInit() {
    this.dataService.ivrAttribute = [
      { default: 1, value: "SESSION" },
      { default: 1, value: "UNIQUE_ID" },
      { default: 1, value: "CALLER_NUMBER" },
      { default: 1, value: "DIAL_NUMBER" },
      { default: 1, value: "SENDER" },
      { default: 1, value: "RECEIVER" },
      { default: 1, value: "CHANNEL_BOT" }, //IVR OR CHAT
      { default: 1, value: "LANGUAGE" },
      { default: 1, value: "SIP_HEADERS" },
      { default: 1, value: "DURATION" },
      { default: 1, value: "ERROR_CODE" },
      { default: 1, value: "ERROR_MESSAGE" },
      { default: 1, value: "CURRENT_ACTION_NAME" },
      { default: 1, value: "MAX_NO_INPUT" },
      { default: 1, value: "MAX_NOT_MATCH" },
      { default: 1, value: "CONVERSATION" },
      { default: 1, value: "CALL_FLOW_ID" },
      { default: 1, value: "DIAL_STATUS" },
      { default: 1, value: "TRANSFER_STATUS" },
      { default: 1, value: "QUEUE_STATUS" },
      { default: 1, value: "FIRST_NAME" },
      { default: 1, value: "LAST_NAME" },
      { default: 1, value: "NAME" },
      { default: 1, value: "PROFILE_PIC" },
      { default: 1, value: "USER_INPUT" },
      { default: 1, value: "BOT_RESPONSE" },
      ...(!this.dataService.ivrAttribute ? [] : this.dataService.ivrAttribute),
    ];
    this.dataService.ivrAttribute = this.removeDuplicateValues(
      this.dataService.ivrAttribute
    );

    this.dataService.ivrAttributeArray = [
      "SESSION",
      "UNIQUE_ID",
      "CALLER_NUMBER",
      "DIAL_NUMBER",
      "SENDER",
      "RECEIVER",
      "CHANNEL_BOT",
      "LANGUAGE",
      "SIP_HEADERS",
      "DURATION",
      "ERROR_CODE",
      "ERROR_MESSAGE",
      "CURRENT_ACTION_NAME",
      "MAX_NO_INPUT",
      "MAX_NOT_MATCH",
      "CONVERSATION",
      "CALL_FLOW_ID",
      "DIAL_STATUS",
      "TRANSFER_STATUS",
      "QUEUE_STATUS",
      "FIRST_NAME",
      "LAST_NAME",
      "NAME",
      "PROFILE_PIC",
      "USER_INPUT",
      "BOT_RESPONSE",
    ];

    this.variableList = this.dataService.ivrAttribute;
  }

  removeDuplicateValues(ivrAttribute) {
    const uniqueValues = [];

    for (const item of ivrAttribute) {
      if (!uniqueValues.some((u) => u.value === item.value)) {
        if (item.default == 1 && item.value == "UNIQUEID") {
        } else {
          uniqueValues.push(item);
        }
      }
    }

    return uniqueValues;
  }

  dragStart($event) {
    $event.dataTransfer.setData("text", $event.toElement.textContent);
  }

  filterVariable() {
    this.variableList = this.dataService.ivrAttribute.filter((attribute) => {
      return (
        attribute.value
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        attribute.content?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  addAttribute() {
    this.selectedItem = null;
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.filterVariable();
        }
      });
  }

  editAttribute(selectedItem) {
    if (selectedItem.default == 0) {
      let index = this.dataService.ivrAttribute.indexOf(selectedItem);

      this.dialogService
        .open(DialogAttributeComponent, {
          autoFocus: false,
          context: {
            index: index,
            value: selectedItem?.value,
            content: selectedItem?.content || "",
            currentValue: selectedItem?.value || "",
          },
        })
        .onClose.subscribe((rs) => {
          this.filterVariable();
        });
    } else if (this.selectedItem.default == 2) {
      this.dialogService
        .open(SettingCallFlowsComponent, {
          closeOnBackdropClick: false,
          autoFocus: false,
          context: {
            updateVariable: true,
            updateVariableName: selectedItem?.value,
            updateCurrentVariableName: selectedItem?.value,
            updateVariableValue: selectedItem?.content,
            callSetting: { ...this.dataService.CallSetting },
            isReadOnly: this.isReadOnly,
          },
        })
        .onClose.subscribe((rs) => {
          this.filterVariable();
        });
    }
  }

  deleteAttribute() {
    if (this.selectedItem?.default == 0) {
      let index = this.dataService.ivrAttribute.indexOf(this.selectedItem);

      this.dataService.ivrAttribute.splice(index, 1);
      this.selectedItem = null;
      this.filterVariable();
    }
  }

  clickedRow(item) {
    this.selectedItem = item;
  }
}
