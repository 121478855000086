<audio (playing)="audioPlaying()" (ended)="audioEnded()" #mainAudio>
  Your browser does not support the audio element.
</audio>
<div class="row">
  <div class="col-md-12">
    <div
      class="header-page"
      style="margin-bottom: 1rem"
      *ngIf="!isCallFlowAction"
    >
      <span class="title-page">Audios & Text To Speech Management</span>
      <form class="d-flex flex-row justify-content-between">
        <nb-form-field>
          <input
            #searchInput
            type="text"
            name="search"
            nbInput
            fieldSize="tiny"
            status="basic"
            (keyup)="filterDatatable($event)"
            placeholder="Search"
          />
        </nb-form-field>
        <button
          *ngIf="showAddButton"
          type="button"
          (click)="showModifyCreate()"
          status="primary"
          nbButton
          size="tiny"
          style="margin-left: 0.5rem"
          class="btn-create"
        >
          <nb-icon icon="plus-outline"></nb-icon>Create
        </button>
      </form>
    </div>

    <div
      *ngIf="audios?.length > 0"
      class="audio-container"
      [ngClass]="{ 'is-call-flow': isCallFlowAction }"
    >
      <nb-accordion
        cdkDropList
        class="audio-list"
        (cdkDropListDropped)="drop($event)"
      >
        <nb-accordion-item
          #audioItem
          class="audio-box"
          *ngFor="let audio of audios"
          [ngClass]="{ 'is-call-flow-item': isCallFlowAction }"
          style="cursor: pointer"
          (click)="clickSelect(audio)"
          cdkDrag
          [cdkDragDisabled]="isCallFlowAction ? audioItem.expanded : true"
          [expanded]="expandedCurrentAudio(audio?.id)"
          [expanded]="audioSelectedToUpdate === audio?.id"
        >
          <nb-accordion-item-header
            [ngClass]="{ 'is-call-flow-name': isCallFlowAction }"
            [ngClass]="{ 'cursor-pointer': !isCallFlowAction }"
            >{{ audio?.name }}
            <nb-badge
              *ngIf="idNew == audio?.id"
              status="danger"
              style="top: unset; margin-right: 75px"
              text="new"
            ></nb-badge>
            <button
              ghost
              nbButton
              status="primary"
              class="icon-action"
              (click)="clickSelect(audio)"
              (click)="showModify($event)"
              size="small"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
            <button
              ghost
              type="button"
              size="small"
              status="primary"
              nbButton
              class="icon-action"
              (click)="clickSelect(audio)"
              (click)="deleteAudio($event, audio)"
            >
              <nb-icon icon="trash-2-outline"></nb-icon>
            </button>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-list>
              <nb-list-item
                *ngFor="
                  let audioLanguage of audio?.audioLanguages;
                  let i = index
                "
                style="cursor: pointer; border: 0"
                [attr.data-index]="i"
                (click)="changeAudioSource(audioLanguage)"
              >
                <div
                  class="flex"
                  [ngClass]="{ 'is-call-flow-flex': isCallFlowAction }"
                >
                  <div class="flex-audio">
                    <!-- <span class="audio-name-index">{{i + 1}}</span> -->
                    <div>
                      <button
                        class="float-end"
                        size="small"
                        status="primary"
                        nbButton
                        ghost
                      >
                        <nb-icon
                          [icon]="
                            audioLanguage.id == currentAudio.id && isPlaying
                              ? 'stop-circle'
                              : 'play-circle'
                          "
                        ></nb-icon>
                      </button>
                    </div>
                    <div class="audio-name">
                      <span>{{ audioLanguage?.languageName }}</span>
                      <span
                        *ngIf="!audioLanguage?.isUpload"
                        class="audio-name-voice"
                        >{{ audioLanguage?.service }} |{{
                          audioLanguage?.voiceId
                        }}
                        | {{ audioLanguage?.gender }}</span
                      >
                      <span
                        *ngIf="audioLanguage?.isUpload"
                        class="audio-name-voice"
                        >File upload</span
                      >
                    </div>
                  </div>
                  <span class="content">
                    <b>Content:</b> {{ audioLanguage?.content }}
                  </span>
                  <nb-icon
                    icon="edit-2-outline"
                    style="color: #3367d6; cursor: pointer; width: 1rem"
                    (click)="handleUpdateAudio($event, audioLanguage)"
                  ></nb-icon>
                </div>
              </nb-list-item>
            </nb-list>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
  </div>
</div>
