import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { ChatGPTClient, MakeTestModelLLMCommand } from "../../../../System-api";

@Component({
  selector: "optimize-prompt",
  templateUrl: "./optimize-prompt.component.html",
  styleUrls: ["./optimize-prompt.component.scss"],
})
export class OptimizePromptComponent implements OnInit, OnDestroy {
  @ViewChild("inputString", { static: false })
  inputString: ElementRef<HTMLElement>;
  name = "Prompt Optimization";
  data = "";
  model = "gpt";
  temperature = 0.3;
  isLoading = false;
  prompt = "";
  promptOptimize = "";
  constructor(
    public dataService: DataService,
    protected ref: NbDialogRef<OptimizePromptComponent>,
    public dialogService: NbDialogService,
    private chatGPTClient: ChatGPTClient
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {
    this.makeTest();
  }
  makeTest() {
    if (this.data && this.data.trim() != "") {
      let data = new MakeTestModelLLMCommand();
      data.input = this.data;
      data.prompt = this.prompt;
      data.model = this.model;
      data.type = "optimize";
      data.temperature = 0.3;
      this.isLoading = true;
      this.chatGPTClient.testLLM(data).subscribe(
        (rs) => {
          this.isLoading = false;
          this.promptOptimize = rs;
        },
        (error) => {
          this.promptOptimize = "";
          this.isLoading = false;
        }
      );
    }
  }
  dismiss() {
    this.ref.close({ status: "cancel" });
  }
  cancel() {
    this.ref.close({ status: "cancel" });
  }
  save() {
    this.ref.close({ status: "save", data: this.promptOptimize });
  }
  retry() {
    this.makeTest();
  }
}
