<form
  class="h-100"
  [formGroup]="dialogAttributeForm"
  novalidate
  (submit)="updateAttribute()"
>
  <nb-card style="margin-bottom: 0; width: 500px">
    <nb-card-header style="padding: 0.5rem 1.5rem; padding-right: 0.7rem">
      <div class="d-flex justify-content-between" style="align-items: center">
        <div class="title-page-wrapper">
          <span>Variable</span
          ><nb-icon
            nbTooltip="You can store key-value pairs as variables. And you can using variable in your flow. Example: &#123;name_variable&#125;."
            nbTooltipPlacement="right"
            nbTooltipTrigger="hover"
            nbTooltipStatus="basic"
            (click)="routerVariable()"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <div>
          <button
            (click)="dismiss()"
            type="button"
            type="button"
            ghost
            size="medium"
            nbButton
          >
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div></div
    ></nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <!-- NOTE: Error checking -->
        <label for="name" class="form-label label required"> Name </label>
        <input
          id="name"
          [(ngModel)]="value"
          type="text"
          nbInput
          fullWidth
          (ngModelChange)="checkInvalidName($event)"
          formControlName="name"
          placeholder="Name"
          id="variable-name"
          [status]="invalidName ? 'danger' : 'basic'"
          (keyup.enter)="updateAttribute()"
          [status]="
            dialogAttributeForm.controls['name'].invalid &&
            (dialogAttributeForm.controls['name'].dirty ||
              dialogAttributeForm.controls['name'].touched)
              ? 'danger'
              : 'basic'
          "
        />
        <ng-container
          *ngIf="
            dialogAttributeForm.controls['name'].invalid &&
            (dialogAttributeForm.controls['name'].dirty ||
              dialogAttributeForm.controls['name'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              dialogAttributeForm.controls['name'].errors.required ||
              dialogAttributeForm.controls['name'].hasError('whitespace')
            "
          >
            Name is required.
          </p>
          <p
            class="caption status-danger d-flex align-items-center"
            *ngIf="
              dialogAttributeForm.controls['name'].hasError('variable') &&
              !dialogAttributeForm.controls['name'].errors.required &&
              !dialogAttributeForm.controls['name'].hasError('whitespace')
            "
          >
            <span>Name is not in the correct format.</span>
            <nb-icon
              class="label tooltipsize"
              nbTooltipClass="multiline-tooltip"
              nbTooltip="- A variable name must start with a letter or _. &#13;&#10; - A variable name cannot start with a number. &#13;&#10; - A variable name can only contain alpha-numeric characters and underscores (A-z, 0-9, and _ )."
              nbTooltipPlacement="end"
              nbTooltipStatus="basic"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              dialogAttributeForm.controls['name'].hasError('duplicateName')
            "
          >
            Name already exists.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              dialogAttributeForm.controls['name'].errors.maxLength &&
              !dialogAttributeForm.controls['name'].hasError('variable')
            "
          >
            Maximum length is 50 characters.
          </p>
        </ng-container>
      </div>
      <div class="mb-2">
        <label for="content" class="form-label label">Default Value</label>
        <textarea
          id="content"
          style="max-height: 40vh; min-height: 39.6px"
          type="text"
          [(ngModel)]="content"
          nbInput
          fullWidth
          id="text-value-attribute"
          formControlName="defaultValue"
          placeholder="Default Value"
          (input)="onChangeContent()"
          (keyup.enter)="updateAttribute()"
        ></textarea>
      </div>
      <div class="float-end mt-2">
        <button
          type="submit"
          [disabled]="!value.trim() || dialogAttributeForm.invalid"
          class="me-2"
          status="primary"
          size="small"
          nbButton
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</form>
