<form
  [formGroup]="functionForm"
  novalidate
  (submit)="onSubmit()"
  style="height: 100%"
>
  <nb-card [ngClass]="{ 'card-action': isAction }" style="height: 100%">
    <nb-card-header>
      <div
        *ngIf="!isAction"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <span *ngIf="isCreate == true">Create Function</span>
          <span *ngIf="isCreate == false">Update Function</span>
        </div>
        <div>
          <button
            [nbSpinner]="isLoadingExport"
            nbSpinnerSize="small"
            nbSpinnerStatus="primary"
            type="button"
            class="ms-2"
            [disabled]="isLoadingExport || isCreate"
            (click)="exportFunction()"
            nbButton
            status="primary"
            size="small"
          >
            <nb-icon icon="log-out-outline"></nb-icon>Export
          </button>
          <button
            type="button"
            [disabled]="isCreate || isLoadingRun"
            [nbSpinner]="isLoadingRun"
            (click)="run()"
            status="primary"
            class="ms-2"
            size="small"
            nbButton
          >
            <nb-icon icon="play-circle-outline"></nb-icon>Run Code
          </button>
          <button
            type="submit"
            [disabled]="
              !functionForm.valid ||
              isExist ||
              isLoading ||
              errorForm ||
              isSaveLoading
            "
            [nbSpinner]="isSaveLoading"
            nbSpinnerSize="small"
            nbSpinnerStatus="primary"
            status="primary"
            class="ms-2"
            size="small"
            nbButton
          >
            <nb-icon icon="save-outline"></nb-icon>Save
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between" *ngIf="isAction">
        Update Function
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row function-wrapper">
        <div class="col-md-3 function-name">
          <div class="mb-2">
            <label for="input-name" class="form-label label required"
              >Function Name</label
            >
            <div>
              <input
                #inputName
                (ngModelChange)="checkFunctionName($event)"
                type="text"
                nbInput
                formControlName="name"
                fieldSize="small"
                [attr.disabled]="
                  isFunctionLoading == false ? null : isFunctionLoading
                "
                [(ngModel)]="customFunction.name"
                [status]="
                  functionForm.controls['name'].invalid &&
                  (functionForm.controls['name'].dirty ||
                    functionForm.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                "
                fullWidth
                id="input-name"
                placeholder="Function Name"
              />
            </div>
          </div>
          <ng-container
            *ngIf="
              functionForm.controls['name'].invalid &&
              (functionForm.controls['name'].dirty ||
                functionForm.controls['name'].touched)
            "
          >
            <p
              class="caption status-danger"
              *ngIf="functionForm.controls['name'].errors.required"
            >
              Function Name is required.
            </p>
            <p
              class="caption status-danger"
              *ngIf="
                !functionForm.controls['name'].errors.required &&
                functionForm.controls['name'].errors.functionInvalid
              "
            >
              Function Name must start with a letter or underscore, and can
              contain only letters, numbers, and underscores.
            </p>
            <p
              class="caption status-danger"
              *ngIf="
                !functionForm.controls['name'].errors.isExist &&
                !functionForm.controls['name'].errors.functionInvalid &&
                functionForm.controls.name['errorMessages'][0]
              "
            >
              {{ functionForm.controls.name["errorMessages"][0] }}
            </p>
            <p
              class="caption status-danger"
              *ngIf="functionForm.controls['name'].errors.serverError"
            >
              {{ functionForm.controls["name"].errors.serverError }}
            </p>
          </ng-container>
          <ngx-input-param
            (paramEmit)="getParam($event)"
            (errorEmit)="getError($event)"
            [paramInput]="customFunction?.param"
            [canAdd]="canAdd"
            [notHidden]="notHidden"
            [isVAcomponent]="isVAcomponent"
            [secondParams]="secondParams"
            paramName="Param Name"
          >
          </ngx-input-param>
          <div
            *ngIf="!canAdd && !secondParams && isVAcomponent"
            style="margin-top: 0.5rem; font-size: 13px; color: #7f8797"
          >
            Note: Send request to the virtual agent using this function's return
            value. Returns the string message if you want send a message to the
            user.
          </div>
          <div
            *ngIf="secondParams"
            style="margin-top: 0.5rem; font-size: 13px; color: #7f8797"
          >
            Note: The virtual agent will respond to the user with this
            function's return value.
          </div>
          <div
            *ngIf="(!canAdd && !secondParams && isVAcomponent) || secondParams"
            style="margin-top: 0.5rem; font-size: 13px; color: #7f8797"
          >
            If you want to return message and include buttons. Please return the
            format below.
          </div>
          <div
            *ngIf="(!canAdd && !secondParams && isVAcomponent) || secondParams"
            style="margin-top: 0.5rem; font-size: 13px; color: #7f8797"
            class="default-return"
          >
            <pre
              style="margin-bottom: 0"
              [innerHTML]="defaultReturn | prettyjson"
            ></pre>
          </div>
        </div>
        <div class="col-md-9 function-code">
          <div class="label-wrapper">
            <label class="form-label label required">Code</label>
            <div class="mb-1">
              <button
                *ngIf="isAction"
                type="button"
                (click)="run()"
                status="primary"
                class="me-2"
                size="tiny"
                [disabled]="isLoadingRun"
                [nbSpinner]="isLoadingRun"
                nbButton
              >
                <nb-icon icon="play-circle-outline"></nb-icon>Run
              </button>
              <nb-select
                class="me-2"
                size="tiny"
                formControlName="language"
                [(ngModel)]="customFunction.language"
                (selectedChange)="selectLanguage($event)"
              >
                <nb-option *ngFor="let item of languages" [value]="item.key">{{
                  item.language
                }}</nb-option>
              </nb-select>
              <button type="button" nbButton size="tiny" (click)="fullScreen()">
                <nb-icon
                  [icon]="expand ? 'collapse-outline' : 'expand-outline'"
                ></nb-icon>
              </button>
            </div>
          </div>
          <div
            [nbSpinner]="isCodeLoading"
            nbSpinnerSize="medium"
            nbSpinnerStatus="info"
            class="editor-container"
            [ngClass]="{ 'editor-container-action': isAction }"
          >
            <div
              class="app-ace-editor"
              #editor
              style="
                width: 100%;
                height: 100%;
                font-size: 14px;
                border: 1px solid lightgray;
              "
            ></div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="isAction">
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          [disabled]="
            !functionForm.valid ||
            isExist ||
            isFunctionLoading ||
            errorForm ||
            isLoading ||
            isSaveLoading
          "
          status="primary"
          [nbSpinner]="isSaveLoading"
          class="ms-2"
          size="small"
          nbButton
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
