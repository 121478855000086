<div class="action-config-wrapper">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Setting</ng-template>
        <div>
          <div class="mb-2">
            <label class="form-label label">Way</label>
            <nb-select
              fullWidth
              placeholder="Select Way"
              [(ngModel)]="data.type"
              size="small"
            >
              <nb-option value="0">Say Digit</nb-option>
              <nb-option value="1">Say Number</nb-option>
            </nb-select>
          </div>
          <div class="mb-2">
            <label class="form-label label">Reader</label>
            <nb-select
              fullWidth
              placeholder="Select Reader"
              [(ngModel)]="data.reader"
              size="small"
            >
              <nb-option value="0">Female</nb-option>
              <nb-option value="1">Male</nb-option>
            </nb-select>
          </div>
          <div class="mb-2">
            <label for="name" class="form-label label required">Number</label>
            <form [formGroup]="formGroup">
              <input
                id="name"
                formControlName="number"
                (drop)="drop($event)"
                type="text"
                [(ngModel)]="data.number"
                nbInput
                fullWidth
                placeholder="Number"
                fieldSize="small"
                [status]="
                  formGroup.controls['number'].invalid &&
                  (formGroup.controls['number'].dirty ||
                    formGroup.controls['number'].touched)
                    ? 'danger'
                    : 'basic'
                "
              />
              <ng-container
                *ngIf="
                  formGroup.controls['number'].invalid &&
                  (formGroup.controls['number'].dirty ||
                    formGroup.controls['number'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    formGroup.controls['number'].hasError('required') ||
                    formGroup.controls['number'].hasError('whitespace')
                  "
                >
                  Number is required.
                </p>
              </ng-container>
            </form>
          </div>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <nb-badge
          text="The selected audio will play after saying number."
          status="primary"
        ></nb-badge>
        <ng-template #labelTwo>Say Somethings</ng-template>
        <nb-card>
          <nb-card-header class="audio-list-style">
            <span>Audio List</span>
            <nb-icon
              class="ms-2"
              style="color: #929bb3; width: 15px"
              nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></nb-card-header>
          <nb-card-body>
            <simple-audio-player
              #audioPlayer
              [events]="eventsSubject.asObservable()"
            ></simple-audio-player>
            <button
              nbButton
              size="small"
              status="primary"
              (click)="showModify()"
            >
              <nb-icon icon="plus-outline"></nb-icon> Add
            </button>
          </nb-card-body>
        </nb-card>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
