import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { OptimizePromptComponent } from "../optimize-prompt/optimize-prompt.component";

@Component({
  selector: "expand-text",
  templateUrl: "./expand-text.component.html",
  styleUrls: ["./expand-text.component.scss"],
})
export class ExpandTextComponent implements OnInit, OnDestroy {
  @ViewChild("inputString", { static: false })
  inputString: ElementRef<HTMLElement>;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  name = "Prompt";
  data = "";
  model = "gpt";
  constructor(
    public dataService: DataService,
    protected ref: NbDialogRef<ExpandTextComponent>,
    public dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}
  ngOnDestroy(): void {}
  optimize() {
    if (this.data && this.data.trim()) {
      this.dialogService
        .open(OptimizePromptComponent, {
          autoFocus: false,
          context: { data: this.data, model: this.model },
        })
        .onClose.subscribe((rs) => {
          if (rs?.status == "save") {
            this.data = rs.data;
          }
        });
    } else {
      this.toastrService.show("Please enter your prompt.", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  ngOnInit() {
    if (!this.data) {
      this.setFocus();
    }
  }

  dismiss() {
    this.ref.close({ status: "cancel" });
  }
  cancel() {
    this.ref.close({ status: "cancel" });
  }
  save() {
    this.ref.close({ status: "save", data: this.data });
  }
  setFocus() {
    setTimeout(() => {
      this.inputString.nativeElement.focus();
    }, 0);
  }
}
