<nb-card class="import-wrapper">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      Import Flow
      <button
        type="button"
        class="btn-close"
        (click)="dismiss(true)"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body
    style="
      padding: 1rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    "
  >
    <img
      src="../../../../../loading.gif"
      alt=""
      style="width: 150px; margin-top: -1rem"
      *ngIf="isLoading == true"
    />
    <img
      src="../../../../../assets/images/error512.png"
      alt=""
      style="width: 90px; margin-bottom: 0.5rem"
      *ngIf="countSuccess == 0 && isLoading == false"
    />
    <img
      src="../../../../../assets/images/success512.png"
      alt=""
      style="width: 90px; margin-bottom: 0.5rem"
      *ngIf="countError == 0 && countSuccess != 0 && isLoading == false"
    />
    <img
      src="../../../../../assets/images/success512.png"
      alt=""
      style="width: 90px; margin-bottom: 0.5rem"
      *ngIf="countError != 0 && countSuccess != 0 && isLoading == false"
    />
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      "
      *ngIf="isLoading == true"
    >
      <span style="font-size: 20px; font-weight: 600"
        >Import all the data...</span
      >
      <span>Sit down, lean back and give us a couple of seconds</span>
      <nb-progress-bar
        style="width: 100%"
        *ngIf="progressBar && isLoading == true"
        [value]="valueProgress"
        status="primary"
        [displayValue]="true"
      ></nb-progress-bar>
      <!-- <input
        *ngIf="progressBar"
        type="range"
        [(ngModel)]="valueProgress"
        min="0"
        [max]="totalProgress"
        step="1"
      /> -->
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      "
      *ngIf="countSuccess == 0 && isLoading == false"
    >
      <span style="font-size: 20px; font-weight: 600"
        >Import Unsuccessfully!</span
      >
      <span>{{ countError }} files were unsuccessfully imported</span>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      "
      *ngIf="countError == 0 && countSuccess != 0 && isLoading == false"
    >
      <span style="font-size: 20px; font-weight: 600"
        >Import Successfully!</span
      >
      <span>{{ countSuccess }} files were successfully imported</span>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      "
      *ngIf="countError != 0 && countSuccess != 0 && isLoading == false"
    >
      <span style="font-size: 20px; font-weight: 600">Import Completed!</span>
      <span>{{ countSuccess }} files were successfully imported</span>
      <span>{{ countError }} files were unsuccessfully imported</span>
    </div>
    <label
      *ngIf="isShow == false"
      class="form-label label"
      style="margin-bottom: 0; margin-top: 0.5rem"
      >Log Details</label
    >
    <label
      *ngIf="isShow == true"
      class="form-label label"
      style="margin-bottom: 0; margin-top: 0.5rem"
      >Quick View</label
    >
    <div
      *ngIf="isShow == false"
      class="log-details log-details-list"
      #listElement
    >
      <nb-list style="overflow-x: hidden">
        <nb-list-item
          *ngFor="let item of logList; let i = index"
          class="item-animation"
        >
          <div *ngIf="item.status == 'progressing'">
            {{ item.message }}
          </div>
          <div *ngIf="item.status == 'failed'" style="color: #ff3d71">
            {{ item.message }}
          </div>
        </nb-list-item>
      </nb-list>
    </div>
    <div
      class="log-details log-details-list"
      *ngIf="
        result && result.length > 0 && isLoading == false && isShow == true
      "
    >
      <nb-list style="overflow-x: hidden">
        <nb-list-item *ngFor="let item of result" class="item-animation">
          <div class="log-name" [title]="item.path">
            <nb-icon icon="file" status="primary"></nb-icon>
            <span>{{ item.fileName }}</span>
          </div>
          <div class="log-action">
            <nb-icon
              *ngIf="item.isSuccess == true"
              icon="checkmark-circle-2"
              title="Success"
              status="success"
            ></nb-icon>
            <nb-icon
              *ngIf="item.isSuccess == false"
              icon="close-circle"
              status="danger"
              title="Failed"
            ></nb-icon>
            <div>
              <button
                nbButton
                size="tiny"
                [nbPopover]="item.note"
                nbPopoverPlacement="right"
              >
                Details
              </button>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      "
    >
      <button
        *ngIf="isLoading == false"
        style="margin-top: 0.5rem"
        type="button"
        status="basic"
        size="small"
        nbButton
        (click)="switch()"
      >
        {{ isShow == false ? "Quick View" : "Log Details" }}
      </button>
      <button
        *ngIf="isLoading == false"
        style="margin-top: 0.5rem"
        type="button"
        status="primary"
        size="small"
        nbButton
        (click)="dismiss()"
      >
        Finish
      </button>
    </div>
  </nb-card-body>
</nb-card>
