import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import {
  ValidInput,
  noWhitespaceValidator,
} from "../../../../../@core/utils/helpers";
import Uppy from "@uppy/core";
import {
  ChatGPTClient,
  GetDocumentDataQuery,
  RetrieveURLCommand,
} from "../../../../../System-api";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import Dashboard from "@uppy/dashboard";

@Component({
  selector: "update-dataset",
  templateUrl: "./update-dataset.component.html",
  styleUrls: ["./update-dataset.component.scss"],
})
export class UpdateDatasetComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("urlBox") urlBox: ElementRef<HTMLDivElement>;

  datasetForm: UntypedFormGroup;
  isLoading: boolean = false;
  isSaveLoading: boolean = false;
  isLoadingExtract: boolean = false;
  isAction: boolean = false;
  title: string = "";
  data: any = {
    id: 0,
    name: "",
    description: "",
    type: "",
    status: "NOT-TRAINED",
    dataTrain: "",
    retrainTime: "",
    files: null,
  };
  uppy: Uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      maxFileSize: 9000000,
      minNumberOfFiles: 1,
      allowedFileTypes: [".txt", ".pdf", ".doc", ".docx", ".json"],
    },
  });

  totalFile = 0;
  fileUpload: any;
  listUrl = [];
  checkExtract = true;
  mainURL: string = null;
  checkAll = true;
  isRetrieve: boolean = false;
  listFileUpload: any = [];
  errorMessage: string = "Somethings went wrong, try again";
  eventListener: any;
  searchLogo = "../../../../../../assets/images/searching.gif";
  constructor(
    private formBuilder: RxFormBuilder,
    private chatGPTClient: ChatGPTClient,
    private toastrService: NbToastrService,
    private router: Router,
    private dialogService: NbDialogService,
    private activatedRoute: ActivatedRoute,
    @Optional() private ref: NbDialogRef<UpdateDatasetComponent>
  ) {}
  ngOnDestroy() {
    if (this.eventListener) {
      document.removeEventListener("click", this.eventListener);
      this.eventListener = null;
    }
  }
  ngAfterViewInit() {}
  changeType(type) {
    this.data.type = type;
  }
  ngOnInit(): void {
    this.createForm();
    if (this.data?.id > 0) {
      if (this.data.type) {
        if (this.data.type == "URL") {
          try {
            if (this.data.dataTrain) {
              this.listUrl = JSON.parse(this.data.dataTrain);
              this.resetToggle();
            }
          } catch {
            const dataTrain = this.data.dataTrain
              .split("\u2022")
              .filter(Boolean);
            dataTrain.forEach((element) => {
              this.listUrl.push({ url: element, enable: true });
            });
            this.resetToggle();
          }
        } else {
          if (this.data.dataTrain) {
            const data = new GetDocumentDataQuery();
            data.path = this.data.dataTrain;
            this.isLoading = true;
            this.chatGPTClient.getDocumentData(data).subscribe((rs) => {
              if (rs?.documents?.length > 0) {
                rs?.documents.forEach(
                  (document) => {
                    this.handleAddFile(document);
                    this.isLoading = false;
                  },
                  (error) => {
                    this.isLoading = false;
                  }
                );
              }
            });
          }
        }
      } else {
        this.data.type = "URL";
      }
    } else {
      this.activatedRoute.params.subscribe((params) => {
        if (params["id"]) {
          this.chatGPTClient.get(params["id"]).subscribe((rs) => {
            if (rs) {
              this.data = rs;
            }
            if (this.data.type) {
              if (this.data.type == "URL") {
                try {
                  if (this.data.dataTrain) {
                    this.listUrl = JSON.parse(this.data.dataTrain);
                    this.resetToggle();
                  }
                } catch {
                  const dataTrain = this.data.dataTrain
                    .split("\u2022")
                    .filter(Boolean);
                  dataTrain.forEach((element) => {
                    this.listUrl.push({ url: element, enable: true });
                  });
                  this.resetToggle();
                }
              } else {
                if (this.data.dataTrain) {
                  const data = new GetDocumentDataQuery();
                  data.path = this.data.dataTrain;
                  this.isLoading = true;
                  this.chatGPTClient.getDocumentData(data).subscribe((rs) => {
                    if (rs?.documents?.length > 0) {
                      rs?.documents.forEach(
                        (document) => {
                          this.handleAddFile(document);
                          this.isLoading = false;
                        },
                        (error) => {
                          this.isLoading = false;
                        }
                      );
                    }
                  });
                }
              }
            } else {
              this.data.type = "URL";
            }
          });
        } else {
          this.data.type = "URL";
        }
      });
    }
    this.uppy.on("file-added", (file) => {
      if (file.data instanceof Blob) {
        if (file.data instanceof File) {
          const existFile = this.listFileUpload.some(
            (element) => element.name === file.name
          );
          if (existFile) {
            this.uppy.info(
              `Cannot add the duplicate file '${file.name}', it already exists.`,
              "error"
            );
            this.uppy.removeFile(file.id);
          } else {
            if (this.listFileUpload.length + 1 > 5) {
              this.uppy.info("You can only upload 5 files.", "error");
              this.uppy.removeFile(file.id);
            } else {
              this.listFileUpload.push(file);
            }
          }
        }
      }
      if (this.listFileUpload.length == 5) {
        this.uppy.setOptions({
          restrictions: { maxNumberOfFiles: this.listFileUpload.length },
        });
      }
      var files = this.uppy.getFiles();
      this.totalFile = files.length;
    });
    this.uppy.on("file-removed", (file, reason) => {
      var files = this.uppy.getFiles();
      if (files.length < this.listFileUpload.length) {
        this.listFileUpload = this.listFileUpload.filter(
          (e) => e.name != file.name
        );
      }
      if (this.listFileUpload.length < 5) {
        this.uppy.setOptions({ restrictions: { maxNumberOfFiles: null } });
      }
      this.totalFile = files.length;
    });
    this.eventListener = (e: any) => {
      document.querySelectorAll(".uppy-Dashboard-Item")?.forEach((item) => {
        if (item.contains(e?.target)) {
          this.download(item.id);
        }
      });
    };
    document.addEventListener("click", this.eventListener);
  }

  createForm() {
    this.datasetForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          Validators.maxLength(50),
          RxwebValidators.pattern({
            expression: {
              regex: /^[^\\\/:*?"<>|]+$/,
            },
          }),
        ],
      ],
      description: [""],
      mainURL: [""],
      retrainTime: ["", ValidInput.retrainTime],
    });
  }

  handleAddFile(document) {
    this.listFileUpload.push(document);
    const byteCharacters = atob(document.data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "application/octet-stream" });

    // Set the file in Uppy
    this.uppy.addFile({
      source: document.source,
      data: blob,
      name: document.name,
    });
  }

  getAllFiles() {
    var files = this.uppy.getFiles();
    files.forEach((element) => {
      (element as any).fileName = element.name;
    });
    return files;
  }

  back() {
    this.router.navigate(["/portal/studio/knowledge-base"]);
  }

  dismiss() {
    this.ref.close();
  }

  toggleExtract(event) {
    this.checkExtract = event;
  }

  onChangeText(event) {
    const text = event.target.value;

    // Save the selection range
    const selectionStart = event.srcElement.selectionStart;
    const selectionEnd = event.srcElement.selectionEnd;

    // Replace the text
    let newText = text.replace(/(^|\n)([^\u2022])/g, "$1\u2022 $2");
    newText = newText.replace(/\u2022\s*\u2022/g, "\u2022");
    event.srcElement.value = newText;

    // Restore the selection range
    const newSelectionStart = selectionStart + (newText.length - text.length);
    const newSelectionEnd = selectionEnd + (newText.length - text.length);
    event.srcElement.setSelectionRange(newSelectionStart, newSelectionEnd);
    this.mainURL = newText;
    return newText;
  }

  toggleAll(event) {
    this.listUrl.forEach((item, index) => {
      this.listUrl[index].enable = event;
    });
    this.checkAll = event;
  }

  removeAll() {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question: "All unchecked URLs will go to the trash list. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.listUrl = this.listUrl.filter((item) => {
            return item.enable == true;
          });
          this.resetToggle();
          this.toastrService.show(
            `Remove all URLs successfully`,
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "success",
            }
          );
        }
      });
  }

  resetToggle() {
    const data = this.listUrl?.filter((element) => {
      return element.enable == true;
    });
    if (data.length == this.listUrl.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }

  toggle(event, index) {
    this.listUrl[index].enable = event;
    this.resetToggle();
  }

  removeUrl(index) {
    if (index != 0) {
      this.listUrl.splice(index, 1);
    } else {
      this.listUrl.shift();
    }
    this.resetToggle();
  }

  handleGetURL() {
    const dataTrain: string[] = this.mainURL.split("\n\u2022").filter(Boolean);
    if (dataTrain.length > 0) {
      dataTrain[0] = dataTrain[0].replace("\u2022", "").trim();
    }
    return dataTrain;
  }

  extractURL() {
    if (this.mainURL) {
      const urls = this.handleGetURL();
      // if (this.checkExtract) {
      this.isLoadingExtract = true;
      let data = new RetrieveURLCommand();
      data.urls = urls;
      data.isExtract = this.checkExtract;
      this.isRetrieve = true;
      this.chatGPTClient.retrieveURL(data).subscribe({
        next: (rs) => {
          if (rs && rs.isSuccess) {
            let count = 0;
            rs.retrieveURLs.forEach((item) => {
              const data = this.listUrl?.filter((element) => {
                return element.url == item.url;
              });
              if (data.length <= 0) {
                this.listUrl.push({
                  url: item.url,
                  enable: true,
                  tokens: item.tokens,
                });
                count++;
              }
            });
            this.toastrService.show(
              `Result: ${count} new ${
                count > 1 ? "URLs" : "URL"
              } added to the list`,
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "success",
              }
            );
          } else {
            this.toastrService.show(
              rs?.message ? rs?.message : this.errorMessage,
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "danger",
              }
            );
          }
          this.isRetrieve = false;
          this.isLoadingExtract = false;
        },
        error: (error) => {
          this.isLoadingExtract = false;
          this.isRetrieve = false;
          this.toastrService.show(this.errorMessage, `Notification`, {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          });
        },
      });
      // } else {
      //     urls.forEach((url) => {
      //         this.addNewURL(url.trim());
      //     });
      // }
    }
  }
  download(id: string) {
    if (id) {
      id = id.substring("uppy_".length);
      var file = this.uppy.getFile(id);
      if (file) {
        this.dialogService
          .open(ConfirmDialogComponent, {
            autoFocus: false,
            context: {
              question: "Do you want to download file " + file.name + " ?",
              textYes: "Download",
              textNo: "Cancel",
              statusYes: "primary",
              statusNo: "basic",
            },
          })
          .onClose.subscribe((isConfirm) => {
            if (isConfirm) {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String: string = reader.result as string;
                let temp = this.dataURLtoFile(base64String);
                this.downloadData(file.name, temp);
              };
              reader.readAsDataURL(file.data);
            }
          });
      }
    }
  }
  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  addNewURL(url) {
    if (url && this.isURL(url)) {
      const data = this.listUrl.filter((item) => {
        return item.url == url;
      });
      if (data.length <= 0) {
        var obj = { url: url, enable: true };
        this.listUrl.push(obj);
        this.toastrService.show(`Add URL successfully`, `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "success",
        });
        setTimeout(() => {
          this.urlBox.nativeElement.scrollTop =
            this.urlBox.nativeElement.scrollHeight;
        }, 50);
      } else {
        this.toastrService.show(`${url} already exists`, `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        });
      }
    } else {
      this.toastrService.show(`${url} not valid`, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  isURL(value: string): boolean {
    const urlPattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return urlPattern.test(value);
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.datasetForm.get(fieldName)) {
          const control = this.datasetForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  onSubmit() {
    if (this.datasetForm.valid) {
      if (this.data.type == "URL")
        this.data.dataTrain = JSON.stringify(this.listUrl);
      if (this.data.type == "DOCUMENT") {
        this.data.retrainData = "";
        this.fileUpload = this.getAllFiles();
      }
      this.data.name = this.data.name?.trim();
      this.isLoading = true;
      this.chatGPTClient
        .create(
          this.data.id,
          this.data.name,
          this.data.description,
          this.data.type,
          this.data.dataTrain,
          this.data.retrainTime,
          this.fileUpload as any
        )
        .subscribe({
          next: (rs) => {
            this.isLoading = false;
            if (rs) {
              this.showToast(true);
              if (this.isAction) {
                this.ref.close({ rs: rs, name: this.data.name });
              } else {
                this.router.navigate([`portal/studio/knowledge-base`]);
              }
            } else {
              this.data.status = "FAILED";
              this.showToast(false);
            }
          },
          error: (error) => {
            this.isLoading = false;
            this.showToast(false);
            if (error.status == 422) {
              this.setValidationErrors(error.response);
            }
          },
        });
    }
  }

  trainingDataset() {
    if (this.datasetForm.valid) {
      if (this.data.type == "URL")
        this.data.dataTrain = JSON.stringify(this.listUrl);
      if (this.data.type == "DOCUMENT") {
        this.data.retrainData = "";
        this.fileUpload = this.getAllFiles();
      }
      this.isLoading = true;
      this.chatGPTClient
        .train(
          this.data.id,
          this.data.name,
          this.data.description,
          this.data.type,
          this.data.dataTrain,
          this.data.retrainTime,
          this.fileUpload as any
        )
        .subscribe({
          next: (rs) => {
            this.isLoading = false;
            if (rs && rs > 0) {
              this.showToast(true);
            } else {
              this.showToast(false);
            }
          },
          error: (error) => {
            this.isLoading = false;
            this.showToast(false);
            if (error.status == 422) {
              this.setValidationErrors(error.response);
            }
          },
        });
    }
  }

  showToast(result) {
    if (result == "required") {
      this.toastrService.show("Data is required", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    } else if (result == true) {
      this.toastrService.show("Save knowledge successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Save knowledge unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
