import { filter } from "rxjs";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from "@angular/material/tree";

import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { FlatTreeControl } from "@angular/cdk/tree";
import { DataService } from "../../../../@core/utils/data.service";
interface FolderNode {
  name: string;
  isOverride: boolean;
  path: string;
  isExist: boolean;
  children?: FolderNode[];
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  isOverride: boolean;
  path: string;
  level: number;
  isExist: boolean;
  children: any;
}
@Component({
  selector: "import-flow-modal",
  templateUrl: "./import-flow-modal.component.html",
  styleUrls: ["./import-flow-modal.component.scss"],
})
export class ImportFlowModalComponent implements OnInit {
  private _transformer = (node: FolderNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      isOverride: node.isOverride,
      path: node.path,
      children: node.children,
      isExist: node.isExist,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  isLoading: boolean = false;
  constructor(
    public ref: NbDialogRef<ImportFlowModalComponent>,
    private toastrService: NbToastrService,
    private dataService: DataService
  ) {}
  dataImport: any = null;
  dataImportFilter: any = [];
  conflictValue: any = [];
  isOverrideAll: any = false;
  conflictOverrideAll: any = false;
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  @ViewChild("tree") tree;

  ngAfterViewInit() {
    this.tree.treeControl.expandAll();
  }
  ngOnInit() {
    this.handleDataTree();
    this.handleGetAttribute();
  }

  handleGetAttribute() {
    if (this.dataImport) {
      let attribute = this.dataImport.find((x) => x.name == "attribute.txt");
      if (attribute?.data) {
        let currentAttribute = this.dataService.ivrAttribute;
        attribute = JSON.parse(attribute.data);
        attribute.forEach((item) => {
          if (item.default != 1 && item["content"] != undefined) {
            let check = 0;
            currentAttribute.forEach((currentItem) => {
              if (
                item.value == currentItem.value &&
                item.content != currentItem.content &&
                check == 0
              ) {
                check = 1;
                this.conflictValue.push({
                  old: currentItem,
                  new: item,
                  isOverride: false,
                });
              }
            });
          }
        });
      }
    }
  }
  handleUpdateDataImport() {
    if (this.dataImportFilter) {
      this.dataImportFilter.forEach((data) => {
        this.dataImport.forEach((dataImport) => {
          if (dataImport.path == data.path) {
            dataImport = data;
          }
        });
      });
      this.handleDataTree();
    }
  }
  handleCheckDataImport() {
    let check = true;
    this.dataImport.forEach((element) => {
      if (
        element.name.includes(".") &&
        element.isExist &&
        element.isOverride == false &&
        element.name == element.newName
      ) {
        check = false;
      }
    });
    return check;
  }
  handleShowFile(node) {
    this.handleUpdateDataImport();
    this.dataImportFilter = [];
    if (this.checkFileExistInFolder(node)) {
      node?.children.forEach((item) => {
        const data = this.dataImport.filter((x) => x.path == item.path);
        if (data) {
          this.dataImportFilter.push(data[0]);
        }
      });
    } else {
      const data = this.dataImport.filter((x) => x.path == node.path);
      if (data) {
        this.dataImportFilter.push(data[0]);
      }
    }
  }
  handleOverride(event, index) {
    this.dataImportFilter[index].isOverride = event;
    // this.checkOverrideAll();
    this.handleUpdateDataImport();
  }
  handleOverrideAll(check) {
    this.dataImportFilter = [];
    this.dataImport.forEach((item) => {
      if (item.isExist) {
        this.dataImportFilter.push(item);
      }
    });
    this.dataImportFilter.forEach((data, index) => {
      this.dataImportFilter[index].isOverride = check;
    });
    this.conflictValue.forEach((attribute, key) => {
      this.conflictValue[key].isOverride = check;
    });
    this.conflictOverrideAll = check;
    this.handleUpdateDataImport();
  }
  handleOverrideAttribute(event, index) {
    this.conflictValue[index].isOverride = event;
    this.checkOverrideAll();
  }
  checkOverrideAll() {
    this.dataImportFilter = [];
    this.dataImport.forEach((item) => {
      if (item.isExist) {
        this.dataImportFilter.push(item);
      }
    });
    const dataImport = this.dataImportFilter.find((x) => x.isOverride == false);
    const attribute = this.conflictValue.find((x) => x.isOverride == false);
    if (dataImport || attribute) {
      this.isOverrideAll = false;
    }
    if (!dataImport && !attribute) {
      this.isOverrideAll = true;
    }
    if (attribute) {
      this.conflictOverrideAll = false;
    } else {
      this.conflictOverrideAll = true;
    }
  }
  handleInputBlur(index) {
    this.dataImportFilter[index].edit = false;
    this.handleUpdateDataImport();
  }
  handleEdit(index) {
    this.dataImportFilter[index].edit = true;
    this.handleUpdateDataImport();
  }
  checkFileExistInFolder(node) {
    let check = false;
    node?.children.forEach((item) => {
      if (item.name.includes(".")) {
        check = true;
      }
    });
    return check;
  }
  handleDataTree() {
    const tree: FolderNode[] = [];
    for (const data of this.dataImport) {
      const pathSegments = data.path.split("/");
      let currentLevel = tree;
      for (const segment of pathSegments) {
        const existingNode = currentLevel.find((node) => node.name === segment);
        if (existingNode) {
          currentLevel = existingNode.children;
        } else {
          const newNode: FolderNode = {
            name: segment,
            path: data.path,
            isOverride: data.isOverride,
            children: [],
            isExist: data.isExist,
          };
          currentLevel.push(newNode);
          currentLevel = newNode.children;
        }
      }
    }
    this.dataSource.data = tree[0].children[0].children[0].children[0].children;
    this.tree?.treeControl?.expandAll();
  }
  dismiss() {
    this.ref.close(null);
  }
  onSubmit() {
    if (this.handleCheckDataImport()) {
      this.ref.close({
        dataImport: this.dataImport,
        conflictValue: this.conflictValue,
      });
    } else {
      this.toastrService.show("Please confirm file existed", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
