<div [formGroup]="makeCallForm">
  <nb-card
    style="max-width: 550px; min-width: 460px"
    [ngClass]="{ 'is-preview': isPreview }"
  >
    <nb-card-header *ngIf="isPreview == false">
      <div class="d-flex justify-content-between">
        Create Extension
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label for="callPhoneNumber" class="form-label label required"
          >Number</label
        >

        <input
          id="callPhoneNumber"
          type="text"
          [(ngModel)]="callPhoneNumber"
          nbInput
          [status]="
            makeCallForm.controls['extension'].invalid &&
            (makeCallForm.controls['extension'].dirty ||
              makeCallForm.controls['extension'].touched)
              ? 'danger'
              : 'basic'
          "
          formControlName="extension"
          fullWidth
          placeholder="Extension"
        />
        <ng-container
          *ngIf="
            makeCallForm.controls['extension'].invalid &&
            (makeCallForm.controls['extension'].dirty ||
              makeCallForm.controls['extension'].touched)
          "
        >
          <p
            class="caption status-danger mb-0"
            *ngIf="
              makeCallForm.controls['extension'].hasError('required') ||
              makeCallForm.controls['extension'].hasError('whitespace')
            "
          >
          Extension is required.
          </p>
          <p
            class="caption status-danger mb-0"
            *ngIf="
              makeCallForm.controls['extension'].hasError('digit') &&
              !makeCallForm.controls['extension'].hasError('maxLength')
            "
          >
          Extension must be numeric.
          </p>
          <p
            class="caption status-danger mb-0"
            *ngIf="makeCallForm.controls['extension'].hasError('maxLength')"
          >
            Maximum length is 15 characters.
          </p>
          <p
            class="caption status-danger mb-0"
            *ngIf="makeCallForm.controls['extension'].hasError('extensionExist')"
          >
            Extension have already existed.
          </p>
        </ng-container>
      </div>
      <div class="mb-2">
        <label for="input-flow-password" class="form-label label"
          >Password</label
        >
        <nb-form-field>
          <input
            [ngClass]="{
              'numeric-password': !showPassword,
              '': showPassword
            }"
            nbInput
            formControlName="password"
            maxlength="15"
            fullWidth
            id="input-flow-password"
            placeholder="Password"
            autocomplete="off"
            name="flow-password"
            [(ngModel)]="pass"
          />
          <button
            type="button"
            nbSuffix
            nbButton
            ghost
            (click)="toggleShowPassword()"
          >
            <nb-icon
              [icon]="showPassword ? 'eye-off-outline' : 'eye-outline'"
              pack="eva"
              [attr.aria-label]="
                showPassword ? 'hide password' : 'show password'
              "
            >
            </nb-icon>
          </button>
        </nb-form-field>
      </div>
      <div class="mb-2">
        <label for="input-extension" class="form-label label required">Phone Type</label>
        <div class="input-group">
          <nb-button-group (valueChange)="updatePhoneType($event)">
            <button
              nbButtonToggle
              [pressed]="phoneType === 'udp'"
              type="button"
              value="udp"
              size="small"
            >
              Soft Phone
            </button>
            <button
              nbButtonToggle
              [pressed]="phoneType === 'wss'"
              type="button"
              value="wss"
              size="small"
            >
              Web Phone
            </button>
          </nb-button-group>
        </div>
      </div>
      <button
        class="float-end mt-2"
        nbButton
        size="small"
        [nbSpinner]="loadingButton"
        nbSpinnerSize="small"
        nbSpinnerStatus="info"
        [disabled]="!callPhoneNumber || !makeCallForm.valid || loadingButton"
        type="button"
        (click)="create()"
        status="primary"
      >
        Create
      </button>
    </nb-card-body>
  </nb-card>
</div>
