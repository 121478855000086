<nb-card
  style="margin-bottom: 0; overflow: auto"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <span>{{ title }}</span>
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body [ngClass]="{ 'width-modal': data.method == 'Success' }">
    <div class="row m-2">
      <div class="col-12">
        <div class="row">
          <div
            class="col-md-6"
            [ngClass]="{ 'col-md-12': data.method == 'Success' }"
          >
            <div class="mb-2">
              <label class="form-label label">Condition</label>
              <nb-select
                fullWidth
                placeholder="Select Condition"
                [(ngModel)]="data.method"
              >
                <nb-option *ngFor="let item of listCondition" [value]="item">{{
                  item
                }}</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="col-md-6" *ngIf="data.method != 'Success'">
            <div class="mb-2">
              <label for="value" class="form-label label">Value</label>
              <input
                id="value"
                *ngIf="data.method != 'Exist' && data.method != 'Empty'"
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="data.data"
                [mentionConfig]="mentionConfig"
                placeholder="Value"
                (ngModelChange)="onModelChange($event)"
                [nbAutocomplete]="autoNgModel"
              />
              <nb-autocomplete #autoNgModel>
                <nb-option
                  *ngFor="let option of filteredNgModelOptions$ | async"
                  [value]="option"
                >
                  {{ option }}
                </nb-option>
              </nb-autocomplete>
              <nb-select
                *ngIf="data.method == 'Exist' || data.method == 'Empty'"
                fullWidth
                placeholder="Select Value"
                [(ngModel)]="data.data"
              >
                <nb-option value="true"> True </nb-option>
                <nb-option value="false"> False </nb-option>
              </nb-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="float-end"
      (click)="save()"
      nbButton
      size="small"
      status="primary"
      [disabled]="!data.data.trim() && data.method != 'Success'"
    >
      Confirm
    </button>
  </nb-card-body>
</nb-card>
