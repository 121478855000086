import { IApplicationData } from "./common";
export class IntentRouting implements IApplicationData {
  constructor() {
    this.name = "Intent Routing";
    this.text = "{USER_INPUT}";
    this.attribute = "";
    this.entityAttribute = "";
    this.grammarArray = "[]";
    this.multipleCase = true;
    this.isRequired = true;
    this.checkFields = true;
  }
  name: string;
  text: string;
  attribute: string;
  entityAttribute: string;
  grammarArray: string;
  multipleCase: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
