import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbTabsetComponent,
  NbToastrService,
} from "@nebular/theme";
import Uppy from "@uppy/core";
import { FileParameter, MediaClient } from "../../System-api";
import { DataService } from "../../@core/utils/data.service";
import { Fancybox } from "@fancyapps/ui";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "media-modal",
  templateUrl: "./media-modal.component.html",
  styleUrls: ["./media-modal.component.scss"],
})
export class MediaModalComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("tabset") tabsetEl: NbTabsetComponent;
  urlData = "";
  type = "image";
  files = [".jpg", ".jpeg", ".gif", ".png"];
  attributes: any = this.dataService.ivrAttribute;
  medias: any = [];
  imgSelected: any;
  selectMany: boolean = false;
  allowUploadVideo: boolean = false;
  searchValue: string = "";
  checkItems: any = {};
  amountImagesChoose: number = 0;
  timeout = null;
  filter: any = {
    min: new Date(),
    max: new Date(),
  };
  isFiltered: boolean = false;
  filterType: string = "all";
  extensionUpload: any = [];
  setCheckItems: boolean = true;
  maxSize: number;
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
    ],
  };
  constructor(
    protected ref: NbDialogRef<MediaModalComponent>,
    private dataService: DataService,
    private toastrService: NbToastrService,
    private mediaClient: MediaClient,
    private elRef: ElementRef,
    private dialogService: NbDialogService
  ) {}
  currentTab = "UPLOAD FILE";
  uppy: Uppy;
  isLoadingUpdate = false;
  save() {
    if (this.currentTab == "FROM URL") {
      this.ref.close({ status: true, data: this.urlData });
    } else if (this.currentTab == "FROM GALLERY") {
      let imageSelected;
      for (let key in this.checkItems) {
        if (this.checkItems.hasOwnProperty(key)) {
          if (this.checkItems[key]) {
            imageSelected = key;
            break;
          }
        }
      }
      imageSelected = this.medias.find((item) => item.id == imageSelected);
      this.ref.close({ status: true, data: imageSelected.path });
    } else {
      this.ref.close();
    }
  }
  handleImageError(event: any) {
    event.target.src = "../../../assets/images/default-image-small.jpg";
  }
  yourFunc(e) {
    this.currentTab = e.tabTitle;
  }
  ngOnInit() {
    this.maxSize = 20 * 1024 * 1024;
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Toolbar: {
        display: {
          left: [],
          right: ["zoomIn", "zoomOut", "close"],
          middle: [],
        },
      },
      Thumbs: {
        type: "modern",
      },
    });

    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 21000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: this.files,
      },
    });
    this.uppy.on("restriction-failed", (file, error) => {
      if (error.message.includes("exceeds maximum allowed size")) {
        this.showToast(false, "", "Exceeded maximum allowable size of 20 MB");
      } else if (error.message.includes(".mp4")) {
        this.showToast(false, "", "You can only upload: mp4");
      } else if (error.message.includes("png")) {
        this.showToast(false, "", "You can only upload: jpg, jpeg, gif, png");
      } else this.showToast(false, "", error.message);
      this.uppy.removeFile(file.id);
      this.fileInput.nativeElement.value = null;
    });
    this.uppy.on("file-added", (file) => {
      this.isLoadingUpdate = true;
      const fileParameter: FileParameter = {
        data: file.data,
        fileName: file.name,
      };
      if (file.type.includes("image")) {
        this.getImageResolution(file)
          .then(([width, height]: any) => {
            this.createMedia(file, null, "image", width, height, fileParameter);
            this.uppy.removeFile(file.id);
          })
          .catch((error) => {
            this.uppy.removeFile(file.id);
            this.isLoadingUpdate = false;
            this.showToast(
              false,
              "Upload " + this.type + " successfully",
              "Upload " + this.type + " unsuccessfully"
            );
          });
      } else if (file.type.includes("video")) {
        this.getDurationVideo(file)
          .then((duration: any) => {
            this.createMedia(file, duration, "video", 0, 0, fileParameter);
            this.uppy.removeFile(file.id);
          })
          .catch((error) => {
            this.uppy.removeFile(file.id);
            this.isLoadingUpdate = false;
            this.showToast(
              false,
              "Upload " + this.type + " successfully",
              "Upload " + this.type + " unsuccessfully"
            );
          });
      }
    });
    this.extensionUpload = this.files;
    this.filterType = this.type;
    this.filterListMedias();
  }
  filterListMedias(filter = 'all') {
    if (filter === 'all') {
      this.filter.min = null;
      this.filter.max = null;
    }
    this.isLoadingUpdate = true;
    let startTime = null, endTime = null;
    if (this.filter.min && this.filter.max) {    
      startTime = this.filter.min.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      endTime = this.filter.max.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    this.mediaClient
      .getAll(this.filterType, this.searchValue, startTime, endTime)
      .subscribe({
        next: (rs) => {
          this.medias = rs.mediaDtos;
          this.medias = this.medias?.map((item) => {
            item.size = Math.floor(item.size / 1024);
            item.created = item.created.toDateString();
            return item;
          });
          this.setValueForCheckItem();
          this.isLoadingUpdate = false;
        },
        error: (error) => {
          this.isLoadingUpdate = false;
        },
      });
  }
  clearDatePickerFilter() {
    if (this.filter) {
      this.filterListMedias();
      this.filter = {
        min: null,
        max: null,
      };
      this.isFiltered = false;
    }
  }
  setValueForCheckItem() {
    if (this.setCheckItems) {
      this.checkItems = this.medias.reduce((acc, media) => {
        if (this.urlData && this.urlData == media.path) {
          acc[media.id] = true;
          this.imgSelected = media;
        } else acc[media.id] = false;
        return acc;
      }, {});
    }
    this.setCheckItems = false;
  }
  createMedia(file, duration, type, width, height, fileParameter) {
    this.mediaClient
      .create(
        file.name,
        file.size,
        duration,
        type,
        width,
        height,
        fileParameter
      )
      .subscribe({
        next: (rs) => {
          this.isLoadingUpdate = false;
          if (rs && rs != null) {
            this.ref.close({ status: true, data: rs.path });
          } else {
            this.showToast(
              false,
              "Upload " + this.type + " successfully",
              "Upload " + this.type + " unsuccessfully"
            );
          }
        },
        error: (error) => {
          this.isLoadingUpdate = false;
          this.showToast(
            false,
            "Upload " + this.type + " successfully",
            "Upload " + this.type + " unsuccessfully"
          );
        },
      });
  }
  showImageDetail(item) {
    const media = this.medias.find((media) => media.id == item.id);
    if (media) {
      if (this.selectMany) {
        if (this.checkItems[media.id]) {
          this.checkItems[media.id] = false;
          if (this.imgSelected?.id == media.id) {
            this.imgSelected = "";
          }
          this.amountImagesChoose--;
        } else {
          this.checkItems[media.id] = true;
          this.imgSelected = item;
          this.amountImagesChoose++;
        }
      } else {
        if (this.imgSelected?.id == media.id) {
          this.imgSelected = "";
          this.checkItems[media.id] = false;
        } else {
          if (this.imgSelected) {
            this.checkItems[this.imgSelected.id] = false;
          }
          this.checkItems[media.id] = true;
          this.imgSelected = media;
        }
      }
    }
  }
  showToast(result, textSuccess, textFail) {
    if (result == true) {
      this.toastrService.show(textSuccess, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(textFail, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  getDurationVideo(file) {
    return new Promise((resolve, reject) => {
      const tempVideo = document.createElement("video");
      if (file?.data) {
        tempVideo.src = URL.createObjectURL(file.data);
      } else tempVideo.src = URL.createObjectURL(file);

      tempVideo.addEventListener("loadedmetadata", () => {
        const videoDuration = tempVideo.duration;
        const hours = Math.floor(videoDuration / 3600);
        const minutes = Math.floor((videoDuration % 3600) / 60);
        const seconds = Math.floor(videoDuration % 60);
        let duration;
        if (tempVideo.duration > 3600) {
          duration = `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        } else {
          duration = `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
        }
        resolve(duration);
      });

      tempVideo.onerror = (error) => {
        reject(error);
      };
    });
  }
  getImageResolution(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      if (file?.data) {
        img.src = URL.createObjectURL(file?.data);
      } else img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        resolve([width, height]);
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  }
  allowSelectMany(event) {
    if (!event) {
      for (let key in this.checkItems) {
        if (this.checkItems.hasOwnProperty(key)) {
          this.checkItems[key] = false;
        }
      }
      this.amountImagesChoose = 0;
    }
    if (this.imgSelected) {
      this.checkItems[this.imgSelected.id] = true;
      this.amountImagesChoose = 1;
    }
    this.selectMany = event;
  }
  filterDate(event) {
    this.filter.min = event?.start || null;
    this.filter.max = event?.end || null;
    if (this.filter.min && this.filter.max){
      this.filterListMedias('date');
      this.isFiltered = true;
    }
  }
  deleteImage(value, mediaType) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: true,
        context: {
          question:
            value == "item" || this.amountImagesChoose == 1
              ? "This " + mediaType + " will be deleted. Sure?"
              : this.amountImagesChoose +
                " " +
                mediaType +
                " will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoadingUpdate = true;
          let listImageDelete = [];
          if (value == "item") {
            listImageDelete.push(this.imgSelected.id);
            this.checkItems[this.imgSelected.id] = false;
            if (this.amountImagesChoose > 0) {
              this.amountImagesChoose--;
            }
            this.imgSelected = "";
          } else {
            for (let key in this.checkItems) {
              if (this.checkItems.hasOwnProperty(key)) {
                if (this.checkItems[key]) {
                  listImageDelete.push(parseInt(key));
                }
              }
            }
            this.imgSelected = "";
            this.amountImagesChoose = 0;
          }
          this.mediaClient.delete(listImageDelete).subscribe({
            next: (rs) => {
              for (let key in this.checkItems) {
                if (this.checkItems.hasOwnProperty(key)) {
                  this.checkItems[key] = false;
                }
              }
              this.showToast(
                rs,
                "Delete "+ (listImageDelete.length > 1 ? `${listImageDelete.length} ${mediaType}s` : mediaType) + " successfully",
                "Delete " + mediaType + " unsuccessfully"
              );
              this.filterListMedias();
              this.isLoadingUpdate = false;
            },
            error: (error) => {
              this.isLoadingUpdate = false;
              this.showToast(
                false,
                "Delete " + mediaType + " successfully",
                "Delete " + mediaType + " unsuccessfully"
              );
            },
          });
        }
      });
  }
  selectFilterType(event) {
    this.filterType = event;
    if (event == "all") {
      this.extensionUpload = [".jpg", ".jpeg", ".gif", ".png", ".mp4"];
    } else if (event == "image") {
      this.extensionUpload = [".jpg", ".jpeg", ".gif", ".png"];
    } else if (event == "video") {
      this.extensionUpload = [".mp4"];
    }
    this.filterListMedias();
  }
  changeSearchValue(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchValue = event.target.value;
      this.filterListMedias();
    }, 500);
  }

  onFileSelected(file: any) {
    if (file.length === 0) return;
    var mimeType = file[0]?.type;
    if (this.filterType == "all") {
      if (!mimeType.match(/(image\/(jpeg|jpg|png|gif)|video\/mp4)/)) {
        this.showToast(
          false,
          "",
          "You can only upload: jpg, jpeg, gif, png, mp4"
        );
        this.fileInput.nativeElement.value = null;
        return;
      }
    } else if (this.filterType == "image") {
      if (!mimeType.match(/image\/(jpeg|jpg|png|gif)/)) {
        this.showToast(false, "", "You can only upload: jpg, jpeg, gif, png");
        this.fileInput.nativeElement.value = null;
        return;
      }
    } else if (this.filterType == "video") {
      if (!mimeType.match(/video\/mp4/)) {
        this.showToast(false, "", "You can only upload: mp4");
        this.fileInput.nativeElement.value = null;
        return;
      }
    }
    if (file[0]?.size > this.maxSize) {
      this.showToast(false, "", "Exceeded maximum allowable size of 20 MB");
      this.fileInput.nativeElement.value = null;
      return;
    }

    this.isLoadingUpdate = true;
    const fileParameter: FileParameter = {
      data: file[0],
      fileName: file[0].name,
    };
    if (file[0].type.includes("image")) {
      this.getImageResolution(file[0])
        .then(([width, height]: any) => {
          this.createMedia(
            file[0],
            null,
            "image",
            width,
            height,
            fileParameter
          );
          this.fileInput.nativeElement.value = null;
          this.showToast(
            true,
            "Upload image successfully",
            "Upload image unsuccessfully"
          );
        })
        .catch((error) => {
          this.fileInput.nativeElement.value = null;
          this.isLoadingUpdate = false;
          this.showToast(
            false,
            "Upload image successfully",
            "Upload image unsuccessfully"
          );
        });
    } else if (file[0].type.includes("video")) {
      this.getDurationVideo(file[0])
        .then((duration: any) => {
          this.createMedia(file[0], duration, "video", 0, 0, fileParameter);
          this.fileInput.nativeElement.value = null;
          this.showToast(
            true,
            "Upload video successfully",
            "Upload video unsuccessfully"
          );
        })
        .catch((error) => {
          this.fileInput.nativeElement.value = null;
          this.isLoadingUpdate = false;
          this.showToast(
            false,
            "Upload video successfully",
            "Upload video unsuccessfully"
          );
        });
    }
  }

  dismiss() {
    this.ref.close({ status: false, data: "" });
  }
}
