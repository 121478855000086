<div>
  <div *ngFor="let property of properties; let i = index">
    <div class="row" style="margin-right: 0px !important">
      <div class="col-sm-5" *ngIf="onlyValue == false">
        <div class="mb-2">
          <label
            for="input-{{ title }}-value-{{ i }}"
            class="form-label label"
            >{{ propertyName }}</label
          >
          <ng-select
            id="input-{{ title }}-value-{{ i }}"
            #selectComponents
            [items]="filteredOptions$ | async"
            [addTag]="addTagFn"
            *ngIf="selectAttribute"
            fullWidth
            placeholder="{{ propertyName }}"
            [(ngModel)]="property.name"
            bindLabel="value"
            bindValue="value"
            (change)="selectVariable($event, i)"
            addTagText="Custom variable"
            [editableSearchTerm]="true"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-index="index"
              let-search="searchTerm"
            >
              <div *ngIf="item.default == 'VARIABLE'" class="add-attribute">
                <nb-icon icon="plus-circle-outline"></nb-icon>
                <span>VARIABLE</span>
              </div>
              <div
                *ngIf="item.default != 'VARIABLE'"
                style="word-break: break-word; white-space: normal"
              >
                {{ item.value || property.name }}
              </div>
            </ng-template>
            >
          </ng-select>
          <input
            id="input-{{ title }}-value-{{ i }}"
            [disabled]="disabled"
            *ngIf="!selectAttribute"
            type="text"
            nbInput
            fieldSize="small"
            fullWidth
            [(ngModel)]="property.name"
            placeholder="{{ propertyName }}"
          />
        </div>
      </div>
      <div class="col-sm-6" [ngClass]="{ 'col-sm-11': onlyValue }">
        <div class="mb-2">
          <label
            for="textarea-value-{{ title }}-{{ i }}"
            class="form-label label"
            >{{ propertyValue }}</label
          >
          <textarea
            (drop)="dropValue($event, i)"
            type="text"
            style="height: 32.4px; max-height: 10vh; min-height: 32.4px"
            fieldSize="small"
            nbInput
            [mentionConfig]="mentionConfig"
            id="textarea-value-{{ title }}-{{ i }}"
            (input)="onChangeContent($event, i)"
            fullWidth
            [(ngModel)]="property.value"
            placeholder="{{ propertyValue }}"
          >
          </textarea>
        </div>
      </div>
      <div *ngIf="!disabled" class="col-sm-1">
        <div style="margin-top: 1.7rem">
          <button
            type="button"
            ghost
            size="small"
            (click)="removeProperty(i)"
            nbButton
          >
            <nb-icon icon="minus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!disabled">
  <div class="mb-2">
    <button
      type="button"
      (click)="addProperty()"
      size="small"
      status="primary"
      class="small mt-2"
      nbButton
    >
      <nb-icon icon="plus-outline"></nb-icon>
      <span>Add</span>
    </button>
  </div>
</div>
