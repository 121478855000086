import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { Observable } from "rxjs";

import { Papa } from "ngx-papaparse";

import {
  ChatGPTClient,
  CheckGrammarQuery,
  CreateGrammarCommand,
  GrammarClient,
  MakeTestModelLLMCommand,
} from "../../../../System-api";
import { DataService } from "../../../../@core/utils/data.service";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { TestActionComponent } from "../test-action/test-action.component";
import { ChatGPT } from "../../../../@core/model/chatgpt";

@Component({
  selector: "llm-grammar",
  templateUrl: "./llm-grammar.component.html",
  styleUrls: ["./llm-grammar.component.scss"],
})
export class LLMGrammarComponent implements OnInit {
  @ViewChild("inputName") inputName: ElementRef<any>;
  title: string;
  isImport = false;
  checkGrammar: CheckGrammarQuery;
  createGrammar: CreateGrammarCommand;
  updateGrammar;
  isMatched: boolean = false;
  grammarForm: UntypedFormGroup;
  intentForm: UntypedFormGroup;
  entityForm: UntypedFormGroup;
  isUpdate: boolean = false;
  filteredLanguage: Observable<any[]>;
  onAdd = new EventEmitter();
  typeGrammar = "";
  fileName = null;
  textUpdate;
  translateGrammar: boolean = false;
  engine: boolean = false;
  engineType: string = "EMBEDDING";
  trainingStatus: string = "NOT-TRAINED";
  isGPTAction: boolean = false;
  fileError = false;
  fileMaximum = false;
  hiddenLanguageFlags: boolean[] = [];
  entity = "";
  entityData = [];
  listIntentValue: string[] = [];
  intentImport: any = null;
  intentImportIndex: any = null;
  currentName: string;
  timeout: any = null;
  isExist: boolean = false;
  isNameLoading: boolean = false;
  maxSize: number;
  loading: boolean = false;
  llm: any = {
    intents: [],
    entities: [
      { display: "name", value: "name" },
      { display: "date", value: "date" },
      { display: "time", value: "time" },
      { display: "phone_number", value: "phone_number" },
      { display: "number", value: "number" },
      { display: "address", value: "address" },
      { display: "email", value: "email" },
      { display: "url", value: "url" },
      { display: "price", value: "price" },
      { display: "job", value: "job" },
      { display: "credit_card_number", value: "credit_card_number" },
    ],
  };
  defaultLanguageCode = "en";
  prompt = "";
  isLoading = false;
  intentPrompt = ``;
  constructor(
    private toastrService: NbToastrService,
    private grammarClient: GrammarClient,
    private formBuilder: RxFormBuilder,
    private dataService: DataService,
    private ref: NbDialogRef<LLMGrammarComponent>,
    private papa: Papa,
    private dialogService: NbDialogService,
    private chatGPTClient: ChatGPTClient
  ) {}
  testLLM() {
    this.dialogService
      .open(TestActionComponent, {
        autoFocus: false,
        context: {
          type: this.engineType,
          name: this.createGrammar.name,
          isGrammar: true,
          intents: this.getListString(this.llm.intents),
          entities: this.getListString(this.llm.entities),
        },
      })
      .onClose.subscribe((rs) => {});
  }
  tagChange(event) {
    const tag = event.tag;
    this.llm.intents[tag.index].value = tag.value;
    this.llm.intents[tag.index].display = tag.value;
  }
  getListString(data) {
    let result = [];
    data.forEach((element) => {
      result.push(element?.value);
    });
    return result;
  }
  generateIntent() {
    if (this.prompt && this.prompt?.trim() != "") {
      let data = new MakeTestModelLLMCommand();
      data.input = this.prompt;
      data.prompt = this.intentPrompt;
      data.model = this.engineType;
      data.type = "intent-llm";
      data.temperature = 0.3;
      this.isLoading = true;
      this.chatGPTClient.testLLM(data).subscribe(
        (rs) => {
          this.isLoading = false;
          try {
            const intents = JSON.parse(rs);
            if (intents.length > 0) {
              this.llm.intents = [];
              intents.forEach((element) => {
                let find = this.llm.intents.find((x) => x.value == element);
                if (!find) {
                  this.llm.intents.push({ display: element, value: element });
                }
              });
            } else {
              this.toastrService.show("No intents found.", `Notification`, {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "danger",
              });
            }
          } catch (error) {
            this.toastrService.show("No intents found.", `Notification`, {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            });
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastrService.show(
            "Something went wrong. Please try again.",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      );
    } else {
      this.toastrService.show("Please enter description.", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  ngOnInit(): void {
    this.createForm();
    this.checkGrammar = new CheckGrammarQuery();
    this.createGrammar = new CreateGrammarCommand();
    this.engineType = "gpt";
    if (this.isUpdate) {
      this.llm = JSON.parse(this.updateGrammar.pattern);
      this.createGrammar.name = this.updateGrammar.name;
      this.createGrammar.id = this.updateGrammar.id;
      this.checkGrammar.pattern = this.updateGrammar.pattern;
      this.translateGrammar = this.updateGrammar.autoTranslate;
      this.engine = this.updateGrammar.engine;
      this.prompt = this.updateGrammar.entity;
      this.engineType = this.updateGrammar.engineType ?? "gpt";
      this.currentName = this.updateGrammar.name;
      this.trainingStatus = this.updateGrammar.status;
    }
  }
  createForm() {
    this.grammarForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          Validators.maxLength(50),
          RxwebValidators.pattern({
            expression: {
              regex: /^[^\\\/:*?"<>|]+$/,
            },
          }),
        ],
      ],
      modal: ["", []],
      prompt: ["", []],
    });
    this.intentForm = this.formBuilder.group({ intent: ["", []] });
    this.entityForm = this.formBuilder.group({ entity: ["", []] });
  }

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.grammarForm.get(fieldName)) {
          const control = this.grammarForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }
  trimObjectValues(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === "string") {
        obj[key] = obj[key]?.trim();
      }
    }
    return obj;
  }
  onSubmit() {
    setTimeout(() => {
      this.createGrammar.engine = this.engine;
      this.createGrammar.autoTranslate = this.translateGrammar;
      this.createGrammar.engineType = this.engineType;
      this.createGrammar.entity = this.prompt;
      if (this.grammarForm.valid) {
        this.createGrammar.pattern = JSON.stringify(this.llm);
        this.createGrammar.type = this.typeGrammar;
        this.createGrammar = this.trimObjectValues(this.createGrammar);
        this.loading = true;
        this.grammarClient.create(this.createGrammar).subscribe({
          next: (result) => {
            if (result !== null) {
              this.showToast(true, "Save grammar successfully");
            } else {
              this.showToast(false, "Save grammar unsuccessfully");
            }
            this.loading = false;
            if (result) {
              this.onAdd.emit({
                name: this.createGrammar.name?.trim(),
                result: result,
                isTranslate: this.translateGrammar,
                engine: this.engine,
              });
            }
          },
          error: (error) => {
            this.loading = false;
            this.showToast(false, "Save grammar unsuccessfully");
            if (error.status == 422) {
              this.setValidationErrors(error.response);
            }
          },
        });
      }
    }, 100);
  }

  dismiss() {
    this.ref.close();
  }

  showToast(result, message) {
    if (result == "required") {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    } else if (result == true) {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
