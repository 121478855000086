<form class="h-full" [formGroup]="inputParamForm" novalidate>
  <div class="d-none">{{ this.formStatus | async }}</div>
  <div style="max-height: 200px !important" formArrayName="nameForm">
    <ng-container
      *ngFor="let nameForm of nameFormGroups.controls; let i = index"
    >
      <div [formGroupName]="i">
        <div class="row">
          <!-- <div class="col-md-12"> -->
          <div class="mb-2">
            <label for="{{ paramName }}" class="form-label label required">{{
              paramName
            }}</label>
            <div class="d-flex custom-gap align-items-start">
              <div class="flex-fill">
                <input
                  id="{{ paramName }}"
                  type="text"
                  formControlName="name"
                  nbInput
                  fullWidth
                  fieldSize="small"
                  placeholder="{{ paramName }}"
                  [status]="
                    nameForm.get('name')?.invalid &&
                    (nameForm.get('name')?.dirty ||
                      nameForm.get('name')?.touched)
                      ? 'danger'
                      : 'basic'
                  "
                />
                <ng-container
                  *ngIf="
                    nameForm.get('name')?.invalid &&
                    (nameForm.get('name')?.dirty ||
                      nameForm.get('name')?.touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    *ngIf="
                      nameForm.get('name')?.errors.required ||
                      nameForm.get('name')?.hasError('whitespace')
                    "
                  >
                    Param Name is required.
                  </p>
                  <p
                    class="caption status-danger"
                    *ngIf="nameForm.get('name')?.errors.maxLength"
                  >
                    Maximum length is 50 characters.
                  </p>
                  <p class="caption status-danger" *ngIf="invalidName">
                    Name already exists.
                  </p>
                  <p
                    class="caption status-danger d-flex align-items-center"
                    *ngIf="
                      nameForm.get('name')?.hasError('variable') &&
                      !nameForm.get('name')?.errors.required &&
                      !nameForm.get('name')?.hasError('whitespace') &&
                      !nameForm.get('name')?.errors.maxLength
                    "
                  >
                    <span>Variable Name is not in the correct format.</span>
                    <nb-icon
                      class="label tooltipsize"
                      nbTooltipClass="multiline-tooltip"
                      nbTooltip="- A variable name must start with a letter or _ or $. &#13;&#10; - A variable name cannot start with a number. &#13;&#10; - A variable name can only contain alpha-numeric characters and underscores (A-z, 0-9, and _ )."
                      nbTooltipPlacement="end"
                      nbTooltipStatus="basic"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon>
                  </p>
                </ng-container>
              </div>
              <button
                *ngIf="canAdd"
                type="button"
                (click)="removeParam(i)"
                outline
                size="small"
                ghost
                nbButton
              >
                <nb-icon icon="minus-circle-outline"></nb-icon>
              </button>

              <nb-icon
                *ngIf="!canAdd && i == 0 && !isVAcomponent"
                class="ms-1"
                style="color: #929bb3; width: 15px; margin-top: 5px"
                nbTooltip="This parameter will represent user input."
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
              <nb-icon
                *ngIf="!canAdd && i == 0 && isVAcomponent"
                class="ms-1"
                style="color: #929bb3; width: 15px; margin-top: 5px"
                icon="question-mark-circle-outline"
                [nbPopover]="templateRef"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
              >
              </nb-icon>
              <nb-icon
                *ngIf="!canAdd && i == 1"
                class="ms-1"
                style="color: #929bb3; width: 15px; margin-top: 5px"
                [nbPopover]="templateRef2"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </ng-container>
    <div class="mb-2" *ngIf="canAdd">
      <button
        type="button"
        (click)="addParam()"
        size="small"
        status="primary"
        class="small mt-2"
        nbButton
      >
        <nb-icon icon="plus-outline"></nb-icon>Add Param
      </button>
    </div>
  </div>
</form>
<ng-template #templateRef>
  <nb-card style="margin-bottom: 0"
    ><nb-card-header
      style="
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
      ><span>Sample Request Body Data</span>
    </nb-card-header>
    <nb-card-body style="padding: 1rem 1.5rem">
      <div class="log-content">
        <pre
          style="margin-bottom: 0"
          [innerHTML]="sampleRequest | prettyjson"
        ></pre>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card style="margin-bottom: 0"
    ><nb-card-header
      style="
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
      ><span>Sample Response Body Data</span>
    </nb-card-header>
    <nb-card-body style="padding: 1rem 1.5rem">
      <div class="log-content">
        <pre
          style="margin-bottom: 0"
          [innerHTML]="sampleResponse | prettyjson"
        ></pre>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
