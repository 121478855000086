<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <div class="mb-2" style="display: flex; align-items: center; gap: 0.5rem">
      <div class="col-md-9" style="flex: 1">
        <label class="form-label label required">Model</label>
        <nb-select
          placeholder="Select Model"
          size="small"
          fullWidth
          [(ngModel)]="data.model"
          class="method-option"
        >
          <nb-option value="gpt">GPT 3.5</nb-option>
          <nb-option value="gemini">Gemini 1.0 Pro</nb-option>
        </nb-select>
      </div>
      <div style="margin-top: 0.5rem; width: 6.5rem">
        <label class="form-label label d-flex mb-0" style="align-items: center"
          ><span>Temperature</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <div class="d-flex">
          <textarea
            type="text"
            nbInput
            maxlength="3"
            fullWidth
            fieldSize="small"
            [(ngModel)]="data.temperature"
            (input)="handleMaxValueTemperature($event)"
            style="height: 32.4px; resize: none"
            onlyDoubleNumber
          ></textarea>
        </div>
      </div>
    </div>
    <div class="mb-2" style="display: flex; gap: 0.5rem">
      <form [formGroup]="formGroup" style="flex: 1">
        <div class="form-label d-flex" style="align-items: center">
          <label class="label required"> Input </label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Information you want to be added to the prompt."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <textarea
          formControlName="text"
          type="text"
          id="input-text"
          nbInput
          fullWidth
          [(ngModel)]="data.text"
          (ngModelChange)="onChange($event)"
          placeholder="Input"
          fieldSize="small"
          [mention]="attributes"
          style="height: 32.4px; max-height: 150px; min-height: 32.4px"
          [mentionConfig]="mentionConfig"
          [status]="
            formGroup.controls['text'].invalid &&
            (formGroup.controls['text'].dirty ||
              formGroup.controls['text'].touched)
              ? 'danger'
              : 'basic'
          "
        ></textarea>
        <ng-container
          *ngIf="
            formGroup.controls['text'].invalid &&
            (formGroup.controls['text'].dirty ||
              formGroup.controls['text'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['text'].hasError('required') ||
              formGroup.controls['text'].hasError('whitespace')
            "
          >
            Input is required.
          </p>
        </ng-container>
      </form>
      <div style="margin-top: 1.7rem">
        <button
          [nbSpinner]="isLoading"
          [disabled]="isLoading"
          nbSpinnerStatus="primary"
          nbButton
          status="primary"
          [disabled]="!data.text || data.text?.trim() == '' || isLoading"
          size="small"
          title="Check Output"
          (click)="makeTest()"
        >
          Check
        </button>
      </div>
    </div>

    <div class="mb-2">
      <div
        class="d-flex justify-content-between"
        style="margin-bottom: 0.15rem"
      >
        <label
          class="form-label label d-flex"
          style="align-items: center; margin-bottom: 0"
        >
          <span>Prompt</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Type the prompt words of the LLM model to get the corresponding answer. Example: Today is {current_day}, if the user's input has a date, please return only the date in 'MM/DD/YYYY' format for response and no text, otherwise NoResponse is returned."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <button
          nbButton
          size="small"
          status="primary"
          ghost
          (click)="optimize()"
          style="padding-left: 0; padding-right: 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17px"
            height="17px"
            fill="currentColor"
            viewBox="0 0 24 24"
            class="vfui_1xa3nyx0"
          >
            <path
              fill-rule="evenodd"
              d="M6.75 15.165a.75.75 0 0 1 .743.648l.007.102v.851h.917a.75.75 0 0 1 .101 1.494l-.101.007-.917-.001v.982a.75.75 0 0 1-1.493.102L6 19.248v-.982h-.917a.75.75 0 0 1-.101-1.492l.101-.007.917-.001v-.851a.75.75 0 0 1 .75-.75Zm8-4.679a.75.75 0 0 1 .75.75 3.163 3.163 0 0 0 3.163 3.162.75.75 0 0 1 .101 1.494l-.101.007A3.163 3.163 0 0 0 15.5 19.06a.75.75 0 0 1-.75.75l-.043-.001a.75.75 0 0 1-.794-.749 3.163 3.163 0 0 0-3.163-3.162.75.75 0 0 1 0-1.5 3.163 3.163 0 0 0 3.162-3.163.75.75 0 0 1 .75-.75l.044.001.044-.001Zm-.045 3.217-.034.056a4.687 4.687 0 0 1-1.384 1.39 4.682 4.682 0 0 1 1.42 1.445c.36-.58.846-1.074 1.419-1.446a4.682 4.682 0 0 1-1.42-1.445ZM7.75 5.417a.75.75 0 0 1 .75.75c0 1.196.97 2.166 2.167 2.166a.75.75 0 0 1 .101 1.494l-.101.006C9.47 9.833 8.5 10.803 8.5 12a.75.75 0 0 1-.75.75l-.041-.001A.75.75 0 0 1 6.917 12c0-1.197-.97-2.167-2.167-2.167a.75.75 0 0 1 0-1.5c1.197 0 2.167-.97 2.167-2.166a.75.75 0 0 1 .75-.75l.04.001a.762.762 0 0 1 .043-.001Zm-.042 2.916-.065.086c-.194.25-.42.473-.671.665.28.213.528.466.737.75.207-.284.456-.537.736-.75a3.67 3.67 0 0 1-.737-.751ZM15.917 4a.75.75 0 0 1 .743.648l.007.102-.001.991h.917a.75.75 0 0 1 .102 1.494l-.102.007-.917-.001v.842a.75.75 0 0 1-1.492.102l-.007-.102-.001-.842h-.916a.75.75 0 0 1-.102-1.493l.102-.006.916-.001V4.75a.75.75 0 0 1 .75-.75Z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span style="margin-left: 0.25rem">Optimize</span>
        </button>
      </div>
      <div style="position: relative" class="llm-prompt-config">
        <textarea
          type="text"
          id="input-text-prompt"
          nbInput
          fullWidth
          [(ngModel)]="data.prompt"
          (ngModelChange)="onChangePrompt($event)"
          placeholder="Prompt"
          fieldSize="small"
          [mention]="attributes"
          style="
            height: 150px;
            max-height: 300px;
            min-height: 150px;
            padding-top: 0.5rem;
          "
          [mentionConfig]="mentionConfig"
        ></textarea>
        <nb-icon
          class="button-expand"
          icon="expand-outline"
          status="basic"
          title="Expand"
          style="cursor: pointer; position: absolute; right: 8px; width: 17px"
          (click)="expand()"
        ></nb-icon>
      </div>
    </div>
    <div class="mb-2" *ngIf="outputReview">
      <label class="form-label label d-flex" style="align-items: center">
        Output Preview
      </label>
      <textarea
        type="text"
        nbInput
        [(ngModel)]="outputReview"
        fullWidth
        placeholder="Output Preview"
        fieldSize="small"
        style="
          height: 75px;
          min-height: 75px;
          max-height: 250px;
          padding-top: 0.5rem;
        "
        readOnly
      ></textarea>
    </div>
    <div class="row">
      <div class="mb-2">
        <div class="form-label" style="display: flex; align-items: center">
          <label class="label d-flex">Output</label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Assign the output to the variable."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>

        <ngx-input-value-static
          placeHolder="Select Output"
          selectAttribute="true"
          [propertyInput]="data.attribute"
          (propertyEmit)="getAttribute($event)"
        >
        </ngx-input-value-static>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
