<div>
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Environment Variable Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltip="Define environment variable for your flow. The environment variable will not lost if the flow goes to the subflow."
            nbTooltipTrigger="hover"
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>

        <span class="line"></span>
      </div>
      <form
        class="h-100"
        [formGroup]="configurationForm"
        novalidate
        (submit)="addVariable()"
      >
        <div class="box-setting add-sentiment" style="flex-wrap: nowrap">
          <div style="width: 15rem">
            <label class="form-label label required" for="input-Name"
              >Name</label
            >
            <input
              id="input-name"
              [(ngModel)]="name"
              type="text"
              nbInput
              fullWidth
              fieldSize="small"
              formControlName="name"
              [status]="invalidName ? 'danger' : 'basic'"
              (ngModelChange)="checkInvalidName($event)"
              placeholder="Name"
              [status]="
                configurationForm.controls['name'].invalid &&
                (configurationForm.controls['name'].dirty ||
                  configurationForm.controls['name'].touched)
                  ? 'danger'
                  : 'basic'
              "
            />
            <ng-container
              *ngIf="
                configurationForm.controls['name'].invalid &&
                (configurationForm.controls['name'].dirty ||
                  configurationForm.controls['name'].touched)
              "
            >
              <p
                class="caption status-danger"
                *ngIf="
                  configurationForm.controls['name'].errors.required ||
                  configurationForm.controls['name'].hasError('whitespace')
                "
              >
                Name is required.
              </p>
              <p
                class="caption status-danger d-flex align-items-center"
                *ngIf="
                  configurationForm.controls['name'].hasError('variable') &&
                  !configurationForm.controls['name'].errors.required &&
                  !configurationForm.controls['name'].hasError('whitespace')
                "
              >
                <span>Name is not in the correct format.</span>
                <nb-icon
                  class="label tooltipsize"
                  nbTooltipClass="multiline-tooltip"
                  nbTooltip="- A variable name must start with a letter or _ or $. &#13;&#10; - A variable name cannot start with a number. &#13;&#10; - A variable name can only contain alpha-numeric characters and underscores (A-z, 0-9, and _ )."
                  nbTooltipPlacement="end"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </p>
              <p
                class="caption status-danger"
                *ngIf="
                  configurationForm.controls['name'].hasError('duplicateName')
                "
              >
                Name already exists.
              </p>
            </ng-container>
          </div>
          <div style="width: 50rem">
            <label class="form-label label" for="text-value-attribute"
              >Value</label
            >
            <textarea
              [(ngModel)]="value"
              type="text"
              nbInput
              fieldSize="small"
              formControlName="value"
              style="
                height: 32.4px;
                max-height: 150px;
                min-height: 32.4px;
                margin-bottom: -4px;
              "
              fullWidth
              id="text-value-attribute"
              (ngModelChange)="handleWhiteSpace('value', $event)"
              placeholder="Value"
            ></textarea>
          </div>
          <div style="margin-top: 1.7rem">
            <button
              type="submit"
              [disabled]="
                !name.trim() || invalidName || configurationForm.invalid
              "
              nbButton
              size="small"
              status="primary"
            >
              {{ actionType }}
            </button>
          </div>
        </div>
      </form>
      <nb-list>
        <nb-list-item
          *ngFor="let configure of configureValue; let i = index"
          [ngClass]="{ 'hidden-variable': configure.default != 2 }"
        >
          <div class="nation-item" style="width: 100%; word-break: break-word">
            <div class="nation-item-name" style="width: 20%">
              <span>{{ configure.value }}</span>
            </div>
            <div
              class="nation-item-name"
              style="width: 66%; word-break: break-word"
            >
              <span>{{ configure.content }}</span>
            </div>
            <div
              class="tag-action"
              style="width: 4%"
              [ngClass]="{ 'hidden-action': currentName == configure.value }"
            >
              <nb-icon
                (click)="handleEdit(configure, $event)"
                icon="edit-2-outline"
                style="font-size: 18px; color: #3367d6; cursor: pointer"
              ></nb-icon>
              <nb-icon
                icon="trash-2-outline"
                (click)="remove(configure, i)"
                style="font-size: 18px; color: #ff4332; cursor: pointer"
              ></nb-icon>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </div>
</div>
