import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
} from "@angular/core";
import { AudioClient, AudioDto } from "../../System-api";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { EventBusService } from "../../@core/utils/eventbus.service";
import { EventData } from "../model/eventdata";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CreateAudioComponent } from "../../portal/customer-role/audios/create/create-audio.component";
import { Observable } from "rxjs";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "simple-audio-player",
  templateUrl: "./simple-audio-player.component.html",
  styleUrls: ["./simple-audio-player.component.scss"],
})
export class SimpleAudioPlayerComponent implements OnInit {
  @ViewChild("mainAudio", { static: true }) mainAudio: ElementRef;
  @ViewChild("searchInput") searchInput: ElementRef<any>;
  selectAudiodto: AudioDto = new AudioDto();
  @Input() events: Observable<void>;
  public eventsSubscription: Subscription;
  newAudiodto: AudioDto = new AudioDto();
  selectedAudio: EventEmitter<any> = new EventEmitter<any>();
  audios: AudioDto[];
  audioData: AudioDto[];
  filteredData: AudioDto[];
  filterColumn = [];
  audioList: any = [];
  audioListId: any = [];
  isPlaying: boolean;
  currentAudio: AudioDto = new AudioDto();
  idNew: number;
  showAddButton: boolean;
  searchText: any;
  isCallFlowAction: boolean = false;
  listAudioInAction: AudioDto[] = [];
  audioSelectedToUpdate: any = null;
  constructor(
    private audioClient: AudioClient,
    private eventBusService: EventBusService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {
    this.filterColumn = ["name"];
  }

  ngOnInit() {
    if (!this.isCallFlowAction) {
      this.loadAudios(0);
    }
    this.eventsSubscription = this.events?.subscribe((rs) => {
      this.audioList = rs;
      if (this.audioList.length > 0) {
        const resultMap: { [key: number]: number } = {};
        this.audioList.forEach((audio, index) => {
          resultMap[audio.id] = index;
        });
        const newArray = Object.values(resultMap).map((i) => this.audioList[i]);
        this.audioList = newArray;
      }

      this.audios = this.audioList;
      this.selectedAudio.emit(this.audioList);
    });
    window.addEventListener("message", (event) => {
      if (event.data.audioId) {
        this.audioSelectedToUpdate = event.data.audioId 
      }
    });
  }
  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let colsAmt = this.audioData.length;
    let keys = Object.keys(this.audioData[0]);
    this.audios = this.filteredData.filter((item) => {
      for (let i = 0; i < colsAmt; i++) {
        if (this.filterColumn.includes(keys[i])) {
          if (
            item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
            !val
          ) {
            return true;
          }
        }
      }
    });
  }
  expandedCurrentAudio(audio) {
    if (audio == this.idNew) {
      return true;
    } else {
      return false;
    }
  }
  loadAudios(idNew) {
    this.audioClient.get().subscribe((audioVm) => {
      if (!this.isCallFlowAction) {
        this.audios = audioVm.audios;
        this.audioData = audioVm.audios;
        this.filteredData = audioVm.audios;
        this.idNew = idNew;
      } else {
        if (this.audioList.length > 0) {
          this.audioListId = [];
          this.audioList.forEach((audioid) => {
            this.audioListId.push(audioid.id);
          });
        }
        this.audioList = [];
        if (this.audioListId.length > 0) {
          this.audioListId.forEach((audioList) => {
            audioVm.audios.forEach((audio) => {
              if (audio.id == audioList) {
                this.audioList.push(audio);
              }
            });
          });
        }
        this.audios = this.audioList;
        this.selectedAudio.emit(this.audioList);
      }
    });

    if (this.selectAudiodto?.id !== undefined) {
      let selectedAudio = this.audios
        .filter((audio) => audio.id == this.selectAudiodto.id)
        .pop();
      this.selectAudiodto = selectedAudio;
      this.clickSelect(this.selectAudiodto);
      this.audios = this.audios.filter(
        (audio) => ![this.selectAudiodto].includes(audio)
      );
      this.audios.unshift(this.selectAudiodto);
    }
  }
  changeAudioSource(audio: AudioDto) {
    if (this.currentAudio != null && this.currentAudio.id == audio.id) {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.mainAudio.nativeElement.src = audio.path + "?" + Date.now();
        this.mainAudio.nativeElement.load();
        this.mainAudio.nativeElement.pause();
        this.mainAudio.nativeElement.currentTime = 0;
      } else {
        this.isPlaying = true;
        this.mainAudio.nativeElement.src = audio.path + "?" + Date.now();
        this.mainAudio.nativeElement.load();
        this.mainAudio.nativeElement.play();
      }
    } else {
      this.currentAudio = audio;
      this.mainAudio.nativeElement.src = audio.path + "?" + Date.now();
      this.mainAudio.nativeElement.load();
      this.mainAudio.nativeElement.play();
    }
  }
  handleUpdateAudio(event, audio) {
    this.currentAudio = audio;
    event.stopPropagation();
    let createAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.newAudiodto,
        createAudio: false,
        audioUpdate: audio,
        title: "Update Audio",
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      if (this.searchInput) {
        this.searchInput.nativeElement.value = "";
      }
      createAudio.close();
      this.loadAudios(rs);
      this.mainAudio.nativeElement.src = audio.path + "?" + Date.now();
      this.mainAudio.nativeElement.load();
    });
  }
  clickSelect(audio: AudioDto) {
    this.selectAudiodto = audio;
    this.eventBusService.emit(new EventData("changeAudio", true));
  }
  playSelectedAudio(audioListId) {
    this.audioListId = audioListId?.split(",");
    if (this.audioList.length > 0) {
      this.audioListId = [];
      this.audioList.forEach((audioid) => {
        this.audioListId.push(audioid.id);
      });
    }
    this.audioList = [];
    if (this.audioListId.length > 0) {
      this.audioListId.forEach((audioList) => {
        this.listAudioInAction.forEach((audio) => {
          if (audio.id == audioList) {
            this.audioList.push(audio);
          }
        });
      });
    }
    this.audios = this.audioList;
    this.selectedAudio.emit(this.audioList);
  }
  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
  getIcon(audio: AudioDto) {
    if (this.selectAudiodto != null && this.selectAudiodto.id == audio.id) {
      return "checkmark-square-2";
    }
    return "square";
  }
  getBackground(audio) {
    if (this.selectAudiodto != null && this.selectAudiodto.id == audio.id) {
      return true;
    }
    return false;
  }
  showModify(event) {
    event.stopPropagation();
    let updateAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.selectAudiodto,
        createAudio: false,
      },
    });
    updateAudio.componentRef.instance.onAdd.subscribe((rs) => {
      if (this.searchInput) {
        this.searchInput.nativeElement.value = "";
      }
      updateAudio.close();
      this.loadAudios(rs);
    });
  }
  showModifyCreate() {
    let createAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.newAudiodto,
        createAudio: true,
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      if (this.searchInput) {
        this.searchInput.nativeElement.value = "";
      }
      createAudio.close();
      this.loadAudios(rs);
    });
  }
  deleteAudio(event, audioDelete: any) {
    event.stopPropagation();
    if (!this.isCallFlowAction) {
      this.dialogService
        .open(ConfirmDialogComponent, {
          autoFocus: true,
          context: {
            question: "Audio '" + audioDelete.name + "' will be deleted. Sure?",
            textYes: "Delete",
            textNo: "Cancel",
            statusYes: "danger",
            statusNo: "basic",
          },
        })
        .onClose.subscribe((isConfirm) => {
          if (isConfirm) {
            this.audioClient.delete(audioDelete.id).subscribe((rs) => {
              this.showToast(
                rs,
                "Delete audio successfully",
                "Delete audio unsuccessfully"
              );
              if (this.searchInput) {
                this.searchInput.nativeElement.value = "";
              }
              this.loadAudios(rs);
            });
          }
        });
    } else {
      this.audios = this.audios.filter(
        (audio) => ![audioDelete].includes(audio)
      );
      this.selectedAudio.emit(this.audios);
    }
  }
  showToast(result, successText, failText) {
    if (result) {
      this.toastrService.show(successText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(failText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.audios, event.previousIndex, event.currentIndex);
    this.selectedAudio.emit(this.audios);
  }
}
