<div class="action-config-wrapper" style="min-width: 500px; max-width: 500px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Setup</ng-template>
        <div class="flex-title mb-3">
          <span class="title">Email Content</span>
          <span class="line"></span>
        </div>
        <nb-radio-group
          [(ngModel)]="data.optionTemplate"
          class="choice-option mt-2 mb-3"
          (ngModelChange)="handleTemplate($event)"
        >
          <nb-radio *ngFor="let option of optionContent" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>

        <div class="row" *ngIf="data.optionTemplate == 1">
          <div class="col-md-12">
            <div class="mb-2">
              <div class="custom-template">
                <ng-select
                  [nbSpinner]="isLoading"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="info"
                  [items]="templates"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="data.template"
                  (change)="changeTemplate($event)"
                  placeholder="Select Template"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <div class="function-item">
                      <span>{{ item.name }}</span>
                      <div class="function-item-action">
                        <nb-icon
                          icon="edit-2-outline"
                          (click)="viewData(item.id)"
                          style="font-size: 15px"
                        ></nb-icon>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <button
                  type="button"
                  (click)="viewData(data.template)"
                  size="small"
                  status="primary"
                  nbButton
                  [disabled]="!data.template"
                >
                  <nb-icon icon="edit-2-outline"></nb-icon>
                </button>
                <button
                  type="button"
                  (click)="addTemplate()"
                  size="small"
                  status="primary"
                  nbButton
                >
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="data.optionTemplate == 2">
          <div class="col-md-12">
            <div class="mb-2">
              <textarea
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="data.templateCustom"
                placeholder="Content"
                fieldSize="small"
                [mention]="attributes"
                [mentionConfig]="mentionConfig"
                style="min-height: 100px"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="flex-title mt-4">
          <span class="title">Variable</span>
          <span class="line"></span>
        </div>
        <div style="width: 500px; font-size: 13px; padding: 5px 0">
          <span>
            The keywords within the Email Content will be replaced the current
            value.
          </span>
        </div>
        <div style="width: 500px; margin-top: 0.5rem">
          <ngx-input-static
            #variableInput
            title="attribute"
            [propertyInput]="data.templateVariable"
            propertyName="Keyword"
            propertyValue="Value"
            (propertyEmit)="getTemplateAttribute($event)"
          ></ngx-input-static>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="mb-2"></div>
          </div>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="flex-title">
          <span class="title">Email Configure</span>
          <span class="line"></span>
        </div>
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="sender-email-name" class="form-label label required"
                  >Sender Email Name</label
                >
                <input
                  formControlName="emailName"
                  id="sender-email-name"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.emailName"
                  placeholder="Sender Email Name"
                  fieldSize="small"
                  [status]="
                    formGroup.controls['emailName'].invalid &&
                    (formGroup.controls['emailName'].dirty ||
                      formGroup.controls['emailName'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  [mention]="attributes"
                  [mentionConfig]="mentionConfig"
                />
                <ng-container
                  *ngIf="
                    formGroup.controls['emailName'].invalid &&
                    (formGroup.controls['emailName'].dirty ||
                      formGroup.controls['emailName'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    style="margin-bottom: 0"
                    *ngIf="
                      formGroup.controls['emailName'].hasError('required') ||
                      formGroup.controls['emailName'].hasError('whitespace')
                    "
                  >
                    Sender Email Name is required.
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="sender-email" class="form-label label required"
                  >Sender Email Address</label
                >
                <input
                  formControlName="emailAddress"
                  id="sender-email"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.emailAddress"
                  placeholder="Sender Email Address"
                  fieldSize="small"
                  [status]="
                    formGroup.controls['emailAddress'].invalid &&
                    (formGroup.controls['emailAddress'].dirty ||
                      formGroup.controls['emailAddress'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  [mention]="attributes"
                  [mentionConfig]="mentionConfig"
                />
                <ng-container
                  *ngIf="
                    formGroup.controls['emailAddress'].invalid &&
                    (formGroup.controls['emailAddress'].dirty ||
                      formGroup.controls['emailAddress'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    style="margin-bottom: 0"
                    *ngIf="
                      formGroup.controls['emailAddress'].hasError('required') ||
                      formGroup.controls['emailAddress'].hasError('whitespace')
                    "
                  >
                    Sender Email Address is required.
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="email-subject" class="form-label label required"
                  >Email Subject</label
                >
                <input
                  formControlName="emailSubject"
                  id="email-subject"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.emailSubject"
                  placeholder="Email Subject"
                  fieldSize="small"
                  [status]="
                    formGroup.controls['emailSubject'].invalid &&
                    (formGroup.controls['emailSubject'].dirty ||
                      formGroup.controls['emailSubject'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  [mention]="attributes"
                  [mentionConfig]="mentionConfig"
                />
                <ng-container
                  *ngIf="
                    formGroup.controls['emailSubject'].invalid &&
                    (formGroup.controls['emailSubject'].dirty ||
                      formGroup.controls['emailSubject'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    style="margin-bottom: 0"
                    *ngIf="
                      formGroup.controls['emailSubject'].hasError('required') ||
                      formGroup.controls['emailSubject'].hasError('whitespace')
                    "
                  >
                    Email Subject is required.
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
        <div class="flex-title">
          <span class="title">Email Recipients</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-2">
              <div>
                <label for="email-subject" class="form-label label required"
                  >Recipients
                </label>
                <nb-icon
                  class="ms-2"
                  style="color: #929bb3; width: 15px;"
                  nbTooltip="Input recipient will receive the email. You can input multiple ones with ',' separator."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
              <tag-input
                id="reci"
                style="width: 100%"
                addOnBlur="true"
                modelAsStrings="true"
                [(ngModel)]="data.emailRecipients"
                secondaryPlaceholder="Add a recipient"
                placeholder="Add a recipient"
                [onTextChangeDebounce]="10"
                (onTextChange)="onTextChange($event)"
                [editable]="true"
                [trimTags]="true"
              >
              </tag-input>
            </div>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
