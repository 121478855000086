<nb-card style="margin-bottom: 0; width: 650px; height: 95vh">
  <nb-card-header style="padding: 0.5rem 1.5rem; padding-right: 0.7rem">
    <div class="d-flex justify-content-between" style="align-items: center">
      <div class="title-page-wrapper">
        <span>{{ name }}</span>
      </div>
      <div
        class="d-flex justify-content-between"
        style="align-items: center; gap: 0.5rem"
      >
        <button
          nbButton
          size="medium"
          status="basic"
          ghost
          (click)="optimize()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17px"
            height="17px"
            fill="currentColor"
            viewBox="0 0 24 24"
            class="vfui_1xa3nyx0"
          >
            <path
              fill-rule="evenodd"
              d="M6.75 15.165a.75.75 0 0 1 .743.648l.007.102v.851h.917a.75.75 0 0 1 .101 1.494l-.101.007-.917-.001v.982a.75.75 0 0 1-1.493.102L6 19.248v-.982h-.917a.75.75 0 0 1-.101-1.492l.101-.007.917-.001v-.851a.75.75 0 0 1 .75-.75Zm8-4.679a.75.75 0 0 1 .75.75 3.163 3.163 0 0 0 3.163 3.162.75.75 0 0 1 .101 1.494l-.101.007A3.163 3.163 0 0 0 15.5 19.06a.75.75 0 0 1-.75.75l-.043-.001a.75.75 0 0 1-.794-.749 3.163 3.163 0 0 0-3.163-3.162.75.75 0 0 1 0-1.5 3.163 3.163 0 0 0 3.162-3.163.75.75 0 0 1 .75-.75l.044.001.044-.001Zm-.045 3.217-.034.056a4.687 4.687 0 0 1-1.384 1.39 4.682 4.682 0 0 1 1.42 1.445c.36-.58.846-1.074 1.419-1.446a4.682 4.682 0 0 1-1.42-1.445ZM7.75 5.417a.75.75 0 0 1 .75.75c0 1.196.97 2.166 2.167 2.166a.75.75 0 0 1 .101 1.494l-.101.006C9.47 9.833 8.5 10.803 8.5 12a.75.75 0 0 1-.75.75l-.041-.001A.75.75 0 0 1 6.917 12c0-1.197-.97-2.167-2.167-2.167a.75.75 0 0 1 0-1.5c1.197 0 2.167-.97 2.167-2.166a.75.75 0 0 1 .75-.75l.04.001a.762.762 0 0 1 .043-.001Zm-.042 2.916-.065.086c-.194.25-.42.473-.671.665.28.213.528.466.737.75.207-.284.456-.537.736-.75a3.67 3.67 0 0 1-.737-.751ZM15.917 4a.75.75 0 0 1 .743.648l.007.102-.001.991h.917a.75.75 0 0 1 .102 1.494l-.102.007-.917-.001v.842a.75.75 0 0 1-1.492.102l-.007-.102-.001-.842h-.916a.75.75 0 0 1-.102-1.493l.102-.006.916-.001V4.75a.75.75 0 0 1 .75-.75Z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span style="margin-left: 0.25rem">Optimize</span>
        </button>
        <button
          (click)="dismiss()"
          type="button"
          type="button"
          ghost
          size="medium"
          nbButton
        >
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div></div
  ></nb-card-header>
  <nb-card-body style="overflow: hidden">
    <textarea
      style="
        height: 100%;
        resize: none;
        padding-top: 0.5rem;
        background-color: #f7f9fc;
        border-color: #e4e9f2;
      "
      #inputString
      nbInput
      type="text"
      [placeholder]="name"
      fullWidth
      [(ngModel)]="data"
      fieldSize="small"
      [mentionConfig]="mentionConfig"
    ></textarea>
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; gap: 0.5rem; justify-content: end">
      <button nbButton size="small" (click)="cancel()">
        <nb-icon icon="close-outline"></nb-icon> <span>Cancel</span>
      </button>
      <button nbButton size="small" status="primary" (click)="save()">
        <nb-icon icon="save-outline"></nb-icon>
        <span> Save</span>
      </button>
    </div>
  </nb-card-footer>
</nb-card>
