<nb-card
  style="margin-bottom: 0; width: 500px; height: 60vh"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-card-header style="padding: 0.5rem 1.5rem; padding-right: 0.7rem">
    <div class="d-flex justify-content-between" style="align-items: center">
      <div class="title-page-wrapper">
        <span>{{ name }}</span>
      </div>
      <div
        class="d-flex justify-content-between"
        style="align-items: center; gap: 0.5rem"
      >
        <button
          (click)="retry()"
          type="button"
          type="button"
          ghost
          size="medium"
          nbButton
        >
          <nb-icon icon="refresh-outline"></nb-icon>
          Retry
        </button>
      </div>
    </div></nb-card-header
  >
  <nb-card-body style="overflow: hidden">
    <textarea
      style="height: 100%; resize: none; padding-top: 0.5rem"
      #inputString
      nbInput
      type="text"
      [placeholder]="name"
      fullWidth
      [(ngModel)]="promptOptimize"
      fieldSize="small"
    ></textarea>
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; gap: 0.5rem; justify-content: end">
      <button nbButton size="small" (click)="cancel()">
        <span>Cancel</span>
      </button>
      <button nbButton size="small" status="primary" (click)="save()">
        <span>Use</span>
      </button>
    </div>
  </nb-card-footer>
</nb-card>
