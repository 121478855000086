<div [formGroup]="parentForm">
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Speech To Text Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Choose the speech-to-text service you want to use for your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>

        <span class="line"></span>
      </div>
      <div class="box-setting">
        <div>
          <label
            class="form-label label required"
            for="input-voice"
            style="display: block"
            >Service</label
          >
          <nb-select
            placeholder="Service"
            size="small"
            formControlName="speechToTextService"
            [(ngModel)]="callSetting.speechToTextService"
            (selectedChange)="handleSelect($event)"
            style="width: 12rem"
          >
            <nb-option value="Amazon">Amazon</nb-option>
            <nb-option value="Azure">Azure</nb-option>
            <nb-option value="Deepgram">Deepgram</nb-option>
            <nb-option value="Google">Google</nb-option>
            <nb-option value="Primas">Primas</nb-option>
            <nb-option value="Soundhound">Soundhound</nb-option>
            <!-- <nb-option value="Kaldi">Kaldi (work offline)</nb-option>
            <nb-option value="Whisper">Whipser (work offline)</nb-option> -->
          </nb-select>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Transcribe Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Choose the transcribe service you want to use for your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>

        <span class="line"></span>
      </div>
      <div class="box-setting">
        <div>
          <label
            class="form-label label required"
            for="input-voice"
            style="display: block"
            >Service</label
          >
          <nb-select
            placeholder="Service"
            size="small"
            formControlName="transcribeService"
            [(ngModel)]="callSetting.transcribeService"
            (selectedChange)="handleSelectTranscribe($event)"
            style="width: 12rem"
          >
            <nb-option value="Amazon">Amazon</nb-option>
            <nb-option value="Azure">Azure</nb-option>
            <nb-option value="Google">Google</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
  </div>
</div>
