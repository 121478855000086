import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NbDialogService, NbPopoverDirective } from "@nebular/theme";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import {
  CallFlowClient,
  CallFlowDto,
  CallSettingDto,
} from "../../../../System-api";
import { DataService } from "../../../../@core/utils/data.service";

@Component({
  selector: "footer-action",
  templateUrl: "action-footer.component.html",
  styleUrls: ["./action-footer.component.scss"],
})
export class ActionFooterComponent implements OnInit {
  @Input() previewable: boolean = false;
  @Input() type: "cards" | "forms" | "none" = "none";
  @Input() data: any;

  @Output() handleCancelAction = new EventEmitter<any>();
  @Output() handleSaveAction = new EventEmitter<any>();
  @Output() handleBackCardAction = new EventEmitter<any>();
  @Output() handleNextCardAction = new EventEmitter<any>();

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  dataCallFlow = new CallFlowDto();
  callSettings = new CallSettingDto();
  logoDefault = "../../../../../../assets/images/logo-bot-default.png";
  constructor(
    private dialogService: NbDialogService,
    public dataService: DataService
  ) {
    this.dataCallFlow = dataService.CallFlow;
    this.callSettings = dataService.CallSetting;
  }

  ngOnInit() {}
  handleCancelActions() {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Are you sure you want to cancel?",
          textYes: "Yes",
          textNo: "Cancel",
          statusYes: "primary",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.handleCancelAction.emit(true);
        }
      });
  }
  handleSaveActions() {
    this.handleSaveAction.emit(true);
  }

  handleTogglePopoverActions() {
    if (this.popover && this.popover.isShown) {
      this.popover.hide();
    } else {
      this.popover.rebuild();
      this.popover.show();
    }
  }
  handleImageError(event: any) {
    event.target.src = "../../../../../assets/images/default-image-small.jpg";
  }
  nextCard() {
    this.handleNextCardAction.emit();
  }

  backCard() {
    this.handleBackCardAction.emit();
  }
}
