<div
  class="action-button"
  style="display: flex; align-items: center; gap: 0.5rem"
  [ngStyle]="{
    'justify-content':
      previewable && data.cards.length > 0 ? 'space-between' : 'flex-end'
  }"
>
  <button
    *ngIf="previewable && data.cards.length > 0"
    type="button"
    size="small"
    nbButton
    status="primary"
    outline
    [nbPopover]="popoverTemplateRef"
    nbPopoverTrigger="noop"
    nbPopoverPlacement="left"
    (click)="handleTogglePopoverActions()"
  >
    <nb-icon icon="eye-outline"></nb-icon>
    Preview
  </button>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
    "
  >
    <button nbButton size="small" (click)="handleCancelActions()">
      <nb-icon icon="close-outline"></nb-icon>
      <span>Cancel</span>
    </button>
    <button
      nbButton
      size="small"
      status="primary"
      (click)="handleSaveActions()"
    >
      <nb-icon icon="save-outline"></nb-icon>
      <span> Save</span>
    </button>
  </div>
</div>
<ng-template #popoverTemplateRef>
  <nb-card style="margin-bottom: 0 !important; width: 500px; height: 650px">
    <nb-card-header class="infor-header-2">
      <nb-user
        onlyPicture
        [picture]="callSettings.botLogo?.trim() || logoDefault"
      >
      </nb-user>
      <span class="botname">{{
        callSettings.botName || dataCallFlow.name || "Bot Name"
      }}</span>
    </nb-card-header>
    <nb-card-body>
      <div
        class="chat-area"
        *ngIf="
          callSettings.botGreetingMessages &&
          callSettings.botGreetingMessages.trim() != ''
        "
      >
        <nb-user
          style="transform: scale(0.8)"
          onlyPicture
          [picture]="callSettings.botLogo?.trim() || logoDefault"
        >
        </nb-user>
        <div class="chat-box">
          <span class="chat-name">{{
            callSettings.botName || dataCallFlow.name || "Bot Name"
          }}</span>
          <span class="chat-text">{{ callSettings.botGreetingMessages }}</span>
        </div>
      </div>
      <div class="chat-area chat-area-user">
        <div class="chat-box chat-box-user">
          <span class="chat-name">You</span>
          <span class="chat-text-user" [style.background]="color"
            >Show me your products</span
          >
        </div>
      </div>
      <div class="chat-area">
        <nb-user
          style="transform: scale(0.8)"
          onlyPicture
          [picture]="callSettings.botLogo?.trim() || logoDefault"
        >
        </nb-user>
        <div class="chat-box" *ngIf="type == 'cards'" style="margin-top: 4px">
          <span class="chat-name">{{
            callSettings.botName || dataCallFlow.name || "Bot Name"
          }}</span>
          <div
            class="mb-4"
            class="mb-4"
            style="
              display: flex;
              align-items: center;
              margin-bottom: 0 !important;
              position: relative;
              width: 300px;
            "
          >
            <div
              class="button-action-card"
              style="transform: translateX(-50%); position: absolute"
            >
              <button
                [hidden]="data.cardIndex == 0"
                nbButton
                size="small"
                shape="round"
                (click)="backCard()"
              >
                <nb-icon icon="arrow-ios-back-outline"></nb-icon>
              </button>
            </div>
            <nb-card
              style="
                flex: 1;
                margin-top: 0.5rem;
                border-radius: 10px;
                overflow: hidden;
              "
              status="basic"
              class="card-display shadow-sm"
            >
              <nb-card-header style="padding: 0">
                <div
                  [nbSpinner]="isLoadingUpdate"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="primary"
                >
                  <div>
                    <img
                      [src]="
                        data.cards[data.cardIndex].image ||
                        '../../../assets/images/default-error.jpg'
                      "
                      (error)="handleImageError($event)"
                      alt=""
                      [ngStyle]="{
                        'aspect-ratio':
                          data.imageRatio == 'horizontal' ? '19/10' : '1/1'
                      }"
                      style="max-width: 300px; width: 100%; object-fit: cover"
                    />
                  </div>
                </div>
              </nb-card-header>
              <nb-card-body>
                <div class="mb-2">
                  <span class="preview-clamp preview-title">{{
                    data.cards[data.cardIndex].title
                  }}</span>
                </div>
                <div class="mb-2">
                  <span class="preview-clamp preview-description">{{
                    data.cards[data.cardIndex].description
                  }}</span>
                </div>
                <div
                  class="mb-2"
                  style="display: flex; flex-direction: column; gap: 0.4rem"
                >
                  <div
                    *ngFor="
                      let item of data.cards[data.cardIndex].buttons;
                      let k = index
                    "
                  >
                    <a
                      style="text-decoration: none; color: unset; width: 100%"
                      *ngIf="item.type == 'open-web'"
                      [href]="item.value"
                      target="_blank"
                      type="button"
                      nbButton
                      size="small"
                    >
                      <span style="max-width: 100px; overflow: hidden">{{
                        item.text
                      }}</span>
                    </a>
                    <a
                      style="text-decoration: none; color: unset; width: 100%"
                      *ngIf="item.type == 'make-call'"
                      [href]="'tel:{{item.value}}'"
                      type="button"
                      nbButton
                      size="small"
                    >
                      <span style="max-width: 100px; overflow: hidden">{{
                        item.text
                      }}</span>
                    </a>
                    <button
                      style="width: 100%"
                      *ngIf="item.type == 'text'"
                      type="button"
                      nbButton
                      size="small"
                    >
                      <span style="max-width: 100px; overflow: hidden">{{
                        item.text
                      }}</span>
                    </button>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
            <div
              class="button-action-card"
              style="transform: translateX(50%); position: absolute; right: 0"
            >
              <button
                nbButton
                size="small"
                shape="round"
                [hidden]="data.cardIndex == data.cards.length - 1"
                (click)="nextCard()"
              >
                <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
