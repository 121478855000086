import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { NgSelectComponent } from "@ng-select/ng-select";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { map, Observable, of } from "rxjs";
import { DataService } from "../../../../@core/utils/data.service";
import { EventBusService } from "../../../../@core/utils/eventbus.service";
import {
  noWhitespaceValidator,
  ValidInput,
  VariableValidator,
} from "../../../../@core/utils/helpers";
import { ShareVariableService } from "../../../../@core/utils/variables.service";
import { DialogAttributeComponent } from "../dialog-attribute/dialog-attribute.component";

@Component({
  selector: "ngx-input-value-static",
  templateUrl: "./input-value-static.component.html",
  styleUrls: ["./input-value-static.component.scss"],
})
export class InputValueStaticComponent implements OnInit, OnDestroy, OnChanges {
  @Output() propertyEmit = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();
  @Input() propertyInput: string;
  @Input() placeHolder: string;
  @Input() selectAttribute: boolean;
  @Input() errorMessage: string;
  filteredOptions$: Observable<any[]> = of(this.dataService.ivrAttribute);

  @ViewChildren(NgSelectComponent)
  selectComponents: QueryList<NgSelectComponent>;
  properties: string;
  formGroup: UntypedFormGroup;
  oldPropertyInput: string;
  constructor(
    public dataService: DataService,
    private eventBusService: EventBusService,
    private dialogService: NbDialogService,
    private formBuilder: RxFormBuilder,
    private shareVariableService: ShareVariableService
  ) {
    this.eventBusService.on("clickConfirmTestFunction", (isTrue: boolean) => {
      if (isTrue) {
        this.propertyEmit.emit(this.properties);
      }
    });
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      property: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stringToProperty();
  }

  ngOnDestroy(): void {
    this.propertyEmit.emit(this.properties);
  }
  ngOnInit() {
    this.filteredOptions$ = this.shareVariableService.data;
    if (!this.placeHolder) {
      this.placeHolder = "Select Variable";
    }
    this.filteredOptions$ = this.filteredOptions$.pipe(
      map((options) => [
        { default: "VARIABLE", value: "" },
        ...this.dataService.ivrAttribute,
      ])
    );
    if (this.propertyInput) {
      let dataExist = this.dataService.ivrAttribute.find(
        (x) => x.value == this.propertyInput
      );
      if (
        !dataExist &&
        this.propertyInput?.trim() != "" &&
        this.propertyInput != null
      ) {
        this.filteredOptions$ = this.filteredOptions$.pipe(
          map((options) => [
            ...options,
            { default: 0, value: this.propertyInput },
          ])
        );
      }
    }

    this.stringToProperty();
  }
  selectVariable(value) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.shareVariableService.updateListVariables([
              ...this.dataService.ivrAttribute,
            ]);
            this.properties = rs;
            this.oldPropertyInput = rs;
          } else if (this.oldPropertyInput) {
            this.properties = this.oldPropertyInput;
          } else {
            this.properties =
              this.propertyInput !== "" && this.propertyInput !== null
                ? this.propertyInput
                : null;
          }
          this.valueChange.emit(rs);
        });
    } else {
      if (value && typeof value == "string") {
        this.oldPropertyInput = value?.trim();
        this.properties = value?.trim(); //custom variable
      }
      if (value && typeof value == "object") {
        this.oldPropertyInput = value?.value?.trim();
        this.properties = value?.value?.trim(); //custom variable
      }
      this.valueChange.emit(this.properties);
    }
  }
  addTagFn = (term) => {
    let newStr = term?.trim().replace(/[{}]/g, "");
    let validateNewStr = new FormControl(newStr, ValidInput.variable);
    if (validateNewStr.valid) {
      this.dataService.ivrAttribute.push({
        default: 0,
        value: newStr,
      });
      this.dataService.ivrAttributeArray.push(newStr);
      this.shareVariableService.updateListVariables([
        ...this.dataService.ivrAttribute,
      ]);
    }
    return newStr;
  };
  stringToProperty() {
    if (this.propertyInput != "") {
      this.properties = this.propertyInput;
    }
  }
}
