import { Component, OnInit, Directive, HostListener } from "@angular/core";
import { NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { SetAttribute } from "../../../../@core/model/set-attribute";
import { MultipleConditions } from "../../../../@core/model/multiple-conditions";

@Component({
  selector: "multiple-conditions",
  templateUrl: "./multiple-conditions.component.html",
  styleUrls: ["./multiple-conditions.component.scss"],
})
export class MultipleConditionsComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  data: MultipleConditions = new MultipleConditions();
  cancelAction = false;
  constructor(protected windowRef: NbWindowRef) {}
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
    }
  }

  getAttribute($event) {
    this.data.attribute = $event;
  }
}
